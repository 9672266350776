import React from "react";
import { Box, Typography } from "@mui/material";
import { AdminDetailUserInfoResponse } from "@veris-health/user-ms/lib/v1";
import { useNavigate } from "react-router-dom";
import { orderBy } from "lodash";
import { VrsAvatar } from "@veris-health/web-core";
import { Routes } from "../../../routes-config";

export interface UserCardProps {
  profile: AdminDetailUserInfoResponse | undefined;
}
const UserRoles: { [key: string]: number } = {
  superadmin: 0,
  admin: 1,
  hospitaladmin: 2,
};
export const UserCard = ({ profile }: UserCardProps): JSX.Element => {
  const navigate = useNavigate();

  const getUserRole = () => {
    const mappedRoles = profile?.user_roles?.map((role) => {
      return {
        ...role,
        hierarchy: UserRoles[role.role_name],
      };
    });
    const [firstRole] = orderBy(mappedRoles, "hierarchy", "asc");
    return firstRole;
  };

  return (
    <>
      <Box
        data-test-hook="user-card"
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => navigate(Routes.PROFILE)}
      >
        <Box>
          <VrsAvatar name={profile && profile.name ? profile.name : ""} />
        </Box>
        <Box>
          <Typography variant="subtitle2" mb={0.5} ml={2}>
            {profile?.name}
          </Typography>
          <Typography
            variant="bodySemibold"
            mb={0.5}
            ml={2}
            color={(theme) => theme.veris.colors.amethyst.normal}
          >
            {getUserRole()?.role_name || ""}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
