import { Box, Grid, Link, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { VrsButton } from "@veris-health/web-core";
import { useNavigate } from "react-router-dom";
import { TechRoleEnum } from "@veris-health/user-ms/lib/v1";
import { VrsActionLayout } from "../../ui/components/VrsActionLayout";
import { InviteUserContainer } from "../Users/containers/InviteUserContainer";
import { SendRelease } from "./components/SendRelease";
import { useAuthenticatedUserRoles, useSuperAdmin } from "../../context/profile";
import { CSVPatientsImport } from "./components/CSVPatientsImport";

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getTermsAndConditionsAsync } from "../shared/slices/authSlice";
import { TagsDialog } from "./components/TagsDialog";
import InvitePatientContainer from "./containers/InvitePatientContainer";
import { AddHospital } from "./components/AddHospital";
import { replaceRouteParam } from "../../routes-config";

export function ActionsContainer(): JSX.Element {
  const isSuperAdmin = useSuperAdmin();
  const authenticatedUserRoles = useAuthenticatedUserRoles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTermsAndConditionsAsync());
  }, [dispatch]);

  const canAccessMioHistory = authenticatedUserRoles.some((role) =>
    [TechRoleEnum.Superadmin, TechRoleEnum.Admin].includes(role as TechRoleEnum),
  );
  return (
    <Box
      width="100%"
      py={3}
      px={2}
      pb={1}
      height="100%"
      gap={4}
      display="flex"
      flexDirection="column"
    >
      <VrsActionLayout title="Invites">
        <Grid item xs={4} p={2}>
          <InviteUserContainer />
          <Typography
            variant="bodySemibold"
            color={(theme) => theme.veris.colors.neutrals["grey-4"]}
            fontWeight={400}
          >
            Invite Medical Staff or Technical Staff type of user
          </Typography>
        </Grid>
        <Grid item xs={4} p={2}>
          <InvitePatientContainer />
          <Typography
            variant="bodySemibold"
            color={(theme) => theme.veris.colors.neutrals["grey-4"]}
            fontWeight={400}
          >
            Create Patient
          </Typography>
        </Grid>
        <Grid item xs={4} p={2}>
          <CSVPatientsImport />
          <Typography
            variant="bodySemibold"
            color={(theme) => theme.veris.colors.neutrals["grey-4"]}
            fontWeight={400}
          >
            Import Patients from a CSV file
          </Typography>
        </Grid>
      </VrsActionLayout>
      {isSuperAdmin && (
        <VrsActionLayout title="Add Hospital">
          <Grid item xs={12} p={2}>
            <AddHospital />
          </Grid>
          <Grid item xs={12} px={2}>
            <Typography
              variant="bodySemibold"
              color={(theme) => theme.veris.colors.neutrals["grey-4"]}
              fontWeight={400}
            >
              Add new hospital to the Veris System
            </Typography>
          </Grid>
        </VrsActionLayout>
      )}
      <VrsActionLayout title="Release Notes">
        <Grid item xs={12} p={2}>
          {isSuperAdmin && <SendRelease />}
        </Grid>
        <Grid item xs={12} px={2}>
          <Link
            href={import.meta.env.VITE_RELEASENOTES_FILE_LINK || ""}
            variant="h6"
            target="_blank"
            sx={{
              color: (theme) => theme.veris.colors.neutrals["grey-4"],
              textDecoration: "underline",
            }}
          >
            Release Notes
          </Link>
        </Grid>
      </VrsActionLayout>

      <VrsActionLayout title="Tags">
        <Grid item xs={12} p={2}>
          <TagsDialog />
          <Typography
            variant="bodySemibold"
            color={(theme) => theme.veris.colors.neutrals["grey-4"]}
            fontWeight={400}
          >
            Create, delete or edit predefined tags
          </Typography>
        </Grid>
      </VrsActionLayout>

      {canAccessMioHistory && (
        <VrsActionLayout title="Mio">
          <Grid item xs={12} p={2}>
            <VrsButton
              buttonType="primary"
              onClick={() => {
                navigate(replaceRouteParam("MIO_HISTORY", ":hubSN", ""));
              }}
              variant="contained"
              color="primary"
            >
              Mio History
            </VrsButton>
          </Grid>
        </VrsActionLayout>
      )}
    </Box>
  );
}
