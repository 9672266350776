import React from "react";
import { Box, styled, Theme, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconName, VrsButton, VrsIcon } from "@veris-health/web-core";
import { Routes } from "../../routes-config";

const PageNotFoundContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const iconTopPosition = () => {
    if (isTablet) {
      return "-24%";
    }
    return isSmallScreen ? "-23%" : "-22%";
  };

  const iconSizeCalc = () => {
    if (isTablet) {
      return 1320;
    }
    return isSmallScreen ? 1480 : 1930;
  };

  const CustomTypography404title = styled(Typography)(({ theme }) => ({
    color: theme.veris.colors.amethyst.normal,
    fontSize: "16.25rem",
    fontWeight: 600,
    lineHeight: "13.75rem",
    [theme.breakpoints.down("xl")]: {
      fontSize: "12.5rem",
      lineHeight: "9.4rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "10rem",
      lineHeight: "6.25rem",
    },
  }));

  const CustomTypography404Subtitle = styled(Typography)(({ theme }) => ({
    color: theme.veris.colors.neutrals["grey-dark"],
    fontSize: "3.15rem",
    lineHeight: "4.4rem",
    textAlign: "center",
    maxWidth: "max-content",
    fontWeight: 600,
    [theme.breakpoints.down("xl")]: {
      fontSize: "2.5rem",
      lineHeight: "3rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.75rem",
      lineHeight: "2.125rem",
    },
  }));

  return (
    <>
      <Box
        height="100vh"
        overflow="hidden"
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Box
          sx={{
            position: "relative",
            top: iconTopPosition(),
          }}
        >
          <VrsIcon name={IconName.Error404} size={iconSizeCalc()} />
        </Box>
        <Box
          sx={{
            position: "absolute",
          }}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <CustomTypography404title m={1} variant="h1">
            404
          </CustomTypography404title>
          <CustomTypography404Subtitle variant="h1">
            Ooops something is missing
          </CustomTypography404Subtitle>
          <Box m={2}>
            <Typography
              variant="h2"
              sx={{
                color: (theme) => theme.veris.colors.neutrals["grey-mid"],
                fontWeight: 400,
                textAlign: "center",
              }}
            >
              The page you are looking for wasn&apos;t found. <br /> We suggest you to go back to
              the main page.
            </Typography>
          </Box>
          <VrsButton
            buttonType="primary"
            sx={{ mt: isSmallScreen ? "2.15rem" : "3.15rem" }}
            onClick={() => {
              navigate(Routes.DASHBOARD);
            }}
          >
            Take me to the main page
          </VrsButton>
        </Box>
      </Box>
    </>
  );
};

export default PageNotFoundContainer;
