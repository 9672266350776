import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import {
  HospitalInfo,
  HospitalItem,
  PatientBasicInfo,
  UserTypeResponse,
} from "@veris-health/user-ms/lib/v1";
import { VrsButton, VrsConfirmationModals } from "@veris-health/web-core";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { extractErrorMessage } from "../../shared/helpers";
import { addStaffHospital, unsetStaffHospitals } from "../api/hospitalsApi";
import { removeMembersFromCareTeam } from "../api/teamApi";
import { selectHospitals, setShouldUpdateUser } from "../userSlice";
import { HospitalAutocomplete } from "./HospitalAutocomplete";

export interface EditHospitalsProps {
  userId: number;
  hospitals: HospitalItem[];
  userType: UserTypeResponse;
  assignedPatients?: PatientBasicInfo[];
}

export default function EditHospitals({
  userId,
  hospitals,
  userType,
  assignedPatients,
}: EditHospitalsProps): JSX.Element {
  const [editing, setEditing] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedHospitals, setSelectedHospitals] = useState<{ value: string; label: string }[]>(
    [],
  );
  const allHospitals = useAppSelector(selectHospitals);
  const hospitalOptions = useMemo(() => {
    return (
      allHospitals.map((hosp) => ({
        value: `${hosp.id}`,
        label: hosp.name || "",
      })) || []
    );
  }, [allHospitals]);
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();

  const dispatch = useAppDispatch();

  async function onSave(
    addArray: HospitalInfo[],
    deleteArray: HospitalInfo[],
    membersToRemove: (PatientBasicInfo | undefined)[],
  ) {
    try {
      const removeCareTeamMembers = membersToRemove?.map(async (patient) => {
        if (patient?.id) {
          await removeMembersFromCareTeam(userId, patient.id);
        }
      });
      await Promise.all(removeCareTeamMembers);
      const addHospitals = addArray.map((item) => addStaffHospital(item.id, userId, userType));
      const removeHospitals = deleteArray.map((item) =>
        unsetStaffHospitals(item.id, userId, userType),
      );
      await Promise.all([...addHospitals, ...removeHospitals]);
      dispatch(setShouldUpdateUser(true));
      SnackbarUtils.success("Hospitals edited successfully");
    } catch (err) {
      const errorMsg = extractErrorMessage(err);
      SnackbarUtils.error(errorMsg || "Something went wrong, please try again");
    } finally {
      setEditing(false);
      setLoading(false);
      setShowConfirmation(false);
    }
  }

  useEffect(() => {
    if (hospitals)
      setSelectedHospitals(
        hospitals.map((hospital) => ({
          label: hospital.name,
          value: String(hospital.id),
        })),
      );
  }, [hospitals, editing]);

  const handleSubmit = async (confirmed?: boolean) => {
    const selectedHospitalIds = selectedHospitals?.map(({ value }) => +value) || [];
    const initialHospitalIds = hospitals?.map(({ id }) => id) || [];

    const hospitalsToAdd = allHospitals.filter(
      ({ id }) => !initialHospitalIds.includes(id) && selectedHospitalIds.includes(id),
    );
    const hospitalsToRemove = allHospitals.filter(
      ({ id }) => !selectedHospitalIds.includes(id) && initialHospitalIds.includes(id),
    );

    if (hospitalsToAdd.length || hospitalsToRemove.length) {
      const membersToRemove = hospitalsToRemove.flatMap((el) =>
        assignedPatients?.filter((patient) => patient.hospital_id === el.id),
      );
      if (assignedPatients && membersToRemove.length > 0 && !confirmed) setShowConfirmation(true);
      else onSave(hospitalsToAdd, hospitalsToRemove, membersToRemove);
    } else setEditing(false);
  };

  return (
    <>
      <Box pt={2}>
        {!editing && (
          <VrsButton
            buttonType="primary"
            onClick={() => {
              setEditing(true);
            }}
          >
            {hospitals && hospitals.length > 0 ? "Edit Hospitals" : "+Add Hospital"}
          </VrsButton>
        )}
      </Box>
      <Box
        my={2}
        px={2}
        py={2}
        sx={{
          backgroundColor: theme.veris.colors.neutrals.white,
          border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
          borderRadius: ({ spacing }) => spacing(1),
        }}
      >
        <Box display="flex" gap={1} py={1} alignItems="center">
          <Typography color={theme.veris.colors.neutrals["grey-3"]} variant="h6" minWidth="150px">
            Hospitals
          </Typography>
          {editing ? (
            <>
              {allHospitals && (
                <HospitalAutocomplete
                  options={hospitalOptions}
                  placeholder="Select hospital"
                  handleChange={(e, option) => {
                    setSelectedHospitals(option || []);
                  }}
                  autoCompleteId="hospital-select"
                  loading={false}
                  values={selectedHospitals}
                />
              )}
            </>
          ) : (
            <>
              {hospitals.map((item) => (
                <Typography variant="body" key={item.id}>
                  {item.name}
                </Typography>
              ))}
            </>
          )}
        </Box>
        {editing && (
          <Box display="flex" justifyContent="end" gap={2} py={2}>
            <VrsButton buttonType="tertiary" onClick={() => handleSubmit()} disabled={loading}>
              Save
              {loading && <CircularProgress sx={{ marginLeft: theme.spacing(2) }} size={16} />}
            </VrsButton>
            <VrsButton buttonType="secondary" onClick={() => setEditing(false)}>
              Cancel
            </VrsButton>
          </Box>
        )}
      </Box>
      <VrsConfirmationModals
        isOpen={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        dialogHeader="Warning!"
        dialogContent="When switching hospitals, assigned patients from those hospitals will be removed!"
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        onCancel={() => setShowConfirmation(false)}
        onConfirm={() => handleSubmit(true)}
      />
    </>
  );
}
