/* eslint-disable camelcase */
import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { get } from "lodash";
import { getToken, Messaging } from "firebase/messaging";
import { MarkAsRead, NotificationView } from "@veris-health/communication-ms/lib/v1";
import dayjs from "dayjs";
import { RootState } from "../../store";
import {
  fetchAllNotifications,
  getNotificationDetails,
  markNotificationAsRead,
  registerFirebaseToken,
  unregisterFirebaseToken,
} from "./api/notificationsApi";
import { Status } from "../shared/interfaces";
import { localizedLogout, logout } from "../shared/slices/authSlice";
import { terminateTokens } from "../../api/utils/localStorage";

export const enum NotificationFilter {
  ViewAll = "all",
  Patients = "patient",
  CareTeam = "careteam",
  System = "system",
}

export interface NotificationState {
  showNotificationDetails: boolean;
  patientId: string | undefined;
  notifications: NotificationView[];
  status: Status;
  totalCount: number;
  unreadCount: number;
  notificationsFetchingOffset: number;
}

const initialState: NotificationState = {
  showNotificationDetails: false,
  patientId: undefined,
  notifications: [],
  status: "idle",
  totalCount: 0,
  unreadCount: 0,
  notificationsFetchingOffset: 1,
};

// Action creator

export const appendNewNotification = createAction(
  "notifications/appendNewNotification",
  (notificationView: NotificationView) => ({
    payload: { ...notificationView },
  }),
);

// Async Thunk(s)

export const setupFirebaseToken = createAsyncThunk(
  "notifications/setupFirebaseToken",
  async ({ userId, firebaseMessaging }: { userId: number; firebaseMessaging: Messaging }) => {
    const token = await getToken(firebaseMessaging, {
      vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
    });
    await registerFirebaseToken(userId, token);
    return token;
  },
);

export const unregisterFirebaseTokenAsync = createAsyncThunk(
  "notifications/unregisterFirebaseToken",
  async (userId: number) => {
    try {
      const response = await unregisterFirebaseToken(userId);
      return response;
    } catch (e) {
      return new Error();
    } finally {
      terminateTokens();
    }
  },
);

export const loadNotificationsDataAsync = createAsyncThunk(
  "notifications/fetchNotificationsData",
  async (
    {
      userId,
      offset,
    }: {
      offset?: number;
      userId: number;
    },
    { getState },
  ): Promise<{
    notifications: NotificationView[];
    totalCount: number;
    unreadCount: number;
    offset: number;
  }> => {
    const response = await fetchAllNotifications(userId, undefined, undefined, offset);
    const { notifications } = getState() as RootState;
    const new_notifications = response.items?.map((notification) => ({
      ...notification,
      payload: {
        ...notification.payload,
        data: {
          ...notification.payload.data,
          name: get(notification, "payload.data.full_name", ""),
          picture: get(notification, "payload.data.image", ""),
          diagnosis: {
            cancerType: get(notification, "payload.data.cancer_type", ""),
            cancerStage: get(notification, "payload.data.cancer_stage", ""),
          },
          type: notification.type,
        },
      },
    }));
    const allNotifications = [
      ...notifications.notifications,
      ...(new_notifications as unknown as NotificationView[]),
    ];
    return {
      notifications: offset
        ? (allNotifications as NotificationView[])
        : (new_notifications as unknown as NotificationView[]),
      totalCount: response.total,
      unreadCount: response.unread_count,
      offset: response.offset,
    };
  },
);

export const markNotificationAsReadAsync = createAsyncThunk(
  "notifications/markNotificationAsRead",
  async ({ userId, markAsRead }: { userId: number; markAsRead: MarkAsRead }) => {
    const markNotificationAsReadResponse = await markNotificationAsRead(userId, markAsRead);
    return markNotificationAsReadResponse.status;
  },
);

export const fetchNotificationDetailsAsync = createAsyncThunk<
  NotificationView,
  { vrsNotificationId?: string },
  { rejectValue: { vrsNotificationId?: string } }
>(
  "notifications/fetchNotificationDetailsAsync",
  async ({ vrsNotificationId }, { getState, dispatch, rejectWithValue }) => {
    const { auth } = getState() as RootState;
    const { userId } = auth;
    try {
      const notificationDetails = await getNotificationDetails({ userId, vrsNotificationId });
      const new_notification = {
        ...notificationDetails,
        payload: {
          ...notificationDetails.payload,
          data: {
            ...notificationDetails.payload.data,
            name: get(notificationDetails, "payload.data.full_name", ""),
            picture: get(notificationDetails, "payload.data.image", ""),
            diagnosis: {
              cancerType: get(notificationDetails, "payload.data.cancer_type", ""),
              cancerStage: get(notificationDetails, "payload.data.cancer_stage", ""),
            },
            type: notificationDetails.type,
          },
        },
      };
      dispatch(appendNewNotification(new_notification as unknown as NotificationView));
      return notificationDetails;
    } catch (error) {
      return rejectWithValue({ vrsNotificationId });
    }
  },
);

// Reducers

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    increaseUnreadCount: (state) => {
      state.unreadCount += 1;
    },
    showNotificationDetails: (state, action: PayloadAction<string>) => {
      state.showNotificationDetails = true;
      state.patientId = action.payload;
    },

    appendNewNotification: (state, action: PayloadAction<NotificationView>) => {
      state.notifications.unshift(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadNotificationsDataAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadNotificationsDataAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.notifications = action.payload.notifications;
        state.totalCount = action.payload.totalCount;
        state.unreadCount = action.payload.unreadCount;
        state.notificationsFetchingOffset = action.payload.offset;
      })
      .addCase(markNotificationAsReadAsync.fulfilled, (state, payload) => {
        const readNotificationIndex = state.notifications.findIndex((notification) =>
          payload.meta.arg.markAsRead.notification_ids.includes(notification.notification_id),
        );
        state.notifications[readNotificationIndex].read = true;
        state.unreadCount -= 1;
      })
      .addCase(unregisterFirebaseTokenAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(unregisterFirebaseTokenAsync.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(unregisterFirebaseTokenAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(localizedLogout, () => {
        return initialState;
      });
  },
});

// Actions

export const { increaseUnreadCount } = notificationsSlice.actions;

export const selectAllNotifications = ({ notifications }: RootState): NotificationView[] =>
  notifications.notifications;

export const selectNotificationDetails = ({ notifications }: RootState): boolean =>
  notifications.showNotificationDetails;

export const selectNotificationsStatus = ({ notifications }: RootState): Status =>
  notifications.status;

export const selectTotalCount = ({ notifications }: RootState): number => notifications.totalCount;

export const selectUnreadCount = ({ notifications }: RootState): number =>
  notifications.unreadCount;

export const selectNotificationsFetchingOffset = ({ notifications }: RootState): number =>
  notifications.notificationsFetchingOffset;

export const selectFilteredNotificationsfromYesterday = createSelector(
  [selectAllNotifications],
  (notifications) => {
    return notifications.filter((notification: NotificationView) =>
      dayjs(notification.date_created).isSame(dayjs().subtract(1, "day"), "day"),
    );
  },
);

export const selectFilteredNotificationsfromToday = createSelector(
  [selectAllNotifications],
  (notifications) => {
    return notifications.filter((notification: NotificationView) =>
      dayjs(notification.date_created).isSame(dayjs(), "day"),
    );
  },
);

export const selectFilteredNotificationsfromEarlier = createSelector(
  [selectAllNotifications],
  (notifications) => {
    return notifications.filter((notification: NotificationView) =>
      dayjs(notification.date_created).isBefore(dayjs().subtract(1, "day")),
    );
  },
);

export default notificationsSlice.reducer;
