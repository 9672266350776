import { AnonymousCredential, BlobServiceClient } from "@azure/storage-blob";
import {
  AppApiV1ModelsChatModelsStatusResponse,
  ChatThreadInfo,
  CommunicationIdentityTokenResponse,
  CreateThreadResponse,
  ThreadParticipant,
  URLResponse,
  Permissions,
  FileAttachment,
  CommunicationUserView,
  LimitOffsetPageFileAttachment,
  UnreadThreadsResponse,
  LimitOffsetPageChatThreadInfo,
  SupportChatInfoRequest,
} from "@veris-health/communication-ms/lib/v1";
import { chatApiV1, chatAttachmentsApiV1 } from "../../../api";
import { retryIfFailed } from "../../../api/retry";

export interface VrsAzureBlobSASToken {
  blobEndpoint: string;
  containerName: string;
  blobName: string;
  sharedAccessSigniture: string;
}

export const fetchChatApiEndpoint = async (): Promise<URLResponse> => {
  const response = await chatApiV1.getEndpointUrl();
  return response.data;
};

export const fetchChatUserAccessToken = async (
  id: number,
): Promise<CommunicationIdentityTokenResponse> => {
  const response = await chatApiV1.getChatToken(id);
  return response.data;
};

export const searchChatThreads = async (
  userId: string,
  searchPhrase?: string,
): Promise<ChatThreadInfo[]> => {
  const response = await chatApiV1.searchThreads(Number(userId), searchPhrase);
  return response.data;
};

export const joinChatThread = async (
  id: number,
  threadId: string,
  participants: ThreadParticipant[],
): Promise<AppApiV1ModelsChatModelsStatusResponse> => {
  const response = await chatApiV1.addParticipantsToThread(id, threadId, participants);
  return response.data;
};

export const joinSupportThread = async (
  patientId: number,
  threadId: string,
  userId: ThreadParticipant[],
): Promise<AppApiV1ModelsChatModelsStatusResponse> => {
  const response = await chatApiV1.addSupportParticipantToThread(patientId, threadId, userId);
  return response.data;
};

export const getChatThreadDetails = async (
  threadId: string,
  userId?: string,
): Promise<ChatThreadInfo> => {
  const response = await retryIfFailed(() => chatApiV1.threadDetails(Number(userId), threadId), 1);
  return response.data;
};

export const createChatThread = async (
  id: number,
  participants: ThreadParticipant[],
): Promise<CreateThreadResponse> => {
  const response = await chatApiV1.createThreadWithParticipants(id, participants);
  return response.data;
};

export const generateSas = async (
  userId: number,
  fileName: string,
  permissions: Permissions,
): Promise<VrsAzureBlobSASToken> => {
  const response = await chatAttachmentsApiV1.generateSas(userId, {
    file_name: fileName,
    permissions,
  });
  const {
    blob_endpoint: blobEndpoint,
    container_name: containerName,
    blob_name: blobName,
    shared_access_signature: sharedAccessSigniture,
  } = response.data;
  return {
    blobEndpoint: blobEndpoint.substring(0, blobEndpoint.length - 1), // remove trailing slash "/" at the end of the endpoint,
    containerName,
    blobName,
    sharedAccessSigniture,
  };
};

export const uploadFile = async ({
  blobEndpoint,
  containerName,
  blobName,
  sharedAccessSigniture,
  file,
}: VrsAzureBlobSASToken & { file: File }): Promise<void> => {
  const blobServiceClient = new BlobServiceClient(
    `${blobEndpoint}?${sharedAccessSigniture}`,
    new AnonymousCredential(),
  );
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  await blockBlobClient.uploadData(file);
};

export const downloadFile = async ({
  blobEndpoint,
  containerName,
  blobName,
  sharedAccessSigniture,
}: VrsAzureBlobSASToken): Promise<Blob | undefined> => {
  const blobServiceClient = new BlobServiceClient(
    `${blobEndpoint}?${sharedAccessSigniture}`,
    new AnonymousCredential(),
  );
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  const blobDownloadResponse = await blockBlobClient.download();

  return blobDownloadResponse.blobBody;
};

export const uploadFileApi = async (
  userId: number,
  threadId: string,
  file: unknown,
): Promise<FileAttachment> => {
  const response = await chatAttachmentsApiV1.uploadFileAttachment(userId, threadId, file);
  return response.data;
};

export const fetchAllChatAttachmentsForThread = async (
  userId: number,
  threadId: string,
  offset?: number,
  limit?: number,
): Promise<LimitOffsetPageFileAttachment> => {
  const response = await chatAttachmentsApiV1.listThreadAttachments(
    userId,
    threadId,
    limit,
    offset,
  );
  return response.data;
};

export const getCommunicationUserInfo = async (userId: number): Promise<CommunicationUserView> => {
  const response = await chatApiV1.communicationUserInfo(userId);
  return response.data;
};

export const getThreadsWithUnreadInfo = async (userId: number): Promise<UnreadThreadsResponse> => {
  const response = await chatApiV1.getUnreadPerThread(userId);
  return response.data;
};

export const getPrivateChatForPatient = async (userId: number): Promise<CreateThreadResponse> => {
  const response = await chatApiV1.createPrivateThread(userId);
  return response.data;
};

export const getSupportThreads = async (
  filterTerm?: string,
  limit?: number,
  offset?: number,
): Promise<LimitOffsetPageChatThreadInfo> => {
  const response = await chatApiV1.getSupportChatThreads(filterTerm, limit, offset);
  return response.data;
};

export const createSupportChat = async (patientId: number): Promise<CreateThreadResponse> => {
  const response = await chatApiV1.createSupportThread(patientId);
  return response.data;
};

export const updateSupportChat = async (
  patientId: number,
  threadId: string,
  info: SupportChatInfoRequest,
): Promise<SupportChatInfoRequest> => {
  const response = await chatApiV1.updateSupportChatInfo(patientId, threadId, info);
  return response.data;
};

export const getSupportChatDetails = async (
  patientId: number,
  threadId: string,
): Promise<ChatThreadInfo> => {
  const response = await chatApiV1.supportThreadDetails(patientId, threadId);
  return response.data;
};
