import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { VrsSelect } from "@veris-health/web-core";
import { billApiV1 } from "../../../api";
import { useAppSelector } from "../../../hooks/useAppSelector";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { selectHospitals } from "../../Users/userSlice";
import { exportCSVFile } from "../../../utils/files";
import PeriodPicker from "../components/PeriodPicker";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { fetchHospitalPeriods, selectPeriods, selectPeriodsStatus } from "../billingSlice";
import { VrsStatusDisplay } from "../../../ui/components/VrsStatusDisplay";
import { BillingReportsTypeEnum, billingReportType } from "../components/constants";
import { VrsTelemedicineRange } from "../api/billingApi";
import { AutoComplete } from "../../../ui/components/Autocomplete/Autocomplete";

const HospitalBillingContainer = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedHospital, setSelectedHospital] = useState<{ value: string; label: string }>();
  const [selectedPeriod, setSelectedPeriod] = useState<string>();
  const [selectedBillReport, setSelectedBillReport] = useState<BillingReportsTypeEnum>();
  const [selectedTelemedincineRange, setSelectedTelemedincineRange] = useState<
    VrsTelemedicineRange | undefined
  >();
  const hospitals = useAppSelector(selectHospitals);
  const periods = useAppSelector(selectPeriods);
  const periodsStatus = useAppSelector(selectPeriodsStatus);
  const dispatch = useAppDispatch();
  const isCorrespondingPeriodSelected = () => {
    return !!(
      selectedBillReport &&
      ((selectedBillReport === BillingReportsTypeEnum.telemedicineSummary &&
        selectedTelemedincineRange) ||
        selectedPeriod)
    );
  };

  const resetRanges = () => {
    setSelectedPeriod(undefined);
    setSelectedTelemedincineRange(undefined);
  };

  const onHospitalChange = (value: { value: string; label: string } | null) => {
    if (value) setSelectedHospital(value);
    setSelectedBillReport(undefined);
    resetRanges();
  };

  const onExportCSV = () => {
    if ((selectedPeriod || selectedTelemedincineRange) && selectedHospital && selectedBillReport) {
      const selectedPeriodRange =
        selectedTelemedincineRange || periods?.find((period) => period.id === selectedPeriod);
      const billTypeLabel = billingReportType.find(
        (bill) => bill.value === selectedBillReport,
      )?.label;
      if (selectedPeriodRange) {
        setLoading(true);
        billApiV1[selectedBillReport](
          Number(selectedHospital.value),
          selectedPeriodRange.start,
          selectedPeriodRange.end,
        )
          .then((res) => {
            const responseMessage = res.data.message;
            if (responseMessage) {
              SnackbarUtils.info(responseMessage);
            } else {
              exportCSVFile(
                res.data.toString(),
                `${billTypeLabel} start:${selectedPeriodRange.start} end:${selectedPeriodRange.end}`,
              );
              SnackbarUtils.success("File Successfully exported");
            }
          })
          .catch(() => {
            SnackbarUtils.error("File exporting failed");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const onBillReportChange = (value: BillingReportsTypeEnum) => {
    setSelectedBillReport(value);
    resetRanges();
    if (value !== BillingReportsTypeEnum.telemedicineSummary && selectedHospital) {
      dispatch(fetchHospitalPeriods({ id: +selectedHospital.value }));
    }
  };

  return (
    <Grid container py={2} px={2}>
      <Grid item xs={4}>
        {hospitals && (
          <AutoComplete
            options={
              hospitals.map((hosp) => ({
                value: `${hosp.id}`,
                label: hosp.name || "",
              })) || []
            }
            placeholder="Select hospital"
            handleChange={(e, option) => onHospitalChange(option)}
            autoCompleteId="hospital-select"
            loading={false}
          />
        )}
        {selectedHospital && (
          <>
            <Box pb={2}>
              <VrsSelect
                options={billingReportType}
                onSelected={(value) => onBillReportChange(value as BillingReportsTypeEnum)}
                placeholder="Select Type of Bill Report"
                value={selectedBillReport}
              />
            </Box>
            <VrsStatusDisplay
              status={periodsStatus}
              errorRetryFunction={() => {
                if (selectedHospital.value)
                  dispatch(fetchHospitalPeriods({ id: +selectedHospital.value }));
              }}
            >
              {selectedBillReport && (
                <PeriodPicker
                  handleExportCSV={onExportCSV}
                  handlePeriodChange={(period: string) => {
                    setSelectedPeriod(period);
                    setSelectedTelemedincineRange(undefined);
                  }}
                  isTelemedicineView={
                    selectedBillReport === BillingReportsTypeEnum.telemedicineSummary
                  }
                  periods={periods}
                  disabledExport={
                    !isCorrespondingPeriodSelected() || !selectedBillReport || loading
                  }
                  onRangeChange={({ start, end }) => {
                    setSelectedTelemedincineRange({ start, end });
                    setSelectedPeriod(undefined);
                  }}
                />
              )}
            </VrsStatusDisplay>
            {loading && (
              <Typography>*It might take some time to finish the download of the file</Typography>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default HospitalBillingContainer;
