import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SymptomEvent } from "@veris-health/virtual-doc-ms/lib/v1";
import { RootState } from "../../../store";
import { localizedLogout, logout } from "./authSlice";

export interface uiState {
  activeTab: ActiveTab;
  leftSideBarExpanded: boolean;
  previewExpanded: boolean;
  expandedSymptomDetails?: SymptomEvent;
}

export type ActiveTab = {
  tabIndex: number;
};

const initialState: uiState = {
  activeTab: {
    tabIndex: 0,
  },
  leftSideBarExpanded: true,
  previewExpanded: false,
};

export const ui = createSlice({
  name: "UI",
  initialState,
  reducers: {
    setActiveNavBarTab: (state, action: PayloadAction<number>) => {
      state.activeTab = {
        tabIndex: action.payload,
      };
    },
    toggleLeftSideBar: (state, action: PayloadAction<boolean>) => {
      state.leftSideBarExpanded = action.payload;
    },
    togglePreviewBar: (state, action: PayloadAction<boolean>) => {
      state.previewExpanded = action.payload;
    },
    toggleDrawers: (state) => {
      if (drawersStatusMatch(state)) {
        state.previewExpanded = !state.previewExpanded;
      } else {
        state.leftSideBarExpanded = !state.leftSideBarExpanded;
        state.previewExpanded = !state.previewExpanded;
      }
    },
    addExpandedSymptomDetails: (state, action: PayloadAction<SymptomEvent | undefined>) => {
      state.expandedSymptomDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState;
    });
    builder.addCase(localizedLogout, () => {
      return initialState;
    });
  },
});

export const {
  setActiveNavBarTab,
  toggleLeftSideBar,
  togglePreviewBar,
  toggleDrawers,
  addExpandedSymptomDetails,
} = ui.actions;

export const selectActiveNavBarTab = ({ ui: uiState }: RootState): ActiveTab => uiState.activeTab;

export const selectToggleLeftSideBar = ({ ui: uiState }: RootState): boolean =>
  uiState.leftSideBarExpanded;

export const selectTogglePreviewBar = ({ ui: uiState }: RootState): boolean =>
  uiState.previewExpanded;

export const selectExpandedSymptomDetails = ({
  ui: uiState,
}: RootState): SymptomEvent | undefined => uiState.expandedSymptomDetails;

function drawersStatusMatch(state: uiState) {
  return (
    (state.previewExpanded && state.leftSideBarExpanded) ||
    (!state.previewExpanded && !state.leftSideBarExpanded)
  );
}

export default ui.reducer;
