export function downloadCsvData(data: string): void {
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([data], { type: "text/csv" });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement("a");

  // Passing the blob downloading url
  a.setAttribute("href", url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute("download", `download.csv`);

  // Performing a download with click
  a.click();
}

export function downloadZipFile(blob: BlobPart) {
  const url = window.URL.createObjectURL(new Blob([blob], { type: "application/zip" }));
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
}

export function exportCSVFile(items: string, fileTitle: string): void {
  const exportedFilename = `${fileTitle}.csv` || "export.csv";

  const blob = new Blob([items], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", exportedFilename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
