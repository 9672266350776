import { Box, Stack, Typography } from "@mui/material";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { GetEducationalVideo } from "@veris-health/virtual-doc-ms/lib/v1";
import { VrsSelect } from "@veris-health/web-core";

import { useAppSelector } from "../../../hooks/useAppSelector";
import { VrsPlainSearch } from "../../../ui/components/VrsPlainSearch";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { EducationalVideosTable } from "../components/EducationalVideosTable";
import { AddVideo } from "../components/AddVideo";
import {
  deleteEducationalVideoAsync,
  getAvailableSymptomsForVideosAsync,
  getEducationalVideosAsync,
  selectSymptoms,
} from "../educationalVideosSlice";

interface TableFiltersProps {
  searchFilter: string;
  symptomsFilter: string;
  onValueChange: (search: string) => void;
  setSearchFilter: (symptom: string) => void;
  setSymptomsFilter: (search: string) => void;
  disabledSearch: boolean;
  selectedVideo: GetEducationalVideo | undefined;
  onReset: () => void;
}

const TableFilters = ({
  searchFilter,
  symptomsFilter,
  onValueChange,
  setSymptomsFilter,
  setSearchFilter,
  disabledSearch,
  selectedVideo,
  onReset,
}: TableFiltersProps) => {
  const symptoms = useAppSelector(selectSymptoms);
  const symptomsOptions = [{ label: "All", value: "" }].concat(
    symptoms.map((symptom) => ({
      label: symptom,
      value: `${symptom}`,
    })),
  );

  return (
    <Box display="flex" mb={2.5}>
      <Stack direction="row" spacing={2}>
        <Box>
          <Typography variant="body1">Search by Title or Description</Typography>
          <VrsPlainSearch
            onValueChange={(value) => onValueChange(value)}
            value={searchFilter}
            disabled={disabledSearch}
          />
        </Box>
        <Box>
          <Typography variant="body1">Symptoms</Typography>
          <VrsSelect
            outerSx={{ width: "150px" }}
            options={symptomsOptions}
            onSelected={(filterOption) => {
              setSymptomsFilter(filterOption);
              setSearchFilter("");
            }}
            value={symptomsFilter}
          />
        </Box>
      </Stack>
      <Stack ml="auto" direction="row" spacing={2}>
        <Box display="flex" pt={2.2}>
          <AddVideo
            selectedVideo={selectedVideo}
            onReset={onReset}
            clearFilters={() => {
              setSearchFilter("");
              setSymptomsFilter("");
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export function EducationalVideosContainer(): JSX.Element {
  const [selectedVideo, setSelectedVideo] = useState<GetEducationalVideo | undefined>();
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [symptomsFilter, setSymptomsFilter] = useState<string>("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEducationalVideosAsync({ searchTerm: undefined, symptom: symptomsFilter }));
  }, [dispatch, symptomsFilter]);

  useEffect(() => {
    dispatch(getAvailableSymptomsForVideosAsync());
  }, [dispatch]);

  const handleEdit = (video: GetEducationalVideo): void => {
    setSelectedVideo(video);
  };

  const handleDelete = (video: GetEducationalVideo): void => {
    dispatch(deleteEducationalVideoAsync(video.id));
  };

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      if (query.length >= 3) {
        dispatch(getEducationalVideosAsync({ searchTerm: query, symptom: symptomsFilter }));
      } else if (query === "")
        dispatch(getEducationalVideosAsync({ searchTerm: undefined, symptom: symptomsFilter }));
    }, 300),
    [symptomsFilter],
  );

  const onValueChange = (value: string) => {
    setSearchFilter(value);
    debouncedSearch(value.trim());
  };

  return (
    <Box p={2}>
      <TableFilters
        onReset={() => setSelectedVideo(undefined)}
        selectedVideo={selectedVideo}
        symptomsFilter={symptomsFilter}
        searchFilter={searchFilter}
        setSymptomsFilter={setSymptomsFilter}
        setSearchFilter={setSearchFilter}
        onValueChange={onValueChange}
        disabledSearch={symptomsFilter !== ""}
      />

      <EducationalVideosTable
        onDelete={handleDelete}
        onEdit={handleEdit}
        fetchEducationalVideos={() =>
          dispatch(getEducationalVideosAsync({ searchTerm: undefined, symptom: undefined }))
        }
      />
    </Box>
  );
}
