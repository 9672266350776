import React from "react";
import { VrsButton } from "@veris-health/web-core";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { VrsActionLayout } from "../../ui/components/VrsActionLayout";
import { Routes } from "../../routes-config";

export const EducationalArea = () => {
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="column" gap={2} m={1}>
      <VrsActionLayout title="Educational Videos">
        <VrsButton
          sx={{ marginLeft: "0.75rem" }}
          onClick={() => navigate(Routes.EDUCATIONAL_VIDEOS)}
          buttonType="tertiary"
        >
          Go to Educational Videos
        </VrsButton>
      </VrsActionLayout>
      <VrsActionLayout title="Educational Articles">
        <VrsButton
          sx={{ marginLeft: "0.75rem" }}
          onClick={() => navigate(Routes.ARTICLES)}
          buttonType="tertiary"
        >
          Go to Educational Articles
        </VrsButton>
      </VrsActionLayout>
    </Box>
  );
};
