import { ProfileRole, TechRoleEnum, UserRoleTypeProfile } from "@veris-health/user-ms/lib/v1";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { getRoles } from "../features/Users/api/usersApi";
import { useAuthenticatedUserRoles } from "./profile";

export const RolesContextData = React.createContext<{ roles: ProfileRole[] }>({ roles: [] });

export const RolesContext = ({ children }: { children: ReactNode }): JSX.Element => {
  const [roles, setRoles] = useState<ProfileRole[]>([]);
  const authenticatedUserRoles = useAuthenticatedUserRoles();

  useEffect(() => {
    const hasAccessToLoadRoles = [
      TechRoleEnum.Hospitaladmin,
      TechRoleEnum.Admin,
      TechRoleEnum.Superadmin,
    ].some((item) => authenticatedUserRoles.includes(item));

    if (hasAccessToLoadRoles) {
      getRoles().then((res) => setRoles(res));
    }
  }, [authenticatedUserRoles]);

  return <RolesContextData.Provider value={{ roles }}>{children}</RolesContextData.Provider>;
};

export const useRoles = (profileType?: UserRoleTypeProfile): ProfileRole[] => {
  const { roles } = useContext(RolesContextData);
  if (profileType) {
    return roles.filter((role) => role.profile_type === profileType);
  }
  return roles;
};
