import React from "react";
import { BrowserRouter, Navigate, Route, Routes as RoutesSwitch } from "react-router-dom";
import { TechRoleEnum } from "@veris-health/user-ms/lib/v1";
import PrivateRoute from "./PrivateRoute";
import PageNotFoundContainer from "../features/PageNotFound/PageNotFoundContainer";
import { Routes } from "../routes-config";
import { LoginRegistrationContainer } from "../features/LoginRegistration";
import { MobileLandingPage } from "../features/LoginRegistration/MobileLandingPage";
import { UsersTableContainer } from "../features/Users/UserTableContainer";
import { NotificationsListContainer } from "../features/Notifications";
import UserDetailsContainer from "../features/Users/containers/UserDetailsContainer";
import { ActionsContainer } from "../features/Actions/ActionsContainer";
import { EducationalVideosContainer } from "../features/EducationalArea/containers/EducationalVideosContainer";
import { ProfileContainer } from "../features/Profile/ProfileContainer";
import BillingContainer from "../features/Billing/BillingContainer";
import { MioDataPointsContainer } from "../features/MioDatapoints/containers/MioDatapointsContainer";
import BillingDashboardContainer from "../features/BillingDashboard/BillingDashboardContainer";
import { HubsContainer } from "../features/MioDatapoints/containers/HubHistoryContainer";
import { MioHistoryContainer } from "../features/MioDatapoints/containers/MioHistoryContainer";
import CommunicationContainer from "../features/Communication/CommunicationContainer";
import { ArticlesList } from "../features/EducationalArea/containers/ArticlesList";
import { ArticleEditorContainer } from "../features/EducationalArea/containers/ArticleEditorContainer";
import { EducationalArea } from "../features/EducationalArea/EducationalArea";
import { SurveysList } from "../features/Surveys/containers/SurveysList";
import { SurveyContainer } from "../features/Surveys/containers/SurveyContainer";
import { ImplantContainer } from "../features/Implant/ImplantContainer";
import ExportsContainer from "../features/Exports/ExportsContainer";

const privateRoutesArray = [
  {
    path: Routes.DASHBOARD,
    element: <UsersTableContainer />,
    roles: [TechRoleEnum.Hospitaladmin, TechRoleEnum.Admin, TechRoleEnum.Superadmin],
  },
  {
    path: Routes.USER_DETAILS,
    element: <UserDetailsContainer />,
    roles: [TechRoleEnum.Hospitaladmin, TechRoleEnum.Admin, TechRoleEnum.Superadmin],
  },
  {
    path: Routes.MIO_DATA_POINTS,
    element: <MioDataPointsContainer />,
    roles: [TechRoleEnum.Hospitaladmin, TechRoleEnum.Admin, TechRoleEnum.Superadmin],
  },
  {
    path: Routes.HUB_HISTORY,
    element: <HubsContainer />,
    roles: [TechRoleEnum.Hospitaladmin, TechRoleEnum.Admin, TechRoleEnum.Superadmin],
  },
  {
    path: Routes.MIO_HISTORY,
    element: <MioHistoryContainer />,
    roles: [TechRoleEnum.Admin, TechRoleEnum.Superadmin],
  },
  {
    path: Routes.NOTIFICATIONS_DETAILS,
    element: <NotificationsListContainer />,
    roles: [TechRoleEnum.Hospitaladmin, TechRoleEnum.Admin, TechRoleEnum.Superadmin],
  },
  {
    path: Routes.ACTIONS,
    element: <ActionsContainer />,
    roles: [TechRoleEnum.Hospitaladmin, TechRoleEnum.Admin, TechRoleEnum.Superadmin],
  },
  {
    path: Routes.PROFILE,
    element: <ProfileContainer />,
    roles: [
      TechRoleEnum.Hospitaladmin,
      TechRoleEnum.Admin,
      TechRoleEnum.Superadmin,
      TechRoleEnum.Billingexpert,
      TechRoleEnum.Implantmanufacturer,
    ],
  },
  {
    path: Routes.BILLING,
    element: <BillingContainer />,
    roles: [TechRoleEnum.Hospitaladmin, TechRoleEnum.Admin, TechRoleEnum.Superadmin],
  },
  {
    path: Routes.BILLING_REPORTS,
    element: <BillingDashboardContainer />,
    roles: [TechRoleEnum.Billingexpert, TechRoleEnum.Superadmin],
  },
  {
    path: Routes.EDUCATIONAL_VIDEOS,
    element: <EducationalVideosContainer />,
    roles: [TechRoleEnum.Superadmin],
  },
  {
    path: Routes.SUPPORT,
    element: <CommunicationContainer />,
    roles: [TechRoleEnum.Superadmin],
  },
  {
    path: Routes.SUPPORT_ID,
    element: <CommunicationContainer />,
    roles: [TechRoleEnum.Superadmin],
  },
  {
    path: Routes.EDUCATIONAL_AREA,
    element: <EducationalArea />,
    roles: [TechRoleEnum.Superadmin],
  },
  {
    path: Routes.ARTICLES,
    element: <ArticlesList />,
    roles: [TechRoleEnum.Hospitaladmin, TechRoleEnum.Admin, TechRoleEnum.Superadmin],
  },
  {
    path: Routes.SURVEYS,
    element: <SurveysList />,
    roles: [TechRoleEnum.Superadmin],
  },
  {
    path: Routes.ARTICLE_DETAILS,
    element: <ArticleEditorContainer />,
    roles: [TechRoleEnum.Hospitaladmin, TechRoleEnum.Admin, TechRoleEnum.Superadmin],
  },
  {
    path: Routes.SURVEY_DETAILS,
    element: <SurveyContainer />,
    roles: [TechRoleEnum.Superadmin],
  },
  {
    path: Routes.IMPLANT,
    element: <ImplantContainer />,
    roles: [TechRoleEnum.Implantmanufacturer, TechRoleEnum.Superadmin],
  },

  {
    path: Routes.EXPORTS,
    element: <ExportsContainer />,
    roles: [TechRoleEnum.Billingexpert, TechRoleEnum.Superadmin],
  },
];

function VrsRouter(): JSX.Element {
  return (
    <BrowserRouter>
      <RoutesSwitch>
        <Route path="/" element={<Navigate replace to={Routes.STAFF_LOGIN} />} />
        <Route path={Routes.STAFF_LOGIN} element={<LoginRegistrationContainer />} />
        <Route path={Routes.STAFF_REGISTRATION} element={<LoginRegistrationContainer />} />
        <Route path={Routes.FORGOT_PASSWORD} element={<LoginRegistrationContainer />} />
        <Route path={Routes.PASSWORD_EXPIRATION} element={<LoginRegistrationContainer />} />
        <Route path={Routes.MOBILE_LANDING_PAGE} element={<MobileLandingPage />} />

        {privateRoutesArray.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<PrivateRoute roles={route.roles}>{route.element}</PrivateRoute>}
            />
          );
        })}

        <Route path="*" element={<PageNotFoundContainer />} />
      </RoutesSwitch>
    </BrowserRouter>
  );
}

export default VrsRouter;
