import {
  ChangeCredentialStaffApi as ChangeCredentialStaffApiV1,
  HospitalsApi as HospitalsApiV1,
  LoginStaffApi as LoginMedicalStaffApiV1,
  PatientsApi as PatientsApiV1,
  RefreshTokenApi as RefreshTokenApiV1,
  RegisterStaffApi as RegisterStaffApiV1,
  TeamsApi as TeamsApiV1,
  TermsAndConditionsApi as TermsAndConditionsApiV1,
  AdminTagsApi as AdminTagsApiV1,
  RegisterApi as RegisterApiV1,
  RolesApi as RolesApiV1,
  TagsApi as TagsApiV1,
  UsersApi as UsersApiV1,
  ReportsApi as ReportsApiV1,
  ImplantDataApi as ImplantDataApiV1,
} from "@veris-health/user-ms/lib/v1";
import { PatientProfileInfoApi as PatientProfileInfoApiV2 } from "@veris-health/user-ms/lib/v2";
import config from "../axios-config";
import { API_ROUTES, getVersion, VERSIONS } from "../helpers";

export const registerStaffApiV1 = new RegisterStaffApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const registerApiV1 = new RegisterApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const loginStaffApiV1 = new LoginMedicalStaffApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);

export const refreshTokenApiV1 = new RefreshTokenApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const changeCredentialsStaffApiV1 = new ChangeCredentialStaffApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const usersApiV1 = new UsersApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const hospitalsApiV1 = new HospitalsApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const termsAndConditionsApiV1 = new TermsAndConditionsApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const rolesApiV1 = new RolesApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const teamsApiV1 = new TeamsApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const patientsApiV1 = new PatientsApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const adminTagsApiV1 = new AdminTagsApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const tagsApiV1 = new TagsApiV1(undefined, getVersion(API_ROUTES.USER, VERSIONS.v1), config);

export const patientProfileApiV2 = new PatientProfileInfoApiV2(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v2),
  config,
);

export const reportsApiV1 = new ReportsApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);

export const implantApiV1 = new ImplantDataApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
