import { Link, Typography, useTheme } from "@mui/material";
import React from "react";
import { TechRoleEnum } from "@veris-health/user-ms/lib/v1/index";
import { useAuthenticatedUserRoles } from "../../../context/profile";

interface HubSNInterface {
  hubSn: string;
}
export function HubSN({ hubSn }: HubSNInterface): JSX.Element {
  const theme = useTheme();
  const authenticatedUserRoles = useAuthenticatedUserRoles();
  const canAccessMioHistory = authenticatedUserRoles.some((role) =>
    [TechRoleEnum.Superadmin, TechRoleEnum.Admin].includes(role as TechRoleEnum),
  );
  return canAccessMioHistory ? (
    <Link href={`/mio-history/${hubSn}`} variant="body">
      {hubSn}
    </Link>
  ) : (
    <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-4"]}>
      {hubSn}
    </Typography>
  );
}
