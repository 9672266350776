import * as React from "react";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import type { SettingName } from "../appSettings";

import { DEFAULT_SETTINGS } from "../appSettings";

type SettingsContextShape = {
  settings: Record<SettingName, boolean>;
};

const Context: React.Context<SettingsContextShape> = createContext({
  settings: DEFAULT_SETTINGS,
});

export const SettingsContext = ({ children }: { children: ReactNode }): JSX.Element => {
  const [settings] = useState(DEFAULT_SETTINGS);

  const contextValue = useMemo(() => {
    return { settings };
  }, [settings]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useSettings = (): SettingsContextShape => {
  return useContext(Context);
};
