import * as React from "react";
import { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Box, styled, Typography } from "@mui/material";
import { IconName, VrsIconButton } from "@veris-health/web-core";

const StyledOverlay = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  flexDirection: "column",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "rgba(40, 40, 40, 0.6)",
  flexGrow: 0,
  flexShrink: 1,
  zIndex: 1204,
}));

const StyledModal = styled("div")(({ theme }) => ({
  padding: "20px",
  paddingTop: "8px",
  minHeight: "100px",
  minWidth: "300px",
  display: "flex",
  flexGrow: 0,
  backgroundColor: theme.veris.colors.neutrals.white,
  flexDirection: "column",
  position: "relative",
  boxShadow: "0 0 20px 0 #444",
  borderRadius: "10px",
}));

const StyledModalContent = styled("div")(() => ({
  paddingTop: "20px",
}));

function PortalImpl({
  onClose,
  children,
  title,
  closeOnClickOutside,
}: {
  children: ReactNode;
  closeOnClickOutside: boolean;
  onClose: () => void;
  title: string;
}) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current !== null) {
      modalRef.current.focus();
    }
  }, []);

  useEffect(() => {
    let modalOverlayElement: HTMLElement | null = null;
    const handler = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };
    const clickOutsideHandler = (event: MouseEvent) => {
      const { target } = event;
      if (
        modalRef.current !== null &&
        !modalRef.current.contains(target as Node) &&
        closeOnClickOutside
      ) {
        onClose();
      }
    };
    const modelElement = modalRef.current;
    if (modelElement !== null) {
      modalOverlayElement = modelElement.parentElement;
      if (modalOverlayElement !== null) {
        modalOverlayElement.addEventListener("click", clickOutsideHandler);
      }
    }

    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
      if (modalOverlayElement !== null) {
        modalOverlayElement?.removeEventListener("click", clickOutsideHandler);
      }
    };
  }, [closeOnClickOutside, onClose]);

  return (
    <StyledOverlay role="dialog">
      <StyledModal tabIndex={-1} ref={modalRef}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h2" style={{ alignSelf: "self-end" }}>
            {title}
          </Typography>
          <VrsIconButton
            iconProps={{ name: IconName.CloseIcon }}
            aria-label="Close modal"
            onClick={onClose}
          />
        </Box>
        <StyledModalContent>{children}</StyledModalContent>
      </StyledModal>
    </StyledOverlay>
  );
}

export default function VrsModal({
  onClose,
  children,
  title,
  closeOnClickOutside = false,
}: {
  children: ReactNode;
  closeOnClickOutside?: boolean;
  onClose: () => void;
  title: string;
}): JSX.Element {
  return createPortal(
    <PortalImpl onClose={onClose} title={title} closeOnClickOutside={closeOnClickOutside}>
      {children}
    </PortalImpl>,
    document.body,
  );
}
