import {
  Box,
  Stack,
  Table,
  Typography,
  CircularProgress,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import {
  VrsConfirmationModals,
  VrsIconButton,
  IconName,
  VrsErrorMsg,
  dateFormats,
} from "@veris-health/web-core";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { FeedbackSurvey } from "@veris-health/virtual-doc-ms/lib/v1/api";
import { HospitalInfo } from "@veris-health/user-ms/lib/v1";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  StyledTableCell,
  StyledTableBody,
  StyledTableRow,
} from "../../../ui/components/Tables/shared/StyledTableElements";
import {
  Column,
  TableColumnNames,
  VrsSortedTableHeader,
} from "../../../ui/components/VrsSortedTableHeader";
import { selectSurveys, selectSurveysStatus } from "../surveysSlice";
import { useHospitals } from "../../../hooks/useHospitals";
import { isDateInPast } from "../../../utils/date";

const columns: Column[] = [
  {
    id: TableColumnNames.Id,
    label: TableColumnNames.Id,
  },
  {
    id: TableColumnNames.Hospital,
    label: TableColumnNames.Hospital,
  },
  {
    id: TableColumnNames.StartDate,
    label: TableColumnNames.StartDate,
  },
  {
    id: TableColumnNames.DateCreated,
    label: TableColumnNames.DateCreated,
  },
  {
    id: TableColumnNames.ExpiresAt,
    label: TableColumnNames.ExpiresAt,
  },
  {
    id: TableColumnNames.Actions,
    label: TableColumnNames.Actions,
  },
];

interface SurveysActionsMenuProps {
  onDelete?: () => void;
  onEdit: () => void;
  onExport: (detailed?: boolean) => void;
}

const ActionsMenu = ({ onEdit, onDelete, onExport }: SurveysActionsMenuProps) => {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

  return (
    <Stack direction="row" ref={anchorRef}>
      {confirmationModalOpen && (
        <VrsConfirmationModals
          isOpen={confirmationModalOpen}
          handleClose={() => setConfirmationModalOpen(false)}
          dialogHeader="Warning!"
          dialogContent="You will delete this survey."
          dialogSecondContentLine="Please confirm."
          onCancel={() => setConfirmationModalOpen(false)}
          onConfirm={() => {
            setConfirmationModalOpen(false);
            if (typeof onDelete === "function") {
              onDelete();
            }
          }}
          cancelButtonText="Cancel"
          confirmButtonText="Continue"
        />
      )}

      <VrsIconButton
        iconProps={{ name: open ? IconName.ActiveDots : IconName.Dots }}
        onClick={() => setOpen(true)}
      />
      <Menu anchorEl={anchorRef.current} open={open} onClose={() => setOpen(false)}>
        {typeof onDelete === "function" && (
          <MenuItem
            onClick={() => {
              setConfirmationModalOpen(true);
              setOpen(false);
            }}
          >
            <Typography variant="body" color={theme.veris.colors.neutrals["grey-4"]}>
              Delete
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setOpen(false);
            onEdit();
          }}
        >
          <Typography variant="body" color={theme.veris.colors.neutrals["grey-4"]}>
            View
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            onExport();
            setOpen(false);
          }}
        >
          <Typography variant="body" color={theme.veris.colors.neutrals["grey-4"]}>
            Export
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onExport(true);
            setOpen(false);
          }}
        >
          <Typography variant="body" color={theme.veris.colors.neutrals["grey-4"]}>
            Detailed Export
          </Typography>
        </MenuItem>
      </Menu>
    </Stack>
  );
};

const StyledTableColumn = ({
  column,
  survey,
  onEdit,
  onDelete,
  onExport,
}: {
  column: Column;
  survey: FeedbackSurvey;
  onDelete: () => void;
  onExport: (survey: FeedbackSurvey, detailed?: boolean) => void;
  onEdit: () => void;
}) => {
  const theme = useTheme();
  const hospitals = useHospitals(false) as HospitalInfo[];
  const hospital = hospitals.find(({ id }) => survey.hospital_id === id);

  const disableEditOrDelete = survey && isDateInPast(survey?.survey_start_date);
  const isExpired = survey && isDateInPast(survey?.expires_at);

  return (
    <StyledTableCell key={column.id}>
      {column.id === TableColumnNames.Id && (
        <Typography variant="subtitle1" color={theme.veris.colors.neutrals["grey-4"]}>
          {survey.id || "N/A"}
        </Typography>
      )}

      {column.id === TableColumnNames.Hospital && (
        <Typography variant="subtitle1" color={theme.veris.colors.neutrals["grey-4"]}>
          {hospital?.name || "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.StartDate && (
        <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-4"]}>
          {survey.survey_start_date
            ? dayjs(survey.survey_start_date).format(dateFormats["MMM DD, YYYY"])
            : "N/A"}
        </Typography>
      )}

      {column.id === TableColumnNames.DateCreated && (
        <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-4"]}>
          {survey.date_created
            ? dayjs(survey.date_created).format(dateFormats["MMM DD, YYYY"])
            : "N/A"}
        </Typography>
      )}

      {column.id === TableColumnNames.ExpiresAt && (
        <Typography
          variant="subtitle2"
          color={
            isExpired ? theme.veris.colors.errors.normal : theme.veris.colors.neutrals["grey-4"]
          }
        >
          {survey.expires_at ? dayjs(survey.expires_at).format(dateFormats["MMM DD, YYYY"]) : "N/A"}
        </Typography>
      )}

      {column.id === TableColumnNames.Actions && (
        <ActionsMenu
          onEdit={onEdit}
          onExport={(detailed?: boolean) => onExport(survey, detailed)}
          onDelete={disableEditOrDelete ? undefined : onDelete}
        />
      )}
    </StyledTableCell>
  );
};

export const SurveysTable = ({
  fetchSurveys,
  onEdit,
  onDelete,
  onExport,
}: {
  fetchSurveys: () => void;
  onEdit: (survey: FeedbackSurvey) => void;
  onDelete: (survey: FeedbackSurvey) => void;
  onExport: (survey: FeedbackSurvey, detailed?: boolean) => void;
}): JSX.Element => {
  const surveys = useAppSelector(selectSurveys);
  const status = useAppSelector(selectSurveysStatus);

  return (
    <>
      {status === "loading" && (
        <Box height="50%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {status === "failed" && <VrsErrorMsg size="small" onClick={fetchSurveys} />}
      {status === "idle" && (
        <Box
          sx={{
            backgroundColor: (theme) => theme.veris.colors.neutrals.white,
            border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
            borderRadius: (theme) => theme.shape.borderRadius,
            height: "73vh",
            overflow: "auto",
          }}
          px={2}
          pb={1}
        >
          {surveys.length > 0 ? (
            <Table>
              <VrsSortedTableHeader columns={columns} />
              <StyledTableBody>
                {surveys.map((survey: FeedbackSurvey) => (
                  <StyledTableRow key={survey.id}>
                    {columns.map((column) => (
                      <StyledTableColumn
                        column={column}
                        survey={survey}
                        key={column.id}
                        onEdit={() => onEdit(survey)}
                        onDelete={() => onDelete(survey)}
                        onExport={onExport}
                      />
                    ))}
                  </StyledTableRow>
                ))}
              </StyledTableBody>
            </Table>
          ) : (
            <Typography
              color={(theme) => theme.veris.colors.neutrals["grey-3"]}
              textAlign="center"
              variant="body"
              mt={2}
            >
              There are no surveys at this moment.
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
