import React, { useEffect, useMemo } from "react";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import { drawerClasses, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { VrsLogo } from "@veris-health/web-core";
import { TechRoleEnum } from "@veris-health/user-ms/lib/v1";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  selectToggleLeftSideBar,
  selectTogglePreviewBar,
  toggleLeftSideBar,
} from "../shared/slices/uiSlice";
import { sideBarMenuItems } from "../../constants";
import { SideBarNavigationItem, SidebarToggle } from "./components";
import { useAuthenticatedUserRoles } from "../../context/profile";

export interface VrsLeftSidebarContainerProps {
  openPreview?: boolean;
  path?: string;
}

const sidebarDrawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: sidebarDrawerWidth,
  padding: theme.spacing(3.75, 2.25),
  overflow: "hidden",
  ":hover": {
    overflowY: "auto",
  },
  [theme.breakpoints.down("lg")]: {
    position: "fixed",
    zIndex: 1203,
    width: sidebarDrawerWidth,
  },
});

const openedPreviewMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: sidebarDrawerWidth,
  position: "fixed",
  zIndex: 1203,
  padding: theme.spacing(3.75, 2.25),
  overflow: "hidden",
  ":hover": {
    overflowY: "auto",
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(10.5)} + 1px)`,
  [theme.breakpoints.down("lg")]: {
    width: `calc(${theme.spacing(12.9)} + 1px)`,
  },
  padding: theme.spacing(3.75, 2.25),
  overflow: "hidden",
  ":hover": {
    overflowY: "auto",
  },
});

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: "auto",
  padding: theme.spacing(0),
  ...theme.mixins.toolbar,
  [theme.breakpoints.down("xl")]: {
    minHeight: "40px",
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop: string) => prop !== "openPreview",
})<VrsLeftSidebarContainerProps>(({ theme, open, openPreview, path }) => ({
  width: sidebarDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    [`& .${drawerClasses.paper}`]: openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    [`& .${drawerClasses.paper}`]: closedMixin(theme),
  }),
  ...(open &&
    openPreview &&
    path?.split("/").includes("patient-status") && {
      ...openedPreviewMixin(theme),
      [`& .${drawerClasses.paper}`]: openedPreviewMixin(theme),
    }),
}));

export function VrsLeftSidebarContainer(): JSX.Element {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const leftSideBarExpanded = useAppSelector(selectToggleLeftSideBar);
  const previewExpanded = useAppSelector(selectTogglePreviewBar);
  const isBigScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    dispatch(toggleLeftSideBar(isBigScreen));
  }, [dispatch, isBigScreen]);

  return (
    <Drawer
      variant="permanent"
      open={leftSideBarExpanded}
      openPreview={previewExpanded}
      path={location.pathname}
    >
      <VrsLogo expanded={leftSideBarExpanded} />
      <NavigationItems />
      <DrawerFooter
        sx={{
          justifyContent: leftSideBarExpanded ? "flex-start" : "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <SidebarToggle
          expanded={leftSideBarExpanded}
          onClick={() => {
            dispatch(toggleLeftSideBar(!leftSideBarExpanded));
          }}
        />
      </DrawerFooter>
    </Drawer>
  );
}

function NavigationItems(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const leftSideBarExpanded = useAppSelector(selectToggleLeftSideBar);
  const authenticatedUserRoles = useAuthenticatedUserRoles();
  const accessibleMenuItems = useMemo(() => {
    return sideBarMenuItems.filter((item) =>
      authenticatedUserRoles.some((role) => item.access.includes(role as TechRoleEnum)),
    );
  }, [authenticatedUserRoles]);

  return (
    <Box display="flex" flexDirection="column" mt="1rem">
      {accessibleMenuItems.map((item) => (
        <SideBarNavigationItem
          title={item.title}
          iconName={item.iconName}
          active={location.pathname.includes(item.path)}
          expanded={leftSideBarExpanded}
          key={item.title}
          onClick={() => navigate(item.path)}
        />
      ))}
    </Box>
  );
}

export default VrsLeftSidebarContainer;
