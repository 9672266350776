import { Box } from "@mui/material";
import { VrsButton } from "@veris-health/web-core";
import React from "react";
import { ExportsMessage } from "../../Exports/components/PeriodPicker";

interface BillingExportProps {
  onExport: () => void;
  disabled: boolean;
}

export function BillingExport({ onExport, disabled }: BillingExportProps): JSX.Element {
  return (
    <Box py={2}>
      <VrsButton buttonType="primary" disabled={disabled} onClick={onExport}>
        Export CSV
      </VrsButton>
      <ExportsMessage />
    </Box>
  );
}
