import React from "react";
import { useTheme } from "@mui/material";
import { TagItem } from "@veris-health/user-ms/lib/v1";
import { StyledTextField } from "../../Users/components/SetHospitalAndCareTeamForm";

type EditInputProps = {
  hospital: string | undefined;
  editingTag: TagItem;
  onEdit: (editedTag: TagItem) => void;
  onEditingChange: (tag: TagItem, value: string) => void;
};

export const EditTagInput = ({
  hospital,
  editingTag,
  onEdit,
  onEditingChange,
}: EditInputProps): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      <StyledTextField
        sx={{
          marginBlock: theme.spacing(0.25),
          marginRight: theme.spacing(1),
          borderRadius: +theme.shape.borderRadius * 4,
          paddingX: theme.spacing(2.4),
          paddingTop: theme.spacing(0.8),
          border:
            editingTag.label.trim().length === 0
              ? `1px solid ${theme.veris.colors.errors.normal} `
              : `1px solid ${theme.veris.colors.amethyst.medium} `,
          width: editingTag.label.length > 9 ? `${editingTag.label.length + 4}ch` : "14ch",
        }}
        placeholder="Rename tag"
        size="small"
        variant="standard"
        disabled={!hospital}
        name="editingTag"
        type="text"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onEditingChange(editingTag, event.target.value);
        }}
        value={editingTag.label}
        onKeyPress={(e) => {
          if (e.key === "Enter" && editingTag && editingTag.label.trim().length > 0) {
            e.preventDefault();
            onEdit(editingTag);
          }
        }}
      />
    </>
  );
};
