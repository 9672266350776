import { createSlice } from "@reduxjs/toolkit";

interface SharedState {}

const initialState: SharedState = {};

const sharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {},
  // extraReducers: (builder) => {},
});

export default sharedSlice.reducer;
