import {
  Autocomplete as MuiAutocomplete,
  Box,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import React, { HTMLAttributes } from "react";
import { Option } from "@veris-health/web-core";

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  ".MuiInput-root:before, .MuiInput-root:after, .MuiInput-root:hover, .MuiInput-root:hover:not(.Mui-disabled):before":
    {
      border: "none",
    },
  ".MuiInput-root.MuiInputBase-sizeSmall": {
    ".MuiInput-input": {
      ...theme.typography.body,
      height: "20px",
      paddingBottom: 0,
      [theme.breakpoints.down("lg")]: {
        padding: theme.spacing(0.725, 0.58, 0, 0),
      },
    },
  },
}));

const renderOption = (
  { onClick, ...rest }: HTMLAttributes<HTMLLIElement | HTMLDivElement>,
  { value, label }: Option,
): JSX.Element => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        cursor: "pointer",
      }}
      onClick={onClick}
      {...rest}
      key={`${value}`}
    >
      <Typography
        variant="body"
        ml={1}
        mr={1}
        color={(theme) => theme.veris.colors.neutrals["grey-dark"]}
      >
        {label}
      </Typography>
    </Box>
  );
};

export interface AutoCompleteProps {
  handleChange: (
    e: React.SyntheticEvent,
    option: {
      label: string;
      value: string;
    } | null,
  ) => void;
  placeholder: string;
  options: {
    label: string;
    value: string;
  }[];
  autoCompleteId: string;
  loading: boolean;
}

export const AutoComplete = ({
  handleChange,
  placeholder,
  options,
  autoCompleteId,
  loading,
}: AutoCompleteProps): JSX.Element => {
  return (
    <MuiAutocomplete
      loading={loading}
      sx={{
        borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]} `,
        padding: (theme) => theme.spacing(1, 1.25),
        marginY: (theme) => theme.spacing(2),
      }}
      id={autoCompleteId}
      onChange={handleChange}
      style={{
        fontWeight: 400,
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={options}
      getOptionLabel={(option) => option.label || ""}
      renderOption={renderOption}
      ListboxProps={{
        style: {
          overflowY: "scroll",
          height: "200px",
        },
      }}
      renderInput={(params) => (
        <StyledTextField {...params} placeholder={placeholder} size="small" variant="standard" />
      )}
      disableClearable
    />
  );
};
