import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { IconName, VrsIcon } from "@veris-health/web-core";
import Registration from "./Registration";
import { Routes } from "../../routes-config";
import LoginContainer from "./LoginContainer";
import { ForgotPasswordContainer } from "./ForgotPasswordContainer";
import { ExpiredPasswordContainer } from "./ExpiredPasswordContainer";
import packageJson from "../../../package.json";

const renderRoute = (pathname: string) => {
  switch (pathname) {
    case Routes.STAFF_REGISTRATION:
      return <Registration />;
    case Routes.STAFF_LOGIN:
      return <LoginContainer />;
    case Routes.FORGOT_PASSWORD:
      return <ForgotPasswordContainer />;
    case Routes.PASSWORD_EXPIRATION:
      return <ExpiredPasswordContainer />;
    default:
      return <LoginContainer />;
  }
};

const LoginRegistrationContainer = (): JSX.Element => {
  const location = useLocation();
  const inXXLScreen = useMediaQuery(({ breakpoints }: Theme) => breakpoints.up("xxl"));
  const isXsScreen = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down("xs"));
  const SLOGAN = "Veris Admin";
  const getLogoWhiteHeight = () => {
    if (inXXLScreen) return "68.125rem";
    if (isXsScreen) return "15.875rem";
    return "38.5rem";
  };

  const getLogoWhiteWidth = () => {
    if (inXXLScreen) return "80.625rem";
    if (isXsScreen) return "23.313rem";
    return "59.375rem";
  };
  return (
    <Box height={isXsScreen ? "11.063rem" : "100vh"} overflow="hidden">
      <Box
        height={isXsScreen ? "11.063rem" : "100vh"}
        display="flex"
        flexDirection={isXsScreen ? "row" : "column"}
        sx={{
          backgroundColor: ({ veris }) => veris.colors.amethyst.normal,
        }}
        position="relative"
      >
        <VrsIcon
          name={IconName.TextLogoWhite}
          sx={{
            height: isXsScreen ? "20px" : "40px",
            width: isXsScreen ? "70px" : "139px",
            margin: ({ spacing }) => (isXsScreen ? spacing(2, 0, 0, 2) : spacing(8, 8, 0, 0)),
            zIndex: 999,
            position: isXsScreen ? "relative" : "absolute",
            right: 0,
          }}
        />
        <Typography
          m={2}
          variant="h4"
          sx={{
            color: (theme) => theme.veris.colors.neutrals.white,
            position: "absolute",
            bottom: 0,
            display: isXsScreen ? "block" : "none",
          }}
        >
          {SLOGAN}
        </Typography>

        <VrsIcon
          name={IconName.LogoWhite}
          sx={{
            height: getLogoWhiteHeight(),
            width: getLogoWhiteWidth(),
            position: "absolute",
            bottom: 0,
            top: isXsScreen ? -40 : "",
            right: isXsScreen ? -140 : "-3%",
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection={isXsScreen ? "column" : "row"}
        height={isXsScreen ? "11.063rem" : "100vh"}
        sx={{ position: "absolute", top: isXsScreen ? "unset" : 0, right: 0, left: 0 }}
      >
        <Box
          width={isXsScreen ? "100vw" : "87vw"}
          sx={{
            backgroundColor: ({ veris }) => veris.colors.neutrals.white,
            padding: ({ spacing }) => (isXsScreen ? 0 : spacing(8)),
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container style={{ margin: "0 auto" }} display="block" xs={10} item>
            {renderRoute(location.pathname)}
          </Grid>
        </Box>
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          paddingTop={({ spacing }) => (isXsScreen ? 0 : spacing(28))}
          marginX={({ spacing }) => (isXsScreen ? 0 : spacing(8))}
          marginY={({ spacing }) => spacing(2.5)}
          minWidth={isXsScreen ? "none" : "30vw"}
          width={isXsScreen ? "60vw" : ""}
        >
          <Typography
            m={1}
            variant="h1"
            sx={{
              color: (theme) => theme.veris.colors.neutrals.white,
              fontSize: "80px",
              lineHeight: "100px",
              fontWeight: 400,
              display: isXsScreen ? "none" : "block",
            }}
          >
            {SLOGAN}
          </Typography>

          <Version isXsScreen={isXsScreen} />
        </Box>
      </Box>
    </Box>
  );
};

function Version({ isXsScreen }: { isXsScreen: boolean }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: isXsScreen ? "relative" : "absolute",
        bottom: "0",
        display: "flex",
        flexDirection: "column",
        margin: isXsScreen ? theme.spacing(2, 5, 5, 5) : 0,
      }}
    >
      <Typography
        variant="caption"
        color={theme.veris.colors.neutrals[isXsScreen ? "grey-3" : "grey-0"]}
      >
        Last Version {packageJson.version}
      </Typography>
      <Typography
        variant="caption"
        color={theme.veris.colors.neutrals[isXsScreen ? "grey-3" : "grey-0"]}
      >
        UDI-DI: {import.meta.env.VITE_UDI_DI}
      </Typography>
    </Box>
  );
}

export default LoginRegistrationContainer;
