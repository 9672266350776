import React from "react";
import { InputAdornment, styled, TextField, TextFieldProps, InputProps } from "@mui/material";
import { IconName, VrsIcon, VrsIconButton } from "@veris-health/web-core";

export interface VrsPlainSearchProps extends InputProps {
  onValueChange?: (value: string) => void;
  value?: string;
  sxProps?: React.CSSProperties;
}

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  width: "200px",
  backgroundColor: theme.veris.colors.neutrals.white,
  ".MuiInputBase-input": {
    height: "1rem",
    paddingTop: theme.spacing(1.25),
    ...theme.typography.body,
  },
  ".MuiInput-root.MuiInputBase-sizeSmall": {
    ".MuiInput-input": {
      ...theme.typography.body,
      padding: theme.spacing(0.429, 0.58, 0, 0),
      height: "20px",
      [theme.breakpoints.down("lg")]: {
        padding: theme.spacing(0.725, 0.58, 0, 0),
      },
    },
  },
}));

export function VrsPlainSearch({
  onValueChange,
  value,
  sxProps,
  disabled,
}: VrsPlainSearchProps): JSX.Element {
  return (
    <StyledTextField
      variant="outlined"
      size="small"
      placeholder="Search"
      sx={sxProps}
      onChange={(event) => onValueChange && onValueChange(event.target.value)}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <VrsIcon name={IconName.Search} />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <VrsIconButton
              data-test-hook="clear-btn"
              iconProps={{ name: IconName.CloseIcon }}
              onClick={() => onValueChange && onValueChange("")}
            />
          </InputAdornment>
        ),
      }}
      disabled={disabled}
    />
  );
}
