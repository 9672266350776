import { Box, Typography } from "@mui/material";
import { Option, VrsButton, VrsSelect } from "@veris-health/web-core";
import React from "react";
import { Datepicker } from "@mobiscroll/react";
import dayjs from "dayjs";
import { VrsBillingPeriod } from "../api/billingApi";
import { BILLING_DATE_FORMAT } from "./constants";
import { ExportsMessage } from "../../Exports/components/PeriodPicker";

export type PeriodPickerProps = {
  handlePeriodChange: (value: string) => void;
  handleExportCSV: () => void;
  periods: VrsBillingPeriod[] | undefined;
  disabledExport: boolean;
  children?: React.ReactNode;
  onRangeChange?: (range: { start: string; end: string }) => void;
  isTelemedicineView?: boolean;
};

const PeriodPicker = ({
  handlePeriodChange,
  periods,
  handleExportCSV,
  disabledExport,
  children,
  onRangeChange,
  isTelemedicineView,
}: PeriodPickerProps): JSX.Element => {
  const formattedPeriodOptions: Option[] =
    periods && periods.length > 0
      ? periods?.map((per) => {
          return {
            label: `${per.start} - ${per.end}`,
            value: per.id,
          };
        })
      : [];

  return (
    <>
      {(periods && periods.length > 0) || isTelemedicineView ? (
        <Box>
          {isTelemedicineView && onRangeChange ? (
            <Datepicker
              controls={["calendar"]}
              anchorAlign="center"
              theme="material"
              themeVariant="light"
              dateFormat="M/D/YYYY"
              inputComponent="input"
              inputProps={{
                placeholder: "Choose range",
                className: "md-mobile-picker-input",
                readOnly: true,
              }}
              touchUi
              select="range"
              onChange={(picker) => {
                const [start, end] = picker.value;
                onRangeChange({
                  start: dayjs(start).format(BILLING_DATE_FORMAT),
                  end: dayjs(end).format(BILLING_DATE_FORMAT),
                });
              }}
            />
          ) : (
            <VrsSelect
              options={formattedPeriodOptions}
              onSelected={handlePeriodChange}
              placeholder="Select a period"
            />
          )}
          {children}
          <Box py={2}>
            <VrsButton buttonType="primary" disabled={disabledExport} onClick={handleExportCSV}>
              Export CSV
            </VrsButton>
            <ExportsMessage />
          </Box>
        </Box>
      ) : (
        <Typography
          variant="body"
          sx={{ color: (theme) => theme.veris.colors.neutrals["grey-3"] }}
          margin={(theme) => theme.spacing(1, 0)}
          textAlign="center"
        >
          No periods to select
        </Typography>
      )}
    </>
  );
};

export default PeriodPicker;
