import {
  HospitalItem,
  MainOncologistRequest,
  StaffProfileType,
  UserTypeResponse,
} from "@veris-health/user-ms/lib/v1";
import { hospitalsApiV1, teamsApiV1 } from "../../../api";

export const getHospitals = async (): Promise<HospitalItem[]> => {
  const response = await hospitalsApiV1.getHospitals();
  return response.data;
};

export const setPatientHospital = async (
  patientId: number,
  hospitalId: number,
): Promise<unknown> => {
  const response = await hospitalsApiV1.setPatientHospital(patientId, { hospital_id: hospitalId });
  return response.data;
};

export const setPatientMainOncologist = async (
  patientId: number,
  medStaffId: MainOncologistRequest,
): Promise<unknown> => {
  const response = await teamsApiV1.setPatientMainOncologist(patientId, medStaffId);
  return response.data;
};

export const addStaffHospital = async (
  hospitalId: number,
  userId: number,
  userType: UserTypeResponse,
) => {
  const response = await hospitalsApiV1.setStaffHospital(userId, {
    hospital_id: hospitalId,
    profile_type: userType as unknown as StaffProfileType,
  });
  return response.data;
};

export const unsetStaffHospitals = async (
  hospitalId: number,
  userId: number,
  userType: UserTypeResponse,
) => {
  const response = await hospitalsApiV1.unsetStaffHospital(userId, {
    hospital_id: hospitalId,
    profile_type: userType as unknown as StaffProfileType,
  });
  return response.data;
};
