import {
  Box,
  CircularProgress,
  InputAdornment,
  inputBaseClasses,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { IconName, VrsIcon, VrsButton, VrsDialog } from "@veris-health/web-core";
import {
  AdminDetailUserInfoResponse,
  MedRoleFilterEnum,
  TechRoleFilterEnum,
  UserTypeResponse,
} from "@veris-health/user-ms/lib/v1";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { getUsersAsync, selectUsers } from "../../../Users/userSlice";
import { VrsUserSelectionList } from "../VrsUserSelectionList";
import { USERS_FETCHING_LIMIT } from "../../../../constants";

export interface VrsUserSelectionDialogProps {
  isOpen: boolean;
  handleSelection: (participant: AdminDetailUserInfoResponse) => void;
  closeNewChatDialog: () => void;
  userRole?: MedRoleFilterEnum | TechRoleFilterEnum;
  subtitle?: string;
  buttonText?: string;
  userType?: UserTypeResponse[];
}

export const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    ...theme.typography.body,
    padding: theme.spacing(1, 0),
  },
}));

export function VrsUserSelectionDialog({
  isOpen,
  handleSelection,
  closeNewChatDialog,
  userRole,
  userType,
  subtitle,
  buttonText,
}: VrsUserSelectionDialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const [searchValue, setSearchValue] = useState<string>();
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [selectedUser, setSelectedUser] = useState<AdminDetailUserInfoResponse>();

  useEffect(() => {
    dispatch(
      getUsersAsync({
        medRole: userRole,
        type: userType,
        active: true,
        limit: USERS_FETCHING_LIMIT,
      }),
    ).then(() => {
      setFirstLoad(false);
    });
  }, []);

  const debouncedSearch = useCallback(
    debounce((search) => {
      dispatch(
        getUsersAsync({
          medRole: userRole,
          type: userType,
          active: true,
          search,
          limit: USERS_FETCHING_LIMIT,
        }),
      );
    }, 500),
    [],
  );

  return (
    <VrsDialog
      open={isOpen}
      title="User List"
      onClose={closeNewChatDialog}
      subtitle={subtitle || "Select a patient to start conversation"}
      height="530px"
      width="500px"
    >
      {firstLoad && (
        <Box p={1} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!firstLoad && (
        <>
          <Box my={7}>
            <StyledTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VrsIcon name={IconName.Search} />
                  </InputAdornment>
                ),
              }}
              type="text"
              fullWidth
              placeholder="Search by name"
              onChange={(event) => {
                setSearchValue(event.target.value);
                debouncedSearch(event.target.value);
              }}
              value={searchValue}
              autoFocus
            />

            <Box my={5} id="users-selection-list-container">
              {users.items.length === 0 && (
                <Typography
                  pt={2}
                  color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                  textAlign="center"
                  variant="body"
                >
                  No matches found
                </Typography>
              )}
              <InfiniteScroll
                dataLength={users.items.length}
                height={240}
                hasMore={users.total - users.items.length > 0}
                next={() =>
                  dispatch(
                    getUsersAsync({
                      medRole: userRole,
                      type: userType,
                      active: true,
                      search: searchValue,
                      limit: USERS_FETCHING_LIMIT,
                      offset: users.items.length,
                    }),
                  )
                }
                loader={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    borderTop={(theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`}
                  >
                    <CircularProgress />
                  </Box>
                }
              >
                <VrsUserSelectionList
                  users={users.items}
                  setSelectedUser={setSelectedUser}
                  selectedId={selectedUser?.id}
                />
              </InfiniteScroll>
            </Box>
          </Box>
          <VrsButton
            buttonType="primary"
            sx={{ width: "150px", alignSelf: "center" }}
            disabled={!selectedUser}
            onClick={() => selectedUser && handleSelection(selectedUser)}
          >
            {buttonText || "Start conversation"}
          </VrsButton>
        </>
      )}
    </VrsDialog>
  );
}
