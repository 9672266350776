import React, { FocusEvent } from "react";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import {
  TextField,
  styled,
  outlinedInputClasses,
  inputBaseClasses,
  Typography,
  FormLabel,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

export interface VrsFormInputFieldProps
  extends Omit<DatePickerProps<Dayjs, Dayjs>, "onChange" | "value" | "renderInput"> {
  onChange: (e: string, f: Dayjs) => void;
  onBlur?: (e: FocusEvent<unknown>) => void;
  value?: Dayjs;
  name: string;
  error?: boolean;
  label: string;
  helperText?: string;
  required?: boolean;
}

interface StyledVrsFieldProps {
  isValidField?: boolean;
}

export const VrsField = styled(TextField, {
  shouldForwardProp: (prop: string) => prop !== "isValidField",
})<StyledVrsFieldProps>(({ theme, isValidField }) => ({
  [`& .${inputBaseClasses.input}`]: {
    ...theme.typography.body,
    borderRadius: 1.5,
    padding: theme.spacing(1.72, 0, 1.28, 1.25),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(2.4, 0, 1.8, 1.25),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.43, 0, 1.2, 1.25),
    },
    ":-internal-autofill-selected": {
      boxShadow: "0 0 0 30px white inset !important",
    },
  },
  "& .MuiSvgIcon-root": {
    color: theme.veris.colors.amethyst.normal,
  },
  [`& .${outlinedInputClasses.root}`]: {
    paddingRight: theme.spacing(1.25),
    "& fieldset": {
      borderColor: theme.veris.colors.amethyst.light,
      ...(isValidField && {
        border: `1px solid ${theme.veris.colors.amethyst.soft}`,
      }),
    },
    "&:hover fieldset": {
      border: `3px solid ${theme.veris.colors.amethyst.light}`,
    },
    [`&.${outlinedInputClasses.focused} fieldset`]: {
      border: `2px solid ${theme.veris.colors.amethyst.soft}`,
    },
  },
  [`& .${inputBaseClasses.error}`]: {
    color: theme.veris.colors.errors.normal,
    backgroundColor: theme.veris.colors.errors.soft,
    "& input": {
      color: theme.veris.colors.neutrals.black,
    },
  },
  [`& .${inputBaseClasses.root}${inputBaseClasses.disabled}`]: {
    border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
    backgroundColor: "#E2E2EA33",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    backgroundColor: theme.veris.colors.neutrals.white,
  },
}));

export const VrsDateInputField = React.memo(
  ({
    onChange,
    onBlur,
    value,
    name,
    error,
    label,
    helperText,
    minDate,
    maxDate,
    disabled,
    required = false,
  }: VrsFormInputFieldProps): JSX.Element => {
    return (
      <>
        {label && (
          <Typography m={0} variant="subtitle2">
            {label}
            <FormLabel required={required} />
          </Typography>
        )}
        <DatePicker
          onChange={(v) => {
            onChange(name, dayjs(v));
          }}
          disabled={disabled}
          value={value || null}
          maxDate={maxDate}
          minDate={minDate}
          renderInput={(props) => (
            <VrsField
              hiddenLabel
              variant="outlined"
              fullWidth
              {...props}
              name={name}
              helperText={helperText}
              onBlur={onBlur}
              error={error}
            />
          )}
        />
      </>
    );
  },
);
