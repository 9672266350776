import { SupportedAttachmentTypesEnum as SupportedAttachmentTypes } from "@veris-health/communication-ms/lib/v1";
import { IconName } from "@veris-health/web-core";

export const getShortName = (name: string): string | undefined => {
  if (name) {
    const names: string[] = name?.trim().split(" ") || [];
    let initials = `${names[0]} `;
    names.shift();

    names.forEach((namePart) => {
      initials += `${namePart.charAt(0)}.`;
    });

    return initials;
  }
  return undefined;
};

export const supportedExtensionsMapping: Record<SupportedAttachmentTypes, IconName> = {
  [SupportedAttachmentTypes.Jpg]: IconName.ImageThumbnail,
  [SupportedAttachmentTypes.Jpeg]: IconName.ImageThumbnail,
  [SupportedAttachmentTypes.Pdf]: IconName.PDFIcon,
  [SupportedAttachmentTypes.Png]: IconName.ImageThumbnail,
  [SupportedAttachmentTypes.Svg]: IconName.SvgFile,
  [SupportedAttachmentTypes.Docx]: IconName.DocFile,
  [SupportedAttachmentTypes.Doc]: IconName.DocFile,
  [SupportedAttachmentTypes.Xls]: IconName.ChartFile,
  [SupportedAttachmentTypes.Xlsx]: IconName.ChartFile,
  [SupportedAttachmentTypes.Rtf]: IconName.RichTextFile,
  [SupportedAttachmentTypes.Ppt]: IconName.PresentationFile,
  [SupportedAttachmentTypes.Pptx]: IconName.PresentationFile,
  [SupportedAttachmentTypes.Odf]: IconName.RichTextFile,
};

export const getFileExtentionIcon = (fileExtention?: string): IconName =>
  supportedExtensionsMapping[fileExtention as SupportedAttachmentTypes] ||
  IconName.UnknownExtension;
