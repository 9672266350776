import { Middleware } from "@reduxjs/toolkit";
import {
  addEducationalVideoAsync,
  deleteEducationalVideoAsync,
  updateEducationalVideoAsync,
} from "../features/EducationalArea/educationalVideosSlice";
import {
  fetchNotificationDetailsAsync,
  markNotificationAsReadAsync,
  setupFirebaseToken,
} from "../features/Notifications/notificationsSlice";
import {
  deleteUserAsync,
  disableUserAsync,
  removeCareTeamMember,
  resendInviteAsync,
} from "../features/Users/userSlice";
import SnackbarUtils from "../utils/SnackbarUtils";

export const snackbarMiddleware: Middleware = () => {
  return (next) => (action) => {
    if (addEducationalVideoAsync.fulfilled.match(action)) {
      SnackbarUtils.success("Educational video added.");
    }
    if (addEducationalVideoAsync.rejected.match(action)) {
      const message = action.payload || "Something went wrong";
      SnackbarUtils.error(message);
    }
    if (updateEducationalVideoAsync.fulfilled.match(action)) {
      const { title } = action.payload;
      SnackbarUtils.success(`Video ${title} updated.`);
    }
    if (updateEducationalVideoAsync.rejected.match(action)) {
      const message = action.payload || "Something went wrong";
      SnackbarUtils.error(message);
    }
    if (deleteEducationalVideoAsync.fulfilled.match(action)) {
      SnackbarUtils.success(`Video deleted.`);
    }
    if (resendInviteAsync.fulfilled.match(action)) {
      const { message } = action.payload.response;
      SnackbarUtils.success(message ?? "Invite Send");
    }
    if (deleteUserAsync.fulfilled.match(action)) {
      SnackbarUtils.success("User deleted!");
    }
    if (disableUserAsync.fulfilled.match(action)) {
      SnackbarUtils.success("User disabled");
    }
    if (removeCareTeamMember.rejected.match(action)) {
      const message = action.payload?.message || "Something went wrong";
      SnackbarUtils.error(message);
    }
    if (setupFirebaseToken.rejected.match(action)) {
      SnackbarUtils.error(
        "Could not connect to notifications service. Real time notifications are disabled.",
      );
    }
    if (fetchNotificationDetailsAsync.rejected.match(action)) {
      SnackbarUtils.error("Failed to load the notification. Please reload the page.");
    }
    if (markNotificationAsReadAsync.rejected.match(action)) {
      SnackbarUtils.error("Could not mark notification as read.");
    }
    next(action);
  };
};
