import { get } from "lodash";

export type Order = "asc" | "desc";

export function genericSorter<T, K extends keyof T>(array: T[], order: Order, orderBy: K): T[] {
  const copiedArray = [...array];
  if (order === "asc") {
    return copiedArray.sort((a: T, b: T) => (get(a, orderBy) > get(b, orderBy) ? 1 : -1));
  }
  return copiedArray.sort((a: T, b: T) => (get(b, orderBy) > get(a, orderBy) ? 1 : -1));
}
