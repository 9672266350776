import { styled } from "@mui/material";
import TableContainer, { tableContainerClasses } from "@mui/material/TableContainer";
import TableHead, { tableHeadClasses } from "@mui/material/TableHead";
import TableBody, { tableBodyClasses } from "@mui/material/TableBody";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  [`&.${tableContainerClasses.root}`]: {
    maxHeight: "80vh",
    border: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
    borderRadius: theme.shape.borderRadius,
    overflowX: "auto",
  },
}));

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [`&.${tableHeadClasses.root}`]: {
    backgroundColor: theme.veris.colors.neutrals["soft-white"],
  },
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  [`&.${tableBodyClasses.root}`]: {
    backgroundColor: theme.veris.colors.neutrals.white,
  },
  "& > tr:last-child": {
    borderBottom: "none",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    borderBottom: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    padding: theme.spacing(1.5),
    border: "none",
  },
}));

export default {
  StyledTableContainer,
  StyledTableHead,
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
};
