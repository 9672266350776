import React, { useCallback, useEffect, useState } from "react";
import { IconName, VrsButton, VrsIcon, VrsSelect } from "@veris-health/web-core";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  ArticleCategoryGroup,
  GetEducationalArticlePreview,
} from "@veris-health/virtual-doc-ms/lib/v1/api";
import { debounce } from "lodash";
import { Lang } from "@veris-health/virtual-doc-ms/lib/v1";
import { VrsPlainSearch } from "../../../ui/components/VrsPlainSearch";
import { replaceRouteParam } from "../../../routes-config";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {
  deleteArticleAsync,
  getCategoriesAsync,
  searchArticlesAsync,
  selectCategories,
} from "../articlesSlice";
import { ArticlesTable } from "../components/ArticlesTable";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { categoryLabels, ContentLanguageEnum } from "./ArticleEditorContainer";

export const ArticlesList = (): JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [category, setCategory] = useState<ArticleCategoryGroup>();
  const [contentLanguage, setContentLanguage] = useState<Lang>(Lang.En);
  const categories = useAppSelector(selectCategories);

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      if (query.length >= 3 || query === "") {
        dispatch(searchArticlesAsync({ searchTerm: query, category, contentLanguage }));
      }
    }, 300),
    [category],
  );

  useEffect(() => {
    dispatch(getCategoriesAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch(searchArticlesAsync({ searchTerm, category, contentLanguage }));
  }, [dispatch, category, contentLanguage]);

  const onEdit = (article: GetEducationalArticlePreview) => {
    navigate(
      `${replaceRouteParam("ARTICLE_DETAILS", ":articleId", String(article.meta_article_id))}`,
    );
  };

  const onDelete = (article: GetEducationalArticlePreview) => {
    dispatch(deleteArticleAsync(article.meta_article_id));
  };

  const categoryOptions = [{ label: "All", value: "" }].concat(
    categories.map((ctg) => ({
      label: categoryLabels[ctg] || ctg,
      value: ctg,
    })),
  );

  const languageOptions = [
    {
      label: ContentLanguageEnum.English,
      value: Lang.En,
    },
    {
      label: ContentLanguageEnum.Spanish,
      value: Lang.Es,
    },
  ];

  return (
    <Box display="flex" mb={2.5} mt={2} ml={2} flexDirection="column">
      <Stack direction="row" spacing={2}>
        <Box>
          <Typography variant="body1">Search Articles</Typography>
          <VrsPlainSearch
            onValueChange={(value) => {
              setSearchTerm(value.trim());
              debouncedSearch(value.trim());
            }}
          />
        </Box>
        <Box>
          <Typography variant="body1">Categories</Typography>
          <VrsSelect
            outerSx={{ width: "150px" }}
            options={categoryOptions}
            onSelected={(option) => {
              setCategory(option as ArticleCategoryGroup);
            }}
            value={category}
          />
        </Box>
        <Box>
          <Typography variant="body1">Language</Typography>
          <VrsSelect
            outerSx={{ width: "150px" }}
            options={languageOptions}
            onSelected={(option) => {
              setContentLanguage(option as Lang);
            }}
            value={contentLanguage}
          />
        </Box>
      </Stack>
      <Stack ml="auto" direction="row" spacing={2}>
        <Box pb={2} pt={2.2} display="flex" justifyContent="flex-start">
          <VrsButton
            buttonType="primary"
            size="small"
            onClick={() => navigate(replaceRouteParam("ARTICLE_DETAILS", ":articleId", "new"))}
          >
            <Typography pl={3} variant="bodySemibold" fontWeight={400} pt={0.25}>
              Add New Article
            </Typography>
            <Box pr={3} pl={1} pt={0.75}>
              <VrsIcon name={IconName.Plus} size={18} stroke={theme.veris.colors.neutrals.white} />
            </Box>
          </VrsButton>
        </Box>
      </Stack>

      <ArticlesTable
        fetchEducationalArticles={() => dispatch(searchArticlesAsync({}))}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </Box>
  );
};
