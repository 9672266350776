import React, { useEffect, useRef, useState } from "react";
import { SessionWarningTimeout } from "../../constants";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  localizedLogout,
  selectIsLoggedIn,
  selectUserId,
  startConnecting,
  terminateConnection,
} from "../shared/slices/authSlice";
import { SessionModal } from "./SessionModal";
import { unregisterFirebaseTokenAsync } from "../Notifications/notificationsSlice";

interface SessionContainerProps {
  children: React.ReactNode;
}

export const SessionContainer = ({ children }: SessionContainerProps): JSX.Element => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const currentUserId = useAppSelector(selectUserId);
  const [modalOpenState, setModalOpenState] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const dispatch = useAppDispatch();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const destroyTimeout = (): void => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  };

  const onPrompt = (): void => {
    destroyTimeout();
    setTimeoutCountdown(2 * 60 * 1000);
    setModalOpenState(true);
  };

  const createTimeout = (): void => {
    destroyTimeout();
    timeoutRef.current = setTimeout(() => {
      onPrompt();
    }, SessionWarningTimeout * 60 * 1000);
  };

  const handleLogout = (): void => {
    destroyTimeout();
    setModalOpenState(false);
    dispatch(unregisterFirebaseTokenAsync(Number(currentUserId)));
    dispatch(localizedLogout());
  };

  const handleContinueSession = (): void => {
    createTimeout();
    setModalOpenState(false);
  };

  useEffect(() => {
    const clickHandler = () => {
      createTimeout();
    };

    if (isLoggedIn) {
      dispatch(startConnecting());
      createTimeout();
      document.addEventListener("click", clickHandler);
    }

    return () => {
      dispatch(terminateConnection());
      document.removeEventListener("click", clickHandler);
      destroyTimeout();
    };
  }, [isLoggedIn]);

  return (
    <>
      {modalOpenState && (
        <SessionModal
          isOpen={!!isLoggedIn && modalOpenState}
          handleContinueSession={handleContinueSession}
          handleLogout={handleLogout}
          countdownTimer={timeoutCountdown}
        />
      )}
      {children}
    </>
  );
};
