import React, { useEffect, useState } from "react";
import { Box, DialogContent, Grid, Typography, useTheme } from "@mui/material";
import {
  AccountStatus,
  AdminDetailUserInfoResponse,
  CareTeamMemberInfo,
  UserTypeResponse,
} from "@veris-health/user-ms/lib/v1";
import { IconName, VrsButton, VrsDialog, VrsIcon } from "@veris-health/web-core";
import { InvitePatient } from "../components/InvitePatient";
import { getUsers } from "../../Users/api/usersApi";
import { Status } from "../../shared/interfaces";
import CareTeamMembersPicker from "../../Users/components/CareTeamMembersPicker";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { teamsApiV1 } from "../../../api";
import { setPatientMainOncologist } from "../../Users/api/hospitalsApi";
import { InvitePatientMainOncologist } from "../components/InvitePatientMainOncologist";

const addMembersToCareTeam = async (
  userId: number,
  members: Array<CareTeamMemberInfo | AdminDetailUserInfoResponse>,
) => {
  await Promise.all(
    members.map((member) =>
      teamsApiV1.addCareTeamMember(userId, {
        member_id: member.id,
        patient_id: userId,
      }),
    ),
  );
};

const InviteUserContainer = (): JSX.Element => {
  const [step, setStep] = useState<number>(1);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState<number>();
  const [careTeamMembers, setCareTeamMembers] = useState<AdminDetailUserInfoResponse[]>([]);
  const [networkStatus, setNetworkStatus] = useState<Status>("idle");
  const [selectedCareTeam, setSelectedCareTeam] = React.useState<
    {
      value: AdminDetailUserInfoResponse | CareTeamMemberInfo;
      label: string | undefined;
    }[]
  >([]);

  const theme = useTheme();

  useEffect(
    () => () => {
      setStep(1);
      setSelectedCareTeam([]);
      setCareTeamMembers([]);
    },
    [open],
  );

  const setMainOncologist = async (mainOncologistId: string) => {
    try {
      if (userId) {
        setNetworkStatus("loading");
        await setPatientMainOncologist(userId, {
          med_staff_id: +mainOncologistId,
        });
        SnackbarUtils.success("Patient main oncologist was set.");
        setStep(1);
        setOpen(false);
      }
    } catch (err) {
      SnackbarUtils.error("Could not set main oncologist");
    } finally {
      setNetworkStatus("idle");
    }
  };

  const createCareTeam = async () => {
    if (userId) {
      try {
        setNetworkStatus("loading");
        await addMembersToCareTeam(
          userId,
          selectedCareTeam.map((entry) => entry.value),
        );
        SnackbarUtils.success("Users added to Clinical care Team.");
        setStep(3);
      } catch (err) {
        SnackbarUtils.error("Could not add clinical care team members, please try again.");
      } finally {
        setNetworkStatus("idle");
      }
    }
  };

  const fetchHospitalUsers = (hospitalId: number) => {
    setNetworkStatus("loading");
    getUsers({
      type: [UserTypeResponse.MedStaff],
      hospital: hospitalId,
      accountStatus: AccountStatus.Active,
    })
      .then((response) => {
        setCareTeamMembers(response.items);
        setNetworkStatus("idle");
      })
      .catch(() => {
        setNetworkStatus("failed");
        SnackbarUtils.error("Something went wrong while fetching hospital members.");
      });
  };

  let title;
  let currentForm;
  if (step === 1) {
    title = "Create Patient";
    currentForm = (
      <InvitePatient
        handlePatientCreated={(patientUserId: number, hospitalId: number) => {
          setUserId(patientUserId);
          fetchHospitalUsers(hospitalId);
          setStep(2);
        }}
      />
    );
  } else if (step === 2) {
    title = "Select Clinical Care Team Members";
    currentForm = (
      <>
        <CareTeamMembersPicker
          careTeamMembers={careTeamMembers}
          careTeamMembersStatus={networkStatus}
          handleChange={setSelectedCareTeam}
          selectedCareTeam={selectedCareTeam}
        />
        <Grid item xs={12} marginTop={4} style={{ display: "flex", flexDirection: "row-reverse" }}>
          <VrsButton
            buttonType="primary"
            disabled={selectedCareTeam.length === 0 || networkStatus === "loading"}
            variant="contained"
            color="primary"
            onClick={createCareTeam}
          >
            Next
          </VrsButton>
        </Grid>
      </>
    );
  } else if (step === 3) {
    title = "Select Main Oncologist";
    currentForm = (
      <InvitePatientMainOncologist
        selectedCareTeam={selectedCareTeam}
        setMainOncologist={setMainOncologist}
        status={networkStatus}
      />
    );
  }

  return (
    <Box pb={2} display="flex" justifyContent="flex-start">
      <VrsButton buttonType="primary" size="small" onClick={() => setOpen(true)}>
        <Typography pl={3} variant="bodySemibold" fontWeight={400} pt={0.25}>
          Create Patient
        </Typography>
        <Box pr={3} pl={1} pt={0.75}>
          <VrsIcon name={IconName.Plus} size={18} stroke={theme.veris.colors.neutrals.white} />
        </Box>
      </VrsButton>
      <VrsDialog
        open={open}
        onClose={() => setOpen(false)}
        title={title}
        sx={{ "& .MuiPaper-root": { height: "auto" } }}
      >
        <DialogContent sx={{ overflowY: "unset" }}>{currentForm}</DialogContent>
      </VrsDialog>
    </Box>
  );
};

export default InviteUserContainer;
