import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FeedbackSurveyByRegistrationPeriod,
  FeedbackSurveyByRegistrationPeriodTypeEnum,
  Lang,
  QuestionnaireLabel,
  SurveyQuestion,
} from "@veris-health/virtual-doc-ms/lib/v1/api";
import { Box, Typography } from "@mui/material";
import { Option, VrsButton, VrsSelect, dateFormats } from "@veris-health/web-core";
import dayjs, { Dayjs } from "dayjs";
import { isEqual } from "lodash";
import { VrsTabsContainer } from "../../../ui/components/VrsTabsContainer";
import { useCurrentSurvey } from "../hooks/useCurrentSurvey";
import { StyledTextField } from "../../Users/components/SetHospitalAndCareTeamForm";
import { ContentLanguageEnum } from "../../EducationalArea/containers/ArticleEditorContainer";
import { useHospitals } from "../../../hooks/useHospitals";
import { VrsDateInputField } from "../../../ui/components/VrsDateField";
import { createFeedbackSurvey, updateFeedbackSurvey } from "../api/surveys";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { extractErrorMessage } from "../../shared/helpers";
import { Routes } from "../../../routes-config";
import { isDateInPast } from "../../../utils/date";

type SurveyQueryParams = {
  surveyId: string;
};

type Questions = {
  [language in ContentLanguageEnum]: {
    [question: string]: string;
  };
};

export const SurveyContainer = () => {
  const navigate = useNavigate();
  const [hospital, setHospital] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().add(1, "day"));
  const [registeredFrom, setRegisteredFrom] = useState<Dayjs | null>();
  const [registeredTo, setRegisteredTo] = useState<Dayjs | null>();
  const hospitals = useHospitals() as Option[];
  const { surveyId } = useParams<SurveyQueryParams>();
  const [questions, setQuestions] = useState<Questions>({
    [ContentLanguageEnum.English]: {
      "Question 1": "",
      "Question 2": "",
      "Question 3": "",
    },
    [ContentLanguageEnum.Spanish]: {
      "Question 1": "",
      "Question 2": "",
      "Question 3": "",
    },
  });
  const { survey } = useCurrentSurvey(surveyId);

  const surveyChanged = useMemo(() => {
    return (
      survey &&
      isEqual(questions, convertJsonToQuestionsState(survey?.questions)) &&
      isEqual(startDate, dayjs(survey.survey_start_date))
    );
  }, [survey, questions, startDate]);

  useEffect(() => {
    if (survey && hospitals) {
      const selectedHospital = hospitals.find(
        (hospitalOption) => String(survey.hospital_id) === hospitalOption.value,
      );
      if (selectedHospital) {
        setHospital(selectedHospital.value);
      }
      setQuestions(convertJsonToQuestionsState(survey.questions));
      setStartDate(dayjs(survey.survey_start_date));
      setRegisteredFrom(
        dayjs((survey.config as FeedbackSurveyByRegistrationPeriod).registered_from),
      );
      setRegisteredTo(dayjs((survey.config as FeedbackSurveyByRegistrationPeriod).registered_to));
    }
  }, [survey, hospitals]);

  const handleInputChange = (language: ContentLanguageEnum, question: string, value: string) => {
    setQuestions((prevAnswers) => ({
      ...prevAnswers,
      [language]: {
        ...prevAnswers[language],
        [question]: value,
      },
    }));
  };

  const onSave = async () => {
    if (!surveyId || !startDate || !hospital) {
      return;
    }
    const formattedStartDate = startDate
      .startOf("day")
      .format(`${dateFormats["YYYY-MM-DD"]}T${dateFormats["HH:mm:ss"]}`);
    const formattedRegisteredFromDate = registeredFrom
      ? registeredFrom
          .startOf("day")
          .format(`${dateFormats["YYYY-MM-DD"]}T${dateFormats["HH:mm:ss"]}`)
      : undefined;
    const formattedRegisteredToDate = registeredTo
      ? registeredTo
          .startOf("day")
          .format(`${dateFormats["YYYY-MM-DD"]}T${dateFormats["HH:mm:ss"]}`)
      : undefined;
    if (surveyId === "new") {
      try {
        await createFeedbackSurvey({
          hospital_id: +hospital,
          survey_start_date: formattedStartDate,
          questions: createQuestionsArrayFromQuestions(questions),
          config: {
            type: FeedbackSurveyByRegistrationPeriodTypeEnum.FeedbackSurvey,
            registered_from: formattedRegisteredFromDate,
            registered_to: formattedRegisteredToDate,
          },
        });
        SnackbarUtils.success(`Survey successfully created!`);
        navigate(Routes.SURVEYS);
      } catch (e) {
        SnackbarUtils.error(extractErrorMessage(e) || "Something went wrong!");
      }
    } else {
      await updateFeedbackSurvey(+surveyId, {
        survey_start_date: formattedStartDate,
        questions: createQuestionsArrayFromQuestions(questions),
      });
      SnackbarUtils.success(`Survey successfully updated!`);
      navigate(Routes.SURVEYS);
    }
  };

  const isFormValid = () =>
    Object.values(questions).every((language) =>
      Object.values(language).every((question) => !!question),
    ) &&
    startDate &&
    hospital;

  if (!surveyId) {
    return null;
  }

  const disableEditing = survey && isDateInPast(survey?.survey_start_date);

  return (
    <>
      <Box sx={{ display: "flex", padding: "1rem", gap: 2, justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box>
            <Typography variant="body1">Hospital*</Typography>
            <VrsSelect
              outerSx={{ width: "150px" }}
              value={hospital}
              disabled={surveyId !== "new" || disableEditing}
              options={hospitals}
              placeholder="Choose hospital"
              onSelected={(value) => setHospital(value)}
              maxHeight="250px"
            />
          </Box>
          <Box>
            <Typography variant="body1">Survey start Date*</Typography>
            <VrsDateInputField
              name=""
              label=""
              disabled={disableEditing}
              onChange={(name, value) => setStartDate(value)}
              value={dayjs(startDate)}
              minDate={dayjs().add(1, "day")}
            />
          </Box>
          <Box>
            <Typography variant="body1">Registered From</Typography>
            <VrsDateInputField
              name=""
              label=""
              disabled={surveyId !== "new"}
              onChange={(name, value) => setRegisteredFrom(value)}
              value={registeredFrom ? dayjs(registeredFrom) : undefined}
              maxDate={dayjs()}
            />
          </Box>
          <Box>
            <Typography variant="body1">Registered Until</Typography>
            <VrsDateInputField
              name=""
              label=""
              disabled={surveyId !== "new"}
              onChange={(name, value) => setRegisteredTo(value)}
              value={registeredTo ? dayjs(registeredTo) : undefined}
              maxDate={dayjs()}
            />
          </Box>
        </Box>

        <Box sx={{ alignSelf: "center" }}>
          <VrsButton
            disabled={!isFormValid() || disableEditing || surveyChanged}
            buttonType="primary"
            size="small"
            onClick={() => onSave()}
          >
            Save
          </VrsButton>
        </Box>
      </Box>
      <VrsTabsContainer
        tabsContainerContent={{
          English: (
            <Box
              key="English"
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
                gap: 2,
                width: "40rem",
              }}
            >
              <StyledTextField
                label="Question 1*"
                sx={{ width: "40rem" }}
                multiline
                value={questions[ContentLanguageEnum.English]["Question 1"]}
                onChange={(event) =>
                  handleInputChange(ContentLanguageEnum.English, "Question 1", event.target.value)
                }
              />
              <StyledTextField
                label="Question 2*"
                sx={{ width: "40rem" }}
                multiline
                value={questions[ContentLanguageEnum.English]["Question 2"]}
                onChange={(event) =>
                  handleInputChange(ContentLanguageEnum.English, "Question 2", event.target.value)
                }
              />
              <StyledTextField
                label="Question 3*"
                sx={{ width: "40rem" }}
                multiline
                value={questions[ContentLanguageEnum.English]["Question 3"]}
                onChange={(event) =>
                  handleInputChange(ContentLanguageEnum.English, "Question 3", event.target.value)
                }
              />
            </Box>
          ),
          Spanish: (
            <Box
              key="Spanish"
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
                gap: 2,
                width: "40rem",
              }}
            >
              <StyledTextField
                label="Question 1*"
                sx={{ width: "40rem" }}
                multiline
                value={questions[ContentLanguageEnum.Spanish]["Question 1"]}
                onChange={(event) =>
                  handleInputChange(ContentLanguageEnum.Spanish, "Question 1", event.target.value)
                }
              />
              <StyledTextField
                label="Question 2*"
                sx={{ width: "40rem" }}
                multiline
                value={questions[ContentLanguageEnum.Spanish]["Question 2"]}
                onChange={(event) =>
                  handleInputChange(ContentLanguageEnum.Spanish, "Question 2", event.target.value)
                }
              />

              <StyledTextField
                label="Question 3*"
                sx={{ width: "40rem" }}
                multiline
                value={questions[ContentLanguageEnum.Spanish]["Question 3"]}
                onChange={(event) =>
                  handleInputChange(ContentLanguageEnum.Spanish, "Question 3", event.target.value)
                }
              />
            </Box>
          ),
        }}
      />
    </>
  );
};

function createQuestionsArrayFromQuestions(questionsState: Questions): QuestionnaireLabel[] {
  const questionKeys = Object.keys(questionsState[ContentLanguageEnum.English]);

  return questionKeys.map((questionKey) => ({
    [Lang.En]: questionsState[ContentLanguageEnum.English][questionKey],
    [Lang.Es]: questionsState[ContentLanguageEnum.Spanish][questionKey],
  }));
}

function convertJsonToQuestionsState(questions: SurveyQuestion[]) {
  const questionsState: Questions = {
    [ContentLanguageEnum.English]: {},
    [ContentLanguageEnum.Spanish]: {},
  };

  questions.forEach((question, index) => {
    const questionId = `Question ${index + 1}`;
    const questionTextEnglish = question.question.en;
    const questionTextSpanish = question.question.es;
    questionsState[ContentLanguageEnum.English][questionId] = questionTextEnglish;
    questionsState[ContentLanguageEnum.Spanish][questionId] = questionTextSpanish;
  });

  return questionsState;
}
