import React, { useEffect } from "react";
import {
  AdminDetailUserInfoResponse,
  HospitalItem,
  PatientBasicInfo,
} from "@veris-health/user-ms/lib/v1/";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Table, Typography, useTheme } from "@mui/material";
import { VrsButton, VrsConfirmationModals, VrsErrorMsg } from "@veris-health/web-core";
import { Status } from "../../shared/interfaces";
import { replaceRouteParam } from "../../../routes-config";
import {
  Column,
  TableColumnNames,
  VrsSortedTableHeader,
} from "../../../ui/components/VrsSortedTableHeader";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../ui/components/Tables/shared/StyledTableElements";
import { genericSorter, Order } from "../../../utils/sorting";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { removeCareTeamMember } from "../userSlice";

const ActionsMenu = ({
  patient,
  removeUser,
  user,
}: {
  patient: PatientBasicInfo;
  removeUser: (userId: number) => void;
  user: AdminDetailUserInfoResponse;
}) => {
  const [confimationModalOpen, setConfirmationModalOpen] = React.useState<boolean>(false);

  return (
    <Box>
      {confimationModalOpen && (
        <VrsConfirmationModals
          isOpen={confimationModalOpen}
          handleClose={() => setConfirmationModalOpen(false)}
          dialogHeader="Warning!"
          dialogContent={`You will remove ${user.name} from ${patient.full_name}'s care team.`}
          dialogSecondContentLine="Please confirm."
          onCancel={() => setConfirmationModalOpen(false)}
          onConfirm={() => {
            removeUser(patient.id);
            setConfirmationModalOpen(false);
          }}
          cancelButtonText="Cancel"
          confirmButtonText="Continue"
          confirmButtonVariant="primary"
        />
      )}
      <VrsButton
        buttonType="secondary"
        onClick={() => {
          setConfirmationModalOpen(true);
        }}
      >
        Remove
      </VrsButton>
    </Box>
  );
};

interface AssignedPatientsProps {
  patients: PatientBasicInfo[];
  status: Status;
  user: AdminDetailUserInfoResponse;
  hospitals: HospitalItem[];
}

const columns: Column[] = [
  {
    id: TableColumnNames.Name,
    label: TableColumnNames.Name,
    orderBy: "full_name",
  },
  {
    id: TableColumnNames.Hospital,
    label: TableColumnNames.Hospital,
    orderBy: "hospital_id",
  },

  {
    id: TableColumnNames.Actions,
    label: TableColumnNames.Actions,
  },
];

export const AssignedPatients = ({
  patients,
  status,
  hospitals,
  user,
}: AssignedPatientsProps): JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [sortedPatients, setSortedPatients] = React.useState(patients);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSortedPatients(genericSorter(patients, order, orderBy as keyof PatientBasicInfo));
  }, [patients, order, orderBy]);

  useEffect(() => {
    setOrder("asc");
    setOrderBy("");
  }, []);

  const handleNavigation = (id: number) => {
    navigate(replaceRouteParam("USER_DETAILS", ":userId", String(id)));
  };

  const getPatientHospital = (id: number) => hospitals.find((hospital) => hospital.id === id)?.name;

  const removeUser = (userId: number) => {
    if (user) {
      dispatch(removeCareTeamMember({ userId, memberId: user.id }));
    }
  };

  return (
    <Box
      my={2}
      px={2}
      py={2}
      sx={{
        backgroundColor: theme.veris.colors.neutrals.white,
        border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        borderRadius: theme.spacing(1),
      }}
    >
      <Typography variant="h5" mb={2}>
        Assigned Patients
      </Typography>
      {status === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {status === "idle" && (
        <Box>
          {patients.length === 0 && <Typography variant="body">No assigned patients.</Typography>}
          {patients.length > 0 && (
            <Box sx={{ height: "29vh", overflow: "scroll" }}>
              <Table>
                <VrsSortedTableHeader
                  columns={columns}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  order={order}
                  orderBy={orderBy}
                />
                <StyledTableBody>
                  {sortedPatients.map((patient) => (
                    <StyledTableRow key={patient.id} sx={{ cursor: "pointer" }}>
                      {columns.map((column) => (
                        <StyledTableCell key={column.id}>
                          {column.id === TableColumnNames.Name && (
                            <Typography
                              variant="body2"
                              color={theme.veris.colors.neutrals["grey-4"]}
                              sx={{ display: "flex", alignItems: "center", gap: 2 }}
                              onClick={() => handleNavigation(patient.id)}
                            >
                              {patient.full_name}
                              {patient.account_status && (
                                <Typography>
                                  • {patient.account_status.replaceAll("-", " ")}
                                </Typography>
                              )}
                            </Typography>
                          )}
                          {column.id === TableColumnNames.Hospital && (
                            <Typography
                              variant="body2"
                              color={theme.veris.colors.neutrals["grey-4"]}
                            >
                              {patient.hospital_id && getPatientHospital(patient.hospital_id)}
                            </Typography>
                          )}
                          {column.id === TableColumnNames.Actions && user && (
                            <ActionsMenu patient={patient} removeUser={removeUser} user={user} />
                          )}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </StyledTableBody>
              </Table>
            </Box>
          )}
        </Box>
      )}
      {status === "failed" && <VrsErrorMsg size="small" />}
    </Box>
  );
};
