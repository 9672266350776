import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArticleCategoryGroup,
  CreateEducationalArticle,
  GetEducationalArticle,
  Lang,
} from "@veris-health/virtual-doc-ms/lib/v1/api";
import { Box, Typography } from "@mui/material";
import { VrsSelect } from "@veris-health/web-core";
import { VrsTabsContainer } from "../../../ui/components/VrsTabsContainer";
import { EditorContainer } from "../components/editor/EditorContainer";
import { addArticle, getTranslationOfArticle, updateArticle } from "../api/articlesApi";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getCategoriesAsync, selectCategories } from "../articlesSlice";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { extractErrorMessage } from "../../shared/helpers";
import { Routes } from "../../../routes-config";

export const categoryLabels: { [key in ArticleCategoryGroup]: string } = {
  [ArticleCategoryGroup.Symptom]: "Symptom",
  [ArticleCategoryGroup.QualityOfLife]: "Wellbeing",
  [ArticleCategoryGroup.Goals]: "Goals",
  [ArticleCategoryGroup.Uncategorized]: "Uncategorized",
};

export enum ContentLanguageEnum {
  English = "English",
  Spanish = "Spanish",
}

type ArticleQueryParams = {
  articleId: string;
};

export const ArticleEditorContainer = () => {
  const [title, setTitle] = useState<{ [key in ContentLanguageEnum]: string }>({
    English: "",
    Spanish: "",
  });

  const [content, setContent] = useState<{ [key in ContentLanguageEnum]: string }>({
    English: "",
    Spanish: "",
  });
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<ArticleCategoryGroup>(
    ArticleCategoryGroup.Uncategorized,
  );
  const [englishArticle, setEnglishArticle] = useState<GetEducationalArticle>();
  const [spanishArticle, setSpanishArticle] = useState<GetEducationalArticle>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { articleId } = useParams<ArticleQueryParams>();
  const categories = useAppSelector(selectCategories);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const translatedArticles = await getTranslationOfArticle(Number(articleId));

        const en = translatedArticles.items.find((item) => item.content_language === "en");
        const es = translatedArticles.items.find((item) => item.content_language === "es");

        if (en && es) {
          setContent({
            English: en.body,
            Spanish: es.active ? es.body : "",
          });

          setTitle({
            English: en.title,
            Spanish: es.title,
          });
          setEnglishArticle(en);
          setSpanishArticle(es);
        }

        setCategory(en?.category as ArticleCategoryGroup);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (articleId !== "new") {
      fetchData();
    }
  }, [articleId]);

  useEffect(() => {
    if (!(Array.isArray(categories) && categories.length > 0)) {
      dispatch(getCategoriesAsync());
    }
  }, [categories]);

  const onSave = async (lang: Lang) => {
    try {
      if (articleId === "new") {
        const articles: CreateEducationalArticle[] = [
          {
            title: title.English,
            body: content.English,
            category,
            content_language: Lang.En,
            active: true,
          },
        ];

        if (!content.English) {
          SnackbarUtils.error("English article is required");
          return;
        }

        if (title.Spanish.length && content.Spanish.length) {
          articles.push({
            title: title.Spanish,
            body: content.Spanish,
            category,
            content_language: Lang.Es,
            active: true,
          });
        }
        await addArticle(articles);
        SnackbarUtils.success("Educational Article created!");
        navigate(Routes.ARTICLES);
      } else {
        const id = lang === Lang.En ? englishArticle?.id : spanishArticle?.id;
        if (!content.English) {
          SnackbarUtils.error("English article is required");
          return;
        }
        await updateArticle(Number(id), {
          title: lang === Lang.En ? title.English : title.Spanish,
          body: lang === Lang.En ? content.English : content.Spanish,
          content_language: lang,
          category,
          active: true,
        });
        SnackbarUtils.success("Educational Article updated successfully!");
        navigate(Routes.ARTICLES);
      }
    } catch (e) {
      SnackbarUtils.error(extractErrorMessage(e) || "Something went wrong!");
    }
  };

  const categoryOptions = categories.map((ctg) => ({
    label: categoryLabels[ctg] || ctg,
    value: ctg,
  }));

  const onChange = (htmlString: string, lang: Lang) => {
    setContent({
      English: lang === Lang.En ? htmlString : content.English,
      Spanish: lang === Lang.Es ? htmlString : content.Spanish,
    });
  };

  if (loading || !articleId) {
    return null;
  }

  return (
    <>
      <Box sx={{ display: "flex", padding: "1rem", gap: 2, justifyContent: "flex-end" }}>
        <Box>
          <Typography variant="body1">Category</Typography>
          <VrsSelect
            outerSx={{ width: "150px" }}
            options={categoryOptions}
            onSelected={(option) => {
              setCategory(option as ArticleCategoryGroup);
            }}
            value={category}
          />
        </Box>
        {englishArticle?.read_time_minutes && (
          <Box display="flex" flexDirection="column" justifyContent="end">
            <Typography variant="body1">
              Read time: {englishArticle?.read_time_minutes} min
            </Typography>
          </Box>
        )}
      </Box>
      <VrsTabsContainer
        tabsContainerContent={{
          English: (
            <EditorContainer
              key={`${englishArticle?.id || "english-new"}`}
              content={content.English}
              title={title.English}
              setTitle={(value) => setTitle({ ...title, English: value })}
              onSave={() => onSave(Lang.En)}
              onChange={onChange}
              lang={Lang.En}
            />
          ),
          Spanish: (
            <EditorContainer
              key={`${spanishArticle?.id || "spanish-new"}`}
              content={content.Spanish}
              title={title.Spanish}
              setTitle={(value) => setTitle({ ...title, Spanish: value })}
              onSave={() => onSave(Lang.Es)}
              onChange={onChange}
              lang={Lang.Es}
            />
          ),
        }}
      />
    </>
  );
};
