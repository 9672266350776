import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  DialogContent,
  FormLabel,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { AxiosError } from "axios";
import { ApplicationType } from "@veris-health/communication-ms/lib/v1";
import { Option, VrsButton, VrsDialog, VrsSelect } from "@veris-health/web-core";
import { VrsFormInputField } from "../../../ui/components/VrsFormInputField";
import { sendReleaseNotification } from "../api/actionsApi";
import SnackbarUtils from "../../../utils/SnackbarUtils";

const initialValues = {
  link: "",
  version: "",
  appType: ApplicationType.WebStaff,
};

const validationSchema = yup.object().shape({
  link: yup
    .string()
    .required("Please provide a link to the release notes.")
    .matches(
      /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi,
      "Should be a valid url.",
    ),
  version: yup.string().required("Please provide a version number."),
  appType: yup.string().required("Please select a notification type"),
});

const appTypeOptions: Option[] = [
  {
    label: "Web Tech Staff",
    value: ApplicationType.WebTechStaff,
  },
  {
    label: "Web Medical Staff",
    value: ApplicationType.WebStaff,
  },
  {
    label: "Mobile Staff",
    value: ApplicationType.MobileStaff,
  },
  {
    label: "Mobile Patients",
    value: ApplicationType.MobilePatient,
  },
];

export const SendRelease = (): JSX.Element => {
  const [showReleaseForm, setShowReleaseForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  const formik = useFormik({
    onSubmit: ({ link, version, appType }) => {
      setIsLoading(true);
      sendReleaseNotification(link, version, appType)
        .then(() => {
          SnackbarUtils.success("Release notification sent.");
          handleClose();
        })
        .catch((err: AxiosError) => {
          const errorMessage = err.response
            ? err.response.data.message
            : "Something went wrong, please try again";
          SnackbarUtils.error(errorMessage);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    initialValues,
    validationSchema,
  });

  const {
    resetForm,
    values,
    handleSubmit,
    handleChange,
    touched,
    errors,
    handleBlur,
    isValid,
    dirty,
    setFieldValue,
  } = formik;

  const { link, version, appType } = values;

  function handleClose() {
    setShowReleaseForm(false);
    resetForm();
  }

  return (
    <Box pb={2}>
      <VrsButton buttonType="primary" onClick={() => setShowReleaseForm(true)}>
        <Typography variant="bodySemibold" fontWeight={400} px={1} pt={0.25}>
          Send Release Notification
        </Typography>
      </VrsButton>
      <VrsDialog
        title="Send Release Notification"
        sx={{ "& .MuiPaper-root": { height: "auto" } }}
        open={showReleaseForm}
        onClose={() => handleClose()}
      >
        <DialogContent sx={{ overflowY: "unset" }}>
          <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
              <Grid
                item
                xs={12}
                container
                spacing={4}
                style={{ margin: "auto", width: "auto", paddingLeft: "0" }}
              >
                <Grid item xs={12} style={{ width: "91.5%", paddingLeft: "0" }}>
                  <VrsFormInputField
                    name="version"
                    onChange={handleChange}
                    value={version}
                    label="Version"
                    onBlur={handleBlur}
                    helperText={touched.version && errors.version ? errors.version : " "}
                    error={!!touched.version && !!errors.version}
                    isValidField={!!errors.version}
                  />
                </Grid>
                <Grid item xs={12} pl={0} style={{ width: "91.5%", paddingLeft: "0" }}>
                  <VrsFormInputField
                    name="link"
                    onChange={handleChange}
                    value={link}
                    label="Link"
                    onBlur={handleBlur}
                    helperText={touched.link && errors.link ? errors.link : " "}
                    error={!!touched.link && !!errors.link}
                    isValidField={!!errors.link}
                  />
                </Grid>
                <Grid item xs={12} pl={0} style={{ width: "91.5%", paddingLeft: "0" }}>
                  <Typography m={0} variant="subtitle2">
                    Application Type
                    <FormLabel />
                  </Typography>
                  <VrsSelect
                    options={appTypeOptions}
                    onSelected={(value) => setFieldValue("appType", value)}
                    name="notificationType"
                    innerSx={{ padding: theme.spacing(0.75, 0.75, 0.75, 0) }}
                    placeholder="Select application type"
                    value={appType}
                    onBlur={handleBlur}
                    error={!!touched.appType && !!errors.appType}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  marginTop={4}
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  <VrsButton
                    buttonType="primary"
                    disabled={!isValid || !dirty}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Send Notification
                    {isLoading && (
                      <CircularProgress
                        sx={{
                          marginLeft: theme.spacing(2),
                          color: theme.veris.colors.neutrals.white,
                        }}
                        size={16}
                      />
                    )}
                  </VrsButton>
                </Grid>
              </Grid>
            </form>
          </FormikProvider>
        </DialogContent>
      </VrsDialog>
    </Box>
  );
};
