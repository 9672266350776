import React from "react";
import {
  Box,
  inputBaseClasses,
  Radio,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { VrsAvatar } from "@veris-health/web-core";
import { AdminDetailUserInfoResponse, PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v1";

export interface VrsUserSelectionListProps {
  users: AdminDetailUserInfoResponse[];
  setSelectedUser: (user?: AdminDetailUserInfoResponse) => void;
  selectedId?: number;
}

export const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    ...theme.typography.body,
    padding: theme.spacing(1, 0),
  },
}));

export function VrsUserSelectionList({
  users,
  setSelectedUser,
  selectedId,
}: VrsUserSelectionListProps): JSX.Element {
  return (
    <Box>
      {users.map((user) => (
        <Box
          display="flex"
          alignItems="center"
          sx={{
            borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
            cursor: "pointer",
          }}
          py={1}
          key={user.id}
          onClick={() => {
            if (
              user.patient_status === PatientStatusEnumAPI.Inactive ||
              user.patient_status === PatientStatusEnumAPI.Deceased
            )
              return;
            setSelectedUser(selectedId === user.id ? undefined : user);
          }}
        >
          <Radio
            checked={selectedId === user.id}
            disabled={
              user.patient_status === PatientStatusEnumAPI.Inactive ||
              user.patient_status === PatientStatusEnumAPI.Deceased
            }
            sx={{ color: (theme) => theme.veris.colors.amethyst.normal }}
          />
          <Box display="flex" alignItems="center">
            <VrsAvatar name={user.name} size={30} />
            <Typography variant="body" mx={1}>
              {user.name}
            </Typography>
            <Typography variant="body" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
              {user.hospital && user.hospital.length > 0 ? user.hospital[0].name : ""}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
