import React, { ChangeEvent, FocusEvent } from "react";
import {
  Typography,
  FormControl,
  TextField,
  FormLabel,
  styled,
  InputAdornment,
  outlinedInputClasses,
  inputBaseClasses,
  Box,
} from "@mui/material";
import { Field } from "formik";
import { IconName, VrsIcon, AlertIcon } from "@veris-health/web-core";

export interface VrsFormInputFieldProps {
  name: string;
  type?: string;
  label?: string;
  value: string | boolean;
  error: boolean;
  helperText?: string;
  errorMessage?: string;
  isValidField: boolean | number;
  onChange: (e: string | ChangeEvent) => void;
  onBlur?: (e: FocusEvent<unknown>) => void;
  onFocus?: () => void;
  disabled?: boolean;
  showPassword?: boolean;
  handleClickShowPassword?: () => void;
  placeholder?: string;
  hideWarningIcon?: boolean;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
}

interface StyledVrsFieldProps {
  isValidField?: boolean;
}

export const VrsField = styled(Field, {
  shouldForwardProp: (prop: string) => prop !== "isValidField",
})<StyledVrsFieldProps>(({ theme, isValidField }) => ({
  [`& .${inputBaseClasses.input}`]: {
    ...theme.typography.body,
    borderRadius: 1.5,
    padding: theme.spacing(1.72, 0, 1.28, 1.25),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(2.4, 0, 1.8, 1.25),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.43, 0, 1.2, 1.25),
    },
    ":-internal-autofill-selected": {
      boxShadow: "0 0 0 30px white inset !important",
    },
  },
  "& .MuiSvgIcon-root": {
    color: theme.veris.colors.amethyst.normal,
  },
  [`& .${outlinedInputClasses.root}`]: {
    borderRadius: theme.shape.borderRadius * 1.5,
    paddingRight: theme.spacing(1.25),
    "& fieldset": {
      borderColor: theme.veris.colors.amethyst.light,
      ...(isValidField && {
        border: `1px solid ${theme.veris.colors.amethyst.soft}`,
      }),
    },
    "&:hover fieldset": {
      border: `3px solid ${theme.veris.colors.amethyst.light}`,
    },
    [`&.${outlinedInputClasses.focused} fieldset`]: {
      border: `2px solid ${theme.veris.colors.amethyst.soft}`,
    },
  },
  [`& .${inputBaseClasses.error}`]: {
    color: theme.veris.colors.errors.normal,
    backgroundColor: theme.veris.colors.errors.soft,
    "& input": {
      color: theme.veris.colors.neutrals.black,
    },
  },
  [`& .${inputBaseClasses.root}${inputBaseClasses.disabled}`]: {
    border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
    backgroundColor: "#E2E2EA33",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    backgroundColor: theme.veris.colors.neutrals.white,
  },
}));

export const VrsFormInputField = React.memo(
  ({
    name,
    type,
    label,
    value,
    error,
    helperText,
    onChange,
    onFocus,
    onBlur,
    errorMessage,
    isValidField,
    disabled,
    showPassword,
    handleClickShowPassword,
    placeholder,
    hideWarningIcon,
    multiline,
    rows,
    required = true,
  }: VrsFormInputFieldProps): JSX.Element => {
    return (
      <FormControl fullWidth required={required}>
        {label && (
          <Typography m={0} variant="subtitle2">
            {label}
            <FormLabel />
          </Typography>
        )}
        <VrsField
          disabled={disabled}
          size="small"
          variant="outlined"
          as={TextField}
          name={name}
          type={type}
          placeholder={placeholder || label}
          onFocus={onFocus}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          helperText={helperText}
          error={error}
          isValidField={isValidField}
          InputProps={{
            multiline,
            rows,
            endAdornment: (
              <InputAdornment position="end">
                {(error || errorMessage) && !hideWarningIcon && <AlertIcon />}
                {(!error || hideWarningIcon) &&
                  ["password", "oldPassword", "newPassword", "repeatPassword"].includes(name) && (
                    <Box
                      onClick={handleClickShowPassword}
                      alignItems="center"
                      display="flex"
                      sx={{ cursor: "pointer" }}
                    >
                      <VrsIcon name={showPassword ? IconName.OpenEye : IconName.CloseEye} />
                    </Box>
                  )}
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    );
  },
);
