import { useEffect, useState } from "react";
import {
  LimitOffsetPageBaseSensorModel,
  MioSensorEnum,
  MioSortingEnum,
} from "@veris-health/med-data-ms/lib/v1/api";
import { sensorDataApiV1 } from "../../api";
import { Status } from "../shared/interfaces";

export interface Sorting {
  key: string;
  direction: "asc" | "desc";
}

function useMioDataPoints(
  userId?: number,
  limit = 25,
): {
  data: LimitOffsetPageBaseSensorModel;
  sensor: MioSensorEnum;
  requestSort: (key: string) => void;
  requestFilter: (newFilter: MioSensorEnum) => void;
  nextPage: () => void;
  status: Status;
  sorting?: Sorting;
} {
  const [data, setData] = useState<LimitOffsetPageBaseSensorModel>({ items: [], total: 0 });
  const [filter, setFilter] = useState<MioSensorEnum>(MioSensorEnum.BloodPressure);
  const [sorting, setSorting] = useState<Sorting>({
    key: "timestamp",
    direction: "desc",
  });
  const [offset, setOffset] = useState<number>(0);
  const [status, setStatus] = useState<Status>("idle");

  const requestSort = (key: string) => {
    setOffset(0);
    if (sorting?.key === key) {
      // if the same key is already being used for sorting, reverse the direction
      setSorting({
        key,
        direction: sorting.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSorting({
        key,
        direction: "asc",
      });
    }
  };

  const requestFilter = (newFilter: MioSensorEnum) => {
    setOffset(0);
    setFilter(newFilter);
  };

  const nextPage = () => {
    setOffset((prevOffset) => prevOffset + limit);
  };

  async function fetchData(id: number) {
    setStatus("loading");
    try {
      const sortBy = sorting
        ? ([`${sorting?.key}__${sorting?.direction}`] as MioSortingEnum[])
        : undefined;
      const response = await sensorDataApiV1.adminGetUserSensorData(
        id,
        filter,
        sortBy,
        limit,
        offset,
      );

      setData({
        ...response.data,
        items: offset > 0 ? [...data.items, ...response.data.items] : response.data.items,
      });
      setOffset(response.data.offset || 0);
      setStatus("idle");
    } catch (e) {
      setStatus("failed");
    }
  }

  useEffect(() => {
    if (userId) {
      fetchData(userId);
    }
  }, [userId, filter, sorting, limit, offset]);

  return {
    data,
    sensor: filter,
    requestSort,
    requestFilter,
    nextPage,
    status,
    sorting,
  };
}

export default useMioDataPoints;
