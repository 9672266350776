import React from "react";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import { VrsButton } from "@veris-health/web-core";
import { StyledAttachmentProps } from "../../containers/AttachmentsContainer";

export interface VrsChatAttachmentToggleProps {
  showAttachmentsTab: boolean;
  setShowAttachmentsTab: (value: boolean) => void;
  threadAttachmentsLength: number;
}

const AttachmentToggle = styled(VrsButton, {
  shouldForwardProp: (prop: string) => prop !== "isActive",
})<StyledAttachmentProps>(({ theme, isActive }) => ({
  color: isActive ? theme.veris.colors.neutrals.white : theme.veris.colors.neutrals.black,
  background: isActive ? theme.veris.colors.amethyst.normal : theme.veris.colors.neutrals.white,
}));

export const VrsChatAttachmentToggle = ({
  showAttachmentsTab,
  setShowAttachmentsTab,
  threadAttachmentsLength,
}: VrsChatAttachmentToggleProps): JSX.Element => {
  return (
    <Box
      display="flex"
      sx={{
        p: (theme) => theme.spacing(1),
        gap: (theme) => theme.spacing(1),
      }}
    >
      <AttachmentToggle
        buttonType="quaternary"
        isActive={!showAttachmentsTab}
        onClick={() => setShowAttachmentsTab(false)}
      >
        Chat
      </AttachmentToggle>
      <AttachmentToggle
        buttonType="quaternary"
        isActive={showAttachmentsTab}
        onClick={() => setShowAttachmentsTab(true)}
      >
        Links, Docs & Images ({threadAttachmentsLength})
      </AttachmentToggle>
    </Box>
  );
};
