import React, { useEffect, useState } from "react";
import { IconName, VrsAlert, VrsButton, VrsIconButton } from "@veris-health/web-core";
import {
  Typography,
  FormControl,
  Grid,
  Checkbox,
  Button,
  Box,
  useMediaQuery,
  Theme,
  Dialog,
  useTheme,
} from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import * as yup from "yup";

import { StaffType } from "@veris-health/user-ms/lib/v1";
import { Routes } from "../../routes-config";
import { VrsFormInputField } from "../../ui/components/VrsFormInputField";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  clearValidationError,
  registerTechStaffAsync,
  registerTechStaffOtpAsync,
  selectValidationError,
  selectIsLoggedIn,
  selectAuthError,
  clearAuthError,
  toggleShowPassword,
  selectIsShowPassword,
  selectOtpScreenRegister,
  setOTPScreenRegister,
  getTermsAndConditionsAsync,
  selectTermsAndConditions,
} from "../shared/slices/authSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { OTPLoginContainer } from "./OTPLoginContainer";
import { StyledActiveNavigationItem } from "../shared/styledComponents";
import { validatePassword } from "../shared/helpers";
import { TermsAndConditions } from "../TermsAndConditions";
import { emailValidation } from "../../utils/validations";

export interface TermsAndConditionsDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const TermsAndConditionsDialog = ({ open, setOpen }: TermsAndConditionsDialogProps) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
      <TermsAndConditions />
      <Box display="flex" justifyContent="center" pb={4}>
        <VrsButton onClick={() => setOpen(false)} buttonType="primary">
          Close
        </VrsButton>
      </Box>
    </Dialog>
  );
};

const Registration = (): JSX.Element => {
  const [openDialog, setOpenDialog] = useState(false);
  const passwordError = useAppSelector(selectValidationError);
  const otpScreen = useAppSelector(selectOtpScreenRegister);
  const regError = useAppSelector(selectAuthError);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const showPassword = useAppSelector(selectIsShowPassword);
  const termsAndConditions = useAppSelector(selectTermsAndConditions);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isXsScreen = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down("xs"));

  useEffect(() => {
    dispatch(getTermsAndConditionsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      if (isXsScreen) {
        navigate(Routes.MOBILE_LANDING_PAGE);
        return;
      }
      navigate(Routes.DASHBOARD);
    }
  }, [isLoggedIn, navigate, isXsScreen]);

  const initialValues = {
    name: (queryString.parse(location.search).first_name as string) || "",
    surname: (queryString.parse(location.search).last_name as string) || "",
    email: (queryString.parse(location.search).email as string) || "",
    phone: (queryString.parse(location.search).phone_number as string)
      ? `+${(queryString.parse(location.search).phone_number as string).replace(/[^0-9]/g, "")}`
      : "",
    password: "",
    confirmPass: "",
    terms: false,
    token: (queryString.parse(location.search).token as string) || "",
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("First name is required")
      .matches(/^[aA-zZ\s]+$/, "Should contain only letters"),
    surname: yup
      .string()
      .required("Last name is required")
      .matches(/^[aA-zZ\s]+$/, "Should contain only letters"),
    email: emailValidation,
    phone: yup
      .string()
      .required("Please add your phone number")
      .min(3, "Please add your phone number")
      .matches(/^[+]?\d+$/, "Should contain only digits"),
    password: yup
      .string()
      .min(8, "Password should be of minimum 8 characters length")
      .max(64, "Password should be of maximum 64 characters length")
      .test(
        "is-valid",
        "The password does not comply with password complexity requirements",
        (value) => {
          if (value) {
            const isValidPassword = validatePassword(value);
            if (isValidPassword) {
              return false;
            }
          }
          return true;
        },
      )
      .required("Password is required"),
    confirmPass: yup
      .string()
      .required("Password confirmation is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    terms: yup.boolean().oneOf([true]),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ name, surname, email, phone, token }) => {
      dispatch(
        registerTechStaffAsync({
          first_name: name,
          last_name: surname,
          email,
          phone_number: phone,
          email_verification_token: token,
          type: StaffType.TechStaff,
        }),
      );
    },
  });

  const { errors, handleSubmit, handleChange, handleBlur, isValid, dirty, validateField } = formik;
  const { name, surname, email, phone, password, confirmPass, terms, token } = formik.values;

  useEffect(() => {
    if (passwordError && (!email || !password)) {
      dispatch(clearValidationError());
      clearAuthError();
    }
  }, [passwordError, dispatch, email, password]);

  useEffect(() => {
    if (showPassword && !password) dispatch(toggleShowPassword());
  }, [showPassword, dispatch, password]);

  return (
    <>
      {regError && (
        <Grid item xs={10} container spacing={4} style={{ margin: "0 auto" }}>
          <Grid item xs={12}>
            <VrsAlert message={regError} onClose={() => dispatch(clearAuthError())} />
          </Grid>
        </Grid>
      )}
      {otpScreen ? (
        <Box marginTop="10%">
          <Box
            display="flex"
            alignItems="center"
            marginTop="5%"
            m={5}
            onClick={() => {
              dispatch(clearValidationError());
              dispatch(clearAuthError());
              dispatch(setOTPScreenRegister(false));
            }}
            sx={{ cursor: "pointer" }}
          >
            <VrsIconButton
              iconProps={{
                name: IconName.ArrowLeft,
                stroke: theme.veris.colors.neutrals.black,
                size: 12,
              }}
            />
            <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-4"]}>
              Back
            </Typography>
          </Box>
          <Box m={5} display="flex">
            <StyledActiveNavigationItem variant="h3" m={2} key="OTP">
              Enter the 4-digit OTP
            </StyledActiveNavigationItem>
          </Box>
          <OTPLoginContainer
            onSubmit={(code) =>
              dispatch(
                registerTechStaffOtpAsync({
                  password,
                  email,
                  phone_number: phone,
                  email_verification_token: token,
                  otp: code,
                  type: StaffType.TechStaff,
                  terms_and_conditions_id: termsAndConditions?.id,
                }),
              )
            }
            onResendCode={() =>
              dispatch(
                registerTechStaffAsync({
                  first_name: name,
                  last_name: surname,
                  email,
                  phone_number: phone,
                  email_verification_token: token,
                  type: StaffType.TechStaff,
                }),
              )
            }
          />
        </Box>
      ) : (
        <FormikProvider value={formik}>
          <form onSubmit={handleSubmit}>
            <Grid item xs={12} container spacing={4} padding={8} marginY={0}>
              <Grid item xs={12} marginBottom={4}>
                <Typography
                  m={0}
                  variant="h3"
                  sx={{
                    color: theme.veris.colors.amethyst.normal,
                  }}
                >
                  Admin Register
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ width: "91.5%" }}>
                <VrsFormInputField
                  name="name"
                  type="text"
                  label="First Name"
                  value={name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.name}
                  error={Boolean(errors.name)}
                  isValidField={name.length && !errors.name}
                />
              </Grid>
              <Grid item xs={6} style={{ width: "91.5%" }}>
                <VrsFormInputField
                  name="surname"
                  type="text"
                  label="Last Name"
                  value={surname}
                  onChange={handleChange}
                  helperText={errors.surname}
                  error={Boolean(errors.surname)}
                  isValidField={surname.length && !errors.surname}
                />
              </Grid>
              <Grid item xs={6} style={{ width: "91.5%" }}>
                <VrsFormInputField
                  name="email"
                  label="Email"
                  type="text"
                  helperText={errors.email}
                  value={email}
                  error={Boolean(errors.email)}
                  isValidField={email.length && !errors.email}
                  onChange={() => email}
                />
              </Grid>
              <Grid item xs={6} style={{ width: "91.5%" }}>
                <VrsFormInputField
                  name="phone"
                  label="Phone Number"
                  value={phone}
                  onChange={handleChange}
                  onBlur={() => validateField("phone")}
                  helperText={errors.phone}
                  error={!!errors.phone}
                  isValidField={phone.length && !errors.phone}
                  type="tel"
                />
              </Grid>
              <Grid item xs={6} style={{ width: "91.5%" }}>
                <VrsFormInputField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  onFocus={() => passwordError && dispatch(clearValidationError())}
                  value={password}
                  onChange={handleChange}
                  helperText={
                    password
                      ? errors.password || passwordError
                      : "Your password must be at least 8 characters long and include 3 out of 4 of: uppercase, lowercase, number or symbol."
                  }
                  error={Boolean(errors.password) || Boolean(passwordError)}
                  isValidField={password.length && !errors.password && !passwordError}
                  showPassword={showPassword}
                  handleClickShowPassword={() => dispatch(toggleShowPassword())}
                />
              </Grid>
              <Grid item xs={6} style={{ width: "91.5%" }}>
                <VrsFormInputField
                  name="confirmPass"
                  type={showPassword ? "text" : "password"}
                  label="Confirm Password"
                  value={confirmPass}
                  onChange={handleChange}
                  helperText={errors.confirmPass}
                  error={Boolean(errors.confirmPass)}
                  isValidField={confirmPass.length && !errors.confirmPass}
                  showPassword={showPassword}
                  handleClickShowPassword={() => dispatch(toggleShowPassword())}
                />
              </Grid>
              <Grid item xs={12} style={{ width: "91.5%" }}>
                <FormControl
                  required
                  sx={{
                    display: "flex",
                    flexDirection: "initial",
                    alignItems: "center",
                    left: ({ spacing }) => spacing(-1.75),
                    height: ({ spacing }) => spacing(3),
                  }}
                >
                  <Field
                    name="terms"
                    type="checkbox"
                    size="large"
                    variant="outlined"
                    as={Checkbox}
                    value={terms}
                    onClick={() => formik.setFieldValue("terms", !terms)}
                    checked={terms}
                    style={{ color: theme.veris.colors.amethyst.normal }}
                  />
                  <Box height="1rem">
                    <Typography m={0} variant="caption" display="flex" alignItems="center">
                      I agree to the
                      <Typography onClick={() => setOpenDialog(true)} sx={{ cursor: "pointer" }}>
                        <span style={{ textDecoration: "underline", margin: "0 4px" }}>
                          Terms and Conditions
                        </span>
                      </Typography>
                    </Typography>
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12} marginTop={4} style={{ width: "91.5%" }}>
                <VrsButton
                  buttonType="primary"
                  disabled={!isValid || !dirty || !!passwordError}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Create Account
                </VrsButton>
              </Grid>
              <Grid item xs={12} style={{ width: "91.5%" }}>
                <Typography marginTop={1} variant="subtitle2">
                  Already have an account?
                  <Button
                    style={{ textTransform: "none", textDecoration: "underline" }}
                    onClick={() => {
                      if (regError) dispatch(clearAuthError());
                      if (passwordError) dispatch(clearValidationError());
                      navigate(Routes.STAFF_LOGIN);
                    }}
                  >
                    Sign in
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
          {openDialog && <TermsAndConditionsDialog open={openDialog} setOpen={setOpenDialog} />}
        </FormikProvider>
      )}
    </>
  );
};

export default Registration;
