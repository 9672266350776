/* eslint-disable camelcase */
import { createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  HTTPValidationError,
  StaffLoginOTPRequest,
  ModelError,
  RequestStaffRegisterOTP,
  TokenResponse,
  StaffLoginRequest,
  RequestStaffRegister,
  ChangePasswordRequest,
  ResetPasswordRequest,
  ExpiredPasswordResetRequest,
  ExpiredPasswordOtpRequest,
  ValidateOtpStaffRequest,
  ResetPasswordOtpRequest,
  TermsAndConditionsItem,
} from "@veris-health/user-ms/lib/v1";

import axios from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { checkIfTokenIsExpired } from "../../../api/utils/jwt-extras";
import { getToken, setToken, setTokensToStorage } from "../../../api/utils/localStorage";
import { Status } from "../interfaces";
import { RootState } from "../../../store";
import {
  loginTechStaff,
  loginOTP,
  registerTechStaffOtp,
  registerTechStaff,
  changePassword,
  getOTP,
  resetPassword,
  validateOTP,
  passwordExpired,
  passwordExpiredOtpValidation,
} from "./api/authApi";
import { getTermsAndConditions } from "./api/userApi";

const ERROR_MESSAGE = "Something went wrong. Please try again";

interface AuthState {
  otpScreen?: boolean;
  otpScreenRegister?: boolean;
  isLoggedIn?: boolean;
  validationError?: string;
  status: Status;
  authError?: string;
  userId?: string;
  showPassword?: boolean;
  activeConnections: number;
  isEstablishingConnection: boolean;
  isConnected: boolean;
  changePassword: {
    status: Status;
    errorMessage?: string;
    lockoutSeconds?: number;
    successMessage?: string;
  };
  expiredPassword: {
    status: Status;
    successMessage?: string;
    errorMessage?: string;
  };
  wsError?: unknown;
  lockoutSeconds: number;
  isAccountBlocked: boolean;
  isLoginBannerOpen: boolean;
  isPasswordExpired: boolean;
  termsAndConditions?: TermsAndConditionsItem;
}

export const isLoggedIn = (): boolean => {
  const token = getToken("VERIS_ACCESS_TOKEN");
  return !!token && !checkIfTokenIsExpired(token);
};

const initUserId = (loggedIn: boolean) => {
  const accessToken = getToken("VERIS_ACCESS_TOKEN");
  if (loggedIn && accessToken) {
    const { extension_realId } = jwtDecode<JwtPayload & { extension_realId: string }>(accessToken);
    return extension_realId;
  }
  return undefined;
};

const initialState: AuthState = {
  otpScreen: false,
  otpScreenRegister: false,
  isLoggedIn: isLoggedIn(),
  validationError: undefined,
  status: "idle",
  authError: undefined,
  userId: initUserId(isLoggedIn()),
  showPassword: false,
  activeConnections: 0,
  isConnected: false,
  isEstablishingConnection: false,
  changePassword: {
    status: "idle",
  },
  expiredPassword: {
    status: "idle",
  },
  lockoutSeconds: 0,
  isAccountBlocked: false,
  isLoginBannerOpen: false,
  isPasswordExpired: false,
  termsAndConditions: undefined,
};

export const setUserId = createAction("auth/setUserId", (userId: string) => ({
  payload: userId,
}));

export const startConnecting = createAction("auth/startConnecting");

export const toggleLoginBanner = createAction<boolean>("auth/toggleLoginBanner");

export const loginTechStaffAsync = createAsyncThunk<
  TokenResponse,
  StaffLoginOTPRequest,
  {
    rejectValue: {
      status: number;
      message: string;
      timeRemaining?: string | number;
      failedStatus: string;
    };
  }
>("auth/loginTechStaff", async (payload: StaffLoginOTPRequest, { rejectWithValue }) => {
  try {
    const loginResponse = await loginTechStaff(payload);
    return loginResponse as TokenResponse;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      if (!response) {
        throw error;
      }
      const { data, status } = response;
      return rejectWithValue({
        status,
        message: (data as ModelError).message || ERROR_MESSAGE,
        failedStatus: data.status,
        timeRemaining: data.lockout_seconds_left,
      });
    }
    throw error;
  }
});

export const loginTechStaffOTPAsync = createAsyncThunk<
  TokenResponse,
  StaffLoginRequest,
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>("auth/loginTechStaffOtp", async (payload: StaffLoginRequest, { rejectWithValue, dispatch }) => {
  try {
    const tokenResponse = await loginOTP(payload);
    const bannerTimes = Number(getToken("VERIS_LOGIN_COUNT"));
    if (bannerTimes < 5) dispatch(toggleLoginBanner(true));
    if (bannerTimes < 6) setToken("VERIS_LOGIN_COUNT", String(bannerTimes + 1));
    setTokensToStorage(tokenResponse);
    const accessToken = getToken("VERIS_ACCESS_TOKEN");
    if (accessToken) {
      const { extension_realId } = jwtDecode<JwtPayload & { extension_realId: string }>(
        accessToken,
      );
      dispatch(setUserId(extension_realId));
    } else {
      throw new Error(ERROR_MESSAGE);
    }

    return tokenResponse;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      if (!response) {
        throw error;
      }
      const { data, status } = response;
      return rejectWithValue({
        status,
        message: (data as ModelError).message || ERROR_MESSAGE,
      });
    }
    throw error;
  }
});

export const registerTechStaffOtpAsync = createAsyncThunk<
  TokenResponse,
  RequestStaffRegister,
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>(
  "auth/registerTechStaffOtp",
  async (payload: RequestStaffRegister, { rejectWithValue, dispatch }) => {
    try {
      const tokenResponse = await registerTechStaffOtp(payload);
      const bannerTimes = Number(getToken("VERIS_LOGIN_COUNT"));
      if (bannerTimes < 5) dispatch(toggleLoginBanner(true));
      if (bannerTimes < 6) setToken("VERIS_LOGIN_COUNT", String(bannerTimes + 1));

      setTokensToStorage(tokenResponse);
      const accessToken = getToken("VERIS_ACCESS_TOKEN");
      if (accessToken) {
        const { extension_realId } = jwtDecode<JwtPayload & { extension_realId: string }>(
          accessToken,
        );
        dispatch(setUserId(extension_realId));
        dispatch(startConnecting());
      } else {
        throw new Error(ERROR_MESSAGE);
      }
      return tokenResponse;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const { response } = error;
        if (!response) {
          throw error;
        }
        const { data, status } = response;
        if (status === 422) {
          const responseData = data as HTTPValidationError;
          const [detail] = responseData.detail || [undefined];
          return rejectWithValue({
            message: (detail && detail.msg) || "Validation error occured.",
            status,
          });
        }
        return rejectWithValue({
          status,
          message: (data as ModelError).message || ERROR_MESSAGE,
        });
      }
      throw error;
    }
  },
);

export const registerTechStaffAsync = createAsyncThunk<
  TokenResponse,
  RequestStaffRegisterOTP,
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>("auth/registerTechStaff", async (payload: RequestStaffRegisterOTP, { rejectWithValue }) => {
  try {
    const tokenResponse = await registerTechStaff(payload);
    return tokenResponse as TokenResponse;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      if (!response) {
        throw error;
      }
      const { data, status } = response;
      if (status === 422) {
        const responseData = data as HTTPValidationError;
        const [detail] = responseData.detail || [undefined];
        return rejectWithValue({
          message: (detail && detail.msg) || "Validation error occured.",
          status,
        });
      }
      return rejectWithValue({
        status,
        message: (data as ModelError).message || ERROR_MESSAGE,
      });
    }
    throw error;
  }
});

export const changePasswordAsync = createAsyncThunk<
  TokenResponse,
  { id: number; newData: ChangePasswordRequest },
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>(
  "auth/changePassword",
  async ({ id, newData }: { id: number; newData: ChangePasswordRequest }, { rejectWithValue }) => {
    try {
      const response = await changePassword(id, newData);
      setTokensToStorage(response);
      return response;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const { response } = error;
        if (!response) {
          throw error;
        }
        const { data, status } = response;
        return rejectWithValue({
          status,
          message: (data as ModelError).message || ERROR_MESSAGE,
        });
      }
      throw error;
    }
  },
);

export const getOTPAsync = createAsyncThunk<
  unknown,
  { payload: ResetPasswordOtpRequest },
  {
    rejectValue: {
      status: number;
      message: string;
      timeRemaining?: string | number;
      failedStatus?: string;
    };
  }
>("auth/getOTP", async ({ payload }: { payload: ResetPasswordOtpRequest }, { rejectWithValue }) => {
  try {
    const response = await getOTP(payload);
    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      if (!response) {
        throw error;
      }
      const { data, status } = response;
      return rejectWithValue({
        status,
        message: (data as ModelError).message || ERROR_MESSAGE,
        failedStatus: data.status,
        timeRemaining: data.lockout_seconds_left,
      });
    }
    throw error;
  }
});

export const resetPasswordAsync = createAsyncThunk<
  { status: string },
  { newData: ResetPasswordRequest },
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>(
  "auth/resetPassword",
  async ({ newData }: { newData: ResetPasswordRequest }, { rejectWithValue }) => {
    try {
      const response = await resetPassword(newData);
      return response;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const { response } = error;
        if (!response) {
          throw error;
        }
        const { data, status } = response;
        return rejectWithValue({
          status,
          message: (data as ModelError).message || ERROR_MESSAGE,
        });
      }
      throw error;
    }
  },
);

export const validateOTPAsync = createAsyncThunk<
  unknown,
  { OTPData: ValidateOtpStaffRequest },
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>(
  "auth/validateOTP",
  async ({ OTPData }: { OTPData: ValidateOtpStaffRequest }, { rejectWithValue }) => {
    try {
      const response = await validateOTP(OTPData);
      return response;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const { response } = error;
        if (!response) {
          throw error;
        }
        const { data, status } = response;
        return rejectWithValue({
          status,
          message: (data as ModelError).message || ERROR_MESSAGE,
        });
      }
      throw error;
    }
  },
);

export const passwordExpiredAsync = createAsyncThunk<
  { status?: string },
  { newData: ExpiredPasswordOtpRequest },
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>(
  "auth/passwordExpiredAsync",
  async ({ newData }: { newData: ExpiredPasswordOtpRequest }, { rejectWithValue }) => {
    try {
      const response = await passwordExpired(newData);
      return response;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const { response } = error;
        if (!response) {
          throw error;
        }
        const { data, status } = response;
        return rejectWithValue({
          status,
          message: (data as ModelError).message || ERROR_MESSAGE,
        });
      }
      throw error;
    }
  },
);

export const passwordExpiredOtpValidationAsync = createAsyncThunk<
  { status?: string },
  { newData: ExpiredPasswordResetRequest },
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>(
  "auth/passwordExpiredOtpValidationAsync",
  async ({ newData }: { newData: ExpiredPasswordResetRequest }, { rejectWithValue }) => {
    try {
      const response = await passwordExpiredOtpValidation(newData);
      return response;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const { response } = error;
        if (!response) {
          throw error;
        }
        const { data, status } = response;
        return rejectWithValue({
          status,
          message: (data as ModelError).message || ERROR_MESSAGE,
        });
      }
      throw error;
    }
  },
);

export const getTermsAndConditionsAsync = createAsyncThunk(
  "auth/getTermsAndConditionsAsync",
  async () => {
    const response = await getTermsAndConditions();
    return response;
  },
);

// Reducers & Thunks

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearValidationError: (state, action: PayloadAction<undefined>) => {
      state.validationError = action.payload;
    },
    clearPasswordExpired: (state) => {
      state.isPasswordExpired = false;
    },
    clearAuthError: (state, action: PayloadAction<undefined>) => {
      state.authError = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    clearLockoutTimer: (state) => {
      state.lockoutSeconds = 0;
    },
    toggleLoginBanner: (state, action) => {
      state.isLoginBannerOpen = action.payload;
    },
    logout: (state) => {
      state.showPassword = false;
      state.otpScreen = false;
      state.otpScreenRegister = false;
      state.isLoggedIn = false;
      state.lockoutSeconds = 0;
    },
    toggleShowPassword: (state) => {
      state.showPassword = !state.showPassword;
    },
    setOTPScreen: (state, action) => {
      state.otpScreen = action.payload;
    },
    setOTPScreenRegister: (state, action) => {
      state.otpScreenRegister = action.payload;
    },
    setActiveConnections: (state, { payload }: PayloadAction<number>) => {
      state.activeConnections = payload;
    },
    startConnecting: (state) => {
      state.isEstablishingConnection = true;
      state.wsError = undefined;
    },
    connectionEstablished: (state) => {
      state.isConnected = true;
      state.isEstablishingConnection = true;
    },
    terminateConnection: (state) => {
      state.isConnected = false;
      state.isEstablishingConnection = false;
    },
    clearChangePasswordMessages: (state, action: PayloadAction<undefined>) => {
      state.changePassword.errorMessage = action.payload;
      state.changePassword.successMessage = action.payload;
      state.changePassword.lockoutSeconds = undefined;
    },
    clearExpiredPassword: (state, action: PayloadAction<undefined>) => {
      state.expiredPassword.errorMessage = action.payload;
      state.expiredPassword.successMessage = undefined;
    },
    setSocketError: (state, { payload }: PayloadAction<unknown>) => {
      state.wsError = payload;
    },
    localizedLogout: (state) => {
      state.isLoggedIn = false;
      state.showPassword = false;
      state.otpScreen = false;
      state.otpScreenRegister = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginTechStaffAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginTechStaffAsync.fulfilled, (state) => {
        state.status = "idle";
        state.otpScreen = true;
      })
      .addCase(loginTechStaffAsync.rejected, (state, { payload, error }) => {
        if (payload) {
          const { status, message, failedStatus, timeRemaining } = payload;
          if (status === 401) {
            state.validationError =
              "The username or password you entered is invalid. Please double check they are entered correctly.";
          }
          if (status === 403) {
            if (failedStatus === "disabled") {
              state.validationError =
                "Your account has been temporarily lockout due to multiple invalid login attempts.";
              state.lockoutSeconds = Number(timeRemaining || 0);
            } else if (failedStatus === "locked") {
              state.validationError =
                "Your account has been locked out due to multiple invalid login attempts. Please reach out to Veris admin for recover your profile.";
              state.isAccountBlocked = true;
            } else if (failedStatus === "expired") {
              state.isPasswordExpired = true;
              state.validationError = message;
            } else state.validationError = message;
          } else {
            state.authError = message;
          }
        } else {
          state.authError = error.message || ERROR_MESSAGE;
        }
        state.status = "failed";
        state.isLoggedIn = false;
      })
      .addCase(loginTechStaffOTPAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginTechStaffOTPAsync.fulfilled, (state) => {
        state.status = "idle";
        state.isLoggedIn = true;
        state.otpScreenRegister = false;
      })
      .addCase(loginTechStaffOTPAsync.rejected, (state, { payload, error }) => {
        if (payload) {
          const { status, message } = payload;
          if (status === 401) {
            state.validationError = "Incorrect security code.";
          } else {
            state.authError = message;
          }
        } else {
          state.authError = error.message || "Incorrect security code.";
        }
        state.status = "failed";
        state.isLoggedIn = false;
      })
      .addCase(registerTechStaffAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerTechStaffAsync.fulfilled, (state) => {
        state.status = "idle";
        state.otpScreenRegister = true;
      })
      .addCase(registerTechStaffAsync.rejected, (state, { payload, error }) => {
        if (payload) {
          const { status, message } = payload;
          if (status === 422) {
            state.validationError = message;
          } else {
            state.authError = message;
          }
        } else {
          state.authError = error.message || ERROR_MESSAGE;
        }
        state.status = "failed";
        state.isLoggedIn = false;
      })
      .addCase(registerTechStaffOtpAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerTechStaffOtpAsync.fulfilled, (state) => {
        state.status = "idle";
        state.isLoggedIn = true;
      })
      .addCase(registerTechStaffOtpAsync.rejected, (state, { payload, error }) => {
        if (payload) {
          const { status, message } = payload;
          if (status === 400) {
            state.validationError = message;
          } else {
            state.authError = message;
          }
        } else {
          state.authError = error.message || ERROR_MESSAGE;
        }
        state.status = "failed";
        state.isLoggedIn = false;
      })
      .addCase(changePasswordAsync.pending, (state) => {
        state.changePassword.status = "loading";
      })
      .addCase(changePasswordAsync.fulfilled, (state) => {
        state.changePassword.status = "idle";
        state.changePassword.errorMessage = undefined;
        state.changePassword.successMessage = "Your password has been successfully changed.";
      })
      .addCase(changePasswordAsync.rejected, (state, { payload }) => {
        if (payload) {
          const { status, message } = payload;
          if (status === 400 || status === 401) {
            state.changePassword.errorMessage = message;
          }
        }
        state.changePassword.successMessage = undefined;
        state.changePassword.status = "failed";
      })
      .addCase(getOTPAsync.pending, (state) => {
        state.status = "loading";
        state.changePassword.status = "loading";
      })
      .addCase(getOTPAsync.fulfilled, (state) => {
        state.status = "idle";
        state.changePassword.status = "idle";
        state.changePassword.errorMessage = undefined;
        state.changePassword.lockoutSeconds = undefined;
      })
      .addCase(getOTPAsync.rejected, (state, { payload }) => {
        state.status = "failed";
        state.changePassword.lockoutSeconds = undefined;
        if (payload) {
          const { status, message } = payload;
          if (status === 400 || status === 422) {
            state.changePassword.errorMessage = message;
          }
          if (status === 403) {
            if (payload.failedStatus === "disabled") {
              state.changePassword.errorMessage =
                "Your account has been temporarily lockout due to multiple invalid login attempts.";
              state.changePassword.lockoutSeconds = Number(payload.timeRemaining || 0);
            } else state.changePassword.errorMessage = message;
          }
        }
        state.changePassword.status = "failed";
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.changePassword.status = "loading";
      })
      .addCase(resetPasswordAsync.fulfilled, (state, { payload }) => {
        state.changePassword.status = "idle";
        state.changePassword.errorMessage = undefined;
        state.changePassword.successMessage = payload.status || "Password successfully changed!";
      })
      .addCase(resetPasswordAsync.rejected, (state, { payload }) => {
        if (payload) {
          const { status, message } = payload;
          if (status === 400 || status === 422) {
            state.changePassword.errorMessage = message;
          }
        }
        state.changePassword.successMessage = undefined;
        state.changePassword.status = "failed";
      })
      .addCase(validateOTPAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(validateOTPAsync.fulfilled, (state) => {
        state.status = "idle";
        state.validationError = undefined;
      })
      .addCase(validateOTPAsync.rejected, (state, { payload }) => {
        if (payload) {
          const { status, message } = payload;
          if (status === 400 || status === 422) {
            state.validationError = message;
          }
        }
        state.status = "failed";
      })
      .addCase(passwordExpiredAsync.pending, (state) => {
        state.expiredPassword.status = "loading";
      })
      .addCase(passwordExpiredAsync.fulfilled, (state) => {
        state.expiredPassword.status = "idle";
        state.expiredPassword.errorMessage = undefined;
        state.otpScreen = true;
      })
      .addCase(passwordExpiredAsync.rejected, (state, { payload }) => {
        if (payload) {
          const { status, message } = payload;
          if (status === 400 || status === 422) {
            state.expiredPassword.errorMessage = message;
          }
        }
        state.expiredPassword.status = "failed";
      })
      .addCase(passwordExpiredOtpValidationAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(passwordExpiredOtpValidationAsync.fulfilled, (state) => {
        state.status = "idle";
        state.validationError = undefined;
        state.expiredPassword.successMessage = "Your password was changed successfully.";
      })
      .addCase(passwordExpiredOtpValidationAsync.rejected, (state, { payload }) => {
        if (payload) {
          const { status, message } = payload;
          if (status === 400 || status === 422) {
            state.validationError = message;
          }
        }
        state.status = "failed";
      })
      .addCase(getTermsAndConditionsAsync.fulfilled, (state, { payload }) => {
        state.termsAndConditions = payload;
      });
  },
});

// Actions

export const {
  clearValidationError,
  logout,
  clearAuthError,
  clearLockoutTimer,
  toggleShowPassword,
  setOTPScreen,
  setOTPScreenRegister,
  setActiveConnections,
  connectionEstablished,
  terminateConnection,
  clearChangePasswordMessages,
  setSocketError,
  localizedLogout,
  clearPasswordExpired,
  clearExpiredPassword,
} = authSlice.actions;

export const selectAuthError = ({ auth }: RootState): string | undefined => auth.authError;

export const selectValidationError = ({ auth }: RootState): string | undefined =>
  auth.validationError;

export const selectIsLoggedIn = ({ auth }: RootState): boolean | undefined => auth.isLoggedIn;

export const selectOtpScreen = ({ auth }: RootState): boolean | undefined => auth.otpScreen;

export const selectOtpScreenRegister = ({ auth }: RootState): boolean | undefined =>
  auth.otpScreenRegister;

export const selectUserId = ({ auth }: RootState): string | undefined => auth.userId;

export const selectIsShowPassword = ({ auth }: RootState): boolean | undefined => auth.showPassword;

export const selectAuthStatus = ({ auth }: RootState): Status | undefined => auth.status;

export const selectlockoutSeconds = ({ auth }: RootState): number => auth.lockoutSeconds;

export const selectIsPasswordExpired = ({ auth }: RootState): boolean => auth.isPasswordExpired;

export const selectIsAccountBlocked = ({ auth }: RootState): boolean => auth.isAccountBlocked;

export const selectIsLoginBannerOpen = ({ auth }: RootState): boolean => auth.isLoginBannerOpen;

export const selectActiveConnections = ({ auth }: RootState): number => auth.activeConnections;

export const selectConnectionStatus = ({ auth }: RootState): boolean => auth.isConnected;

export const selectIsEstablishingConnection = ({ auth }: RootState): boolean =>
  auth.isEstablishingConnection;

export const selectTermsAndConditions = ({ auth }: RootState): TermsAndConditionsItem | undefined =>
  auth.termsAndConditions;

export const selectChangePasswordStatus = ({
  auth,
}: RootState): {
  status: Status;
  errorMessage?: string;
  successMessage?: string;
} => auth.changePassword;

export const selectExpiredPasswordStatus = ({
  auth,
}: RootState): {
  status: Status;
  errorMessage?: string;
  successMessage?: string;
} => auth.expiredPassword;

export default authSlice.reducer;
