import { Box, CircularProgress } from "@mui/material";
import { VrsErrorMsg } from "@veris-health/web-core";
import React, { ReactNode } from "react";
import { Status } from "../../../features/shared/interfaces";

export type VrsStatusDisplayProps = {
  status: Status;
  errorRetryFunction: () => void;
  children: ReactNode;
};

export const VrsStatusDisplay = ({
  status,
  errorRetryFunction,
  children,
}: VrsStatusDisplayProps): JSX.Element => {
  return (
    <>
      {status === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="center" p={1}>
          <CircularProgress />
        </Box>
      )}
      {status === "idle" && children}
      {status === "failed" && (
        <Box justifyContent="center" alignItems="center" width="100%">
          <VrsErrorMsg onClick={errorRetryFunction} />
        </Box>
      )}
    </>
  );
};
