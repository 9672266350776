import { Autocomplete, Box, Chip, Typography } from "@mui/material";
import { AdminDetailUserInfoResponse, CareTeamMemberInfo } from "@veris-health/user-ms/lib/v1";
import React, { HTMLAttributes } from "react";
import { get } from "lodash";
import { StyledTextField } from "./SetHospitalAndCareTeamForm";

const renderOption = (
  { onClick, ...rest }: HTMLAttributes<HTMLLIElement | HTMLDivElement>,
  {
    value,
    label,
  }: { value: AdminDetailUserInfoResponse | CareTeamMemberInfo; label: string | undefined },
) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        cursor: "pointer",
      }}
      onClick={onClick}
      {...rest}
      key={`${value.id}`}
    >
      <Typography
        variant="body"
        ml={1}
        mr={1}
        color={(theme) => theme.veris.colors.neutrals["grey-dark"]}
      >
        {label}
      </Typography>
    </Box>
  );
};

type CareTeamMembersPickerProps = {
  handleChange: (
    value: { value: AdminDetailUserInfoResponse | CareTeamMemberInfo; label: string | undefined }[],
  ) => void;
  careTeamMembersStatus: string;
  selectedCareTeam: {
    value: AdminDetailUserInfoResponse | CareTeamMemberInfo;
    label: string | undefined;
  }[];
  careTeamMembers: AdminDetailUserInfoResponse[];
};

const getOptionLabel = (name?: string, profession?: string): string => {
  if (profession) return `${name} - ${profession}`;
  return name || "";
};

const CareTeamMembersPicker = ({
  handleChange,
  careTeamMembersStatus,
  selectedCareTeam,
  careTeamMembers,
}: CareTeamMembersPickerProps): JSX.Element => {
  return (
    <Autocomplete
      loading={careTeamMembersStatus === "loading"}
      sx={{
        borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]} `,
        padding: (theme) => theme.spacing(1, 1.25),
        marginY: (theme) => theme.spacing(2),
      }}
      multiple
      id="care-team-input"
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.value.id === value.value.id}
      value={selectedCareTeam || []}
      onChange={(e, value) => {
        handleChange(value);
      }}
      style={{
        fontWeight: 400,
      }}
      options={
        careTeamMembers?.map((careTeamMember) => ({
          value: careTeamMember,
          label: getOptionLabel(careTeamMember.name, careTeamMember.profession),
        })) || []
      }
      renderOption={renderOption}
      ListboxProps={{
        style: {
          overflowY: "scroll",
          height: "200px",
        },
      }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          placeholder="Select clinical care team member(s)"
          size="small"
          variant="standard"
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={getOptionLabel(
              get(option.value, "full_name", get(option.value, "name")),
              option.value.profession,
            )}
            sx={{
              ...(theme) => theme.typography.body,
              fontWeight: (theme) => theme.typography.body,
            }}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
};

export default CareTeamMembersPicker;
