import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import {
  HospitalInfo,
  HTTPValidationError,
  LimitOffsetPageAdminDetailUserInfoResponse,
  ModelError,
  UserSorting,
  UserTypeResponse,
  AdminDetailUserInfoResponse,
  PatientBasicInfo,
  SuccessfulResponse,
  UserLockStatus,
} from "@veris-health/user-ms/lib/v1";
import axios from "axios";
import {
  AccountStatus,
  MedRoleFilterEnum,
  TechRoleFilterEnum,
} from "@veris-health/user-ms/lib/v1/api";
import { RootState } from "../../store";
import { Status } from "../shared/interfaces";
import { localizedLogout, logout } from "../shared/slices/authSlice";
import { getHospitals } from "./api/hospitalsApi";
import {
  deleteUser,
  disableUser,
  getAssignedPatients,
  getUsers,
  resendInvite,
  unblockUser,
} from "./api/usersApi";
import { teamsApiV1, usersApiV1 } from "../../api";
import { orderFirstHospital } from "./helpers/sorting";
import SnackbarUtils from "../../utils/SnackbarUtils";

const USERS_SLICE_NAME = "users";

export const getUsersAsync = createAsyncThunk(
  "users/getUsersAsync",
  async ({
    sorting,
    type,
    active,
    hospital,
    offset,
    search,
    limit,
    id,
    medRole,
    accountStatus,
  }: {
    id?: number;
    sorting?: UserSorting[];
    type?: UserTypeResponse[];
    active?: boolean;
    hospital?: number;
    search?: string;
    offset?: number;
    limit?: number;
    medRole?: MedRoleFilterEnum | TechRoleFilterEnum;
    accountStatus?: AccountStatus;
  }) => {
    const response = await getUsers({
      sorting,
      type,
      active,
      hospital,
      search,
      offset,
      limit,
      id,
      medRole,
      accountStatus,
    });

    if (hospital) {
      response.items.forEach((item) => {
        orderFirstHospital(item, hospital);
      });
    }

    return response;
  },
);

export const getHospitalsAsync = createAsyncThunk("users/getHospitalsAsync", async () => {
  const response = await getHospitals();
  return response;
});

export const removeCareTeamMember = createAsyncThunk<
  unknown,
  { userId: number; memberId: number },
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>("users/removeCareTeamMember", async ({ userId, memberId }, { rejectWithValue }) => {
  try {
    const response = await teamsApiV1.deleteCareTeamMember(userId, {
      member_id: memberId,
      patient_id: userId,
    });
    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      if (!response) {
        throw error;
      }
      const { data, status } = response;
      return rejectWithValue({
        status,
        message: (data as ModelError).message || "Removing assigned patient failed",
      });
    }
    throw error;
  }
});

export const unblockUserAsync = createAsyncThunk<
  unknown,
  number,
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>("users/unblockUserAsync", async (userId: number, { rejectWithValue }) => {
  try {
    const response = await unblockUser(userId);
    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      if (!response) {
        throw error;
      }
      const { data, status } = response;
      if (status === 422) {
        const responseData = data as HTTPValidationError;
        const [detail] = responseData.detail || [undefined];
        return rejectWithValue({
          message: (detail && detail.msg) || "Unlocking user failed.",
          status,
        });
      }
      return rejectWithValue({
        status,
        message: (data as ModelError).message || "Unlocking user failed",
      });
    }
    throw error;
  }
});

export const deleteUserAsync = createAsyncThunk<
  { response: unknown },
  { userId: number },
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>("users/deleteUserAsync", async ({ userId }, { rejectWithValue }) => {
  try {
    const response = await deleteUser(userId);
    return { response };
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      if (!response) {
        throw error;
      }
      const { data, status } = response;
      if (status === 422) {
        const responseData = data as HTTPValidationError;
        const [detail] = responseData.detail || [undefined];
        return rejectWithValue({
          message: (detail && detail.msg) || "Deleting user failed.",
          status,
        });
      }
      return rejectWithValue({
        status,
        message: (data as ModelError).message || "Deleting user failed",
      });
    }
    throw error;
  }
});

export const disableUserAsync = createAsyncThunk<
  { user?: AdminDetailUserInfoResponse; response: unknown },
  { userId: number },
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>("users/disableUserAsync", async ({ userId }, { rejectWithValue }) => {
  try {
    const response = await disableUser(userId);
    const updatedUser = await usersApiV1.adminGetSingleUserInfo(userId);
    return { user: updatedUser?.data, response };
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      if (!response) {
        throw error;
      }
      const { data, status } = response;
      if (status === 422) {
        const responseData = data as HTTPValidationError;
        const [detail] = responseData.detail || [undefined];
        return rejectWithValue({
          message: (detail && detail.msg) || "Disabling user failed.",
          status,
        });
      }
      return rejectWithValue({
        status,
        message: (data as ModelError).message || "Disabling user failed",
      });
    }
    throw error;
  }
});

export const resendInviteAsync = createAsyncThunk<
  { user: AdminDetailUserInfoResponse; response: SuccessfulResponse },
  number,
  {
    rejectValue: {
      status: number;
      message: string;
      timeRemaining?: string | number;
    };
  }
>("users/resendInviteAsync", async (userId: number, { rejectWithValue }) => {
  try {
    const response = await resendInvite(userId);
    const updatedUser = await usersApiV1.adminGetSingleUserInfo(userId);
    return { user: updatedUser.data, response };
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      if (!response) {
        throw error;
      }
      const { data, status } = response;
      return rejectWithValue({
        message: (data as ModelError).message || "Resending Invite failed.",
        status,
        timeRemaining: data.retry_seconds_left,
      });
    }

    throw error;
  }
});

export const getAssignedPatientsAsync = createAsyncThunk(
  "users/getAssignedPatients",
  async (id: number) => {
    const patients = await getAssignedPatients(id);
    return { patients, id };
  },
);

export interface NormalizedAssignedPatients {
  patients: PatientBasicInfo[];
}

interface PatientsState {
  data: LimitOffsetPageAdminDetailUserInfoResponse;
  status: Status;
  hospitals: { data: HospitalInfo[]; status: Status };
  shouldUpateUser?: boolean;
  actionErrorMessage?: string;
  lockoutSeconds?: number;
  assignedPatients: {
    data: EntityState<NormalizedAssignedPatients>;
    status: Status;
  };
}

const assignedPatientsAdapter = createEntityAdapter<NormalizedAssignedPatients>();

const initialState: PatientsState = {
  data: { limit: 0, offset: 0, total: 0, items: [] },
  status: "idle",
  hospitals: { data: [], status: "idle" },
  shouldUpateUser: false,
  actionErrorMessage: undefined,
  assignedPatients: {
    status: "idle",
    data: assignedPatientsAdapter.getInitialState(),
  },
};

const usersSlice = createSlice({
  name: USERS_SLICE_NAME,
  initialState,
  reducers: {
    clearActionErrorMessage: (state) => {
      state.actionErrorMessage = undefined;
    },
    setShouldUpdateUser: (state, action) => {
      state.shouldUpateUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(localizedLogout, () => {
        return initialState;
      })
      .addCase(getUsersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUsersAsync.fulfilled, (state, { payload }) => {
        state.data = {
          ...payload,
          items:
            payload.offset && payload.offset > 0
              ? state.data.items.concat(payload.items)
              : payload.items,
        };
        state.status = "idle";
      })
      .addCase(getUsersAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getHospitalsAsync.pending, (state) => {
        state.hospitals.status = "loading";
      })
      .addCase(getHospitalsAsync.fulfilled, (state, { payload }) => {
        state.hospitals.data = payload;
        state.hospitals.status = "idle";
      })
      .addCase(getHospitalsAsync.rejected, (state) => {
        state.hospitals.status = "failed";
      })
      .addCase(unblockUserAsync.fulfilled, (state, { meta }) => {
        state.actionErrorMessage = undefined;
        if (meta.arg) {
          const foundIndex = state.data.items.findIndex((entry) => entry.id === meta.arg);
          if (foundIndex > -1) state.data.items[foundIndex].lock_status = UserLockStatus.Active;
        }
      })
      .addCase(unblockUserAsync.rejected, (state, { payload }) => {
        if (payload) {
          const { message } = payload;
          state.actionErrorMessage = message;
        } else {
          state.actionErrorMessage = "Unlocking User Failed";
        }
      })
      .addCase(deleteUserAsync.rejected, (state, { payload }) => {
        if (payload) {
          const { message } = payload;
          state.actionErrorMessage = message;
        } else {
          state.actionErrorMessage = "Deleting User Failed";
        }
      })
      .addCase(deleteUserAsync.fulfilled, (state, { meta }) => {
        state.actionErrorMessage = undefined;
        if (meta.arg.userId) {
          const foundIndex = state.data.items.findIndex((entry) => entry.id === meta.arg.userId);
          if (foundIndex > -1) {
            state.data.items.splice(foundIndex, 1);
            state.data.total -= 1;
          }
        }
      })
      .addCase(disableUserAsync.rejected, (state, { payload }) => {
        if (payload) {
          const { message } = payload;
          state.actionErrorMessage = message;
        } else {
          state.actionErrorMessage = "Disabling User Failed";
        }
      })
      .addCase(disableUserAsync.fulfilled, (state, { payload, meta }) => {
        state.actionErrorMessage = undefined;
        if (meta.arg.userId) {
          const foundIndex = state.data.items.findIndex((entry) => entry.id === meta.arg.userId);
          if (foundIndex > -1) {
            if (payload.user) state.data.items[foundIndex] = payload.user;
          }
        }
      })
      .addCase(resendInviteAsync.rejected, (state, { payload }) => {
        if (payload) {
          const { message } = payload;
          state.lockoutSeconds = Number(payload.timeRemaining || 0);
          state.actionErrorMessage = message;
        } else {
          state.actionErrorMessage = "Resending invite Failed";
        }
      })
      .addCase(resendInviteAsync.fulfilled, (state, { payload }) => {
        state.actionErrorMessage = undefined;
        if (payload.user.id) {
          const foundIndex = state.data.items.findIndex((entry) => entry.id === payload.user.id);
          if (foundIndex > -1) {
            state.data.items[foundIndex] = payload.user;
          }
        }
      })
      .addCase(removeCareTeamMember.fulfilled, (state, { meta }) => {
        const assignedPatients =
          assignedPatientsAdapter
            .getSelectors()
            .selectById(state.assignedPatients.data, meta.arg.memberId)?.patients || [];
        assignedPatientsAdapter.updateOne(state.assignedPatients.data, {
          id: meta.arg.memberId,
          changes: {
            patients: assignedPatients.filter((patient) => patient.id !== meta.arg.userId),
          },
        });

        SnackbarUtils.success("Patient removed");
      })
      .addCase(getAssignedPatientsAsync.pending, (state) => {
        state.assignedPatients.status = "loading";
      })
      .addCase(getAssignedPatientsAsync.fulfilled, (state, { payload }) => {
        state.assignedPatients.status = "idle";
        if (state.assignedPatients.data.ids.includes(payload.id))
          assignedPatientsAdapter.updateOne(state.assignedPatients.data, {
            id: payload.id,
            changes: { patients: payload.patients },
          });
        else assignedPatientsAdapter.addOne(state.assignedPatients.data, payload);
      })
      .addCase(getAssignedPatientsAsync.rejected, (state) => {
        state.assignedPatients.status = "failed";
      });
  },
});

export const selectUsers = ({ users }: RootState): LimitOffsetPageAdminDetailUserInfoResponse => {
  return users.data;
};

export const selectAssignedPatientsStatus = ({ users }: RootState): Status =>
  users.assignedPatients.status;

export const selectAssignedPatients = (rootState: RootState, id?: number): PatientBasicInfo[] => {
  if (id) {
    return (
      assignedPatientsAdapter
        .getSelectors<RootState>((state) => state.users.assignedPatients.data)
        .selectById(rootState, id)?.patients || []
    );
  }
  return [];
};

export const selectShouldUpdateUser = ({ users }: RootState): boolean | undefined => {
  return users.shouldUpateUser;
};

export const selectUsersStatus = ({ users }: RootState): Status => users.status;

export const selectHospitals = ({ users }: RootState): HospitalInfo[] => users.hospitals.data;

export const selectActionError = ({ users }: RootState): string | undefined =>
  users.actionErrorMessage;

export const selectRemainingSeconds = ({ users }: RootState): number | undefined =>
  users.lockoutSeconds;

export const { clearActionErrorMessage, setShouldUpdateUser } = usersSlice.actions;

export default usersSlice.reducer;
