import {
  SearchEducationalVideos,
  EducationalVideoBaseView,
  GetEducationalVideo,
} from "@veris-health/virtual-doc-ms/lib/v1";
import { educationalVideosApiV1, symptomsApiV1 } from "../../../api";

export const getEducationalVideos = async (
  searchTerm?: string,
  symptom?: string,
): Promise<SearchEducationalVideos> => {
  if (symptom) {
    const response = await educationalVideosApiV1.getEducationalVideos(searchTerm, symptom);
    return response.data;
  }

  const response = await educationalVideosApiV1.getEducationalVideos(searchTerm);
  return response.data;
};

export const addEducationalVideo = async (
  video: EducationalVideoBaseView,
): Promise<GetEducationalVideo> => {
  const response = await educationalVideosApiV1.uploadEducationalVideo(video);
  return response.data;
};

export const getSymptoms = async (): Promise<string[]> => {
  const { data } = await symptomsApiV1.getSymptoms();

  const availableSymptoms: string[] = [];
  Object.keys(data).forEach((key) => {
    const dt = data[key].map((el) => el.label);
    availableSymptoms.push(...dt);
  });
  return Array.from(new Set(availableSymptoms));
};

export const updateEducationalVideo = async (
  id: number,
  video: EducationalVideoBaseView,
): Promise<GetEducationalVideo> => {
  const response = await educationalVideosApiV1.updateEducationalVideo(id, video);
  return response.data;
};

export const deleteEducationalVideo = async (id: number): Promise<void> => {
  const response = await educationalVideosApiV1.deleteEducationalVideo(id);
  return response.data;
};
