import parsePhoneNumberFromString, { format, PhoneNumber } from "libphonenumber-js";

export const getParsedPhoneNumber = (value: string): PhoneNumber | undefined => {
  let number = parsePhoneNumberFromString(value);

  if (number?.countryCallingCode === undefined) {
    number = parsePhoneNumberFromString(`+${value}`);
  }

  return number;
};

export const getInternationalPhoneFormat = (value: string): string => {
  const parsedNumber = getParsedPhoneNumber(value);
  const number = parsedNumber && format(parsedNumber?.number as string, "NATIONAL");
  return number || value;
};
