import React from "react";
import { alpha, Badge, Stack, styled, Typography, Box, Chip } from "@mui/material";
import { verisColors } from "@veris-health/web-core";
import { PatientType } from "@veris-health/user-ms/lib/v1";
import { SupportChatStatus, ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import { UserType } from "@veris-health/med-data-ms/lib/v1";
import { VrsPatientInfoCard } from "../VrsPatientInfoCard";
import { VrsPatientInfo } from "../../../shared/interfaces";

const StyledThreadWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  backgroundColor: isActive ? alpha(verisColors.amethyst.light, 0.5) : verisColors.neutrals.white,
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  border: isActive ? `1px solid ${theme.veris.colors.amethyst.soft}` : "",
  borderRadius: isActive ? +theme.shape.borderRadius + 2 : 0,
  overflow: "hidden",
  "&:hover": {
    backgroundColor: verisColors.neutrals["grey-1"],
    zIndex: 1,
  },
}));

export interface VrsThreadPreviewProps {
  participantInfo?: VrsPatientInfo;
  threadParticipant?: ThreadParticipantInfo;
  msgTime?: string | Date;
  hasUnread: boolean;
  isActive: boolean;
  isGroupChat?: boolean;
  threadLabel?: string;
  groupChatParticipants?: ThreadParticipantInfo[];
  handleClick: () => void;
  status?: SupportChatStatus;
}

export function VrsThreadPreview({
  threadParticipant,
  msgTime,
  hasUnread,
  isActive,
  handleClick,
  status,
}: VrsThreadPreviewProps): JSX.Element {
  const type =
    threadParticipant?.user_type === UserType.SupportMember
      ? "Friends and family"
      : threadParticipant?.user_type;
  const patientInfo = {
    id: threadParticipant?.user_id,
    name: threadParticipant?.display_name,
    type: PatientType.Veris,
    gender: "N/A",
    diagnosis: {
      cancerType: threadParticipant?.cancer_type,
      cancerStage: threadParticipant?.cancer_stage,
    },
    picture: threadParticipant?.image && `data:image/png;base64,${threadParticipant.image}`,
    patientStatus: threadParticipant?.patient_status,
  } as VrsPatientInfo;

  const getColorByStatus = () => {
    if (status === SupportChatStatus.Pending) {
      return { color: verisColors.amethyst.normal, backgroundColor: verisColors.amethyst.light };
    }
    if (status === SupportChatStatus.Resolved) {
      return { color: verisColors.moderate.normal, backgroundColor: verisColors.moderate.light };
    }
    return {
      color: verisColors.neutrals["grey-4"],
      backgroundColor: verisColors.neutrals["grey-2"],
    };
  };

  return (
    <StyledThreadWrapper isActive={isActive} onClick={handleClick}>
      <Stack direction="row" paddingRight={1} py={1.5} justifyContent="space-between">
        <Box display="flex">
          {patientInfo && (
            <VrsPatientInfoCard
              patientInfo={patientInfo}
              backgroundColor="transparent"
              searchView
              subtitle={type}
            />
          )}
          {hasUnread && (
            <Box
              sx={{
                display: "grid",
                placeContent: "center",
                placeItems: "center",
                height: "40%",
              }}
            >
              <Badge
                color="info"
                variant="dot"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{
                  "& .MuiBadge-badge": { height: "10px", width: "10px", borderRadius: "100%" },
                }}
              />
            </Box>
          )}
        </Box>
        <Box>
          {status && (
            <Chip
              label={
                <Typography variant="description" color={getColorByStatus().color}>
                  {status}
                </Typography>
              }
              sx={{ backgroundColor: getColorByStatus().backgroundColor, height: "25px" }}
            />
          )}
          {msgTime && (
            <Box mx={1} display="flex">
              <Typography variant="body" color={verisColors.neutrals["grey-mid"]} pt={1}>
                {msgTime}
              </Typography>
            </Box>
          )}
        </Box>
      </Stack>
    </StyledThreadWrapper>
  );
}
