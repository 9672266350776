import React, { useState } from "react";
import { Box, Link, Button, Typography, useTheme } from "@mui/material";
import { ChangePasswordRequest } from "@veris-health/user-ms/lib/v1";
import { IconName, VrsConfirmationModals, VrsIcon } from "@veris-health/web-core";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  changePasswordAsync,
  clearChangePasswordMessages,
  logout,
  selectUserId,
} from "../shared/slices/authSlice";
import { VrsTabsContainer } from "../../ui/components/VrsTabsContainer";
import { ChangePassword } from "./components/ChangePassword";
import { unregisterFirebaseTokenAsync } from "../Notifications/notificationsSlice";
import { TermsAndConditionsDialog } from "../TermsAndConditions";

export function ProfileContainer(): JSX.Element {
  const currentUserId = useAppSelector(selectUserId);
  const [showTNC, setShowTNC] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const dispatch = useAppDispatch();
  const theme = useTheme();

  const submitPasswordChange = (data: ChangePasswordRequest) => {
    dispatch(
      changePasswordAsync({
        id: Number(currentUserId),
        newData: data,
      }),
    );
  };

  const onLogout = (): void => {
    dispatch(unregisterFirebaseTokenAsync(Number(currentUserId)));
    dispatch(logout());
  };

  return (
    <Box p={2}>
      <VrsTabsContainer
        tabsContainerContent={{
          Security: (
            <ChangePassword
              onSubmit={submitPasswordChange}
              clearValidationMessages={() => dispatch(clearChangePasswordMessages())}
            />
          ),
        }}
      >
        <Box marginLeft="auto">
          <Button
            size="small"
            sx={{
              color: theme.veris.colors.neutrals.black,
              padding: ({ spacing }) => spacing(2, 2.5),
            }}
            onClick={() => setShowLogoutModal(true)}
          >
            <VrsIcon name={IconName.Logout} size={18} />
            <Typography
              variant="subtitle2"
              sx={{ padding: ({ spacing }) => spacing(0.25, 1, 0, 1) }}
            >
              Logout
            </Typography>
          </Button>
          <VrsConfirmationModals
            isOpen={showLogoutModal}
            handleClose={() => setShowLogoutModal(false)}
            dialogHeader="Log out"
            dialogContent="You are about to log out."
            dialogSecondContentLine="Are you sure you want to logout now?"
            onCancel={() => setShowLogoutModal(false)}
            onConfirm={() => onLogout()}
            cancelButtonText="Cancel"
            confirmButtonText="Logout"
            confirmButtonVariant="primary"
            dialogContentSpacing={1}
            sx={{
              dialogContent: { display: "flex", flexDirection: "column", justifyContent: "center" },
            }}
          />
        </Box>
      </VrsTabsContainer>
      <Box display="flex" pt={1} pb={2} flexDirection="column">
        <Link
          href={import.meta.env.VITE_RELEASENOTES_FILE_LINK || ""}
          variant="body"
          target="_blank"
          sx={{
            color: theme.veris.colors.neutrals["grey-5"],
            textDecoration: "underline",
          }}
          pl={0.5}
        >
          Release Notes
        </Link>
        <Typography
          onClick={() => setShowTNC(true)}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          variant="body"
          pl={0.5}
        >
          Terms and Conditions
        </Typography>
      </Box>
      {showTNC && <TermsAndConditionsDialog open={showTNC} setOpen={setShowTNC} />}
    </Box>
  );
}
