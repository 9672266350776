import { AdminDetailUserInfoResponse, CareTeamMemberInfo } from "@veris-health/user-ms/lib/v1";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useAppSelector } from "../useAppSelector";
import {
  selectShouldUpdateUser,
  selectUsers,
  setShouldUpdateUser,
} from "../../features/Users/userSlice";
import { teamsApiV1, usersApiV1 } from "../../api";
import { useAppDispatch } from "../useAppDispatch";
import { Status, VrsPatientInfo } from "../../features/shared/interfaces";
import { getPatientDetails } from "../../features/Users/api/patientsApi";
import SnackbarUtils from "../../utils/SnackbarUtils";

type UserQueryParams = {
  userId: string;
};

export function useSelectedUser(): {
  user: AdminDetailUserInfoResponse | undefined;
  careTeamMembers: CareTeamMemberInfo[] | undefined;
  loadingUser: Status;
  patientInfo?: VrsPatientInfo;
  getUserInfo: () => void;
} {
  const [user, setUser] = useState<AdminDetailUserInfoResponse>();
  const [careTeamMembers, setCareTeamMembers] = useState<CareTeamMemberInfo[]>();
  const shouldUpateUser = useAppSelector(selectShouldUpdateUser);
  const { userId } = useParams<UserQueryParams>();
  const users = useAppSelector(selectUsers);
  const dispatch = useAppDispatch();
  const [loadingUser, setLoadingUser] = useState<Status>("idle");
  const [patientInfo, setPatientInfo] = useState<VrsPatientInfo | undefined>(undefined);

  const getUserInfo = () => {
    setLoadingUser("loading");
    usersApiV1
      .adminGetSingleUserInfo(Number(userId))
      .then((response) => setUser(response.data))
      .finally(() => setLoadingUser("idle"));
  };

  useEffect(() => {
    setUser(undefined);
    if (userId && users.items.length > 0) {
      const targetUser = users.items.find((item) => item.id === +userId);
      if (!targetUser) {
        getUserInfo();
      } else {
        setUser(targetUser);
      }
    } else {
      if (userId) {
        getUserInfo();
      }
      setUser(undefined);
    }
  }, [userId, users]);

  useEffect(() => {
    setPatientInfo(undefined);
    setCareTeamMembers(undefined);
    if (user && user.type === "patient") {
      teamsApiV1.getPatientCareTeam(user.id).then((response) => {
        return setCareTeamMembers(response.data);
      });

      getPatientDetails(user.id)
        .then((response) => {
          setPatientInfo(response);
          setLoadingUser("idle");
        })
        .catch((err: AxiosError) => {
          setLoadingUser("failed");
          SnackbarUtils.error(
            err.response?.data.message ?? "Unable to retrieve some patient details.",
          );
        });
    }
  }, [user]);

  useEffect(() => {
    if (shouldUpateUser) {
      setLoadingUser("loading");
      usersApiV1
        .adminGetSingleUserInfo(Number(userId))
        .then((responseUser) => setUser(responseUser.data))
        .finally(() => setLoadingUser("idle"));
    }
    return () => {
      dispatch(setShouldUpdateUser(false));
    };
  }, [shouldUpateUser]);

  return {
    user,
    careTeamMembers,
    loadingUser,
    patientInfo,
    getUserInfo,
  };
}
