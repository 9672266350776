import {
  LimitOffsetPageAdminDetailUserInfoResponse,
  ProfileRole,
  UserSorting,
  UserTypeResponse,
  PatientBasicInfo,
  SuccessfulResponse,
  LimitOffsetPageTagItem,
  AdminUpdateMedStaffInfo,
} from "@veris-health/user-ms/lib/v1";
import {
  AccountStatus,
  MedRoleFilterEnum,
  TechRoleFilterEnum,
} from "@veris-health/user-ms/lib/v1/api";
import { patientsApiV1, registerStaffApiV1, rolesApiV1, usersApiV1, tagsApiV1 } from "../../../api";

export const getUsers = async ({
  sorting,
  type,
  active,
  hospital,
  search,
  offset,
  limit,
  id,
  medRole,
  accountStatus,
}: {
  sorting?: UserSorting[];
  type?: UserTypeResponse[];
  active?: boolean;
  hospital?: number;
  search?: string;
  offset?: number;
  limit?: number;
  id?: number;
  medRole?: MedRoleFilterEnum | TechRoleFilterEnum;
  accountStatus?: AccountStatus;
}): Promise<LimitOffsetPageAdminDetailUserInfoResponse> => {
  const response = await usersApiV1.adminGetUserInfo(
    sorting,
    id,
    type,
    active,
    search,
    hospital,
    medRole,
    accountStatus,
    limit,
    offset,
  );
  return response.data;
};

export const unblockUser = async (userId: number): Promise<unknown> => {
  const response = await usersApiV1.adminUnlockUser(userId);
  return response.data;
};

export const deleteUser = async (userId: number): Promise<unknown> => {
  const response = await usersApiV1.deleteUser(userId);
  return response.data;
};

export const disableUser = async (userId: number): Promise<unknown> => {
  const response = await usersApiV1.deactivateStaffUser(userId);
  return response.data;
};

export const getRoles = async (): Promise<ProfileRole[]> => {
  const response = await rolesApiV1.listAvailableRoles();
  return response.data;
};

export const getAssignedPatients = async (id: number): Promise<PatientBasicInfo[]> => {
  const response = await patientsApiV1.getAssignedPatients(id);
  return response.data;
};

export const resendInvite = async (id: number): Promise<SuccessfulResponse> => {
  const response = await registerStaffApiV1.resendInviteStaffUser(id);
  return response.data;
};

export const getHospitalTags = async (hospitalId: number): Promise<LimitOffsetPageTagItem> => {
  const response = await tagsApiV1.getTags(hospitalId);
  return response.data;
};

export const updateMedStaffData = async (
  medStaffId: number,
  info: AdminUpdateMedStaffInfo,
): Promise<unknown> => {
  const response = await usersApiV1.adminUpdateMedStaffData(medStaffId, info);
  return response.data;
};
