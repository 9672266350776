import { Box, Typography } from "@mui/material";
import { VrsButton, dateFormats } from "@veris-health/web-core";
import React from "react";
import { Datepicker } from "@mobiscroll/react";
import dayjs from "dayjs";

type PeriodPickerProps = {
  onExport: () => void;
  disabledExport: boolean;
  children?: React.ReactNode;
  onRangeChange: (range: { start: string; end: string }) => void;
};

export const ExportsMessage = () => {
  return (
    <Typography mt={1} sx={{ display: "block" }} variant="description">
      *Please avoid choosing automatic text-to-number detection, as it may result in the loss of
      leading zeros
    </Typography>
  );
};

const PeriodPicker = (
  { onExport, disabledExport, children, onRangeChange }: PeriodPickerProps,
  mt = 1,
): JSX.Element => {
  return (
    <>
      <Box>
        <Datepicker
          controls={["calendar"]}
          anchorAlign="center"
          theme="material"
          themeVariant="light"
          dateFormat="M/D/YYYY"
          inputComponent="input"
          inputProps={{
            placeholder: "Choose range",
            className: "md-mobile-picker-input",
            readOnly: true,
          }}
          max={new Date()}
          touchUi
          select="range"
          onChange={(picker) => {
            const [start, end] = picker.value;
            if (start && end) {
              onRangeChange({
                start: dayjs(start).format(dateFormats["YYYY-MM-DD"]),
                end: dayjs(end).format(dateFormats["YYYY-MM-DD"]),
              });
            }
          }}
        />
        {children}
        <Box py={2}>
          <VrsButton buttonType="primary" disabled={disabledExport} onClick={onExport}>
            Export
          </VrsButton>
          <ExportsMessage />
        </Box>
      </Box>
    </>
  );
};

export default PeriodPicker;
