import {
  FeedbackSurveyCreateModel,
  FeedbackSurvey,
  UpdateFeedbackSurvey,
} from "@veris-health/virtual-doc-ms/lib/v1/api";
import { feedbackSurveyApiV1 } from "../../../api";

export const getFeedbackSurveys = async (expired?: boolean): Promise<FeedbackSurvey[]> => {
  const response = await feedbackSurveyApiV1.getAllFeedbackSurveys(expired);
  return response.data;
};

export const getFeedbackSurvey = async (surveyId: number): Promise<FeedbackSurvey> => {
  const response = await feedbackSurveyApiV1.getFeedbackSurvey(surveyId);
  return response.data;
};

export const createFeedbackSurvey = async (
  survey: FeedbackSurveyCreateModel,
): Promise<FeedbackSurvey> => {
  const response = await feedbackSurveyApiV1.createFeedbackSurvey(survey);
  return response.data;
};

export const updateFeedbackSurvey = async (
  id: number,
  survey: UpdateFeedbackSurvey,
): Promise<FeedbackSurvey> => {
  const response = await feedbackSurveyApiV1.updateFeedbackSurvey(id, survey);
  return response.data;
};

export const deleteFeedbackSurvey = async (id: number): Promise<void> => {
  const response = await feedbackSurveyApiV1.deleteFeedbackSurvey(id);
  return response.data;
};

export const exportFeedbackSurvey = async (id: number, detailed?: boolean): Promise<string> => {
  const response = await feedbackSurveyApiV1.exportPatientFeedbackBySurveyId(id, detailed);
  return response.data;
};
