import { Grid } from "@mui/material";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { billApiV1 } from "../../../api";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { getPatientPeriods, selectPeriods } from "../billingSlice";
import BillingForm from "../components/BillingForm";
import { exportCSVFile } from "../../../utils/files";

const PatientBillingContainer = (): JSX.Element => {
  const periods = useAppSelector(selectPeriods);
  const [selectedPatient, setSelectedPatient] = useState<{ value: string; label: string }>();
  const [selectedPeriod, setSelectedPeriod] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onPatientChange = (value: { value: string; label: string } | null) => {
    setSelectedPeriod(undefined);
    if (value) {
      setSelectedPatient(value);
      dispatch(getPatientPeriods({ id: +value.value }));
    } else setSelectedPatient(undefined);
  };

  const onPeriodChange = (value: string) => {
    if (value) {
      setSelectedPeriod(value);
    }
  };

  const onExportCSV = () => {
    if (selectedPatient?.value && selectedPeriod) {
      const periodRange = periods?.find((period) => period.id === selectedPeriod);
      if (periodRange) {
        setLoading(true);
        billApiV1
          .exportBillSummaryForPatientForPeriod(
            +selectedPatient.value,
            periodRange.start,
            periodRange.end,
          )
          .then((res) => {
            exportCSVFile(
              res.data.toString(),
              `${selectedPatient.label} Bill start:${periodRange.start} end:${periodRange.end}`,
            );
            SnackbarUtils.success("File Successfully exported");
          })
          .catch((err: AxiosError) => {
            if (err.response?.data.message || err.response?.data.detail) {
              SnackbarUtils.error(err.response.data.message || err.response?.data.detail);
            } else {
              SnackbarUtils.error("File exporting failed");
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <Grid container py={2}>
      <Grid item xs={4} px={2}>
        <BillingForm
          handlePatientChange={onPatientChange}
          handlePeriodChange={onPeriodChange}
          selectedPatient={selectedPatient}
          selectedPeriod={selectedPeriod}
          handleExportCSV={onExportCSV}
          loadingStatus={loading}
        />
      </Grid>
    </Grid>
  );
};

export default PatientBillingContainer;
