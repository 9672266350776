import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { NotificationView } from "@veris-health/communication-ms/lib/v1";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectAllNotifications } from "../notificationsSlice";

type NotificationQueryParams = {
  notificationId: string;
};

export function useNotification(): NotificationView | undefined {
  const [notification, setNotification] = useState<NotificationView>();
  const { notificationId } = useParams<NotificationQueryParams>();
  const notifications = useAppSelector(selectAllNotifications);

  useEffect(() => {
    if (notificationId === "all") {
      setNotification(notifications[0]);
      return;
    }

    const selectedNotification = notifications.find(
      ({ notification_id: id }) => id === Number(notificationId),
    );

    if (selectedNotification) {
      setNotification(selectedNotification);
    }
  }, [notificationId, notifications]);

  return notification;
}
