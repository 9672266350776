import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { VrsActionLayout } from "../../ui/components/VrsActionLayout";
import { AutoComplete } from "../../ui/components/Autocomplete";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectHospitals } from "../Users/userSlice";
import { reportsApiV1 } from "../../api";
import SnackbarUtils from "../../utils/SnackbarUtils";
import { exportCSVFile } from "../../utils/files";
import PeriodPicker from "./components/PeriodPicker";
import { useSuperAdmin } from "../../context/profile";
import { DeIdentifiedMioExport } from "./DeIdentifiedMioExport";

interface Range {
  start: string;
  end: string;
}

export const ExportsContainer = (): JSX.Element => {
  const hospitals = useAppSelector(selectHospitals);
  const isSuperAdmin = useSuperAdmin();
  const [selectedHospital, setSelectedHospital] = useState<{ value: string; label: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<Range | undefined>();

  const onHospitalChange = (value: { value: string; label: string } | null) => {
    if (value) {
      setSelectedHospital(value);
    } else setSelectedHospital(undefined);
  };

  const onExportCSV = () => {
    if (selectedRange && selectedHospital) {
      if (selectedRange) {
        setLoading(true);
        reportsApiV1
          .exportDemographicsDataForHospitalForPeriod(
            selectedRange.start,
            selectedRange.end,
            Number(selectedHospital.value),
          )
          .then((res) => {
            const responseMessage = res.data.message;
            if (responseMessage) {
              SnackbarUtils.info(responseMessage);
            } else {
              exportCSVFile(
                res.data.toString(),
                `Demographics export  start:${selectedRange.start} end:${selectedRange.end}`,
              );
              SnackbarUtils.success("File Successfully exported");
            }
          })
          .catch(() => {
            SnackbarUtils.error("File exporting failed");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <Box
      width="100%"
      py={3}
      px={2}
      pb={1}
      height="100%"
      gap={4}
      display="flex"
      flexDirection="column"
    >
      <VrsActionLayout title="Demographics">
        <Grid container py={2} px={2}>
          <Grid item xs={4}>
            {hospitals && (
              <AutoComplete
                options={
                  hospitals.map((hosp) => ({
                    value: `${hosp.id}`,
                    label: hosp.name || "",
                  })) || []
                }
                placeholder="Select hospital"
                handleChange={(e, option) => onHospitalChange(option)}
                autoCompleteId="hospital-select"
                loading={false}
              />
            )}
            {selectedHospital && (
              <Grid item>
                <PeriodPicker
                  onExport={onExportCSV}
                  disabledExport={loading || !selectedRange}
                  onRangeChange={({ start, end }) => {
                    setSelectedRange({ start, end });
                  }}
                />

                {loading && (
                  <Typography>
                    *It might take some time to finish the download of the file
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </VrsActionLayout>
      {isSuperAdmin && (
        <VrsActionLayout title="At-home-devices (spot check data)">
          <Grid container py={2} px={2}>
            <Grid item>
              <DeIdentifiedMioExport />
            </Grid>
          </Grid>
        </VrsActionLayout>
      )}
    </Box>
  );
};
export default ExportsContainer;
