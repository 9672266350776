export const enum TableColumnNames {
  Id = "Id",
  HubSN = "Hub SN",
  SensorType = "Sensor",
  TimeStamp = "Timestamp",
  IngestionTimestamp = "Ingestion Timestamp",
  MioTimestamp = "Hub Timestamp",
  CreatedAt = "Created At",
}

export interface MioColumn {
  id: TableColumnNames;
  label: TableColumnNames;
  orderBy?: string;
  description?: string;
}
export const columns: MioColumn[] = [
  {
    id: TableColumnNames.Id,
    label: TableColumnNames.Id,
    orderBy: "id",
  },
  {
    id: TableColumnNames.HubSN,
    label: TableColumnNames.HubSN,
  },
  {
    id: TableColumnNames.TimeStamp,
    label: TableColumnNames.TimeStamp,
    orderBy: "timestamp",
    description: "Measurement timestamp",
  },

  {
    id: TableColumnNames.MioTimestamp,
    label: TableColumnNames.MioTimestamp,
    orderBy: "mio_data_timestamp",
    description: "The time of the send-data event (from the HUB to MIO servers)",
  },
  {
    id: TableColumnNames.CreatedAt,
    label: TableColumnNames.CreatedAt,
    orderBy: "mio_created_at",
    description: "Record creation timestamp (on MIO servers)",
  },
  {
    id: TableColumnNames.IngestionTimestamp,
    label: TableColumnNames.IngestionTimestamp,
    orderBy: "ingestion_timestamp",
    description: "The time of ingestion/receiving the data on Veris servers",
  },
];
