export const routeParams = [
  ":userId",
  ":hospitalId",
  ":notificationId",
  ":hubSN",
  ":articleId",
  ":surveyId",
] as const;
export const routeParamsKeys = [
  "userId",
  "hospitalId",
  "notificationId",
  ":hubSN",
  ":articleId",
  ":surveyId",
] as const;
export type RouteParams = typeof routeParams[number];
export type RouteParamsKeys = typeof routeParamsKeys[number];

// NOTE: use this utility type for destructuring route param value in usePrams hook
// along with typescript Pick<> utility if you want to pick subset of values
// const { "key-name": "key-alias" } = useParams<Pick<RouteParamsKeysValues, "key-name">>();
export type RouteParamsKeysValues = {
  [k in RouteParamsKeys]: string;
};

export const USER_ID: RouteParams = ":userId";
export const HUB_SN: RouteParams = ":hubSN";
export const NOTIFICATION_ID: RouteParams = ":notificationId";
export const HOSPITAL_ID: RouteParams = ":hospitalId";
export const ARTICLE_ID: RouteParams = ":articleId";
export const SURVEY_ID: RouteParams = ":surveyId";

export const Routes = {
  STAFF_REGISTRATION: "/invite",
  STAFF_LOGIN: "/staff-login",
  MOBILE_LANDING_PAGE: "/mobile-landing-page",
  DASHBOARD: "/dashboard",
  USER_DETAILS: `/users/${USER_ID}`,
  MIO_DATA_POINTS: `/users/${USER_ID}/mio`,
  HUB_HISTORY: `/users/${USER_ID}/hub`,
  MIO_HISTORY: `/mio-history/${HUB_SN}?`,
  ACTIONS: "/actions",
  EDUCATIONAL_VIDEOS: "/educational-videos",
  SUPPORT: `/support`,
  SUPPORT_ID: `/support/${USER_ID}`,
  PATIENT_STATUS: "/patient-status",
  ALL_PATIENTS: "/patient-status/all-patients",
  PATIENT_DETAILS: `/patient-details/${USER_ID}`,
  PATIENT_GOALS: `/patient-details/${USER_ID}/goals`,
  NOTIFICATIONS_DETAILS: `/notifications/${NOTIFICATION_ID}`,
  CALENDAR: "/calendar",
  PROFILE: "/profile",
  VIDEO_COMMUNICATION: `/video-communication/${USER_ID}`,
  LOGIN_OTP_CODE: "/staff-login-otp",
  FORGOT_PASSWORD: "/forgot-password",
  PASSWORD_EXPIRATION: "/expired-password",
  BILLING: "/billing",
  BILLING_REPORTS: "/billing-reports",
  IMPLANT: "/implant",
  EDUCATIONAL_AREA: "/educational-area",
  SURVEYS: "/surveys",
  EMPTY: " ",
  ARTICLES: "/articles",
  EXPORTS: "/exports",
  ARTICLE_DETAILS: `/articles/${ARTICLE_ID}`,
  SURVEY_DETAILS: `/surveys/${SURVEY_ID}`,
} as const;

export const replaceRouteParam = (
  route: RouteDictionaryKeys,
  routeParam: RouteParams,
  routeValue: string,
): string => Routes[route].replace(routeParam, routeValue);

export type RouteDictionaryKeys = keyof typeof Routes;

export type RouteDictionaryValues =
  | typeof Routes.STAFF_REGISTRATION
  | typeof Routes.STAFF_LOGIN
  | typeof Routes.MOBILE_LANDING_PAGE
  | typeof Routes.DASHBOARD
  | typeof Routes.USER_DETAILS
  | typeof Routes.ACTIONS
  | typeof Routes.EDUCATIONAL_VIDEOS
  | typeof Routes.SUPPORT
  | typeof Routes.SUPPORT_ID
  | typeof Routes.PATIENT_STATUS
  | typeof Routes.ALL_PATIENTS
  | typeof Routes.PATIENT_DETAILS
  | typeof Routes.PATIENT_GOALS
  | typeof Routes.NOTIFICATIONS_DETAILS
  | typeof Routes.CALENDAR
  | typeof Routes.PROFILE
  | typeof Routes.VIDEO_COMMUNICATION
  | typeof Routes.LOGIN_OTP_CODE
  | typeof Routes.FORGOT_PASSWORD
  | typeof Routes.PASSWORD_EXPIRATION
  | typeof Routes.BILLING
  | typeof Routes.BILLING_REPORTS
  | typeof Routes.EMPTY
  | typeof Routes.MIO_DATA_POINTS
  | typeof Routes.MIO_HISTORY
  | typeof Routes.HUB_HISTORY
  | typeof Routes.ARTICLE_DETAILS
  | typeof Routes.SURVEY_DETAILS
  | typeof Routes.SURVEYS
  | typeof Routes.EDUCATIONAL_AREA
  | typeof Routes.IMPLANT
  | typeof Routes.EXPORTS
  | typeof Routes.ARTICLES;
