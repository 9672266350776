import { TokenResponse } from "@veris-health/user-ms/lib/v1";

export type TokenKeys = "VERIS_ACCESS_TOKEN" | "VERIS_REFRESH_TOKEN" | "VERIS_LOGIN_COUNT";

export const getToken = (tokenKey: TokenKeys): string | null => localStorage.getItem(tokenKey);

export const setToken = (tokenKey: TokenKeys, token: string): void =>
  localStorage.setItem(tokenKey, token);

export const clearToken = (tokenKey: TokenKeys): void => localStorage.removeItem(tokenKey);

export const terminateTokens = (): void => {
  clearToken("VERIS_ACCESS_TOKEN");
  clearToken("VERIS_REFRESH_TOKEN");
};

export const setTokensToStorage = (tokenResponse: TokenResponse): void => {
  const { accessToken, refreshToken } = tokenResponse;
  if (accessToken) {
    setToken("VERIS_ACCESS_TOKEN", accessToken);
  }
  if (refreshToken) {
    setToken("VERIS_REFRESH_TOKEN", refreshToken);
  }
};
