import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import {
  EducationalVideoBaseView,
  GetEducationalVideo,
  SearchEducationalVideos,
} from "@veris-health/virtual-doc-ms/lib/v1";
import { difference } from "lodash";
import { RootState } from "../../store";
import { extractErrorMessage } from "../shared/helpers";
import { Status } from "../shared/interfaces";
import { localizedLogout, logout } from "../shared/slices/authSlice";
import {
  addEducationalVideo,
  deleteEducationalVideo,
  getEducationalVideos,
  getSymptoms,
  updateEducationalVideo,
} from "./api/educationalVideosApi";
import SnackbarUtils from "../../utils/SnackbarUtils";

export const getEducationalVideosAsync = createAsyncThunk<
  SearchEducationalVideos,
  { searchTerm: string | undefined; symptom: string | undefined }
>(
  "videos/getEducationalVideos",
  async (
    { searchTerm, symptom }: { searchTerm: string | undefined; symptom: string | undefined },
    { rejectWithValue },
  ) => {
    try {
      const response = await getEducationalVideos(searchTerm, symptom);
      return response;
    } catch (error) {
      const errorMsg = extractErrorMessage(error);
      return rejectWithValue(errorMsg || "Could not fetch educational videos");
    }
  },
);

export const addEducationalVideoAsync = createAsyncThunk<
  GetEducationalVideo,
  EducationalVideoBaseView,
  {
    rejectValue: string;
  }
>("videos/addEducationalVideo", async (video: EducationalVideoBaseView, { rejectWithValue }) => {
  try {
    return await addEducationalVideo(video);
  } catch (error) {
    const errorMsg = extractErrorMessage(error);
    return rejectWithValue(errorMsg || "Could not create educational video");
  }
});

export const updateEducationalVideoAsync = createAsyncThunk<
  GetEducationalVideo,
  { id: number; video: EducationalVideoBaseView },
  {
    rejectValue: string;
  }
>("videos/updateEducationalVideo", async ({ id, video }, { rejectWithValue }) => {
  try {
    return await updateEducationalVideo(id, video);
  } catch (error) {
    const errorMsg = extractErrorMessage(error);
    return rejectWithValue(errorMsg || "Could not update educational video");
  }
});

export const deleteEducationalVideoAsync = createAsyncThunk<number, number>(
  "videos/deleteEducationalVideo",
  async (id, { rejectWithValue }) => {
    try {
      await deleteEducationalVideo(id);
      return id;
    } catch (error) {
      const errorMsg = extractErrorMessage(error);
      return rejectWithValue(errorMsg || "Could not delete educational video");
    }
  },
);

export const getAvailableSymptomsForVideosAsync = createAsyncThunk(
  "videos/availableSymptoms",
  async () => {
    const symptoms = await getSymptoms();
    return symptoms;
  },
);

interface EducationalVideosState {
  educationalVideos: {
    data: SearchEducationalVideos;
    status: Status;
    createStatus: {
      status: Status;
    };
  };
  availableSymptoms: { data: string[]; status: Status };
}

const initialState: EducationalVideosState = {
  educationalVideos: {
    data: {} as SearchEducationalVideos,
    status: "idle",
    createStatus: { status: "idle" },
  },
  availableSymptoms: { data: [], status: "idle" },
};

const educationalVideoSlice = createSlice({
  name: "Educational videos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(localizedLogout, () => {
        return initialState;
      })
      .addCase(getEducationalVideosAsync.pending, (state) => {
        state.educationalVideos.status = "loading";
      })
      .addCase(getEducationalVideosAsync.fulfilled, (state, { payload }) => {
        state.educationalVideos.data = payload;
        state.educationalVideos.status = "idle";
      })
      .addCase(getEducationalVideosAsync.rejected, (state) => {
        state.educationalVideos.status = "failed";
      })
      .addCase(addEducationalVideoAsync.pending, (state) => {
        state.educationalVideos.createStatus.status = "loading";
      })
      .addCase(addEducationalVideoAsync.fulfilled, (state, { payload }) => {
        state.educationalVideos.data.items = [payload, ...state.educationalVideos.data.items];
        state.educationalVideos.createStatus.status = "idle";
      })
      .addCase(addEducationalVideoAsync.rejected, (state) => {
        state.educationalVideos.createStatus.status = "failed";
      })
      .addCase(updateEducationalVideoAsync.fulfilled, (state, { payload }) => {
        state.educationalVideos.data.items = state.educationalVideos.data.items.map((video) => {
          if (video.id === payload.id) {
            return payload;
          }
          return video;
        });
        state.educationalVideos.createStatus.status = "idle";
      })
      .addCase(updateEducationalVideoAsync.pending, (state) => {
        state.educationalVideos.createStatus.status = "loading";
      })
      .addCase(updateEducationalVideoAsync.rejected, (state) => {
        state.educationalVideos.createStatus.status = "failed";
      })
      .addCase(deleteEducationalVideoAsync.fulfilled, (state, { payload }) => {
        SnackbarUtils.success("Educational Video Deleted!");
        state.educationalVideos.data.items = state.educationalVideos.data.items.filter(
          (video) => video.id !== payload,
        );
        state.educationalVideos.status = "idle";
      })
      .addCase(getAvailableSymptomsForVideosAsync.pending, (state) => {
        state.availableSymptoms.status = "loading";
      })
      .addCase(getAvailableSymptomsForVideosAsync.fulfilled, (state, { payload }) => {
        state.availableSymptoms.status = "idle";
        state.availableSymptoms.data = payload;
      })
      .addCase(getAvailableSymptomsForVideosAsync.rejected, (state) => {
        state.availableSymptoms.status = "failed";
      });
  },
});

export const selectEducationalVideos = ({
  educationalVideos,
}: RootState): SearchEducationalVideos => {
  return educationalVideos.educationalVideos.data;
};

export const selectEducationalVideosStatus = ({ educationalVideos }: RootState): Status =>
  educationalVideos.educationalVideos.status;

export default educationalVideoSlice.reducer;

export const selectSymptoms = ({ educationalVideos }: RootState): string[] =>
  educationalVideos.availableSymptoms.data;

export const selectAvailableSymptoms = createSelector(
  [selectEducationalVideos, selectSymptoms],
  ({ items }, symptoms) => {
    if (items) {
      const remappedVideoSymptoms = items.map((video) => video.symptom);
      return difference(symptoms, remappedVideoSymptoms);
    }
    return symptoms;
  },
);
