import React from "react";
import { Box, Grid, Theme, Typography, useMediaQuery, Badge, Chip } from "@mui/material";
import { IconName, VrsIcon, VrsAvatar, VrsTooltip, verisColors } from "@veris-health/web-core";
import { VrsPatientInfo } from "../../../shared/interfaces";
import { getShortName } from "../../helpers/helpers";

export interface VrsPatientInfoCardProps {
  patientInfo: VrsPatientInfo;
  backgroundColor?: string;
  newNotification?: boolean;
  onClick?: (id: number) => void;
  searchView?: boolean;
  subtitle?: string;
}

export function VrsPatientInfoCard({
  patientInfo,
  backgroundColor,
  newNotification,
  onClick,
  searchView = false,
  subtitle,
}: VrsPatientInfoCardProps): JSX.Element {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const { name, id, inactive, observation, patientStatus } = patientInfo;
  const avatarSize = isSmallScreen ? 38 : 44;

  return (
    <Box
      data-test-hook="patientInfoCard-element"
      display="flex"
      alignItems="center"
      paddingY={(theme) => theme.spacing(0.375)}
      paddingX={(theme) => theme.spacing(0.625)}
      sx={{
        borderRadius: 1.5,
        flexGrow: 1,
        backgroundColor: backgroundColor || verisColors.neutrals["grey-background"],
      }}
    >
      <>
        {newNotification ? (
          <Badge
            data-test-hook="badge-element"
            variant="dot"
            color="info"
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            sx={{ paddingLeft: "4px", paddingTop: "4px" }}
            overlap="circular"
          >
            <Box mr={1.5}>
              <VrsAvatar
                imageUrl={patientInfo.picture}
                name={name}
                size={avatarSize}
                opacity={inactive ? "70%" : "100%"}
                bottomRightIcon={observation && <VrsIcon name={IconName.Observation} />}
              />
            </Box>
          </Badge>
        ) : (
          <Box
            data-test-hook="avatarWrapper-element"
            mr={1.5}
            onClick={() => onClick && onClick(id)}
            sx={{ cursor: onClick ? "pointer" : "auto" }}
          >
            <VrsAvatar
              imageUrl={patientInfo.picture}
              name={name}
              size={avatarSize}
              opacity={inactive ? "70%" : "100%"}
              bottomRightIcon={observation && <VrsIcon name={IconName.Observation} />}
            />
          </Box>
        )}
      </>

      <Grid marginX={(theme) => theme.spacing(0, 1.5)} marginTop={(theme) => theme.spacing(0.5)}>
        <Box
          sx={{
            display: "flex",
            cursor: onClick ? "pointer" : "auto",
            alignItems: "center",
            flexWrap: "wrap",
          }}
          onClick={() => onClick && onClick(id)}
        >
          <Box marginRight={(theme) => theme.spacing(1)}>
            {/* Shorten name if it's a small screen - but not on search views */}
            {isSmallScreen && !searchView ? (
              <VrsTooltip title={name} bcgcolor={verisColors.neutrals.black}>
                <Typography
                  variant="subtitle1"
                  color={inactive ? verisColors.neutrals["grey-3"] : verisColors.neutrals.black}
                >
                  {getShortName(name)}
                </Typography>
              </VrsTooltip>
            ) : (
              <Typography
                variant="subtitle1"
                color={inactive ? verisColors.neutrals["grey-3"] : verisColors.neutrals.black}
                sx={{ wordBreak: "break-word" }}
              >
                {name}
              </Typography>
            )}
            <Typography
              variant="body"
              color={verisColors.neutrals["grey-mid"]}
              sx={{ textTransform: "capitalize" }}
            >
              {patientStatus}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" whiteSpace="nowrap" justifyContent="flex-start" alignItems="baseline">
          <Box
            data-test-hook="diagnosis-tag"
            sx={{
              display: "flex",
              alignItems: "center",
              flexFlow: "row wrap",
              wordBreak: "break-word",
              whiteSpace: "normal",
            }}
          >
            <Chip
              label={
                <Typography
                  variant="description"
                  color={(theme) => theme.veris.colors.mango.normal}
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  {subtitle}
                </Typography>
              }
              sx={{
                backgroundColor: (theme) => theme.veris.colors.mango.light,
                height: "25px",
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}
