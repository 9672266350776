import { Middleware, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

export const crashReporterMiddleware: Middleware =
  () => (next) => (action: PayloadAction<unknown>) => {
    try {
      return next(action);
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          action: action.type,
        },
      });
      throw e;
    }
  };
