import React, { useRef } from "react";
import { Box, CircularProgress, Table, Typography, useTheme } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { LimitOffsetPageMioHistory, MioHistory } from "@veris-health/med-data-ms/lib/v1/api";
import dayjs from "dayjs";
import { VrsSortedTableHeader } from "../../../ui/components/VrsSortedTableHeader";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../ui/components/Tables/shared/StyledTableElements";
import { Status } from "../../shared/interfaces";
import { columns, HubColumn, TableColumnNames } from "../config/hubHistory";
import { Sorting } from "../useMioDataPoints";
import { HubSN } from "./HubSN";

export interface HubHistoryTableProps {
  data: LimitOffsetPageMioHistory;
  status?: Status;
  total?: number;
  fetchMore: () => void;
  sorting?: Sorting;
}

export const HubHistoryTable = React.forwardRef<HTMLTableElement, HubHistoryTableProps>(
  ({ data, status, fetchMore, sorting }: HubHistoryTableProps, ref) => {
    const scrollParentRef = useRef<HTMLElement>(null);
    return (
      <>
        {data.items.length > 0 ? (
          <Box
            id="hub-hisotry-table-scroll-container"
            ref={scrollParentRef}
            sx={{
              backgroundColor: (theme) => theme.veris.colors.neutrals.white,
              border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
              borderRadius: (theme) => theme.shape.borderRadius,
            }}
          >
            <InfiniteScroll
              scrollableTarget="users-table-scroll-container"
              hasMore={data.total - data.items.length > 0}
              style={{ borderRadius: 15, maxHeight: "73vh" }}
              height="73vh"
              dataLength={data?.items?.length}
              next={() => {
                fetchMore();
              }}
              loader={
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={1}
                  borderTop={(theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`}
                >
                  <CircularProgress />
                </Box>
              }
            >
              <Table ref={ref}>
                <VrsSortedTableHeader
                  columns={columns}
                  order={sorting?.direction}
                  orderBy={sorting?.key}
                />
                <StyledTableBody>
                  {data.items.map((hub) => (
                    <StyledTableRow key={hub.hub_sn}>
                      {columns.map((column) => (
                        <HubHistoryDataColumn column={column} data={hub} key={column.id} />
                      ))}
                    </StyledTableRow>
                  ))}
                </StyledTableBody>
              </Table>
            </InfiniteScroll>
          </Box>
        ) : (
          <>
            {status === "idle" && data.items.length === 0 && (
              <Typography
                color={({ veris }) => veris.colors.neutrals["grey-3"]}
                textAlign="center"
                variant="body"
              >
                There is no data at this moment.
              </Typography>
            )}
          </>
        )}
      </>
    );
  },
);

function HubHistoryDataColumn({ column, data }: { column: HubColumn; data: MioHistory }) {
  const theme = useTheme();
  return (
    <StyledTableCell key={column.id}>
      {column.id === TableColumnNames.HubSn && <HubSN hubSn={data.hub_sn} />}
      {column.id === TableColumnNames.SetAt && (
        <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-4"]}>
          {data.set_at ? dayjs(data.set_at).format() : "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.UnsetAt && (
        <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-4"]}>
          {data.unset_at ? dayjs(data.unset_at).format() : "N/A"}
        </Typography>
      )}
    </StyledTableCell>
  );
}
