import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { DialogContent, FormLabel, Grid, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { FormikProvider, useFormik } from "formik";
import {
  MedRoleEnum,
  StaffType,
  TechRoleEnum,
  UserRoleTypeProfile,
  UserTypeResponse,
} from "@veris-health/user-ms/lib/v1";
import { AxiosError } from "axios";
import { get } from "lodash";
import { Option, VrsButton, VrsDialog, VrsSelect } from "@veris-health/web-core";
import { VrsFormInputField } from "../../../ui/components/VrsFormInputField";
import { PhoneFormInputField } from "../../LoginRegistration/PhoneFormInputField";
import { inviteStaffUser } from "../../shared/slices/api/authApi";
import { RoleNames } from "../../../constants";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectHospitals } from "../userSlice";
import { useAuthenticatedUserRoles, useProfile } from "../../../context/profile";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { useRoles } from "../../../context/roles";
import { emailValidation } from "../../../utils/validations";

const inviteUserValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required.")
    .matches(/^[aA-zZ\s]+$/, "Should contain only letters"),
  lastName: yup
    .string()
    .required("Last name is required.")
    .matches(/^[aA-zZ\s]+$/, "Should contain only letters"),
  phone: yup
    .string()
    .required("Phone Number is required.")
    .max(20)
    .when("country", {
      is: (value: string) => value.length >= 2,
      then: yup.string().min(8),
      otherwise: yup.string().min(10),
    })
    .matches(/^[+]?\d+$/, "Should contain only digits."),
  email: emailValidation,
  profession: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, "Should contain only letters")
    .notRequired(),
  role: yup.string().when("type", {
    is: (value: string) => value !== UserTypeResponse.ProcedureStaff,
    then: yup.string().required("Role is required."),
    otherwise: yup.string().notRequired(),
  }),
  hospital: yup.string().when("role", {
    is: (value: string) =>
      value === TechRoleEnum.Admin ||
      value === TechRoleEnum.Superadmin ||
      value === TechRoleEnum.Billingexpert ||
      value === TechRoleEnum.Implantmanufacturer,
    then: yup.string().notRequired(),
    otherwise: yup.string().required("Hospital is required."),
  }),
  npi: yup
    .string()
    .when("role", {
      is: (value: string) => value === MedRoleEnum.Doctor,
      then: yup.string().required("NPI is required."),
      otherwise: yup.string().notRequired(),
    })
    .matches(/^[+]?\d+$/, "Should contain only digits."),
});

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  type: UserTypeResponse.MedStaff,
  country: "1",
  profession: "",
  npi: "",
  role: undefined,
  hospital: undefined,
};

interface InviteUserProps {
  open: boolean;
  onClose: () => void;
}

export function InviteUser({ open, onClose }: InviteUserProps): JSX.Element {
  const hospitals = useAppSelector(selectHospitals);
  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>();
  const roles = useRoles();
  const theme = useTheme();
  const loggedUser = useProfile();
  const authenticatedUserRoles = useAuthenticatedUserRoles();

  const canInviteImplantManufacturer = useMemo(() => {
    return authenticatedUserRoles.some((item) =>
      [TechRoleEnum.Admin, TechRoleEnum.Superadmin].includes(item as TechRoleEnum),
    );
  }, [authenticatedUserRoles]);

  const hospitalsOptions: Option[] = [
    { label: "None", value: "" },
    ...hospitals.map((item) => {
      return {
        label: item.name,
        value: String(item.id),
      };
    }),
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      loggedUserRole: loggedUser?.user_roles?.map((userRole) => userRole.role_name) || [],
    },
    validationSchema: inviteUserValidationSchema,
    onSubmit: ({
      firstName,
      lastName,
      email,
      phone,
      type,
      country,
      profession,
      role,
      hospital,
      npi,
    }) => {
      setLoading(true);
      inviteStaffUser({
        type: type as unknown as StaffType,
        first_name: firstName,
        last_name: lastName,
        hospital_id: hospital || undefined,
        email,
        phone_number: `+${country}${phone}`,
        profession: profession || undefined,
        roles: role ? [role] : undefined,
        npi: npi || undefined,
      })
        .then(() => {
          SnackbarUtils.success("Invite sent");
          setMessage("Invite sent");
          setError(false);
          onClose();
        })
        .catch((e: AxiosError) => {
          const errorMessage =
            e.response &&
            (get(e, "response.data.message", "") || get(e, "response.data.detail[0].msg", ""));

          const generalErrorMessage = "Something went wrong, please try again";
          SnackbarUtils.error(errorMessage || generalErrorMessage);
          setMessage(errorMessage || generalErrorMessage);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const {
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
    resetForm,
    touched,
    setFieldTouched,
  } = formik;
  const { firstName, lastName, email, phone, type, profession, npi, role, hospital } =
    formik.values;

  const onTypeChange = async () => {
    await setFieldValue("profession", "");
    await setFieldValue("role", undefined);
    await setFieldValue("hospital", undefined);
    await setFieldTouched("hospital", true);
    formik.validateForm();
  };

  useEffect(() => {
    if (formik.touched.type === true) {
      onTypeChange();
    }
  }, [formik.values.type]);

  useEffect(
    () => () => {
      if (open) {
        resetForm();
        setMessage(undefined);
      }
    },
    [open],
  );

  const showHospital = React.useMemo(() => {
    if (type === UserTypeResponse.ProcedureStaff) {
      return true;
    }
    if (!role) {
      return false;
    }
    if (type === UserTypeResponse.MedStaff) {
      return true;
    }
    if (type === UserTypeResponse.TechStaff) {
      return !(
        role === TechRoleEnum.Superadmin ||
        role === TechRoleEnum.Admin ||
        role === TechRoleEnum.Billingexpert ||
        role === TechRoleEnum.Implantmanufacturer
      );
    }
    return false;
  }, [role, type]);

  const showSelectRole = type !== UserTypeResponse.ProcedureStaff;
  const showNPI = type === UserTypeResponse.MedStaff && hospital && role;

  const hospitalFormItemStyles: React.CSSProperties = {
    width: "91.5%",
  };

  const npiFormItemStyles: React.CSSProperties = {
    width: "91.5%",
    paddingLeft: 0,
  };
  if (type !== UserTypeResponse.ProcedureStaff) {
    npiFormItemStyles.paddingTop = 0;
  }

  if (showSelectRole) {
    hospitalFormItemStyles.paddingLeft = 0;
  } else {
    hospitalFormItemStyles.paddingTop = 0;
  }

  return (
    <VrsDialog
      open={open}
      onClose={onClose}
      title="Invite User"
      sx={{ "& .MuiPaper-root": { height: "auto" } }}
    >
      <DialogContent sx={{ overflowY: "unset" }}>
        <FormikProvider value={formik}>
          <form onSubmit={handleSubmit}>
            <Grid item xs={12} container spacing={4} style={{ margin: "auto", width: "auto" }}>
              <Grid item xs={6} style={{ width: "91.5%", paddingLeft: "0", paddingTop: 0 }}>
                <VrsFormInputField
                  name="firstName"
                  type="text"
                  label="First Name"
                  value={firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.firstName && errors.firstName ? errors.firstName : " "}
                  error={!!touched.firstName && Boolean(errors.firstName)}
                  isValidField={firstName.length && !errors.firstName}
                />
              </Grid>
              <Grid item xs={6} style={{ width: "91.5%", paddingTop: 0 }}>
                <VrsFormInputField
                  name="lastName"
                  type="text"
                  label="Last Name"
                  value={lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.lastName && errors.lastName ? errors.lastName : " "}
                  error={!!touched.lastName && Boolean(errors.lastName)}
                  isValidField={lastName.length && !errors.lastName}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: "0", paddingTop: 0 }}>
                <VrsFormInputField
                  name="email"
                  label="Email"
                  type="text"
                  value={email}
                  error={!!touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email ? errors.email : " "}
                  isValidField={email.length && !errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6} style={{ width: "91.5%", paddingTop: 0 }}>
                <PhoneFormInputField
                  name="phone"
                  label="Phone Number"
                  value={phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.phone && errors.phone ? errors.phone : " "}
                  error={Boolean(errors.phone) && !!touched.phone}
                  isValidField={phone.length && !errors.phone}
                  onSelected={(value) => {
                    setFieldValue("country", value.code.trim());
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ width: "91.5%", paddingTop: 0, paddingLeft: 0 }}>
                <Typography m={0} variant="subtitle2">
                  Staff type *
                  <FormLabel />
                </Typography>
                <VrsSelect
                  placeholder="Choose Staff Type"
                  options={[
                    { label: "Medical Staff", value: UserTypeResponse.MedStaff },
                    { label: "Tech Staff", value: UserTypeResponse.TechStaff },
                    ...(!["production", "preprod"].includes(import.meta.env.VITE_ENVIRONMENT)
                      ? [
                          {
                            label: "Procedure Staff",
                            value: UserTypeResponse.ProcedureStaff,
                          },
                        ]
                      : []),
                  ]}
                  value={type}
                  onSelected={(value) => setFieldValue("type", value)}
                  innerSx={{ padding: theme.spacing(0.75, 0.75, 0.75, 0) }}
                  onBlur={() => formik.setFieldTouched("type", true)}
                />
              </Grid>
              {showSelectRole && (
                <Grid item xs={6} style={{ width: "91.5%", paddingTop: 0 }}>
                  <Typography m={0} variant="subtitle2">
                    Staff role *
                    <FormLabel />
                  </Typography>
                  <VrsSelect
                    onBlur={() => formik.setFieldTouched("role", true)}
                    name="role"
                    placeholder="Choose Staff Role"
                    options={roles
                      .filter((entry) => {
                        if (
                          entry.profile_type === UserRoleTypeProfile.TechStaff &&
                          entry.role_name === TechRoleEnum.Implantmanufacturer
                        ) {
                          return canInviteImplantManufacturer;
                        }
                        return (entry.profile_type as unknown as UserTypeResponse) === type;
                      })
                      .map((value) => {
                        return {
                          label: value?.role_name
                            ? RoleNames[value.role_name as MedRoleEnum | TechRoleEnum]
                            : "",
                          value: value?.role_name,
                        };
                      })}
                    value={role}
                    onSelected={(value) => {
                      if (!showHospital) {
                        setFieldValue("hospital", undefined);
                      }
                      setFieldValue("role", value);
                    }}
                    innerSx={{ padding: theme.spacing(0.75, 0.75, 0.75, 0) }}
                    maxHeight="250px"
                  />
                  {errors.role && touched.role && (
                    <Grid item xs={12} style={{ paddingLeft: 0 }}>
                      <Typography
                        color={theme.veris.colors.errors.normal}
                        variant="caption"
                        component="div"
                        my={1.5}
                      >
                        {errors.role}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
              {showHospital && (
                <Grid
                  item
                  xs={6}
                  style={{
                    ...hospitalFormItemStyles,
                  }}
                >
                  <Box>
                    <Typography m={0} variant="subtitle2">
                      Hospital*
                      <FormLabel />
                    </Typography>
                    <VrsSelect
                      innerSx={{ padding: theme.spacing(0.75, 0.75, 0.75, 0) }}
                      value={hospital}
                      options={hospitalsOptions}
                      placeholder="Choose hospital"
                      onSelected={(value) => setFieldValue("hospital", value)}
                      maxHeight="250px"
                      onBlur={() => formik.setFieldTouched("hospital", true)}
                    />
                    {errors.hospital && touched.hospital && (
                      <Grid item xs={12} style={{ paddingLeft: 0 }}>
                        <Typography
                          color={theme.veris.colors.errors.normal}
                          variant="caption"
                          component="div"
                          my={1.5}
                        >
                          {errors.hospital}
                        </Typography>
                      </Grid>
                    )}
                  </Box>
                </Grid>
              )}

              {type === UserTypeResponse.MedStaff && hospital && role && (
                <Grid item xs={6}>
                  <VrsFormInputField
                    name="profession"
                    label="Profession (Specialty)"
                    value={profession}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.profession && errors.profession ? errors.profession : " "}
                    error={Boolean(errors.profession) && !!touched.profession}
                    isValidField={profession.length && !errors.profession}
                    required={false}
                  />
                </Grid>
              )}
              {showNPI && (
                <Grid item xs={6} style={{ ...npiFormItemStyles }}>
                  <VrsFormInputField
                    name="npi"
                    label="NPI"
                    value={npi}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.npi && errors.npi ? errors.npi : " "}
                    error={Boolean(errors.npi) && !!touched.npi}
                    isValidField={npi.length && !errors.npi}
                    required={role === MedRoleEnum.Doctor}
                  />
                </Grid>
              )}
              {message && (
                <Grid item xs={12} style={{ paddingLeft: 0 }}>
                  <Typography
                    color={
                      error ? theme.veris.colors.errors.normal : theme.veris.colors.info.success
                    }
                    variant="caption"
                    component="div"
                    my={1.5}
                  >
                    {message}
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                marginTop={4}
                style={{ display: "flex", flexDirection: "row-reverse" }}
              >
                <VrsButton
                  buttonType="primary"
                  disabled={!isValid || !dirty || loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Invite User
                </VrsButton>
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      </DialogContent>
    </VrsDialog>
  );
}
