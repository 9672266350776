import { createSelector } from "@reduxjs/toolkit";
import {
  ChatThreadInfo,
  SupportedAttachmentTypesEnum as SupportedAttachmentTypes,
} from "@veris-health/communication-ms/lib/v1";
import { RootState } from "../../../store";
import { Status } from "../../shared/interfaces";
import {
  AvailableAttachmentFiltering,
  NormalizedAttachments,
  NormalizedChatMessages,
  VrsTypingIndicatorReceived,
} from "./interfaces";
import { attachmentsAdapter, messagesAdapter } from "./communicationSlice";

export const selectChatApiEndpoint = ({ communication }: RootState): string =>
  communication.chatApiEndpoint;

export const selectChatUserAccessToken = ({ communication }: RootState): string =>
  communication.chatUserAccessToken;

export const selectMyAzureChatUserId = ({ communication }: RootState): string =>
  communication.chatUserId;

export const selectMyChatDisplayName = ({ communication }: RootState): string =>
  communication.chatDisplayName;

export const selectChatThreads = ({ communication }: RootState): ChatThreadInfo[] =>
  communication.chatThreads.threads;

export const selectChatThreadsStatus = ({ communication }: RootState): Status =>
  communication.chatThreads.status;

export const selectCurrentThreadId = ({ communication }: RootState): string | undefined =>
  communication.currentThreadId;

export const selectMessages = (rootState: RootState, threadId?: string): NormalizedChatMessages => {
  if (threadId) {
    return (
      messagesAdapter
        .getSelectors<RootState>((state) => state.communication.chatMessages.items)
        .selectById(rootState, threadId) || { messages: [], isLastPage: true, id: threadId }
    );
  }
  return {
    messages: [],
    isLastPage: true,
    id: "",
  };
};

export const selectNewMessageNotification = ({ communication }: RootState): { threadId?: string } =>
  communication.newMessageNotification;

export const selectStatus = ({ communication }: RootState): Status => communication.status;

export const selectMessagesStatus = ({ communication }: RootState): Status =>
  communication.chatMessages.status;

export const selectThreadAttachments = (
  rootState: RootState,
  threadId?: string,
): NormalizedAttachments | undefined => {
  if (threadId) {
    return attachmentsAdapter
      .getSelectors<RootState>((state) => state.communication.attachments.items)
      .selectById(rootState, threadId);
  }
  return undefined;
};

export const selectChatThreadsWithSingleParticipantId = createSelector(
  [selectChatThreads],
  (threads) => {
    const filteredThreads = threads.map((thread) => {
      const index = thread.participants.findIndex(
        (participant) => participant.user_type === "patient",
      );
      return { ...thread, patient: thread.participants[index] };
    });

    return filteredThreads;
  },
);

export const selectUnreadInfo = ({ communication }: RootState): Record<string, boolean> =>
  communication.chatThreads.unreadThreadStatus;

export const selectChatThreadsPreview = createSelector(
  [selectChatThreadsWithSingleParticipantId, selectUnreadInfo],
  (threads, unreadInfo) =>
    threads.map((thread) => ({
      ...thread,
      hasUnread: unreadInfo[thread.id],
      lastMessageReceivedOn:
        thread.last_message_received_on || thread.support_chat_info?.date_created,
    })),
);

export const selectDocTypeAttachments = createSelector(
  [(state: RootState, threadId: string) => selectThreadAttachments(state, threadId)],
  (data) => {
    if (data && data.attachments) {
      return data.attachments.filter(
        (attachment) =>
          attachment?.file_extension === SupportedAttachmentTypes.Pdf ||
          attachment?.file_extension === SupportedAttachmentTypes.Doc ||
          attachment?.file_extension === SupportedAttachmentTypes.Docx ||
          attachment?.file_extension === SupportedAttachmentTypes.Xls ||
          attachment?.file_extension === SupportedAttachmentTypes.Xlsx ||
          attachment?.file_extension === SupportedAttachmentTypes.Rtf ||
          attachment?.file_extension === SupportedAttachmentTypes.Ppt ||
          attachment?.file_extension === SupportedAttachmentTypes.Pptx ||
          attachment?.file_extension === SupportedAttachmentTypes.Odf,
      );
    }
    return [];
  },
);

export const selectImageTypeAttachments = createSelector(
  [(state: RootState, threadId: string) => selectThreadAttachments(state, threadId)],
  (data) => {
    if (data) {
      return data.attachments?.filter(
        (attachment) =>
          attachment?.file_extension === SupportedAttachmentTypes.Jpg ||
          attachment?.file_extension === SupportedAttachmentTypes.Svg ||
          attachment?.file_extension === SupportedAttachmentTypes.Png ||
          attachment?.file_extension === SupportedAttachmentTypes.Jpeg,
      );
    }
    return [];
  },
);

export const selectAttachmentFilteringType = ({
  communication,
}: RootState): AvailableAttachmentFiltering => communication.attachments.attachmentFilteringType;

export const selectFilteredThreadAttachments = createSelector(
  [
    (state: RootState, threadId: string) => selectThreadAttachments(state, threadId),
    (state: RootState, threadId: string) => selectImageTypeAttachments(state, threadId),
    (state: RootState, threadId: string) => selectDocTypeAttachments(state, threadId),
    selectAttachmentFilteringType,
  ],
  (data, imgAttachments, docTypeAttachments, filterType) => {
    switch (filterType) {
      case "all": {
        return data?.attachments?.slice();
      }

      case "doc": {
        return docTypeAttachments.slice();
      }

      case "img": {
        return imgAttachments.slice();
      }
      default: {
        return data?.attachments?.slice();
      }
    }
  },
);

export const selectAttachmentsStatus = ({ communication }: RootState): Status =>
  communication.attachments.attachmentsStatus;

export const selectTotalThreads = ({ communication }: RootState): number =>
  communication.chatThreads.total;

export const selectTypingIndicator = ({
  communication,
}: RootState): VrsTypingIndicatorReceived | undefined => communication.typingIndicator;

export const selectCurrentThread = createSelector(
  [selectChatThreadsWithSingleParticipantId, selectCurrentThreadId],
  (threads, currentThreadId) => {
    if (!currentThreadId) return undefined;
    return threads.find((thread) => thread.id === currentThreadId);
  },
);

export const selectHasUnreadMessages = createSelector([selectUnreadInfo], (threadMap) => {
  return Object.keys(threadMap).some((key) => threadMap[key]);
});

export const selectCurrentChatThreadStatus = ({ communication }: RootState): Status =>
  communication.currentChatThreadStatus;
