import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { AdminDetailUserInfoResponse, CareTeamMemberInfo } from "@veris-health/user-ms/lib/v1";
import { VrsCareTeamMember } from "../../../ui/components/VrsTags";

export interface HospitalAndCareTeamWidgetProps {
  careTeamMembers?: CareTeamMemberInfo[];
  user?: AdminDetailUserInfoResponse;
}

export default function HospitalAndCareTeamWidget({
  careTeamMembers,
  user,
}: HospitalAndCareTeamWidgetProps): JSX.Element {
  const theme = useTheme();
  const mainOncologist = careTeamMembers?.find((member) => member.is_main_oncologist);
  return (
    <Box
      my={2}
      px={2}
      py={2}
      sx={{
        backgroundColor: theme.veris.colors.neutrals.white,
        border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        borderRadius: ({ spacing }) => spacing(1),
      }}
    >
      <Typography variant="h5" mb={2}>
        Clinical Care Team
      </Typography>
      <Box display="flex" gap={1} py={1} alignItems="center">
        <Typography color={theme.veris.colors.neutrals["grey-3"]} variant="h6" minWidth="150px">
          Hospital
        </Typography>
        {user?.hospital?.map((item) => (
          <Typography variant="body" key={item.id}>
            {item.name}{" "}
          </Typography>
        ))}
      </Box>
      <Box display="flex" gap={1} py={1} alignItems="baseline">
        <Typography color={theme.veris.colors.neutrals["grey-3"]} variant="h6" minWidth="150px">
          Clinical Care Team
        </Typography>
        <Box gap={1} width="90%">
          {careTeamMembers && careTeamMembers.length > 0 ? (
            careTeamMembers.map((member) => (
              <VrsCareTeamMember
                member={member}
                color={theme.veris.colors.neutrals["grey-3"]}
                key={member.id}
              />
            ))
          ) : (
            <Typography variant="body">N/A</Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" gap={1} py={1} alignItems="center">
        <Typography color={theme.veris.colors.neutrals["grey-3"]} variant="h6" minWidth="150px">
          Main Oncologist
        </Typography>
        {mainOncologist && (
          <Typography variant="body" key={mainOncologist.id}>
            {mainOncologist.full_name}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
