import { Option, dateFormats } from "@veris-health/web-core";

export const enum BillingReportsTypeEnum {
  hospitalSummary = "exportBillSummaryForHospitalForPeriod",
  monitoringSummary = "exportBillMonitoringSummaryForHospitalForPeriod",
  telemedicineSummary = "exportBillTelemedicineSummaryForHospitalForPeriod",
}

export const billingReportType: Option[] = [
  {
    label: "Billing Report (by CPT code)",
    value: BillingReportsTypeEnum.hospitalSummary,
  },
  {
    label: "Full Patient Extract (by patient)",
    value: BillingReportsTypeEnum.monitoringSummary,
  },
  {
    label: "Telemedicine",
    value: BillingReportsTypeEnum.telemedicineSummary,
  },
];
export const BILLING_DATE_FORMAT = dateFormats["YYYY-MM-DD"];
