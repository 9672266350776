import React, { useEffect } from "react";
import { Box, CircularProgress, Grid, Typography, useMediaQuery } from "@mui/material";
import updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";
import useTheme from "@mui/material/styles/useTheme";
import { UserType } from "@veris-health/med-data-ms/lib/v1";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  resetThreadId,
  fetchChatThreadsCombinedAsync,
  selectCurrentThread,
  selectCurrentChatThreadStatus,
  fetchChatConfiguration,
} from "../store";
import { ThreadContainer } from "./ThreadContainer";
import { ChatSidebarContainer } from "./ChatSidebarContainer";
import { ChatHeaderContainer } from "./ChatHeaderContainer";
import { checkIfTokenIsExpired } from "../../../api/utils/jwt-extras";

export interface ChatContainerProps {
  endpoint: string;
  token: string;
  userId: string;
  myAzureChatUserId: string;
  myChatDisplayName: string;
}

const ChatContainer = ({
  endpoint,
  token,
  userId,
  myAzureChatUserId,
  myChatDisplayName,
}: ChatContainerProps): JSX.Element => {
  const currentThread = useAppSelector(selectCurrentThread);
  const currentChatThreadStatus = useAppSelector(selectCurrentChatThreadStatus);
  const threadParticipant =
    currentThread?.patient ||
    currentThread?.participants.find(
      (participant) => participant.user_type === UserType.SupportMember,
    );
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xl"));
  dayjs.extend(updateLocale);
  dayjs.updateLocale("en", {
    relativeTime: {
      future: "now",
      past: "%s ago",
      s: "1s",
      m: "1m",
      mm: "%dm",
      h: "1h",
      hh: "%dh",
      d: "1d",
      dd: "%dd",
      w: "%d w",
      ww: "%d w",
      M: "a month",
      MM: "%d months",
      y: "a y",
      yy: "%d yrs",
    },
  });

  useEffect(() => {
    const tokenIsExpired = checkIfTokenIsExpired(token);
    if (token) {
      if (tokenIsExpired) {
        dispatch(fetchChatConfiguration({ id: Number(userId) }));
      } else {
        dispatch(fetchChatThreadsCombinedAsync());
      }
    }
  }, [userId, endpoint, token]);

  useEffect(() => {
    return () => {
      dispatch(resetThreadId());
    };
  }, []);

  return (
    <>
      <Grid container sx={{ backgroundColor: theme.veris.colors.neutrals.white }}>
        <Grid
          sx={{
            position: "relative",
            height: isSmallScreen ? "88vh" : "90vh",
            backgroundColor:
              currentThread && currentThread?.private
                ? theme.veris.colors.pink.light
                : theme.veris.colors.neutrals.white,
          }}
          display="flex"
          flexDirection="column"
          item
          lg={8}
          xl={9}
          xs={8}
          marginBottom={0}
        >
          {currentChatThreadStatus === "loading" && (
            <Box display="flex" justifyContent="center" alignItems="center" p={1}>
              <CircularProgress />
            </Box>
          )}
          {currentChatThreadStatus === "idle" && (
            <>
              <ChatHeaderContainer
                currentThread={currentThread}
                userId={userId}
                threadParticipant={threadParticipant}
              />
              {currentThread && currentThread.id ? (
                <ThreadContainer
                  currentThreadId={currentThread.id}
                  myAzureChatUserId={myAzureChatUserId}
                  myChatDisplayName={myChatDisplayName}
                  endpoint={endpoint}
                  token={token}
                  participants={currentThread.participants}
                  isPrivateThread={currentThread.private}
                  userId={userId}
                  threadParticipant={threadParticipant}
                />
              ) : (
                <Typography
                  color={theme.veris.colors.neutrals["grey-3"]}
                  display="flex"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  component="div"
                  variant="h6"
                  height="90%"
                >
                  Please select/create a thread to start a conversation.
                </Typography>
              )}
            </>
          )}
        </Grid>
        <ChatSidebarContainer userId={userId} />
      </Grid>
    </>
  );
};

export default ChatContainer;
