import React, { useState } from "react";
import { Box, CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import * as yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { IconName, VrsButton, VrsIconButton } from "@veris-health/web-core";
import { StaffType } from "@veris-health/user-ms/lib/v1";
import { validatePassword } from "../shared/helpers";
import { VrsFormInputField } from "../../ui/components/VrsFormInputField";
import {
  clearExpiredPassword,
  passwordExpiredAsync,
  passwordExpiredOtpValidationAsync,
  selectExpiredPasswordStatus,
  selectOtpScreen,
  setOTPScreen,
} from "../shared/slices/authSlice";
import { useAppSelector } from "../../hooks/useAppSelector";
import { StyledActiveNavigationItem } from "../shared/styledComponents";
import { OTPLoginContainer } from "./OTPLoginContainer";
import { Routes } from "../../routes-config";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { emailValidation } from "../../utils/validations";

const validationSchema = yup.object().shape({
  email: emailValidation,
  oldPassword: yup.string().required("Old password is required"),
  newPassword: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .max(64, "Password should be of maximum 64 characters length")
    .test(
      "is-valid",
      "The password does not comply with password complexity requirements",
      (value) => {
        if (value) {
          const isValidPassword = validatePassword(value);
          if (isValidPassword) {
            return false;
          }
        }
        return true;
      },
    )
    .required("New password is required"),
  repeatPassword: yup
    .string()
    .required("Password confirmation is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const initialValues = {
  email: "",
  oldPassword: "",
  newPassword: "",
  repeatPassword: "",
};

export function ExpiredPasswordContainer(): JSX.Element {
  const [showPassword, setShowPassword] = useState<{ [fieldName: string]: boolean }>({
    oldPassword: false,
    newPassword: false,
    repeatPassword: false,
  });
  const isOtpScreen = useAppSelector(selectOtpScreen);
  const { status, errorMessage } = useAppSelector(selectExpiredPasswordStatus);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: ({ oldPassword, newPassword, email }) => {
      dispatch(
        passwordExpiredAsync({
          newData: {
            staff_type: StaffType.TechStaff,
            email,
            current_password: oldPassword,
            new_password: newPassword,
          },
        }),
      );
    },
  });

  const { errors, handleSubmit, handleChange, isValid, dirty, touched, handleBlur } = formik;
  const { email, oldPassword, repeatPassword, newPassword } = formik.values;
  const navigate = useNavigate();

  const onPasswordResetSubmit = (code: string) => {
    dispatch(
      passwordExpiredOtpValidationAsync({
        newData: {
          staff_type: StaffType.TechStaff,
          email,
          current_password: oldPassword,
          new_password: newPassword,
          otp: code,
        },
      }),
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(setOTPScreen(false));
        navigate(Routes.STAFF_LOGIN);
      }
    });
  };

  const fieldsData = [
    {
      label: "Email",
      name: "email",
      value: email,
      helperText: errors.email,
      error: !!errors.email,
      isValidField: !!touched.email && email.length && !errors.email,
      placeholder: "Email",
    },

    {
      label: "Current Password",
      name: "oldPassword",
      value: oldPassword,
      helperText: errors.oldPassword,
      error: !!errors.oldPassword,
      isValidField: !!touched.oldPassword && oldPassword.length && !errors.oldPassword,
      placeholder: "Write your current password",
    },
    {
      label: "New Password",
      name: "newPassword",
      value: newPassword,
      helperText: errors.newPassword,
      error: !!errors.newPassword,
      isValidField: !!touched.newPassword && newPassword.length && !errors.newPassword,
      placeholder: "Write your new password",
    },
    {
      label: "Repeat Password",
      name: "repeatPassword",
      value: repeatPassword,
      helperText: errors.repeatPassword,
      error: !!errors.repeatPassword,
      isValidField: !!touched.repeatPassword && repeatPassword.length && !errors.repeatPassword,
      placeholder: "Repeat your new password",
    },
  ];
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        m={5}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          if (isOtpScreen) dispatch(setOTPScreen(false));
          else navigate(Routes.STAFF_LOGIN);
        }}
      >
        <VrsIconButton
          iconProps={{
            name: IconName.ArrowLeft,
            stroke: theme.veris.colors.neutrals.black,
            size: 12,
          }}
        />
        <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-4"]}>
          Back
        </Typography>
      </Box>
      {isOtpScreen ? (
        <Box gap={2} display="flex" flexDirection="column" justifyContent="space-between">
          <StyledActiveNavigationItem variant="h3" m={7} mt={3} width="fit-content">
            Enter the verification code
          </StyledActiveNavigationItem>
          <Typography variant="h6" pl={7} pb={2} color={theme.veris.colors.amethyst.normal}>
            OTP code sent via sms.
          </Typography>
          <OTPLoginContainer
            onSubmit={(code) => onPasswordResetSubmit(code)}
            onResendCode={() =>
              dispatch(
                passwordExpiredAsync({
                  newData: {
                    staff_type: StaffType.TechStaff,
                    email,
                    current_password: oldPassword,
                    new_password: newPassword,
                  },
                }),
              )
            }
          />
        </Box>
      ) : (
        <Box marginTop="0%" display="flex" alignItems="baseline" flexDirection="column" mx={6}>
          <StyledActiveNavigationItem variant="h3" width="fit-content">
            Password Expired
          </StyledActiveNavigationItem>
          <Box my={4}>
            <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-4"]}>
              Every 90 days your password should be redefined for security reasons.
              <br /> Please add a new one without using the last 3 passwords previously used.
            </Typography>
          </Box>
          <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
              <Box>
                <Grid item xs={12} container spacing={2}>
                  {fieldsData.map((field) => (
                    <Grid item xs={7}>
                      <VrsFormInputField
                        label={field.label}
                        name={field.name}
                        type={
                          showPassword[field.name] || field.name === "email" ? "text" : "password"
                        }
                        showPassword={showPassword[field.name]}
                        value={field.value}
                        helperText={field.helperText}
                        error={!!field.error || !!errorMessage}
                        isValidField={field.isValidField}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        handleClickShowPassword={() =>
                          setShowPassword({
                            ...showPassword,
                            [field.name]: !showPassword[field.name],
                          })
                        }
                        placeholder={field.placeholder}
                        hideWarningIcon
                        onFocus={() => dispatch(clearExpiredPassword())}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Typography
                  color={theme.veris.colors.neutrals["grey-3"]}
                  variant="caption"
                  component="div"
                  my={1.5}
                >
                  Your password must be at least 8 characters long with at least <br /> 3 out of 4
                  of lowercase, uppercase, numbers, or symbols.
                </Typography>
                <Box my={1} display="flex" flexDirection="column">
                  {status === "failed" && errorMessage && (
                    <Typography my={1} color={theme.veris.colors.errors.normal} variant="caption">
                      {errorMessage}
                    </Typography>
                  )}
                </Box>
                <Box my={2}>
                  <VrsButton
                    buttonType="primary"
                    disabled={!isValid || status === "loading" || !touched || !dirty}
                    type="submit"
                  >
                    Change password
                    {status === "loading" && (
                      <Box px={1}>
                        <CircularProgress size={16} />
                      </Box>
                    )}
                  </VrsButton>
                </Box>
              </Box>
            </form>
          </FormikProvider>
        </Box>
      )}
    </>
  );
}
