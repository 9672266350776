import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VrsButton, dateFormats } from "@veris-health/web-core";
import { PatientStatusEnumAPI, TechRoleEnum } from "@veris-health/user-ms/lib/v1";
import { useSelectedUser } from "../../../hooks/useSelectedUser";
import UserDemographicsContainer from "./UserDemographicsContainer";
import SetHospitalAndCareTeamForm from "../components/SetHospitalAndCareTeamForm";
import HospitalAndCareTeamWidget from "../components/HospitalAndCareTeamWidget";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {
  getAssignedPatientsAsync,
  selectAssignedPatients,
  selectAssignedPatientsStatus,
} from "../userSlice";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { AssignedPatients } from "../components/AssignedPatients";
import { replaceRouteParam } from "../../../routes-config";
import EditHospitals from "../components/EditHospitals";
import { checkUserRoles } from "../../../utils/access";
import { useAuthenticatedUserRoles, useProfile } from "../../../context/profile";
import { utcToLocal } from "../../../utils/date";

const UserDetailsContainer = (): JSX.Element => {
  const { user, loadingUser, careTeamMembers, patientInfo, getUserInfo } = useSelectedUser();
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const [hospitalEdit, setHospitalEdit] = useState<boolean>(false);
  const loggedUser = useProfile();
  const assignedPatients = useAppSelector((state) => selectAssignedPatients(state, user?.id));
  const assignedPatientsStatus = useAppSelector(selectAssignedPatientsStatus);
  const authenticatedUserRoles = useAuthenticatedUserRoles();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (user && user.id && user.type === "med_staff") {
      dispatch(getAssignedPatientsAsync(user.id));
    }
  }, [user]);
  const canAccessMioHistory = authenticatedUserRoles.some((role) =>
    [TechRoleEnum.Superadmin, TechRoleEnum.Admin].includes(role as TechRoleEnum),
  );

  return (
    <Box p={2}>
      <UserDemographicsContainer
        user={user}
        loading={loadingUser}
        patientInfo={patientInfo}
        getUserInfo={getUserInfo}
      />
      {loadingUser === "idle" && user && user.type === "patient" && !toggleForm && (
        <Box display="flex" gap={3} my={2}>
          <VrsButton
            buttonType="primary"
            onClick={() => {
              setToggleForm(true);
              setHospitalEdit(true);
            }}
            disabled={
              user.patient_status &&
              [PatientStatusEnumAPI.Inactive, PatientStatusEnumAPI.Deceased].includes(
                user.patient_status,
              )
            }
          >
            {user.hospital && user.hospital.length > 0 ? "Edit Hospital" : "+Add Hospital"}
          </VrsButton>
          {user.hospital && user.hospital.length > 0 && (
            <VrsButton
              buttonType="primary"
              onClick={() => {
                setToggleForm(true);
              }}
              disabled={
                user.patient_status &&
                [PatientStatusEnumAPI.Inactive, PatientStatusEnumAPI.Deceased].includes(
                  user.patient_status,
                )
              }
            >
              Edit Clinical Care Team
            </VrsButton>
          )}

          {user && user.mio_hub_serial_number && (
            <VrsButton
              buttonType="secondary"
              onClick={() => {
                navigate(replaceRouteParam("MIO_DATA_POINTS", ":userId", String(user.id)));
              }}
            >
              Mio Datapoints
            </VrsButton>
          )}
          {user && canAccessMioHistory && (
            <VrsButton
              buttonType="secondary"
              onClick={() => {
                navigate(replaceRouteParam("HUB_HISTORY", ":userId", String(user?.id)));
              }}
            >
              Hub History
            </VrsButton>
          )}
        </Box>
      )}
      {toggleForm && user && (
        <SetHospitalAndCareTeamForm
          setToggleForm={() => {
            setToggleForm(false);
            setHospitalEdit(false);
          }}
          user={user}
          existingCareTeamMembers={careTeamMembers}
          hospitalEdit={hospitalEdit}
        />
      )}
      {user?.type === "patient" && !toggleForm && user?.hospital && user?.hospital?.length > 0 && (
        <HospitalAndCareTeamWidget user={user} careTeamMembers={careTeamMembers} />
      )}
      {/* You can edit hospital only for Hospital Admin if you're logged in as admin or superadmin */}
      {user &&
        user.type === "tech_staff" &&
        checkUserRoles(["hospitaladmin"], user.user_roles) &&
        checkUserRoles(["superadmin", "admin"], loggedUser?.user_roles) && (
          <EditHospitals userId={user.id} hospitals={user.hospital || []} userType={user.type} />
        )}
      {user && user.type === "med_staff" && (
        <>
          {checkUserRoles(["superadmin", "admin"], loggedUser?.user_roles) && (
            <EditHospitals
              userId={user.id}
              hospitals={user.hospital || []}
              userType={user.type}
              assignedPatients={assignedPatients}
            />
          )}
          {user.hospital && (
            <AssignedPatients
              patients={assignedPatients}
              status={assignedPatientsStatus}
              user={user}
              hospitals={user.hospital}
            />
          )}
        </>
      )}

      <Typography variant="description" my={1} component="div">
        Last login:
        {user?.last_login_timestamp
          ? ` ${utcToLocal(user?.last_login_timestamp).format(
              `${dateFormats["MMM DD, YYYY"]}, ${dateFormats["hh:mm"]} A`,
            )}`
          : "N/A"}
      </Typography>
    </Box>
  );
};

export default UserDetailsContainer;
