import {
  Badge,
  Box,
  CircularProgress,
  Popover,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useCallback, useEffect } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { NotificationView } from "@veris-health/communication-ms/lib/v1";
import { IconName, VrsIconButton, VrsDivider } from "@veris-health/web-core";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { NotificationListItem } from "../../ui/components/NotificationListItem";
import {
  fetchNotificationDetailsAsync,
  loadNotificationsDataAsync,
  selectAllNotifications,
  selectFilteredNotificationsfromEarlier,
  selectFilteredNotificationsfromToday,
  selectFilteredNotificationsfromYesterday,
  selectNotificationsFetchingOffset,
  selectNotificationsStatus,
  selectUnreadCount,
  setupFirebaseToken,
  increaseUnreadCount,
  selectTotalCount,
} from "./notificationsSlice";
import { useFirebaseMessaging } from "./hooks/useFirebaseMessaging";
import { selectUserId } from "../shared/slices/authSlice";
import { replaceRouteParam, Routes } from "../../routes-config";
import { NOTIFICATIONS_OFFSET } from "../../constants";
import SnackbarUtils from "../../utils/SnackbarUtils";

const StyledBadge = styled(Badge)(({ theme }) => ({
  ...theme.typography.caption,
  "& .MuiBadge-badge": {
    right: 16,
    top: 12,
    background: theme.veris.colors.pink.normal,
    color: theme.veris.colors.neutrals.white,
    width: "8px",
    minWidth: "8px",
    height: "8px",
    paddingTop: "2px",
    cursor: "pointer",
    borderRadiis: "50%",
  },
}));

export const NotificationsDropdownContainer = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { firebaseMessaging } = useFirebaseMessaging({
    onFirebaseMessage: (vrsNotificationId) =>
      vrsNotificationId
        ? dispatch(fetchNotificationDetailsAsync({ vrsNotificationId })).then(() =>
            dispatch(increaseUnreadCount()),
          )
        : SnackbarUtils.error("Failed to load the notification. Please reload the page."),
  });

  const filteredNotificationsToday = useAppSelector(selectFilteredNotificationsfromToday);
  const filteredNotificationsYesterday = useAppSelector(selectFilteredNotificationsfromYesterday);
  const filteredNotificationsEarlier = useAppSelector(selectFilteredNotificationsfromEarlier);
  const unreadCount = useAppSelector(selectUnreadCount);
  const currentUserId = useAppSelector(selectUserId);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = !!anchorEl;
  const offset = useAppSelector(selectNotificationsFetchingOffset);
  const totalCount = useAppSelector(selectTotalCount);
  const notificationStatus = useAppSelector(selectNotificationsStatus);
  const notifications = useAppSelector(selectAllNotifications);
  const theme = useTheme();
  const isNotificationsRoute = Boolean(
    matchPath({ path: Routes.NOTIFICATIONS_DETAILS }, location.pathname),
  );

  const fetchNotifications = useCallback(async () => {
    if (notificationStatus === "loading") {
      return;
    }
    dispatch(
      loadNotificationsDataAsync({
        userId: Number(currentUserId),
        offset: offset + NOTIFICATIONS_OFFSET,
      }),
    );
  }, [notificationStatus]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const iconName: IconName = location.pathname.includes("notifications")
    ? IconName.ActiveNotifications
    : IconName.Notification;

  const handleNotificationClick = (notification: NotificationView) => {
    navigate(
      replaceRouteParam(
        "NOTIFICATIONS_DETAILS",
        ":notificationId",
        notification.notification_id.toString(),
      ),
    );
    handleClose();
  };

  useEffect(() => {
    if (Number(currentUserId) && firebaseMessaging) {
      dispatch(loadNotificationsDataAsync({ userId: Number(currentUserId) }));
      dispatch(setupFirebaseToken({ userId: Number(currentUserId), firebaseMessaging }));
    }
  }, [dispatch, currentUserId, firebaseMessaging]);

  return (
    <>
      <StyledBadge
        invisible={unreadCount < 1}
        variant="dot"
        onClick={(e) =>
          !isNotificationsRoute && handleClick(e as React.MouseEvent<HTMLButtonElement>)
        }
        data-test-hook="notifications"
      >
        <VrsIconButton disabled={isNotificationsRoute} iconProps={{ name: iconName, size: 36 }} />
      </StyledBadge>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          style: { maxWidth: "500px", minWidth: "400px" },
        }}
      >
        <Stack
          direction="row"
          m={2}
          position="static"
          overflow="hidden"
          justifyContent="space-between"
        >
          <Typography variant="h5" pt={1}>
            Notifications
          </Typography>
          <VrsIconButton iconProps={{ name: IconName.CloseIcon }} onClick={handleClose} />
        </Stack>
        <VrsDivider orientation="horizontal" height="100%" />
        <Stack
          px={1}
          overflow="auto"
          sx={{ maxHeight: "500px", maxWidth: "550px", minHeight: "60px" }}
          id="notifications-dropdown-scroll-container"
        >
          <InfiniteScroll
            scrollableTarget="notifications-dropdown-scroll-container"
            hasMore={notifications.length < totalCount}
            style={{ overflow: "hidden" }}
            dataLength={notifications.length}
            next={() => {
              if (notificationStatus === "idle") fetchNotifications();
            }}
            loader={
              <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                <CircularProgress />
              </Box>
            }
          >
            {notifications.length === 0 && (
              <Typography
                variant="body"
                paddingY={1}
                mt={2}
                color={theme.veris.colors.neutrals["grey-3"]}
              >
                There are no notifications available.
              </Typography>
            )}
            {filteredNotificationsToday.length > 0 && (
              <>
                <Typography
                  variant="body1"
                  paddingY={1}
                  mt={2}
                  color={theme.veris.colors.neutrals["grey-3"]}
                  sx={{ textTransform: "uppercase" }}
                >
                  Today
                </Typography>
                {filteredNotificationsToday.map((notification) => (
                  <NotificationListItem
                    key={notification.notification_id}
                    notification={notification}
                    onClick={() => handleNotificationClick(notification)}
                  />
                ))}
              </>
            )}
            {filteredNotificationsYesterday.length > 0 && (
              <>
                <Typography
                  variant="body1"
                  paddingY={1}
                  mt={2}
                  color={theme.veris.colors.neutrals["grey-3"]}
                  sx={{ textTransform: "uppercase" }}
                >
                  Yesterday
                </Typography>
                {filteredNotificationsYesterday.map((notification) => (
                  <NotificationListItem
                    key={notification.notification_id}
                    notification={notification}
                    onClick={() => handleNotificationClick(notification)}
                  />
                ))}
              </>
            )}
            {filteredNotificationsEarlier.length > 0 && (
              <>
                <Typography
                  variant="body1"
                  paddingY={1}
                  mt={2}
                  color={theme.veris.colors.neutrals["grey-3"]}
                  sx={{ textTransform: "uppercase" }}
                >
                  Earlier
                </Typography>
                {filteredNotificationsEarlier.map((notification) => (
                  <NotificationListItem
                    key={notification.notification_id}
                    notification={notification}
                    onClick={() => handleNotificationClick(notification)}
                  />
                ))}
              </>
            )}
          </InfiniteScroll>
        </Stack>
        <Stack
          sx={{
            backgroundColor: theme.veris.colors.neutrals["smoke-white"],
            padding: ({ spacing }) => spacing(2),
            boxShadow: `2px 0px 8px ${theme.veris.colors.neutrals["grey-1"]}`,
          }}
          alignItems="end"
        >
          <VrsIconButton
            data-test-hook="notifications-max-btn"
            onClick={() => {
              navigate(replaceRouteParam("NOTIFICATIONS_DETAILS", ":notificationId", "all"));
              handleClose();
            }}
            sx={{ marginLeft: "auto", cursor: "pointer" }}
            iconProps={{ name: IconName.ExpandIcon }}
          />
        </Stack>
      </Popover>
    </>
  );
};
