import { useEffect, useMemo } from "react";
import { Option } from "@veris-health/web-core";
import { HospitalInfo } from "@veris-health/user-ms/lib/v1";
import { useAppSelector } from "../useAppSelector";
import { getHospitalsAsync, selectHospitals } from "../../features/Users/userSlice";
import { useAppDispatch } from "../useAppDispatch";

export const useHospitals = (
  asDropdownOptions = true,
  includeNone = false,
): HospitalInfo[] | Option[] => {
  const hospitals = useAppSelector(selectHospitals);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (hospitals.length === 0) {
      dispatch(getHospitalsAsync());
    }
  }, [dispatch]);

  return useMemo(() => {
    if (asDropdownOptions) {
      const hospitalsOptions: Option[] = hospitals.map((item) => {
        return {
          label: item.name,
          value: String(item.id),
        };
      });
      if (includeNone) {
        return [{ label: "None", value: "" }, ...hospitalsOptions];
      }
      return hospitalsOptions;
    }
    return hospitals;
  }, [hospitals, asDropdownOptions, includeNone]);
};
