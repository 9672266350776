import React, { useEffect, useState } from "react";
import { Typography, Box, DialogContent, useTheme } from "@mui/material";
import { FormikProvider, FormikValues, useFormik } from "formik";
import * as yup from "yup";
import { GetEducationalVideo } from "@veris-health/virtual-doc-ms/lib/v1";
import { IconName, Option, VrsButton, VrsDialog, VrsIcon, VrsSelect } from "@veris-health/web-core";
import { VrsFormInputField } from "../../../ui/components/VrsFormInputField";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {
  addEducationalVideoAsync,
  selectAvailableSymptoms,
  updateEducationalVideoAsync,
} from "../educationalVideosSlice";
import { useAppSelector } from "../../../hooks/useAppSelector";

const validationSchema = yup.object({
  title: yup.string().required("Video title is required."),
  description: yup.string().required("Video description is required."),
  link: yup.string().url("Video link should be complete url.").required("Video link is required."),
});

const initialValues = {
  title: "",
  description: "",
  link: "",
  symptom: "",
};

interface AddVideoProps {
  selectedVideo: GetEducationalVideo | undefined;
  onReset: () => void;
  clearFilters: () => void;
}

export const AddVideo = ({ selectedVideo, onReset, clearFilters }: AddVideoProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const availableSymptoms = useAppSelector(selectAvailableSymptoms);

  const symptomOptions: Option[] = availableSymptoms.reduce(
    (acc, symptom) => {
      if (symptom) {
        acc.push({
          value: symptom,
          label: symptom,
        });
        return acc;
      }
      return acc;
    },
    [{ value: "", label: "None" }] as Option[],
  );

  if (selectedVideo) {
    symptomOptions.push({
      value: String(selectedVideo.symptom),
      label: String(selectedVideo.symptom),
    });
  }

  const dispatch = useAppDispatch();
  const theme = useTheme();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ title, link, description, symptom }) => {
      if (selectedVideo) {
        handleEdit({ title, link, description, symptom: symptom === "" ? undefined : symptom });
      } else {
        handleCreate({ title, link, description, symptom: symptom === "" ? undefined : symptom });
      }
    },
  });

  const {
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    isValid,
    dirty,
    resetForm,
    touched,
    setFieldValue,
    values,
  } = formik;

  function handleClose() {
    setOpen(false);
    onReset();
    resetForm();
  }

  function handleCreate({ title, link, description, symptom }: FormikValues) {
    dispatch(addEducationalVideoAsync({ title, link, description, symptom }));
    clearFilters();
    handleClose();
  }

  function handleEdit({ title, link, description, symptom }: FormikValues) {
    if (selectedVideo) {
      dispatch(
        updateEducationalVideoAsync({
          id: selectedVideo.id,
          video: { title, link, description, symptom },
        }),
      );
      clearFilters();
      handleClose();
    }
  }

  useEffect(() => {
    if (selectedVideo) {
      setOpen(true);
      setFieldValue("title", selectedVideo.title);
      setFieldValue("description", selectedVideo.description);
      setFieldValue("link", selectedVideo.link);
      setFieldValue("symptom", selectedVideo.symptom);
    }
  }, [selectedVideo]);

  return (
    <Box pb={2} display="flex" justifyContent="flex-start">
      <VrsButton buttonType="primary" size="small" onClick={() => setOpen(true)}>
        <Typography pl={3} variant="bodySemibold" fontWeight={400} pt={0.25}>
          Add Video
        </Typography>
        <Box pr={3} pl={1} pt={0.75}>
          <VrsIcon name={IconName.Plus} size={18} stroke={theme.veris.colors.neutrals.white} />
        </Box>
      </VrsButton>
      <VrsDialog
        sx={{ "& .MuiDialog-paper": { height: "auto" }, "& div::-webkit-scrollbar": { height: 0 } }}
        open={open}
        title={selectedVideo ? "Edit Video" : "Add Video"}
        onClose={() => handleClose()}
        width="600px"
      >
        <DialogContent>
          <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
              <Box>
                <VrsFormInputField
                  name="title"
                  value={values.title}
                  label="Title"
                  error={!!touched.title && Boolean(errors.title)}
                  isValidField={values.title.length && !errors.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.title && errors.title ? errors.title : " "}
                />
              </Box>
              <Box my={2}>
                <VrsFormInputField
                  name="description"
                  value={values.description}
                  multiline
                  rows={3}
                  label="Description"
                  error={!!touched.description && Boolean(errors.description)}
                  isValidField={values.description.length && !errors.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.description && errors.description ? errors.description : " "}
                />
              </Box>
              <Box>
                <VrsFormInputField
                  name="link"
                  value={values.link}
                  label="Link"
                  error={!!touched.link && Boolean(errors.link)}
                  isValidField={values.link.length && !errors.link}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.link && errors.link ? errors.link : " "}
                />
              </Box>
              <Box my={2}>
                <Typography m={0} variant="subtitle2">
                  Choose Symptom
                </Typography>
                <VrsSelect
                  onBlur={handleBlur}
                  name="symptom"
                  placeholder="Choose Symptom"
                  options={symptomOptions}
                  value={values.symptom}
                  onSelected={(value) => setFieldValue("symptom", value)}
                  innerSx={{ padding: theme.spacing(0.75, 0.75, 0.75, 0) }}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <VrsButton
                  buttonType="primary"
                  disabled={!isValid || !dirty}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {selectedVideo ? "Edit Video" : "Add Video"}
                </VrsButton>
              </Box>
            </form>
          </FormikProvider>
        </DialogContent>
      </VrsDialog>
    </Box>
  );
};
