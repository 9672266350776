import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { VrsButton } from "@veris-health/web-core";
import { useNavigate } from "react-router-dom";
import { TechRoleEnum } from "@veris-health/user-ms/lib/v1";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { VrsActionLayout } from "../../ui/components/VrsActionLayout";
import { getPatientsAsync } from "./billingSlice";
import { VrsTabsContainer } from "../../ui/components/VrsTabsContainer";
import HospitalBillingContainer from "./containers/HospitalBillingContainer";
import PatientBillingContainer from "./containers/PatientBillingContainer";
import { Routes } from "../../routes-config";
import { useAuthenticatedUserRoles } from "../../context/profile";

export const BillingContainer = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authenticatedUserRoles = useAuthenticatedUserRoles();

  useEffect(() => {
    dispatch(getPatientsAsync());
  }, []);

  const canAccessBillingReports = authenticatedUserRoles.includes(TechRoleEnum.Superadmin);

  return (
    <Box
      width="100%"
      py={3}
      px={2}
      pb={1}
      height="100%"
      gap={4}
      display="flex"
      flexDirection="column"
    >
      <VrsActionLayout title="Billing">
        <VrsTabsContainer
          tabsContainerContent={{
            Patient: <PatientBillingContainer key="patientForm" />,
            Hospital: <HospitalBillingContainer key="hospitalForm" />,
          }}
        />
      </VrsActionLayout>
      {canAccessBillingReports && (
        <VrsActionLayout title="Billing Reports">
          <VrsButton
            sx={{ marginLeft: "0.75rem" }}
            onClick={() => navigate(Routes.BILLING_REPORTS)}
            buttonType="tertiary"
          >
            Go to Billing Reports
          </VrsButton>
        </VrsActionLayout>
      )}
    </Box>
  );
};
export default BillingContainer;
