import { AdminDetailUserInfoResponse, UserTypeResponse } from "@veris-health/user-ms/lib/v1";
import React from "react";
import { Status, VrsPatientInfo } from "../../shared/interfaces";
import MedStaffUserDemographics from "../components/MedStaffUserDemographics";
import UserDemographics from "../components/UserDemographics";

const UserDemographicsContainer = ({
  user,
  loading,
  patientInfo,
  getUserInfo,
}: {
  user: AdminDetailUserInfoResponse | undefined;
  loading: Status;
  patientInfo: VrsPatientInfo | undefined;
  getUserInfo: () => void;
}): JSX.Element => {
  const getUserDemographicsComponent = () => {
    switch (user?.type) {
      case UserTypeResponse.MedStaff:
        return <MedStaffUserDemographics user={user} />;
      case UserTypeResponse.Patient:
        return (
          <UserDemographics
            user={user}
            patientInfo={patientInfo}
            userLoading={loading}
            getUserInfo={getUserInfo}
          />
        );
      default:
        return (
          <UserDemographics
            user={user}
            patientInfo={patientInfo}
            userLoading={loading}
            getUserInfo={getUserInfo}
          />
        );
    }
  };

  return <>{getUserDemographicsComponent()}</>;
};

export default UserDemographicsContainer;
