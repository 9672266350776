import { BillingPeriod, BillReport } from "@veris-health/communication-ms/lib/v1";
import { v4 as uuid } from "uuid";
import { billApiV1 } from "../../../api";

export interface VrsTelemedicineRange {
  start: string;
  end: string;
}

export interface VrsBillingPeriod extends VrsTelemedicineRange {
  id: string;
}

export const getPeriods = async (userId: number): Promise<VrsBillingPeriod[]> => {
  const response = await billApiV1.listBillingPeriodsForPatient(userId);
  return response.data.map((period: BillingPeriod) => {
    return {
      ...period,
      id: uuid(),
    };
  });
};

export const getHospitalPeriods = async (hospitalId: number): Promise<VrsBillingPeriod[]> => {
  const response = await billApiV1.listBillingPeriodsForHospital(hospitalId);
  return response.data.map((period: BillingPeriod) => {
    return {
      ...period,
      id: uuid(),
    };
  });
};

export const getPeriodDetail = async (
  patientId: number,
  start: string,
  end: string,
): Promise<BillReport> => {
  const response = await billApiV1.getBillDetailsForPatientForPeriod(patientId, start, end);
  return response.data;
};
