import {
  NotificationsApi as NotificationsApiV1,
  ChatApi as ChatApiV1,
  ChatAttachmentsApi as ChatAttachmentsApiV1,
} from "@veris-health/communication-ms/lib/v1";
import { BillApi as BillApiV1 } from "@veris-health/communication-ms/lib/v1/api";
import config from "../axios-config";
import { API_ROUTES, getVersion, VERSIONS } from "../helpers";

export const notificationsApiV1 = new NotificationsApiV1(
  undefined,
  getVersion(API_ROUTES.COMMUNICATION, VERSIONS.v1),
  config,
);

export const billApiV1 = new BillApiV1(
  undefined,
  getVersion(API_ROUTES.COMMUNICATION, VERSIONS.v1),
  config,
);

export const chatApiV1 = new ChatApiV1(
  undefined,
  getVersion(API_ROUTES.COMMUNICATION, VERSIONS.v1),
  config,
);

export const chatAttachmentsApiV1 = new ChatAttachmentsApiV1(
  undefined,
  getVersion(API_ROUTES.COMMUNICATION, VERSIONS.v1),
  config,
);
