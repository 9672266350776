import { AdminDetailUserInfoResponse } from "@veris-health/user-ms/lib/v1";

export function orderFirstHospital(item: AdminDetailUserInfoResponse, hospital: number): void {
  if (item.hospital) {
    const hospitalIndex = item.hospital?.findIndex((userHospital) => userHospital.id === hospital);
    if (hospitalIndex !== -1 && hospitalIndex !== 0) {
      [item.hospital[0], item.hospital[hospitalIndex]] = [
        item.hospital[hospitalIndex],
        item.hospital[0],
      ];
    }
  }
}
