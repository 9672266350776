import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import * as yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { ChangePasswordRequest } from "@veris-health/user-ms/lib/v1";
import { VrsButton } from "@veris-health/web-core";
import { VrsFormInputField } from "../../../ui/components/VrsFormInputField";
import { selectChangePasswordStatus } from "../../shared/slices/authSlice";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { validatePassword } from "../../shared/helpers";

export interface ChangePasswordProps {
  clearValidationMessages: () => void;
  onSubmit: (data: ChangePasswordRequest) => void;
}

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required("Old password is required"),
  newPassword: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .max(64, "Password should be of maximum 64 characters length")
    .test(
      "is-valid",
      "The password does not comply with password complexity requirements",
      (value) => {
        if (value) {
          const isValidPassword = validatePassword(value);
          if (isValidPassword) {
            return false;
          }
        }
        return true;
      },
    )
    .required("New password is required"),
  repeatPassword: yup
    .string()
    .required("Password confirmation is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const initialValues = {
  oldPassword: "",
  newPassword: "",
  repeatPassword: "",
};

export function ChangePassword({
  clearValidationMessages,
  onSubmit,
}: ChangePasswordProps): JSX.Element {
  const [showPassword, setShowPassword] = useState<{ [fieldName: string]: boolean }>({
    oldPassword: false,
    newPassword: false,
    repeatPassword: false,
  });
  const { status, errorMessage, successMessage } = useAppSelector(selectChangePasswordStatus);

  useEffect(() => {
    return () => {
      clearValidationMessages();
    };
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: ({ oldPassword, newPassword }) => {
      onSubmit({ oldPassword, newPassword });
    },
  });

  const { errors, handleSubmit, handleChange, isValid, dirty, touched, resetForm } = formik;
  const { oldPassword, repeatPassword, newPassword } = formik.values;

  useEffect(() => {
    if (!oldPassword || !newPassword || !repeatPassword) {
      clearValidationMessages();
    }
  }, [oldPassword, newPassword, repeatPassword]);

  const fieldsData = [
    {
      label: "Current Password",
      name: "oldPassword",
      value: oldPassword,
      helperText: errors.oldPassword,
      error: !!errors.oldPassword,
      isValidField: !!touched.oldPassword && oldPassword.length && !errors.oldPassword,
      placeholder: "Write your current password",
    },
    {
      label: "New Password",
      name: "newPassword",
      value: newPassword,
      helperText: errors.newPassword,
      error: !!errors.newPassword,
      isValidField: !!touched.newPassword && newPassword.length && !errors.newPassword,
      placeholder: "Write your new password",
    },
    {
      label: "Repeat Password",
      name: "repeatPassword",
      value: repeatPassword,
      helperText: errors.repeatPassword,
      error: !!errors.repeatPassword,
      isValidField: !!touched.repeatPassword && repeatPassword.length && !errors.repeatPassword,
      placeholder: "Repeat your new password",
    },
  ];

  return (
    <Box sx={{ padding: (theme) => theme.spacing(4, 2) }}>
      <Typography
        color={(theme) => theme.veris.colors.amethyst.normal}
        variant="h6Semibold"
        mb={2.3}
        component="div"
        p={1.5}
      >
        Change password
      </Typography>

      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          {fieldsData.map((field) => (
            <Box display="flex" key={field.label} alignItems="center" my={1.7}>
              <Grid item mr={1.7} width={150} p={1.5}>
                <Typography variant="subtitle2">{field.label}</Typography>
              </Grid>
              <Grid item sx={{ width: "300px" }}>
                <VrsFormInputField
                  name={field.name}
                  type={showPassword[field.name] ? "text" : "password"}
                  showPassword={showPassword[field.name]}
                  value={field.value}
                  helperText={field.helperText}
                  error={!!field.error}
                  isValidField={field.isValidField}
                  onChange={handleChange}
                  handleClickShowPassword={() =>
                    setShowPassword({ ...showPassword, [field.name]: !showPassword[field.name] })
                  }
                  placeholder={field.placeholder}
                  hideWarningIcon
                  onFocus={clearValidationMessages}
                />
              </Grid>
            </Box>
          ))}

          <Typography
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
            variant="caption"
            component="div"
            ml="162px"
          >
            Your password must be at least 8 characters long with <br />
            at least 3 out of 4 of lowercase, uppercase, numbers, or symbols.
          </Typography>

          {status === "loading" ? (
            <Box p={1.5} display="flex" alignItems="center" justifyContent="center" width="550px">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {(errorMessage || successMessage) && (
                <Typography
                  color={(theme) =>
                    errorMessage
                      ? theme.veris.colors.errors.normal
                      : theme.veris.colors.moderate.normal
                  }
                  variant="caption"
                  component="div"
                  ml="162px"
                  my={1.5}
                >
                  {errorMessage || successMessage}
                </Typography>
              )}
            </>
          )}

          <Box display="flex" justifyContent="flex-end">
            <Box alignSelf="flex-end" display="flex">
              <VrsButton
                onClick={() => {
                  clearValidationMessages();
                  resetForm();
                  setShowPassword({
                    oldPassword: false,
                    newPassword: false,
                    repeatPassword: false,
                  });
                }}
                buttonType="transparent"
              >
                <Typography
                  variant="body"
                  sx={{
                    textDecoration: "underline",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Typography>
              </VrsButton>

              <VrsButton
                buttonType="secondary"
                disabled={
                  !isValid || !touched || !dirty || !oldPassword || !newPassword || !repeatPassword
                }
                type="submit"
              >
                Save
              </VrsButton>
            </Box>
          </Box>
        </form>
      </FormikProvider>
    </Box>
  );
}
