import React, { useEffect } from "react";
import {
  styled,
  SelectProps,
  drawerClasses,
  inputBaseClasses,
  outlinedInputClasses,
  selectClasses,
  Select,
  MenuItem,
  ListSubheader,
  SelectChangeEvent,
  ListSubheaderProps,
} from "@mui/material";
import { IconName, VrsIcon, verisColors } from "@veris-health/web-core";

function MyListSubheader(props: ListSubheaderProps) {
  return (
    <ListSubheader
      {...props}
      sx={{
        lineHeight: (theme) => theme.typography.body1.lineHeight,
        fontFamily: (theme) => theme.typography.body1.fontFamily,
        fontSize: (theme) => theme.typography.body1.fontSize,
        color: (theme) => theme.veris.colors.neutrals["grey-3"],
      }}
    />
  );
}

MyListSubheader.muiSkipListHighlight = true;
export default MyListSubheader;

export const VrsStyledSelect = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  [`.${drawerClasses.paper}`]: {
    top: "unset",
  },

  [`& .${inputBaseClasses.root}.${outlinedInputClasses.root} `]: {
    backgroundColor: theme.veris.colors.neutrals.white,
    borderRadius: +theme.shape.borderRadius * 1.5,
    [`& .${outlinedInputClasses.notchedOutline} `]: {
      borderColor: verisColors.neutrals["grey-2"],
      borderWidth: "1px",
    },
  },

  [`.${selectClasses.icon}`]: {
    top: "unset",
  },
}));

export interface Option {
  value: string;
  label: string;
}

export interface VrsGroupedSelectProps extends SelectProps {
  groups: { options: Option[]; label?: string }[];
  initialValue?: string;
  value?: string;
  onSelected: (value: string) => void;
  outerSx?: React.CSSProperties;
  open?: boolean;
  setOpen?: () => void;
}

export function VrsGroupedSelect({
  groups,
  initialValue,
  outerSx,
  value = initialValue,
  onSelected,
  open,
  ...rest
}: VrsGroupedSelectProps): JSX.Element {
  const [selected, setSelected] = React.useState("");
  useEffect(() => {
    if (initialValue) {
      setSelected(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    setSelected(value || "");
  }, [value]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value);
    onSelected(event.target.value);
  };
  return (
    <VrsStyledSelect
      sx={{
        [`.${selectClasses.select}`]: {
          padding: (theme) => ({
            sm: theme.spacing(2, 1.75, 0.5, 1.75),
            lg: theme.spacing(1, 1.75, 0.5, 1.75),
          }),
          ...outerSx,
        },
      }}
    >
      <Select
        {...rest}
        defaultValue={initialValue}
        sx={{ width: "100%", fontWeight: 400, textTransform: "capitalize" }}
        value={selected}
        onChange={handleChange}
        IconComponent={(props) => <VrsIcon {...props} name={IconName.ArrowDown} />}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        open={open}
      >
        <MenuItem
          key="All"
          value=""
          sx={{
            fontSize: (theme) => theme.typography.body.fontSize,
            fontWeight: (theme) => theme.typography.body.fontWeight,
            padding: (theme) => theme.spacing(1),
            color: (theme) => theme.veris.colors.neutrals["grey-4"],
            textTransform: "capitalize",
            body: (theme) => theme.typography.body,
          }}
        >
          All
        </MenuItem>
        {groups.map((group) => [
          <MyListSubheader key={group.label}>{group.label}</MyListSubheader>,
          [
            ...group.options.map((option) => (
              <MenuItem
                key={option.label}
                value={option.value}
                sx={{
                  fontSize: (theme) => theme.typography.body.fontSize,
                  fontWeight: (theme) => theme.typography.body.fontWeight,
                  padding: (theme) => theme.spacing(1),
                  color: (theme) => theme.veris.colors.neutrals["grey-4"],
                  textTransform: "capitalize",
                  body: (theme) => theme.typography.body,
                }}
              >
                {option.label}
              </MenuItem>
            )),
          ],
        ])}
      </Select>
    </VrsStyledSelect>
  );
}
