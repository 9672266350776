import {
  SensorDataApi as SensorDataApiV1,
  DataExportApi as DataExportApiV1,
} from "@veris-health/med-data-ms/lib/v1";

import config from "../axios-config";
import { API_ROUTES, getVersion, VERSIONS } from "../helpers";

export const sensorDataApiV1 = new SensorDataApiV1(
  undefined,
  getVersion(API_ROUTES.MED_DATA, VERSIONS.v1),
  config,
);

export const dataExportApiV1 = new DataExportApiV1(
  undefined,
  getVersion(API_ROUTES.MED_DATA, VERSIONS.v1),
  config,
);
