import { Box, Typography } from "@mui/material";
import React from "react";

interface NewMessageNotificationProps {
  onClick: () => void;
  text: string;
}

export const NewMessageNotification = ({
  onClick,
  text,
}: NewMessageNotificationProps): JSX.Element => (
  <Box
    onClick={onClick}
    sx={{ display: "flex", alignSelf: "center", position: "absolute", top: "115px" }}
  >
    <Typography
      variant="caption"
      color={(theme) => theme.veris.colors.neutrals["grey-3"]}
      sx={{
        cursor: "pointer",
        borderRadius: (theme) => theme.spacing(1),
        backgroundColor: (theme) => theme.veris.colors.neutrals["grey-1"],
        width: "fit-content",
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
      }}
      p={1}
    >
      {text}
    </Typography>
  </Box>
);
