import React, { useState } from "react";
import { VrsButton } from "@veris-health/web-core";
import { TechRoleFilterEnum } from "@veris-health/user-ms/lib/v1";
import { Box, Typography } from "@mui/material";
import { SupportChatStatus, ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import { VrsGroupChatHeader } from "../components/VrsGroupChatHeader";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {
  ChatThreadInfoWithPatient,
  updateSupportChatThreadAsync,
  updateSupportChatAsync,
} from "../store";
import { VrsUserSelectionDialog } from "../components/VrsUserSelectionDialog";
import useModal from "../../../hooks/useModal/useModal";

interface ChatHeaderContainerProps {
  currentThread?: ChatThreadInfoWithPatient;
  userId: string;
  threadParticipant?: ThreadParticipantInfo;
}

export const ChatHeaderContainer = ({
  currentThread,
  userId,
  threadParticipant,
}: ChatHeaderContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [toggleNewChatDialog, setToggleNewChatDialog] = useState<boolean>(false);
  const [modal, showModal] = useModal();

  const handleAssigneeSelection = () => {
    if (currentThread && currentThread.support_chat_info && threadParticipant) {
      dispatch(
        updateSupportChatAsync({
          status: currentThread.support_chat_info.chat_status,
          threadId: currentThread.id,
          asigneeId: +userId,
          patientId: threadParticipant.user_id,
        }),
      );
      setToggleNewChatDialog(false);
    }
  };

  const handleUpdate = (status: SupportChatStatus) => {
    if (currentThread && threadParticipant)
      dispatch(
        updateSupportChatThreadAsync({
          patientId: threadParticipant.user_id,
          threadId: currentThread.id,
          status,
        }),
      );
  };

  return (
    <>
      {currentThread && currentThread.id && (
        <>
          <VrsGroupChatHeader
            threadStatus={currentThread.support_chat_info?.chat_status}
            participants={currentThread.participants || []}
            onUpdate={(status: SupportChatStatus) => {
              showModal("Please confirm your action", (onClose) => (
                <Box my={2}>
                  <Typography
                    variant="h6"
                    color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                    my={2}
                  >
                    Are you sure you want to mark this conversation as {status} ?{" "}
                  </Typography>
                  <Box display="flex" justifyContent="right" gap={2}>
                    <VrsButton buttonType="secondary" onClick={onClose}>
                      Cancel
                    </VrsButton>
                    <VrsButton
                      buttonType="primary"
                      onClick={() => {
                        handleUpdate(status);
                        onClose();
                      }}
                    >
                      Confrim
                    </VrsButton>
                  </Box>
                </Box>
              ));
            }}
            onUpdateAsigneeClick={() => setToggleNewChatDialog(true)}
            threadParticipant={threadParticipant}
          />

          {toggleNewChatDialog && (
            <VrsUserSelectionDialog
              isOpen={toggleNewChatDialog}
              handleSelection={handleAssigneeSelection}
              closeNewChatDialog={() => setToggleNewChatDialog(false)}
              userRole={TechRoleFilterEnum.SuperadminTechStaff}
              subtitle="Select admin as assignee"
              buttonText="Select assignee"
            />
          )}
          {modal}
        </>
      )}
    </>
  );
};
