import React, { useEffect } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { VrsErrorMsg } from "@veris-health/web-core";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectUserId } from "../shared/slices/authSlice";
import {
  selectChatApiEndpoint,
  selectChatUserAccessToken,
  selectStatus,
  selectMyAzureChatUserId,
  selectMyChatDisplayName,
  fetchChatConfiguration,
} from "./store";
import ChatContainer from "./containers/ChatContainer";
import { useAppDispatch } from "../../hooks/useAppDispatch";

const CommunicationContainer = (): JSX.Element => {
  const status = useAppSelector(selectStatus);
  const chatApiEndpoint = useAppSelector(selectChatApiEndpoint);
  const chatUserAccessToken = useAppSelector(selectChatUserAccessToken);
  const myAzureChatUserId = useAppSelector(selectMyAzureChatUserId);
  const myChatDisplayName = useAppSelector(selectMyChatDisplayName);
  const userId = useAppSelector(selectUserId);
  const dispatch = useAppDispatch();
  const isChatConfigInitialized =
    chatApiEndpoint && chatUserAccessToken && myAzureChatUserId && myChatDisplayName;

  useEffect(() => {
    dispatch(fetchChatConfiguration({ id: Number(userId) }));
  }, []);

  return (
    <Grid container height="90vh">
      {status === "loading" && (
        <Grid display="flex" justifyContent="center" py={2} item xs={12}>
          <CircularProgress />
        </Grid>
      )}
      {status === "idle" && userId && (
        <Grid item xs={12}>
          {isChatConfigInitialized && (
            <ChatContainer
              endpoint={chatApiEndpoint}
              token={chatUserAccessToken}
              userId={userId}
              myAzureChatUserId={myAzureChatUserId}
              myChatDisplayName={myChatDisplayName}
            />
          )}
        </Grid>
      )}
      {status === "failed" && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: (theme) => theme.veris.colors.neutrals.white,
            margin: "auto",
            width: "100%",
            height: "90vh",
          }}
        >
          <VrsErrorMsg
            size="large"
            onClick={() => dispatch(fetchChatConfiguration({ id: Number(userId) }))}
          />
        </Box>
      )}
    </Grid>
  );
};

export default CommunicationContainer;
