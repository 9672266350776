import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import {
  VrsButton,
  VrsConfirmationModals,
  dateFormats,
  useCountDown,
  useTargetTimestamp,
} from "@veris-health/web-core";
import { StaffType, TechRoleEnum } from "@veris-health/user-ms/lib/v1";
import { Routes } from "../../routes-config";
import { VrsFormInputField } from "../../ui/components/VrsFormInputField";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  clearValidationError,
  loginTechStaffAsync,
  selectValidationError,
  selectIsLoggedIn,
  toggleShowPassword,
  selectIsShowPassword,
  selectAuthStatus,
  selectOtpScreen,
  loginTechStaffOTPAsync,
  selectChangePasswordStatus,
  clearChangePasswordMessages,
  selectlockoutSeconds,
  selectIsAccountBlocked,
  clearLockoutTimer,
  clearAuthError,
  selectIsPasswordExpired,
  clearPasswordExpired,
  selectExpiredPasswordStatus,
  clearExpiredPassword,
} from "../shared/slices/authSlice";
import { OTPLoginContainer } from "./OTPLoginContainer";
import { testAccountEmail, testOTP } from "../../constants";
import { useAuthenticatedUserRoles } from "../../context/profile";
import { emailValidation } from "../../utils/validations";

const Login = (): JSX.Element => {
  const validationError = useAppSelector(selectValidationError);
  const otpScreen = useAppSelector(selectOtpScreen);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const authenticatedUserRoles = useAuthenticatedUserRoles();
  const showPassword = useAppSelector(selectIsShowPassword);
  const authStatus = useAppSelector(selectAuthStatus);
  const changePasswordStatus = useAppSelector(selectChangePasswordStatus);
  const lockoutSeconds = useAppSelector(selectlockoutSeconds);
  const isAccountBlocked = useAppSelector(selectIsAccountBlocked);
  const isPasswordExpired = useAppSelector(selectIsPasswordExpired);
  const { successMessage } = useAppSelector(selectExpiredPasswordStatus);
  const [isAccountBlockedPopupOpen, setAccountBlockedPopup] = useState(isAccountBlocked);

  const targetTimestamp = useTargetTimestamp(lockoutSeconds);
  const remainingTime = useCountDown(targetTimestamp);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(clearLockoutTimer());
      dispatch(clearValidationError());
      dispatch(clearAuthError());
      dispatch(clearPasswordExpired());
      dispatch(clearExpiredPassword());
    };
  }, []);

  useEffect(() => {
    setAccountBlockedPopup(isAccountBlocked);
  }, [isAccountBlocked]);

  useEffect(() => {
    if (isLoggedIn) {
      if (authenticatedUserRoles.includes(TechRoleEnum.Billingexpert)) {
        navigate(Routes.BILLING_REPORTS);
      } else {
        navigate(Routes.DASHBOARD);
        dispatch(clearChangePasswordMessages());
      }
    }
  }, [isLoggedIn, navigate, authenticatedUserRoles]);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: emailValidation,
    password: yup.string().required(""),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ email, password }) => {
      const trimmedEmail = email.trim();
      if (testAccountEmail === trimmedEmail) {
        dispatch(
          loginTechStaffOTPAsync({
            email: trimmedEmail,
            password,
            otp: testOTP,
            type: StaffType.TechStaff,
          }),
        );
      } else {
        dispatch(loginTechStaffAsync({ email: trimmedEmail, password, type: StaffType.TechStaff }));
      }
    },
  });

  const { errors, handleSubmit, handleChange, handleBlur, isValid, touched } = formik;
  const { email, password } = formik.values;
  const trimmedEmail = email.trim();

  useEffect(() => {
    if (validationError && (!email || (!password && remainingTime === 0))) {
      dispatch(clearValidationError());
      dispatch(clearAuthError());
      dispatch(clearLockoutTimer());
    }
  }, [validationError, email, password, dispatch]);

  useEffect(() => {
    if (validationError && remainingTime === 0) {
      dispatch(clearValidationError());
      dispatch(clearAuthError());
      dispatch(clearLockoutTimer());
    }
  }, [remainingTime]);

  return (
    <>
      {otpScreen && testAccountEmail !== trimmedEmail ? (
        <OTPLoginContainer
          onSubmit={(code) =>
            dispatch(
              loginTechStaffOTPAsync({
                email: trimmedEmail,
                password,
                otp: code,
                type: StaffType.TechStaff,
              }),
            )
          }
          onResendCode={() =>
            dispatch(
              loginTechStaffAsync({ email: trimmedEmail, password, type: StaffType.TechStaff }),
            )
          }
        />
      ) : (
        <Box>
          <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
                style={{ margin: "0 auto", width: "95%" }}
                display="block"
              >
                <Grid item xs={7}>
                  <VrsFormInputField
                    name="email"
                    type="text"
                    label="Email"
                    value={email}
                    onFocus={() => {
                      if (!!validationError && remainingTime === 0 && !isAccountBlocked) {
                        dispatch(clearValidationError());
                        dispatch(clearAuthError());
                      }
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.email && errors.email ? errors.email : undefined}
                    error={(touched.email && Boolean(errors.email)) || Boolean(validationError)}
                    isValidField={email.length && !errors.email && !validationError}
                  />
                </Grid>
                <Grid item xs={7}>
                  <VrsFormInputField
                    name="password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    value={password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() => {
                      if (!!validationError && remainingTime === 0 && !isAccountBlocked) {
                        dispatch(clearValidationError());
                        dispatch(clearAuthError());
                      }
                    }}
                    helperText={
                      errors.password ||
                      (remainingTime === 0
                        ? validationError
                        : `${validationError} Please try again after ${dayjs
                            .duration(remainingTime)
                            .format(dateFormats["m:ss"])} minutes.`)
                    }
                    error={Boolean(errors.password) || Boolean(validationError)}
                    isValidField={password.length && !errors.password && !validationError}
                    showPassword={showPassword}
                    handleClickShowPassword={() => dispatch(toggleShowPassword())}
                  />
                </Grid>
                {!isAccountBlocked && remainingTime === 0 && (
                  <Grid item xs={12}>
                    <Link to={Routes.FORGOT_PASSWORD}>
                      <Typography
                        variant="caption"
                        color={(theme) => theme.veris.colors.amethyst.normal}
                      >
                        Forgot password?
                      </Typography>
                    </Link>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {changePasswordStatus.successMessage && (
                    <Typography
                      color={(theme) => theme.veris.colors.moderate.normal}
                      variant="caption"
                      component="div"
                    >
                      {changePasswordStatus.successMessage}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} marginTop={5}>
                  <VrsButton
                    buttonType="primary"
                    disabled={
                      !isValid ||
                      !!validationError ||
                      !touched ||
                      authStatus === "loading" ||
                      remainingTime > 0 ||
                      isAccountBlocked
                    }
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Sign in
                    {authStatus === "loading" && (
                      <CircularProgress
                        sx={{ marginLeft: (theme) => theme.spacing(2) }}
                        size={16}
                      />
                    )}
                  </VrsButton>
                </Grid>
              </Grid>
            </form>
            <VrsConfirmationModals
              isOpen={isAccountBlockedPopupOpen}
              handleClose={() => setAccountBlockedPopup(false)}
              dialogHeader="Account locked out"
              dialogContent={validationError || ""}
              onConfirm={() => setAccountBlockedPopup(false)}
              confirmButtonText="Close"
            />
            <VrsConfirmationModals
              isOpen={isPasswordExpired}
              handleClose={() => dispatch(clearPasswordExpired())}
              dialogHeader="Password Expired"
              dialogContent="Please create a new one."
              onCancel={() => dispatch(clearPasswordExpired())}
              onConfirm={() => navigate(Routes.PASSWORD_EXPIRATION)}
              confirmButtonText="Add new password"
              confirmButtonVariant="primary"
              cancelButtonText="Cancel"
            />
            <VrsConfirmationModals
              isOpen={!!successMessage}
              handleClose={() => dispatch(clearExpiredPassword())}
              dialogHeader="Success"
              dialogContent={successMessage || ""}
              onConfirm={() => dispatch(clearExpiredPassword())}
              confirmButtonText="Login"
            />
          </FormikProvider>
        </Box>
      )}
    </>
  );
};

export default Login;
