import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FeedbackSurvey } from "@veris-health/virtual-doc-ms/lib/v1/api";
import { RootState } from "../../store";
import { extractErrorMessage } from "../shared/helpers";
import { Status } from "../shared/interfaces";
import { localizedLogout, logout } from "../shared/slices/authSlice";
import { deleteFeedbackSurvey, getFeedbackSurvey, getFeedbackSurveys } from "./api/surveys";

export const fetchSurveysAsync = createAsyncThunk<
  FeedbackSurvey[],
  { expired?: boolean | undefined }
>(
  "surveys/getFeedbackSurveys",
  async ({ expired }: { expired?: boolean | undefined }, { rejectWithValue }) => {
    try {
      const response = await getFeedbackSurveys(expired);
      return response;
    } catch (error) {
      const errorMsg = extractErrorMessage(error);
      return rejectWithValue(errorMsg || "Could not fetch surveys");
    }
  },
);

export const fetchSurveyByIdAsync = createAsyncThunk<FeedbackSurvey, { surveyId: number }>(
  "surveys/getFeedbackSurveyById",
  async ({ surveyId }: { surveyId: number }, { rejectWithValue }) => {
    try {
      const response = await getFeedbackSurvey(surveyId);
      return response;
    } catch (error) {
      const errorMsg = extractErrorMessage(error);
      return rejectWithValue(errorMsg || "Could not survey");
    }
  },
);

export const deleteSurveyAsync = createAsyncThunk<number, number>(
  "surveys/deleteSurvey",
  async (id, { rejectWithValue }) => {
    try {
      await deleteFeedbackSurvey(id);
      return id;
    } catch (error) {
      const errorMsg = extractErrorMessage(error);
      return rejectWithValue(errorMsg || "Could not delete survey");
    }
  },
);

interface SurveysState {
  surveys: {
    data: FeedbackSurvey[];
    status: Status;
  };
}

const initialState: SurveysState = {
  surveys: {
    data: [],
    status: "idle",
  },
};

const surveysSlice = createSlice({
  name: "Surveys",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(localizedLogout, () => {
        return initialState;
      })
      .addCase(fetchSurveysAsync.pending, (state) => {
        state.surveys.status = "loading";
      })
      .addCase(fetchSurveysAsync.fulfilled, (state, { payload }) => {
        state.surveys.data = payload;
        state.surveys.status = "idle";
      })
      .addCase(fetchSurveysAsync.rejected, (state) => {
        state.surveys.status = "failed";
      })
      .addCase(fetchSurveyByIdAsync.fulfilled, (state, { payload }) => {
        const updatedSurveyIndex = state.surveys.data.findIndex(
          (survey) => survey.id === payload.id,
        );

        if (updatedSurveyIndex !== -1) {
          state.surveys.data[updatedSurveyIndex] = payload;
        } else {
          state.surveys.data.push(payload);
        }

        state.surveys.status = "idle";
      })
      .addCase(deleteSurveyAsync.fulfilled, (state, { payload }) => {
        state.surveys.data = state.surveys.data.filter((survey) => survey.id !== payload);
        state.surveys.status = "idle";
      });
  },
});

export const selectSurveys = ({ surveys }: RootState): FeedbackSurvey[] => {
  return surveys.surveys.data;
};

export const selectSurveysStatus = ({ surveys }: RootState): Status => surveys.surveys.status;

export default surveysSlice.reducer;
