import { Box, Typography, Grid, CircularProgress, useTheme } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import relativeTime from "dayjs/plugin/relativeTime";
import { NotificationView } from "@veris-health/communication-ms/lib/v1";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { NotificationListItem } from "../../ui/components/NotificationListItem";
import {
  selectFilteredNotificationsfromEarlier,
  selectFilteredNotificationsfromToday,
  selectFilteredNotificationsfromYesterday,
  markNotificationAsReadAsync,
  loadNotificationsDataAsync,
  selectNotificationsStatus,
  selectNotificationsFetchingOffset,
  selectTotalCount,
  selectAllNotifications,
} from "./notificationsSlice";
import { selectUserId } from "../shared/slices/authSlice";
import { replaceRouteParam } from "../../routes-config";
import { NotificationsDetailsContainer } from "./NotificationDetailsContainer";
import { useNotification } from "./hooks/useNotification";
import { NOTIFICATIONS_OFFSET } from "../../constants";

dayjs.extend(relativeTime);

export const NotificationsListContainer = (): JSX.Element => {
  const filteredNotificationsToday = useAppSelector(selectFilteredNotificationsfromToday);
  const filteredNotificationsEarlier = useAppSelector(selectFilteredNotificationsfromEarlier);
  const filteredNotificationsYesterday = useAppSelector(selectFilteredNotificationsfromYesterday);
  const activeNotification = useNotification();
  const currentUserId = useAppSelector(selectUserId);
  const offset = useAppSelector(selectNotificationsFetchingOffset);
  const totalCount = useAppSelector(selectTotalCount);
  const notificationStatus = useAppSelector(selectNotificationsStatus);
  const notifications = useAppSelector(selectAllNotifications);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const navigate = useNavigate();

  const fetchNotifications = useCallback(async () => {
    if (notificationStatus === "loading") {
      return;
    }
    dispatch(
      loadNotificationsDataAsync({
        userId: Number(currentUserId),
        offset: offset + NOTIFICATIONS_OFFSET,
      }),
    );
  }, [notificationStatus]);

  const handleNotificationClick = (notification: NotificationView) => {
    navigate(
      replaceRouteParam(
        "NOTIFICATIONS_DETAILS",
        ":notificationId",
        notification.notification_id.toString(),
      ),
    );
  };

  useEffect(() => {
    if (activeNotification && !activeNotification.read && currentUserId) {
      dispatch(
        markNotificationAsReadAsync({
          userId: Number(currentUserId),
          markAsRead: { notification_ids: [activeNotification.notification_id] },
        }),
      );
    }
  }, [dispatch, activeNotification, currentUserId]);

  return (
    <Grid padding={3} container sx={{ height: "100%" }}>
      <Grid
        container
        sx={{
          border: ({ veris }) => `1px solid ${veris.colors.neutrals["grey-light"]}`,
          borderRadius: "0.25rem",
        }}
      >
        <Grid
          item
          xs={notifications.length === 0 ? 12 : 6}
          sx={{
            borderRight: ({ veris }) =>
              notifications.length === 0
                ? "none"
                : `1px solid ${veris.colors.neutrals["grey-light"]}`,

            backgroundColor: ({ veris }) => veris.colors.neutrals.white,
            borderRadius: "0.25rem",
            borderTopRightRadius: notifications.length === 0 ? "0.25rem" : "0",
            borderBottomRightRadius: notifications.length === 0 ? "0.25rem" : "0",
          }}
        >
          <Box
            marginX={1.6}
            marginRight={0.5}
            paddingRight={0.9}
            height="73vh"
            overflow="auto"
            display="flex"
            flexDirection="column"
            id="notifications-list-scroll-container"
          >
            <InfiniteScroll
              scrollableTarget="notifications-list-scroll-container"
              dataLength={notifications.length}
              style={{ overflow: "hidden" }}
              hasMore={notifications.length < totalCount}
              next={() => {
                if (notificationStatus === "idle") fetchNotifications();
              }}
              loader={
                <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                  <CircularProgress />
                </Box>
              }
            >
              <Box>
                {notifications.length === 0 && (
                  <Typography
                    variant="h6"
                    paddingY={1}
                    mt={2}
                    color={theme.veris.colors.neutrals["grey-3"]}
                  >
                    There are no notifications available.
                  </Typography>
                )}
                {filteredNotificationsToday.length > 0 && (
                  <>
                    <Typography
                      variant="body1"
                      paddingY={1}
                      mt={2}
                      color={theme.veris.colors.neutrals["grey-3"]}
                      sx={{ textTransform: "uppercase" }}
                    >
                      Today
                    </Typography>
                    {filteredNotificationsToday.map((notification) => (
                      <NotificationListItem
                        key={notification.notification_id}
                        notification={notification}
                        isActive={
                          !!activeNotification &&
                          activeNotification.notification_id === notification.notification_id
                        }
                        onClick={() => handleNotificationClick(notification)}
                      />
                    ))}
                  </>
                )}
                {filteredNotificationsYesterday.length > 0 && (
                  <>
                    <Typography
                      variant="body1"
                      paddingY={1}
                      mt={2}
                      color={theme.veris.colors.neutrals["grey-3"]}
                      sx={{ textTransform: "uppercase" }}
                    >
                      Yesterday
                    </Typography>
                    {filteredNotificationsYesterday.map((notification) => (
                      <NotificationListItem
                        key={notification.notification_id}
                        notification={notification}
                        isActive={
                          !!activeNotification &&
                          activeNotification.notification_id === notification.notification_id
                        }
                        onClick={() => handleNotificationClick(notification)}
                      />
                    ))}
                  </>
                )}

                {filteredNotificationsEarlier.length > 0 && (
                  <>
                    <Typography
                      variant="body1"
                      paddingY={1}
                      mt={2}
                      color={theme.veris.colors.neutrals["grey-3"]}
                      sx={{ textTransform: "uppercase" }}
                    >
                      Earlier
                    </Typography>
                    {filteredNotificationsEarlier.map((notification) => {
                      return (
                        <NotificationListItem
                          key={notification.notification_id}
                          notification={notification}
                          isActive={
                            !!activeNotification &&
                            activeNotification.notification_id === notification.notification_id
                          }
                          onClick={() => handleNotificationClick(notification)}
                        />
                      );
                    })}
                  </>
                )}
              </Box>
            </InfiniteScroll>
          </Box>
        </Grid>

        <Grid
          sx={{
            backgroundColor: ({ veris }) => veris.colors.neutrals["grey-background"],
            borderRadius: "0.25rem",
          }}
          item
          xs={notifications.length === 0 ? 0 : 6}
        >
          <NotificationsDetailsContainer />
        </Grid>
      </Grid>
    </Grid>
  );
};
