import { IconName, VrsIconButton } from "@veris-health/web-core";
import React from "react";

export interface SidebarToggleProps {
  expanded: boolean;
  onClick: (e: React.MouseEvent) => void;
}

export function SidebarToggle({
  expanded = true,
  onClick,
  ...rest
}: SidebarToggleProps): JSX.Element {
  return (
    <VrsIconButton
      iconProps={{ name: expanded ? IconName.ToggleClose : IconName.ToggleOpen }}
      onClick={onClick}
      {...rest}
      square
    />
  );
}
