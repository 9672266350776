import { Box, Typography, useTheme } from "@mui/material";
import { OtpTypePassword, StaffType } from "@veris-health/user-ms/lib/v1";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconName, VrsIconButton } from "@veris-health/web-core";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { Routes } from "../../routes-config";
import { StyledActiveNavigationItem } from "../shared/styledComponents";
import {
  clearChangePasswordMessages,
  getOTPAsync,
  resetPasswordAsync,
  selectChangePasswordStatus,
  validateOTPAsync,
} from "../shared/slices/authSlice";
import { EmailForm } from "./ForgotPassword/EmailForm";
import { ResetForm } from "./ForgotPassword/ResetForm";
import { OTPLoginContainer } from "./OTPLoginContainer";

enum ForgotPasswordScreen {
  EMAIL = "Email",
  OTP = "OTP",
  RESET = "Reset Password",
}

export function ForgotPasswordContainer(): JSX.Element {
  const theme = useTheme();
  const [activeScreen, setActiveScreen] = useState<ForgotPasswordScreen>(
    ForgotPasswordScreen.EMAIL,
  );
  const [OTPCode, setOTPCode] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const resetPasswordValidation = useAppSelector(selectChangePasswordStatus);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearChangePasswordMessages());
  }, []);

  const onEmailSubmit = (userEmail: string) => {
    dispatch(getOTPAsync({ payload: { email: userEmail, staff_type: StaffType.TechStaff } })).then(
      (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setEmail(userEmail);
          setActiveScreen(ForgotPasswordScreen.OTP);
        }
      },
    );
  };

  const onPasswordResetSubmit = (password: string) => {
    dispatch(
      resetPasswordAsync({
        newData: {
          staff_type: StaffType.TechStaff,
          verification_code: OTPCode,
          password,
          email,
        },
      }),
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        navigate(Routes.STAFF_LOGIN);
      }
    });
  };

  const onOTPSubmit = (code: string) => {
    dispatch(
      validateOTPAsync({
        OTPData: {
          verification_code: code,
          email,
          verification_code_type: OtpTypePassword.ResetPasswordTechStaff,
        },
      }),
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setOTPCode(code);
        setActiveScreen(ForgotPasswordScreen.RESET);
      }
    });
  };

  const clearValidationError = () => {
    dispatch(clearChangePasswordMessages());
  };

  const renderActiveScreen = (screenName: ForgotPasswordScreen) => {
    switch (screenName) {
      case ForgotPasswordScreen.EMAIL:
        return (
          <EmailForm
            onSubmit={onEmailSubmit}
            validationStatus={resetPasswordValidation}
            clearValidationError={clearValidationError}
          />
        );
      case ForgotPasswordScreen.OTP:
        return (
          <>
            <StyledActiveNavigationItem variant="h3" m={5} width="fit-content" ml={1}>
              Enter the verification code
            </StyledActiveNavigationItem>
            <Typography variant="h6" pl={1} pb={2} color={theme.veris.colors.amethyst.normal}>
              OTP code sent via sms.
            </Typography>
            <OTPLoginContainer onSubmit={onOTPSubmit} onResendCode={() => onEmailSubmit(email)} />
          </>
        );
      case ForgotPasswordScreen.RESET:
        return (
          <ResetForm
            onSubmit={onPasswordResetSubmit}
            validationStatus={resetPasswordValidation}
            clearValidationError={clearValidationError}
          />
        );
      default:
        return (
          <EmailForm
            onSubmit={() => setActiveScreen(ForgotPasswordScreen.OTP)}
            validationStatus={resetPasswordValidation}
            clearValidationError={clearValidationError}
          />
        );
    }
  };

  const backButtonLogic = (screenName: string) => {
    switch (screenName) {
      case ForgotPasswordScreen.EMAIL:
        navigate(Routes.STAFF_LOGIN);
        break;
      case ForgotPasswordScreen.OTP:
        setActiveScreen(ForgotPasswordScreen.EMAIL);
        break;
      case ForgotPasswordScreen.RESET:
        setActiveScreen(ForgotPasswordScreen.OTP);
        break;
      default:
        navigate(Routes.STAFF_LOGIN);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        marginTop="10%"
        m={5}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          backButtonLogic(activeScreen);
        }}
      >
        <VrsIconButton
          iconProps={{
            name: IconName.ArrowLeft,
            stroke: theme.veris.colors.neutrals.black,
            size: 12,
          }}
        />
        <Typography variant="subtitle2" color={({ veris }) => veris.colors.neutrals["grey-4"]}>
          Back
        </Typography>
      </Box>
      <Box marginTop="10%" display="flex" alignItems="baseline" flexDirection="column">
        {renderActiveScreen(activeScreen)}
      </Box>
    </>
  );
}
