import React from "react";
import { alpha, Box, styled, Typography } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import { AttachmentMetadata } from "@veris-health/communication-ms/lib/v1";
import { VrsAvatar, dateFormats, verisColors } from "@veris-health/web-core";
import { VrsAttachment } from "../VrsAttachment";
import { VrsFileAttachment } from "../../store";

export interface VrsChatTextMessageProps {
  text: string;
  metadata?: AttachmentMetadata;
  currentUserMsg: boolean;
  timestamp: string;
  isPrivateChat: boolean;
  attachment: VrsFileAttachment | undefined;
  staffData?: StaffData;
  imageUrl?: string;
  onAttachmentDownload?: () => void;
}

type StaffData = {
  name: string;
  role?: string;
};

const StyledTextMessageWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "currentUserMsg",
})<Pick<VrsChatTextMessageProps, "currentUserMsg">>(({ theme, currentUserMsg }) => ({
  maxWidth: "610px",
  width: "fit-content",
  display: "flex",
  flexDirection: "column",
  backgroundColor: currentUserMsg
    ? theme.veris.colors.neutrals["grey-1"]
    : alpha(theme.veris.colors.amethyst.light, 0.4),
  border: currentUserMsg
    ? `1px solid ${theme.veris.colors.neutrals["grey-2"]}`
    : `1px solid ${alpha(theme.veris.colors.amethyst.light, 0.4)}`,
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1.5),
}));

export function VrsChatTextMessage({
  text,
  metadata,
  currentUserMsg,
  timestamp,
  staffData,
  isPrivateChat,
  imageUrl,
  onAttachmentDownload,
  attachment,
}: VrsChatTextMessageProps): JSX.Element {
  return (
    <Box display="flex" flexDirection={currentUserMsg ? "row-reverse" : "row"}>
      <Box ml={1.5} mr={1.5}>
        <VrsAvatar imageUrl={imageUrl} name={staffData?.name || ""} />
      </Box>
      <StyledTextMessageWrapper currentUserMsg={currentUserMsg}>
        {isPrivateChat && (
          <Typography color={verisColors.neutrals["grey-3"]} variant="subtitle1" pb={1.5}>
            {staffData?.name} {staffData?.role && `• ${staffData?.role}`}
          </Typography>
        )}
        <Typography
          sx={{
            wordWrap: "break-word",
            wordBreak: "break-word",
          }}
          variant="subtitle24"
        >
          {text}
        </Typography>
        {metadata &&
          _.has(metadata, "attachmentName") &&
          _.has(metadata, "attachmentUrl") &&
          _.has(metadata, "attachmentFileType") &&
          _.has(metadata, "attachmentUuid") &&
          onAttachmentDownload && (
            <VrsAttachment
              attachment={attachment}
              metadata={metadata}
              onDownload={onAttachmentDownload}
            />
          )}
        <Typography variant="body" color={verisColors.neutrals["grey-3"]} pt={1.5} ml="auto">
          {dayjs(timestamp).format(`${dateFormats["MMM D, YYYY"]} • ${dateFormats["h:mm"]} a`)}
        </Typography>
      </StyledTextMessageWrapper>
    </Box>
  );
}
