import dayjs from "dayjs";
import jwtDecode, { JwtPayload } from "jwt-decode";

/**
 * Helper function to get the decoded token.
 * @param jwt The raw token.
 * @returns The contents of the token.
 */
export const getDecodedToken = (jwt: string): JwtPayload => {
  return jwtDecode<JwtPayload>(jwt);
};

/**
 * Check if the token is expired.
 * @param jwt The token for validation.
 * @returns True if expired, false otherwise.
 */
export const checkIfTokenIsExpired = (jwt: string): boolean => {
  const decodedToken = getDecodedToken(jwt);
  return decodedToken.exp ? dayjs.unix(decodedToken.exp).diff(dayjs()) < 0 : true;
};
