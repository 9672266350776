import { Grid, useTheme } from "@mui/material";
import { AdminDetailUserInfoResponse, CareTeamMemberInfo } from "@veris-health/user-ms/lib/v1";
import { VrsButton, VrsSelect } from "@veris-health/web-core";
import React, { useState } from "react";
import { Status } from "../../shared/interfaces";

type InvitePatientMainOncologistProps = {
  selectedCareTeam: {
    value: AdminDetailUserInfoResponse | CareTeamMemberInfo;
    label: string | undefined;
  }[];
  setMainOncologist: (mainOncologistId: string) => void;
  status: Status;
};

export const InvitePatientMainOncologist = ({
  selectedCareTeam,
  setMainOncologist,
  status,
}: InvitePatientMainOncologistProps): JSX.Element => {
  const [mainOncologistId, setMainOncologistId] = useState<string>("");
  const theme = useTheme();

  const mainOncologistOptions = selectedCareTeam
    .filter((member) => {
      const { value } = member;
      const roles = (value as CareTeamMemberInfo).roles || [];
      const userRoles = (value as AdminDetailUserInfoResponse)?.user_roles || [];
      return roles.includes("doctor") || userRoles.some((role) => role.role_name === "doctor");
    })
    .map(({ label, value }) => ({
      label: label ?? "",
      value: value?.id?.toString() ?? "",
    }));

  return (
    <>
      <VrsSelect
        required
        innerSx={{ maxWidth: "max-content", paddingRight: theme.spacing(1) }}
        value={mainOncologistId}
        options={mainOncologistOptions}
        placeholder="Choose main oncologist"
        onSelected={setMainOncologistId}
      />
      <Grid item xs={12} marginTop={4} style={{ display: "flex", flexDirection: "row-reverse" }}>
        <VrsButton
          buttonType="primary"
          disabled={selectedCareTeam.length === 0 || status === "loading"}
          variant="contained"
          color="primary"
          onClick={() => setMainOncologist(mainOncologistId)}
        >
          Submit
        </VrsButton>
      </Grid>
    </>
  );
};
