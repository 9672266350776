import { dateFormats } from "@veris-health/web-core";
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const calculateDate = (date: Date | string): string => {
  if (dayjs(dayjs()).diff(date, "days") >= 7) return dayjs(date).format(dateFormats["MMM DD"]);
  return dayjs(date).fromNow();
};

export function utcToLocal(date: string): Dayjs {
  return dayjs.utc(date).local();
}

export const calculateTimeFromNow = (date: Date | string): string => {
  try {
    if (dayjs(dayjs()).diff(date, "days") >= 7) return dayjs(date).format(dateFormats["MMM DD"]);
    return dayjs.utc(date).fromNow();
  } catch (e) {
    return dayjs(date).format(dateFormats["MMM DD"]);
  }
};

export function isDateInPast(date: string) {
  const currentDate = dayjs().startOf("day");
  const providedDate = dayjs(date).startOf("day");

  return providedDate.isBefore(currentDate) || providedDate.isSame(currentDate, "day");
}
