import { Typography, useTheme } from "@mui/material";
import { CareTeamMemberInfo } from "@veris-health/user-ms/lib/v1";
import React from "react";

export interface VrsCareTeamMemberProps {
  member: CareTeamMemberInfo;
  color: string;
  small?: boolean;
}

export function VrsCareTeamMember({ member, color, small }: VrsCareTeamMemberProps): JSX.Element {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        borderRadius: +theme.shape.borderRadius * 0.375,
        color: `${color}`,
        border: `1px solid ${color}`,
        background: `${theme.veris.colors.neutrals.white}`,
        padding: small
          ? theme.spacing(0.375, 0.375, 0.25, 0.375)
          : theme.spacing(0.75, 0.75, 0.5, 0.75),
        margin: small
          ? theme.spacing(0.1875, 0.375, 0.1875, 0)
          : theme.spacing(0.375, 0.75, 0.375, 0),
        display: "inline-flex",
        maxWidth: "unset",
      }}
      component="div"
      variant="body"
    >
      {member.full_name}
    </Typography>
  );
}
