import React from "react";
import { SnackbarContent, SnackbarProvider as NotistackSnackbarProvider } from "notistack";

const SnackbarWrapper = React.forwardRef<HTMLDivElement, { message: string | React.ReactNode }>(
  (props, ref) => {
    return <SnackbarContent ref={ref}>{props.message}</SnackbarContent>;
  },
);
SnackbarWrapper.displayName = "SnackbarWrapper";

export const SnackbarProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <NotistackSnackbarProvider
      content={(key, message) => <SnackbarWrapper key={key} message={message} />}
      autoHideDuration={4000}
      maxSnack={3}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};
