import { Box, Grid } from "@mui/material";
import React, { useMemo, useState } from "react";
import { formatDate } from "@mobiscroll/react";
import { AxiosError } from "axios";
import { VrsActionLayout } from "../../ui/components/VrsActionLayout";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectHospitals } from "../Users/userSlice";
import BillingRangePicker from "./components/BillingRangePicker";
import { BillingExport } from "./components/BillingExport";
import { reportsApiV1 } from "../../api";
import { exportCSVFile } from "../../utils/files";
import SnackbarUtils from "../../utils/SnackbarUtils";
import { BILLING_DATE_FORMAT } from "../Billing/components/constants";
import { AutoComplete } from "../../ui/components/Autocomplete";

export const BillingDashboardContainer = (): JSX.Element => {
  const [selectedHospital, setSelectedHospital] = useState<{ value: string; label: string }>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const hospitals = useAppSelector(selectHospitals);

  const onHospitalChange = (value: { value: string; label: string } | null) => {
    if (value) {
      setSelectedHospital(value);
    } else setSelectedHospital(undefined);
  };

  const onDateChange = (value: { value: Date }) => {
    if (value.value as Date) {
      const date = new Date(value.value);
      const year = date.getFullYear();
      const month = date.getMonth();
      const firstDayOfMonth = new Date(year, month, 1);
      const lastDayOfMonth = new Date(year, month + 1, 0);
      setStartDate(formatDate(BILLING_DATE_FORMAT, firstDayOfMonth));
      setEndDate(formatDate(BILLING_DATE_FORMAT, lastDayOfMonth));
    }
  };

  const onExportCSV = () => {
    if (startDate && endDate && selectedHospital) {
      reportsApiV1
        .exportActivityDataForHospitalForPeriod(startDate, endDate, Number(selectedHospital.value))
        .then((res) => {
          exportCSVFile(res.data.toString(), `Bill start:${startDate} end:${endDate}`);
          SnackbarUtils.success("File Successfully exported");
        })
        .catch((err: AxiosError) => {
          if (err.response?.data.message || err.response?.data.detail) {
            SnackbarUtils.error(err.response.data.message || err.response?.data.detail);
          } else {
            SnackbarUtils.error("File exporting failed");
          }
        });
    }
  };

  const hospitalOptions = useMemo(() => {
    return (
      hospitals.map((hosp) => ({
        value: `${hosp.id}`,
        label: hosp.name || "",
      })) || []
    );
  }, [hospitals]);

  const disableExport = !startDate || !endDate;

  return (
    <Box width="100%" py={3} px={2} gap={4} display="flex" flexDirection="row">
      <VrsActionLayout title="Billing">
        <Grid container xs={4} py={2}>
          <Grid item xs={12}>
            <AutoComplete
              options={hospitalOptions}
              placeholder="Select hospital"
              handleChange={(e, option) => onHospitalChange(option)}
              autoCompleteId="hospital-select"
              loading={false}
            />
          </Grid>

          <Grid item xs={12} sx={{ alignSelf: "center" }}>
            <BillingRangePicker onDateChange={onDateChange} />
          </Grid>
          <Grid item>
            <BillingExport onExport={onExportCSV} disabled={disableExport} />
          </Grid>
        </Grid>
      </VrsActionLayout>
    </Box>
  );
};
export default BillingDashboardContainer;
