import React from "react";
import { matchPath, useLocation } from "react-router-dom";
import { topBarTitles } from "../../constants";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Routes } from "../../routes-config";
import { setActiveNavBarTab } from "../shared/slices/uiSlice";
import { NavBarContainer } from "./containters/NavBarContainer";

const NavBarLayoutContainer = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const match = Object.values(Routes).find((route) =>
    matchPath({ path: route, end: true }, location.pathname),
  );

  const onTabClick = (index: number) => {
    dispatch(setActiveNavBarTab(index));
  };

  return (
    <NavBarContainer
      title={(match && topBarTitles[match]) || ""}
      menuItems={[]}
      onTabClick={onTabClick}
      breadcrumbItems={[]}
    />
  );
};

export default NavBarLayoutContainer;
