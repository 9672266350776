import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { MioSensorEnum } from "@veris-health/med-data-ms/lib/v1/api";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { VrsSelect } from "@veris-health/web-core";
import { MioHistoryTable } from "../components/MioHistory";
import useMioHistory from "../useMioHistory";
import { sensorOptions } from "./MioDatapointsContainer";
import { VrsPlainSearch } from "../../../ui/components/VrsPlainSearch";
import { replaceRouteParam } from "../../../routes-config";

type HubQueryParams = {
  hubSN: string;
};

export function MioHistoryContainer(): JSX.Element {
  const navigate = useNavigate();
  const { hubSN } = useParams<HubQueryParams>();
  const [hub, setHub] = useState(hubSN);
  const { data, requestSort, requestFilter, sensor, nextPage, status, sorting, error } =
    useMioHistory(hubSN);
  const tableRef = useRef<HTMLTableElement>(null);
  const theme = useTheme();

  const onSort = (key: string) => {
    requestSort(key);
  };

  const onFilter = (value: MioSensorEnum) => {
    requestFilter(value);
  };

  const onSearchByHubSn = (event: FormEvent) => {
    event.preventDefault();
    navigate(replaceRouteParam("MIO_HISTORY", ":hubSN", hub || ""));
  };

  useEffect(() => {
    if (tableRef.current) tableRef.current.scrollIntoView({ block: "start" });
  }, [sensor, sorting]);

  return (
    <Box width="100%" py={2.5} px={2} pb={1}>
      <Box display="flex" mb={2.5}>
        <Stack direction="row" spacing={2}>
          <Box>
            <Typography variant="body1">Sensors</Typography>
            <VrsSelect
              outerSx={{ width: "150px" }}
              options={sensorOptions}
              onSelected={(filterOption) => {
                onFilter(filterOption as MioSensorEnum);
              }}
              value={sensor}
            />
          </Box>
          <Box>
            <Grid container>
              <Typography variant="body1" sx={{ display: "flex" }}>
                Search by Hub Serial Number
              </Typography>
            </Grid>
            <form onSubmit={onSearchByHubSn}>
              <VrsPlainSearch onValueChange={setHub} value={hub} />
            </form>
          </Box>
        </Stack>
      </Box>
      {hubSN ? (
        <MioHistoryTable
          status={status}
          error={error}
          data={data}
          fetchMore={() => {
            if (status === "idle") {
              nextPage();
            }
          }}
          onSort={onSort}
          sorting={sorting}
          ref={tableRef}
        />
      ) : (
        <Typography
          variant="subtitle2"
          sx={{ marginTop: "0.5rem" }}
          color={theme.veris.colors.neutrals["grey-4"]}
        >
          Please enter the Hub Serial Number
        </Typography>
      )}
    </Box>
  );
}
