import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, Stack, Typography, useTheme } from "@mui/material";
import { IconName, VrsButton, VrsIcon } from "@veris-health/web-core";
import { FeedbackSurvey } from "@veris-health/virtual-doc-ms/lib/v1/api";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { HospitalInfo } from "@veris-health/user-ms/lib/v1";
import { replaceRouteParam } from "../../../routes-config";
import { SurveysTable } from "../components/SurveysTable";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { deleteSurveyAsync, fetchSurveysAsync } from "../surveysSlice";
import { exportFeedbackSurvey } from "../api/surveys";
import { exportCSVFile } from "../../../utils/files";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { useHospitals } from "../../../hooks/useHospitals";

export const SurveysList = () => {
  const [includeExpired, setIncludeExpired] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const hospitals = useHospitals(false) as HospitalInfo[];

  const onEdit = (survey: FeedbackSurvey) => {
    navigate(`${replaceRouteParam("SURVEY_DETAILS", ":surveyId", String(survey.id))}`);
  };

  const onDelete = (survey: FeedbackSurvey) => {
    dispatch(deleteSurveyAsync(survey.id));
  };

  const onExport = (survey: FeedbackSurvey, detailed?: boolean) => {
    exportFeedbackSurvey(survey.id, detailed)
      .then((res) => {
        const hospital = hospitals.find(({ id }) => survey.hospital_id === id);
        exportCSVFile(res, `${detailed ? "[Detailed] " : ""}Survey for ${hospital?.name} `);
        SnackbarUtils.success("File Successfully exported");
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.message || err.response?.data.detail) {
          SnackbarUtils.error(err.response.data.message || err.response?.data.detail);
        } else {
          SnackbarUtils.error("File exporting failed");
        }
      });
  };

  useEffect(() => {
    dispatch(fetchSurveysAsync({ expired: includeExpired }));
  }, [dispatch, includeExpired]);

  return (
    <Box display="flex" mb={2.5} mt={2} ml={2} flexDirection="column">
      <Stack ml="auto" direction="row" spacing={2}>
        <Box pb={2} pt={2.2} display="flex" justifyContent="flex-start">
          <FormControlLabel
            control={
              <Checkbox
                checked={includeExpired}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setIncludeExpired(event.target.checked)
                }
              />
            }
            label="Include expired"
          />

          <VrsButton
            buttonType="primary"
            size="small"
            onClick={() => navigate(replaceRouteParam("SURVEY_DETAILS", ":surveyId", "new"))}
          >
            <Typography pl={3} variant="bodySemibold" fontWeight={400} pt={0.25}>
              Create a new survey
            </Typography>
            <Box pr={3} pl={1} pt={0.75}>
              <VrsIcon name={IconName.Plus} size={18} stroke={theme.veris.colors.neutrals.white} />
            </Box>
          </VrsButton>
        </Box>
      </Stack>

      <SurveysTable
        fetchSurveys={() => dispatch(fetchSurveysAsync({}))}
        onEdit={onEdit}
        onDelete={onDelete}
        onExport={onExport}
      />
    </Box>
  );
};
