import { Box, CircularProgress, Typography } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import * as yup from "yup";
import { VrsButton, dateFormats, useCountDown, useTargetTimestamp } from "@veris-health/web-core";
import dayjs from "dayjs";
import { VrsFormInputField } from "../../../ui/components/VrsFormInputField";
import { StyledActiveNavigationItem } from "../../shared/styledComponents";
import { Status } from "../../shared/interfaces";
import { emailValidation } from "../../../utils/validations";

const initialValues = {
  email: "",
};

const validationSchema = yup.object().shape({
  email: emailValidation,
});

export function EmailForm({
  onSubmit,
  validationStatus,
  clearValidationError,
}: {
  onSubmit: (email: string) => void;
  validationStatus: {
    status: Status;
    errorMessage?: string;
    lockoutSeconds?: number;
    successMessage?: string;
  };
  clearValidationError: () => void;
}): JSX.Element {
  const { status, errorMessage, lockoutSeconds } = validationStatus;
  const targetTimestamp = useTargetTimestamp(lockoutSeconds);
  const remainingTime = useCountDown(targetTimestamp);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ email }) => {
      onSubmit(email);
    },
  });
  useEffect(() => {
    if (validationStatus.lockoutSeconds && remainingTime === 0) {
      clearValidationError();
    }
  }, [remainingTime]);

  const { errors, handleSubmit, handleChange, handleBlur, isValid, touched, dirty } = formik;

  return (
    <Box mx={5}>
      <StyledActiveNavigationItem variant="h3" width="fit-content">
        Forgot password
      </StyledActiveNavigationItem>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <Box mt={10}>
            <Box>
              <VrsFormInputField
                name="email"
                type="text"
                label="Email"
                value={formik.values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.email)}
                isValidField={formik.values.email.length && !errors.email}
                helperText={errors.email}
                onFocus={() => errorMessage && clearValidationError()}
              />
            </Box>
            <Box my={1} display="flex" flexDirection="column">
              <Typography
                variant="caption"
                color={(theme) => theme.veris.colors.neutrals["grey-4"]}
              >
                After entering your email adress, you will receive a verification code.
              </Typography>

              {status === "failed" && errorMessage && (
                <Typography
                  my={1}
                  color={(theme) => theme.veris.colors.errors.normal}
                  variant="caption"
                >
                  {errorMessage}
                  {remainingTime &&
                    `Please try again after ${dayjs
                      .duration(remainingTime)
                      .format(dateFormats["m:ss"])} minutes.`}
                </Typography>
              )}
            </Box>
            <Box my={4}>
              <VrsButton
                buttonType="primary"
                disabled={!isValid || status === "loading" || !touched || !dirty}
                type="submit"
              >
                Next
                {status === "loading" && (
                  <Box px={1}>
                    <CircularProgress size={16} />
                  </Box>
                )}
              </VrsButton>
            </Box>
          </Box>
        </form>
      </FormikProvider>
    </Box>
  );
}
