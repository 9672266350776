import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";
import { SupportChatStatus } from "@veris-health/communication-ms/lib/v1";
import {
  AvailableAttachmentFiltering,
  CommunicationState,
  NormalizedAttachments,
  NormalizedChatMessages,
  VrsChatMessage,
  VrsChatMessageReceivedEvent,
  VrsChatThreadCreatedEvent,
  VrsTypingIndicatorReceived,
} from "./interfaces";
import {
  addChatThreadAsync,
  createSupportThreadAsync,
  downloadAttachmentSasAsync,
  fetchAttachmentsForThreadAsync,
  fetchChatConfiguration,
  fetchChatThreadsCombinedAsync,
  getChatThreadMessagesAsync,
  updateThreadAsync,
  updateSupportChatAsync,
  fetchChatThreads,
  updateSupportChatThreadAsync,
} from "./asyncThunks";
import { localizedLogout, logout } from "../../shared/slices/authSlice";

export const messagesAdapter = createEntityAdapter<NormalizedChatMessages>();
export const attachmentsAdapter = createEntityAdapter<NormalizedAttachments>();

const initialState: CommunicationState = {
  chatApiEndpoint: "",
  chatUserAccessToken: "",
  chatUserId: "",
  chatDisplayName: "",
  chatMessages: {
    status: "idle",
    items: messagesAdapter.getInitialState(),
  },
  chatThreads: {
    total: 0,
    threads: [],
    status: "idle",
    unreadThreadStatus: {},
  },
  chatSupportUpdate: {
    status: "idle",
  },
  currentChatThreadStatus: "idle",
  status: "idle",
  attachments: {
    attachmentsStatus: "idle",
    attachmentFilteringType: "all",
    items: attachmentsAdapter.getInitialState(),
  },
  newMessageNotification: { threadId: undefined },
};

export const communicationSlice = createSlice({
  name: "communication",
  initialState,
  reducers: {
    setThreadId: (state, { payload }: PayloadAction<{ threadId: string }>) => {
      state.currentThreadId = payload.threadId;
      state.currentChatThreadStatus = "idle";
    },
    resetThreadId: (state) => {
      state.currentThreadId = undefined;
      state.currentChatThreadStatus = "idle";
    },
    addChatThreadMessage: (state, { payload }: PayloadAction<VrsChatMessageReceivedEvent>) => {
      const { id, version, senderDisplayName, metadata, message, sender, createdOn, threadId } =
        payload;
      const vrsMessage: VrsChatMessage = {
        id,
        version,
        type: "text",
        content: {
          message,
        },
        senderDisplayName,
        sender,
        createdOn,
        metadata,
        threadId,
      };

      if (state.chatMessages.items.ids.includes(threadId)) {
        const items = state.chatMessages.items.entities[threadId];
        if (items && items.messages) {
          items.messages.unshift(vrsMessage);
          messagesAdapter.updateOne(state.chatMessages.items, {
            id: threadId,
            changes: { messages: items.messages },
          });
        }
      }
      const threadIndex = state.chatThreads.threads.findIndex((thread) => thread.id === threadId);
      if (threadIndex !== -1) {
        state.chatThreads.threads[threadIndex].last_message_received_on = createdOn;
      }
      state.typingIndicator = undefined;

      const targetThread = state.chatThreads.threads.find(
        (chatThread) => chatThread.id === threadId,
      );
      if (targetThread) {
        const updatedThread = {
          ...targetThread,
          support_chat_info: {
            ...targetThread?.support_chat_info,
            chat_status: SupportChatStatus.Pending,
          },
        };
        state.chatThreads.threads = state.chatThreads.threads.filter(
          (el) => el.id !== targetThread?.id,
        );

        state.chatThreads.threads.unshift(updatedThread);
      }
    },
    addChatThread: (state, { payload }: PayloadAction<VrsChatThreadCreatedEvent>) => {
      state.currentThreadId = payload.threadId;
    },
    setAttachmentTypeFiltering: (
      state,
      { payload }: PayloadAction<AvailableAttachmentFiltering>,
    ) => {
      state.attachments.attachmentFilteringType = payload;
    },
    setTypingIndicator: (state, action: PayloadAction<VrsTypingIndicatorReceived | undefined>) => {
      state.typingIndicator = action.payload;
    },
    setThreadUnreadStatus: (state, { payload }: PayloadAction<string>) => {
      state.chatThreads.unreadThreadStatus[payload] = state.currentThreadId !== payload;
    },
    resetCurrentChatThreadStatus: (state) => {
      state.currentChatThreadStatus = "idle";
    },
    resetMessages: (state, { payload }: PayloadAction<string>) => {
      if (state.chatMessages.items.ids.includes(payload)) {
        messagesAdapter.setOne(state.chatMessages.items, {
          id: payload,
          messages: [],
          isLastPage: true,
          continuationToken: undefined,
        });
      }
    },
    setNewMessageNotification: (
      state,
      { payload }: PayloadAction<{ threadId: string | undefined }>,
    ) => {
      state.newMessageNotification = {
        threadId: payload.threadId,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatConfiguration.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChatConfiguration.fulfilled, (state, { payload }) => {
        state.chatApiEndpoint = payload.url;
        state.chatUserAccessToken = payload.token;
        state.chatUserId = payload.user_id;
        state.chatDisplayName = payload.chatDisplayName;
        state.status = "idle";
      })
      .addCase(fetchChatConfiguration.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchChatThreadsCombinedAsync.pending, (state) => {
        state.chatThreads.status = "loading";
      })
      .addCase(fetchChatThreadsCombinedAsync.fulfilled, (state, { payload }) => {
        const vrsChatThreads = payload;
        state.chatThreads.total = vrsChatThreads.total;
        state.chatThreads.threads = vrsChatThreads.items;
        state.chatThreads.status = "idle";
      })
      .addCase(fetchChatThreadsCombinedAsync.rejected, (state) => {
        state.chatThreads.status = "failed";
      })
      .addCase(getChatThreadMessagesAsync.pending, (state) => {
        state.chatMessages.status = "loading";
      })
      .addCase(fetchChatThreads.fulfilled, (state, { payload }) => {
        state.chatThreads.threads = payload.offset
          ? state.chatThreads.threads.concat(payload.items)
          : payload.items;
        state.chatThreads.total = payload.total;
        state.chatThreads.status = "idle";
      })
      .addCase(fetchChatThreads.rejected, (state) => {
        state.chatThreads.status = "failed";
      })
      .addCase(fetchChatThreads.pending, (state) => {
        state.chatThreads.status = "loading";
      })
      .addCase(getChatThreadMessagesAsync.fulfilled, (state, { payload }) => {
        if (state.chatMessages.items.ids.includes(payload.id)) {
          const oldItems = state.chatMessages.items.entities[payload.id];
          if (oldItems && oldItems.messages) {
            oldItems.messages.push(...payload.messages);
            messagesAdapter.upsertOne(state.chatMessages.items, {
              ...payload,
              messages: uniqBy(oldItems.messages, (message) => message.id),
            });
          }
        } else {
          messagesAdapter.addOne(state.chatMessages.items, payload);
        }
        state.chatMessages.status = "idle";
      })
      .addCase(getChatThreadMessagesAsync.rejected, (state) => {
        state.chatMessages.status = "failed";
      })
      .addCase(createSupportThreadAsync.fulfilled, (state, { payload }) => {
        const index = state.chatThreads.threads.findIndex((thread) => thread.id === payload.id);
        if (index === -1) {
          state.chatThreads.threads.unshift({ ...payload });
        }
        state.currentThreadId = payload.id;
        state.currentChatThreadStatus = "idle";
      })
      .addCase(updateSupportChatThreadAsync.fulfilled, (state, { payload, meta }) => {
        const threadToUpdate = state.chatThreads.threads.find(
          (thread) => thread.id === meta.arg.threadId,
        );
        if (threadToUpdate) {
          const updatedThread = {
            ...threadToUpdate,
            support_chat_info: {
              ...threadToUpdate.support_chat_info,
              chat_status: payload.support_chat_status,
            },
          };
          state.chatThreads.threads = state.chatThreads.threads.filter(
            (el) => el.id !== meta.arg.threadId,
          );
          if (
            updatedThread &&
            updatedThread.support_chat_info.chat_status === SupportChatStatus.Resolved
          )
            state.chatThreads.threads.push(updatedThread);

          if (
            updatedThread &&
            updatedThread.support_chat_info.chat_status === SupportChatStatus.Pending
          )
            state.chatThreads.threads.unshift(updatedThread);
        }
      })
      .addCase(addChatThreadAsync.fulfilled, (state, { payload }) => {
        const index = state.chatThreads.threads.findIndex((thread) => thread.id === payload.id);
        if (index === -1) {
          state.chatThreads.threads.unshift(payload);
        }
      })
      .addCase(downloadAttachmentSasAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          const { objectUrl, messageId, threadId } = payload;
          const threadMessages = state.chatMessages.items.entities[threadId];
          if (threadMessages) {
            const index = threadMessages.messages.findIndex(({ id }) => id === messageId);
            if (index >= 0) {
              threadMessages.messages[index] = {
                ...threadMessages.messages[index],
                metadata: {
                  ...threadMessages.messages[index].metadata,
                  objectUrl,
                },
              };
            }
            messagesAdapter.setOne(state.chatMessages.items, threadMessages);
          }
        }
      })
      .addCase(fetchAttachmentsForThreadAsync.pending, (state) => {
        state.attachments.attachmentsStatus = "loading";
      })
      .addCase(fetchAttachmentsForThreadAsync.fulfilled, (state, { payload }) => {
        state.attachments.attachmentsStatus = "idle";
        const currentAttachments = attachmentsAdapter
          .getSelectors()
          .selectById(state.attachments.items, payload.id);

        if (currentAttachments) {
          attachmentsAdapter.updateOne(state.attachments.items, {
            id: payload.id,
            changes: payload,
          });
        } else attachmentsAdapter.addOne(state.attachments.items, payload);
      })
      .addCase(fetchAttachmentsForThreadAsync.rejected, (state) => {
        state.attachments.attachmentsStatus = "failed";
      })
      .addCase(updateSupportChatAsync.rejected, (state) => {
        state.chatSupportUpdate.status = "failed";
      })
      .addCase(updateSupportChatAsync.pending, (state) => {
        state.chatSupportUpdate.status = "loading";
      })
      .addCase(updateSupportChatAsync.fulfilled, (state) => {
        state.chatSupportUpdate.status = "idle";
      })
      .addCase(updateThreadAsync.fulfilled, (state, { payload }) => {
        const threadIndex = state.chatThreads.threads.findIndex(
          (thread) => thread.id === payload.id,
        );
        if (threadIndex > -1) {
          state.chatThreads.threads[threadIndex] = {
            ...state.chatThreads.threads[threadIndex],
            ...payload,
          };
        }
      })
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(localizedLogout, () => {
        return initialState;
      });
  },
});

// Actions

export const {
  setThreadId,
  resetThreadId,
  addChatThreadMessage,
  addChatThread,
  setAttachmentTypeFiltering,
  setTypingIndicator,
  setThreadUnreadStatus,
  resetCurrentChatThreadStatus,
  resetMessages,
  setNewMessageNotification,
} = communicationSlice.actions;

export default communicationSlice.reducer;
