import { TokenResponse } from "@veris-health/user-ms/lib/v1";
import { refreshExpiredToken } from "../features/shared/slices/api/authApi";

let refreshTokenPromise: Promise<TokenResponse> | null = null;

export const refreshToken = async (token: string): Promise<TokenResponse> => {
  if (!refreshTokenPromise) {
    refreshTokenPromise = refreshExpiredToken(token)
      .then((response) => {
        refreshTokenPromise = null;
        return response;
      })
      .catch((error) => {
        refreshTokenPromise = null;
        return Promise.reject(error);
      });
  }
  return refreshTokenPromise;
};
