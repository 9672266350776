import {
  StaffLoginOTPRequest,
  TokenResponse,
  RequestStaffRegisterOTP,
  StaffLoginRequest,
  RequestStaffRegister,
  ChangePasswordRequest,
  ResetPasswordRequest,
  ExpiredPasswordResetRequest,
  ExpiredPasswordOtpRequest,
  ValidateOtpStaffRequest,
  RequestInviteStaffUser,
  ResetPasswordOtpRequest,
  StaffType,
} from "@veris-health/user-ms/lib/v1";
import {
  changeCredentialsStaffApiV1,
  loginStaffApiV1,
  refreshTokenApiV1,
  registerStaffApiV1,
} from "../../../../api";

export const loginTechStaff = async ({
  email,
  password,
  type = StaffType.TechStaff,
}: StaffLoginOTPRequest): Promise<unknown> => {
  const response = await loginStaffApiV1.staffUserLoginOtp({ email, password, type });
  return response.data;
};

export const loginOTP = async ({
  email,
  password,
  otp,
  type = StaffType.TechStaff,
}: StaffLoginRequest): Promise<TokenResponse> => {
  const response = await loginStaffApiV1.staffUserLogin({ email, password, otp, type });
  return response.data;
};

export const registerTechStaff = async ({
  ...params
}: RequestStaffRegisterOTP): Promise<unknown> => {
  const response = await registerStaffApiV1.verifyAndGetOtp({
    ...params,
  });
  return response.data;
};

export const registerTechStaffOtp = async ({
  ...params
}: RequestStaffRegister): Promise<TokenResponse> => {
  const response = await registerStaffApiV1.verifyAndRegister({
    ...params,
  });
  return response.data;
};

export const inviteStaffUser = async ({ ...params }: RequestInviteStaffUser): Promise<unknown> => {
  const response = await registerStaffApiV1.inviteStaffUser({
    ...params,
  });
  return response.data;
};

export const refreshExpiredToken = async (refreshToken: string): Promise<TokenResponse> => {
  const response = await refreshTokenApiV1.refreshToken({ refreshToken });
  return response.data;
};

export const changePassword = async (
  id: number,
  data: ChangePasswordRequest,
): Promise<TokenResponse> => {
  const response = await changeCredentialsStaffApiV1.changePassword(id, data);
  return response.data;
};

export const getOTP = async (data: ResetPasswordOtpRequest): Promise<unknown> => {
  const response = await changeCredentialsStaffApiV1.verificationCodeStaff(data);
  return response.data;
};

export const resetPassword = async (data: ResetPasswordRequest): Promise<{ status: string }> => {
  const response = await changeCredentialsStaffApiV1.resetMedStaffPassword(data);
  return response.data as { status: string };
};

export const validateOTP = async (data: ValidateOtpStaffRequest): Promise<unknown> => {
  const response = await changeCredentialsStaffApiV1.validateOtpStaff(data);
  return response.data;
};

export const passwordExpired = async (
  data: ExpiredPasswordOtpRequest,
): Promise<{ status?: string }> => {
  const response = await changeCredentialsStaffApiV1.verificationCodeEmailExpiredPassword(data);
  return response.data;
};

export const passwordExpiredOtpValidation = async (
  data: ExpiredPasswordResetRequest,
): Promise<{ status?: string }> => {
  const response = await changeCredentialsStaffApiV1.resetMedStaffExpiredPassword(data);
  return response.data;
};
