import React, { useEffect, useRef } from "react";
import { MioSensorEnum } from "@veris-health/med-data-ms/lib/v1/api";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { capitalize } from "lodash";
import { VrsSelect } from "@veris-health/web-core";
import useMioDataPoints from "../useMioDataPoints";
import { useSelectedUser } from "../../../hooks/useSelectedUser";
import { MioTable } from "../components/MioData";

export const sensorOptions = Object.values(MioSensorEnum).map((key) => ({
  value: key,
  label: capitalize(key.replace(/_/g, " ")),
}));

export function MioDataPointsContainer(): JSX.Element {
  const { user } = useSelectedUser();
  const { data, requestSort, requestFilter, sensor, nextPage, status, sorting } = useMioDataPoints(
    user?.id,
  );
  const tableRef = useRef<HTMLTableElement>(null);
  const theme = useTheme();

  const onSort = (key: string) => {
    requestSort(key);
  };

  const onFilter = (value: MioSensorEnum) => {
    requestFilter(value);
  };

  useEffect(() => {
    if (tableRef.current) tableRef.current.scrollIntoView({ block: "start" });
  }, [sensor, sorting]);

  if (!user || !user.mio_hub_serial_number) {
    return <></>;
  }

  return (
    <Box width="100%" py={2.5} px={2} pb={1}>
      <Box display="flex" mb={2.5}>
        <Stack direction="row" spacing={2}>
          <Box>
            <Typography variant="body1">Symptoms</Typography>
            <VrsSelect
              outerSx={{ width: "150px" }}
              options={sensorOptions}
              onSelected={(filterOption) => {
                onFilter(filterOption as MioSensorEnum);
              }}
              value={sensor}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1">Current Hub SN</Typography>
            <Typography
              variant="subtitle2"
              sx={{ marginTop: "0.5rem" }}
              color={theme.veris.colors.neutrals["grey-4"]}
            >
              {user.mio_hub_serial_number}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1">User ID</Typography>
            <Typography
              variant="subtitle2"
              sx={{ marginTop: "0.5rem" }}
              color={theme.veris.colors.neutrals["grey-4"]}
            >
              {user.id}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <MioTable
        status={status}
        data={data}
        fetchMore={() => {
          if (status === "idle") {
            nextPage();
          }
        }}
        onSort={onSort}
        sorting={sorting}
        ref={tableRef}
      />
    </Box>
  );
}
