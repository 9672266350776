import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { IconName, VrsButton, VrsIcon } from "@veris-health/web-core";
import { InviteUser } from "../components/InviteUser";

export function InviteUserContainer(): JSX.Element {
  const [showInviteUserPopup, setInviteUserModal] = useState(false);
  const theme = useTheme();

  return (
    <Box pb={2} display="flex" justifyContent="flex-start">
      <VrsButton buttonType="primary" size="small" onClick={() => setInviteUserModal(true)}>
        <Typography pl={3} variant="bodySemibold" fontWeight={400} pt={0.25}>
          Invite User
        </Typography>
        <Box pr={3} pl={1} pt={0.75}>
          <VrsIcon name={IconName.Plus} size={18} stroke={theme.veris.colors.neutrals.white} />
        </Box>
      </VrsButton>
      {showInviteUserPopup && (
        <InviteUser
          open={showInviteUserPopup}
          onClose={() => {
            setInviteUserModal(false);
          }}
        />
      )}
    </Box>
  );
}
