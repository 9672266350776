import React from "react";
import { Grid, Typography } from "@mui/material";
import { VrsActionLayout } from "../../ui/components/VrsActionLayout";
import { ImplantDataUpload } from "./components/ImplantDataUpload";

export const ImplantContainer = () => {
  return (
    <VrsActionLayout title="Implant Data">
      <Grid item xs={12} p={2}>
        <ImplantDataUpload />
        <Typography
          variant="bodySemibold"
          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
          fontWeight={400}
        >
          Import Implant data from a CSV file
        </Typography>
      </Grid>
    </VrsActionLayout>
  );
};
