import React, { useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { AdminDetailUserInfoResponse, TechRoleEnum } from "@veris-health/user-ms/lib/v1";
import { useAppSelector } from "../hooks/useAppSelector";
import { selectIsLoggedIn, selectUserId } from "../features/shared/slices/authSlice";
import { Status } from "../features/shared/interfaces";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { getHospitalsAsync } from "../features/Users/userSlice";
import { usersApiV1 } from "../api";

export const ProfileContextData = React.createContext<{
  profileInfo?: AdminDetailUserInfoResponse;
  authenticatedUserRoles: string[];
  status?: Status;
}>({
  profileInfo: undefined,
  authenticatedUserRoles: [],
  status: undefined,
});

export const ProfileContext = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [profileInfo, setProfileInfo] = useState<AdminDetailUserInfoResponse>();
  const [status, setStatus] = useState<Status | undefined>(undefined);
  const authenticatedUserRoles = useRef<string[]>([]);

  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const userId = useAppSelector(selectUserId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoggedIn && userId) {
      setSentryContext(userId);
      setStatus("loading");
      usersApiV1
        .adminGetSelfUserInfo(userId)
        .then(({ data }) => {
          const roles = data.user_roles?.map((role) => role.role_name) || [];

          authenticatedUserRoles.current = roles;
          setProfileInfo({ ...data, user_roles: data.user_roles || [] });
          setStatus("idle");
        })
        .catch(() => {
          setStatus("failed");
        })
        .finally(() => {
          if (
            authenticatedUserRoles.current.length > 0 &&
            !authenticatedUserRoles.current.includes(TechRoleEnum.Implantmanufacturer)
          ) {
            dispatch(getHospitalsAsync());
          }
        });
    } else {
      // Reset the state when user logs out
      setProfileInfo(undefined);
      authenticatedUserRoles.current = [];
      setStatus(undefined);
    }
  }, [isLoggedIn, userId, dispatch]);

  return (
    <ProfileContextData.Provider
      value={{ profileInfo, status, authenticatedUserRoles: authenticatedUserRoles.current }}
    >
      {children}
    </ProfileContextData.Provider>
  );
};

export const useProfile = (): AdminDetailUserInfoResponse | undefined =>
  useContext(ProfileContextData).profileInfo;

export const useSuperAdmin = (): boolean =>
  useContext(ProfileContextData).authenticatedUserRoles.includes(TechRoleEnum.Superadmin);
export const useAuthenticatedUserRoles = (): string[] =>
  useContext(ProfileContextData).authenticatedUserRoles;

export const useContextStatus = (): Status | undefined => useContext(ProfileContextData).status;

function setSentryContext(id: string) {
  Sentry.setContext("userId", { id });
}
