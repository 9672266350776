import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { FileAttachment, ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import { VrsAttachmentListItem } from "../VrsAttachmentListItem";
import { VrsFileAttachment } from "../../store";
import { Status } from "../../../shared/interfaces";

export interface VrsAttachmentsTabProps {
  attachments: VrsFileAttachment[];
  onDownloadAttachment: (value: FileAttachment) => void;
  attachmentsStatus: Status;
  participants: Record<string, ThreadParticipantInfo>;
  isPrivateThread: boolean;
}

export const VrsAttachmentsTab = ({
  attachments,
  participants,
  onDownloadAttachment,
  attachmentsStatus,
  isPrivateThread,
}: VrsAttachmentsTabProps): JSX.Element => {
  const findDisplayName = (userId: number): string => {
    return participants[userId] ? participants[userId]?.display_name || "Unknown" : "Unknown";
  };

  return (
    <Box
      sx={{
        overflowY: "scroll",
        maxHeight: "60vh",
      }}
      id="attachments-scrollable-container"
    >
      {attachmentsStatus === "loading" && (
        <Box p={1} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {attachments.length > 0 &&
        attachmentsStatus !== "failed" &&
        attachments.map((attachment) => (
          <VrsAttachmentListItem
            sentBy={findDisplayName(attachment.uploaded_by_user_id)}
            onDownloadAttachment={() => onDownloadAttachment(attachment)}
            attachment={attachment}
            key={attachment.file_name}
            isPrivateThread={isPrivateThread}
          />
        ))}
      {attachments.length === 0 && attachmentsStatus === "idle" && (
        <Typography
          variant="body"
          color={(theme) => theme.veris.colors.neutrals["grey-3"]}
          textAlign="center"
          pt={2}
        >
          There are no attachments in this chat.
        </Typography>
      )}
    </Box>
  );
};
