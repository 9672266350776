import {
  Reporter,
  ListNotificationsResponse,
  MarkAsRead,
  AppApiV1ModelsNotificationModelsStatusResponse as NotificationStatusChange,
  ApplicationType,
  Platform,
  NotificationView,
  NotificationPayloadView,
} from "@veris-health/communication-ms/lib/v1";
import { notificationsApiV1 } from "../../../api";

export const fetchAllNotifications = async (
  userId: number,
  read?: boolean,
  reportedBy?: Reporter,
  offset?: number,
  limit?: number,
): Promise<ListNotificationsResponse> => {
  const response = await notificationsApiV1.listNotificationsForRecipient(
    userId,
    read,
    reportedBy,
    undefined,
    undefined,
    limit ?? 10,
    offset,
  );
  return response.data;
};

export const markNotificationAsRead = async (
  userId: number,
  markAsRead: MarkAsRead,
): Promise<NotificationStatusChange> => {
  const response = await notificationsApiV1.markAsRead(userId, markAsRead);
  return response.data;
};

export const registerFirebaseToken = async (
  userId: number,
  firebaseToken: string,
): Promise<NotificationStatusChange> => {
  const response = await notificationsApiV1.registerNotificationsToken(userId, {
    app_type: ApplicationType.WebTechStaff,
    platform: Platform.Web,
    token: firebaseToken,
  });
  return response.data;
};

export const getNotificationDetails = async ({
  userId,
  vrsNotificationId,
}: {
  userId?: string;
  vrsNotificationId?: string;
}): Promise<NotificationView> => {
  const { data: responseData } = await notificationsApiV1.getNotificationDetails(
    Number(userId),
    Number(vrsNotificationId),
  );
  const { payload, type } = responseData;
  const { data, text, title } = payload;

  return {
    ...responseData,
    payload: {
      title,
      text,
      data: {
        ...data,
        type,
      } as Extract<"data", NotificationPayloadView>,
    },
  };
};

export const unregisterFirebaseToken = async (userId: number): Promise<unknown> => {
  const response = await notificationsApiV1.unregisterToken(userId, {
    app_type: ApplicationType.WebTechStaff,
    platform: Platform.Web,
  });

  return response.data;
};
