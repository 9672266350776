import { getMessaging, Messaging, onMessage, MessagePayload } from "firebase/messaging";
import { useEffect } from "react";
import { get } from "lodash";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../../api/firebase-config";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { Routes } from "../../../routes-config";

interface MessageEventData {
  type: "backgroundMessage";
  payload: MessagePayload;
}

export const useFirebaseMessaging = ({
  onFirebaseMessage,
}: {
  onFirebaseMessage: (id?: string) => void;
}): {
  firebaseMessaging: Messaging;
} => {
  const firebaseInstance = initializeApp(firebaseConfig);
  const firebaseMessaging = getMessaging(firebaseInstance);

  const handleBackgroundMessages = (event: MessageEvent<MessageEventData>) => {
    if (event.data.type === "backgroundMessage") {
      const notificationId = get(event, "data.payload.data.notification_id");
      if (notificationId) {
        onFirebaseMessage(notificationId);
      } else {
        onNewMessageNotification(true);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onMessage(firebaseMessaging, ({ data }) => {
      const vrsNotificationId = data && data.notification_id;
      if (vrsNotificationId) onFirebaseMessage(vrsNotificationId);
      else {
        onNewMessageNotification();
      }
    });

    navigator.serviceWorker.addEventListener("message", handleBackgroundMessages);

    return () => {
      unsubscribe();
      navigator.serviceWorker.removeEventListener("message", handleBackgroundMessages);
    };
  }, []);

  return {
    firebaseMessaging,
  };
};

function onNewMessageNotification(persist?: boolean) {
  if (!window.location.pathname.includes(Routes.SUPPORT)) {
    if (persist) {
      SnackbarUtils.toast("New chat message received!", "success", persist);
    }
    SnackbarUtils.success("New chat message received!");
  }
}
