import React, { useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useSelectedUser } from "../../../hooks/useSelectedUser";
import useHubHistory from "../useHubHistory";
import { HubHistoryTable } from "../components/HubHistory";

export function HubsContainer(): JSX.Element {
  const { user } = useSelectedUser();

  const { data, nextPage, status } = useHubHistory(user?.id);
  const tableRef = useRef<HTMLTableElement>(null);

  if (!user || !user.mio_hub_serial_number) {
    return (
      <Typography variant="bodySemibold" mt={1} ml={1}>
        MioHub user was not found
      </Typography>
    );
  }

  return (
    <Box width="100%" py={2.5} px={2} pb={1}>
      <Box display="flex" mb={2.5}>
        <Stack direction="row" spacing={2}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="h5"> {user.name}</Typography>
          </Box>
        </Stack>
      </Box>
      <HubHistoryTable
        data={data}
        fetchMore={() => {
          if (status === "idle") {
            nextPage();
          }
        }}
        ref={tableRef}
      />
    </Box>
  );
}
