import React from "react";
import { useTheme } from "@mui/material";
import { StyledTextField } from "../../Users/components/SetHospitalAndCareTeamForm";

type NewTagInputProps = {
  hospital: string | undefined;
  newTag: string;
  onCreate: (tag: string) => void;
  onNewTagChange: (value: string) => void;
};

export const NewTagInput = ({
  hospital,
  newTag,
  onCreate,
  onNewTagChange,
}: NewTagInputProps): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      <StyledTextField
        sx={{ paddingTop: theme.spacing(0.8), width: "100%" }}
        placeholder={!hospital ? "Select a hospital" : "Type additional predefined tags"}
        size="small"
        variant="standard"
        disabled={!hospital}
        name="newTag"
        type="text"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onNewTagChange(event.target.value);
        }}
        value={newTag}
        onKeyPress={(e) => {
          if (e.key === "Enter" && newTag.trim().length > 0) {
            e.preventDefault();
            onCreate(newTag);
          }
        }}
      />
    </>
  );
};
