import { VrsAlert, SeverityLevel } from "@veris-health/web-core";
import { useSnackbar, WithSnackbarProps } from "notistack";
import React from "react";

let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator = (): null => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  success(msg: string): void {
    this.toast(msg, "success");
  },
  warning(msg: string): void {
    this.toast(msg, "warning");
  },
  info(msg: string): void {
    this.toast(msg, "info");
  },
  error(msg: string): void {
    this.toast(msg, "error");
  },
  toast(msg: string, severity: SeverityLevel = "success", persist = false): void {
    useSnackbarRef.enqueueSnackbar(<VrsAlert message={msg} severity={severity} />, {
      persist,
      onClose: () => useSnackbarRef.closeSnackbar(),
    });
  },
};
