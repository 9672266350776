import { TableHead, TableRow, TableSortLabel, Typography, useTheme } from "@mui/material";
import React from "react";
import { VrsTooltip } from "@veris-health/web-core";
import { Order } from "../../../utils/sorting";
import { StyledTableCell } from "../Tables/shared/StyledTableElements";
import { MioColumn } from "../../../features/MioDatapoints/config/mioData";
import { HubColumn } from "../../../features/MioDatapoints/config/hubHistory";
import { MioHistoryColumn } from "../../../features/MioDatapoints/config/mioHistory";

export const enum TableColumnNames {
  Name = "Name",
  Active = "Active",
  Email = "Email",
  UserType = "Type",
  Hospitals = "Hospitals",
  LockStatus = "Lock Status",
  Actions = "Action",
  Roles = "Roles",
  InvitationExpiration = "Invitation Expiration",
  PhoneNumber = "Phone Number",
  Title = "Title",
  Id = "Id",
  Description = "Description",
  Link = "Link",
  Symptom = "Symptom",
  DateUploaded = "Date Uploaded",
  DateCreated = "Date Created",
  ExpiresAt = "Expires At",
  StartDate = "Start Date",
  SerialNumber = "Hub SN",
  Hospital = "Hospital",
  AccountStatus = "Account Status",
  Category = "Category",
  ReadTime = "Read Time",
}

export interface Column {
  id: TableColumnNames;
  label: TableColumnNames;
  orderBy?: string;
  description?: string;
}

export interface VrsSortedTableHeaderProps {
  columns: Column[] | MioColumn[] | HubColumn[] | MioHistoryColumn[];
  setOrder?: (field: Order) => void;
  setOrderBy?: (direction: string) => void;
  order?: Order;
  orderBy?: string;
}

export function VrsSortedTableHeader({
  columns,
  setOrder,
  setOrderBy,
  order,
  orderBy,
}: VrsSortedTableHeaderProps): JSX.Element {
  const theme = useTheme();
  return (
    <TableHead
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: theme.veris.colors.neutrals.white,
        borderBottom: `2px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        zIndex: 100,
      }}
    >
      <TableRow>
        {columns.map((column) => (
          <StyledTableCell key={column.id}>
            <TableSortLabel
              hideSortIcon={!column.orderBy}
              active={order && orderBy === column.orderBy}
              direction={orderBy === column.orderBy ? order : "asc"}
              onClick={() => {
                if (column.orderBy) {
                  if (setOrderBy) {
                    setOrderBy(column.orderBy);
                  }
                  if (setOrder) {
                    setOrder(order === "asc" ? "desc" : "asc");
                  }
                }
              }}
              style={{ cursor: column.orderBy ? "pointer" : "default" }}
            >
              {column.description ? (
                <VrsTooltip
                  title={
                    <Typography variant="description" sx={{ textTransform: "capitalize" }}>
                      {column.description}
                    </Typography>
                  }
                  bcgcolor={theme.veris.colors.neutrals.black}
                >
                  <Typography
                    variant="bodyMedium"
                    component="span"
                    sx={{
                      textTransform: "uppercase",
                      color: theme.veris.colors.neutrals["grey-mid"],
                    }}
                  >
                    {column.label}
                  </Typography>
                </VrsTooltip>
              ) : (
                <Typography
                  variant="bodyMedium"
                  component="span"
                  sx={{
                    textTransform: "uppercase",
                    color: theme.veris.colors.neutrals["grey-mid"],
                  }}
                >
                  {column.label}
                </Typography>
              )}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
