import { useEffect, useState } from "react";
import { LimitOffsetPageMioHistory } from "@veris-health/med-data-ms/lib/v1/api";
import { sensorDataApiV1 } from "../../api";
import { Status } from "../shared/interfaces";

function useHubHistory(
  userId?: number,
  limit = 25,
): {
  data: LimitOffsetPageMioHistory;
  nextPage: () => void;
  status: Status;
} {
  const [data, setData] = useState<LimitOffsetPageMioHistory>({ items: [], total: 0 });

  const [offset, setOffset] = useState<number>(0);
  const [status, setStatus] = useState<Status>("idle");

  const nextPage = () => {
    setOffset((prevOffset) => prevOffset + limit);
  };

  async function fetchData(id: number) {
    setStatus("loading");
    try {
      const response = await sensorDataApiV1.adminGetViewAllHubHistory(id, limit, offset);

      setData({
        ...response.data,
        items: offset > 0 ? [...data.items, ...response.data.items] : response.data.items,
      });
      setOffset(response.data.offset || 0);
      setStatus("idle");
    } catch (e) {
      setStatus("failed");
    }
  }

  useEffect(() => {
    if (userId) {
      fetchData(userId);
    }
  }, [userId, limit, offset]);

  return {
    data,
    nextPage,
    status,
  };
}

export default useHubHistory;
