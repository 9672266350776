import React, { useState, useRef } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import { IconName, VrsIconButton, verisColors } from "@veris-health/web-core";

export interface VrsChatUploadMenuProps {
  onUploadFromComputer?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export function VrsChatUploadMenu({
  onUploadFromComputer,
  disabled,
}: VrsChatUploadMenuProps): JSX.Element {
  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
    setOpenMenu(false);
  };
  return (
    <Box ref={anchorRef}>
      <VrsIconButton
        iconProps={
          openMenu
            ? { name: IconName.CloseIcon }
            : {
                name: IconName.Plus,
                stroke: disabled ? verisColors.neutrals["grey-3"] : verisColors.amethyst.normal,
              }
        }
        onClick={() => setOpenMenu(true)}
        disabled={disabled}
      />
      <Menu
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openMenu}
        anchorEl={anchorRef.current}
        onClose={() => setOpenMenu(false)}
      >
        <MenuItem
          disabled
          sx={{
            fontSize: (theme) => theme.typography.subtitle2.fontSize,
            fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
          }}
        >
          Attach
        </MenuItem>
        <MenuItem
          onClick={handleUpload}
          sx={{
            fontSize: (theme) => theme.typography.subtitle2.fontSize,
            fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
            color: (theme) => theme.veris.colors.neutrals["grey-4"],
          }}
        >
          Upload from your computer
        </MenuItem>
      </Menu>
      <input
        ref={hiddenFileInput}
        onChange={onUploadFromComputer}
        type="file"
        hidden
        accept=".jpg, .jpeg, .svg, .png, .pdf, .doc, .docx, .xls, .xlsx, .rtf, .ppt, .pptx, .odf"
      />
    </Box>
  );
}
