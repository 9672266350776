import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import dayjs from "dayjs";
import PeriodPicker from "./components/PeriodPicker";
import { dataExportApiV1 } from "../../api";
import SnackbarUtils from "../../utils/SnackbarUtils";
import { downloadZipFile } from "../../utils/files";

interface Range {
  start: string;
  end: string;
}

export const DeIdentifiedMioExport = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<Range | undefined>();

  const onExport = () => {
    if (selectedRange) {
      setLoading(true);
      dataExportApiV1
        .exportDeIdentifiedDevicesData(
          dayjs(selectedRange.start).utc().toISOString(),
          dayjs(selectedRange.end).utc().toISOString(),
        )
        .then(async (res) => {
          downloadZipFile(res.data as BlobPart);
          SnackbarUtils.success("File Successfully downloaded");
        })
        .catch(() => {
          SnackbarUtils.error("File exporting failed");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <Grid item>
      <PeriodPicker
        onExport={onExport}
        disabledExport={loading || !selectedRange}
        onRangeChange={({ start, end }) => {
          setSelectedRange({ start, end });
        }}
      />

      {loading && (
        <Typography>*It might take some time to finish the download of the file</Typography>
      )}
    </Grid>
  );
};
