import {
  EducationalVideosApi as EducationalVideosApiV1,
  SymptomsApi as SymptomsApiV1,
  EducationalArticlesApi as EducationalArticlesApiV1,
  FeedbackSurveyApi as FeedbackSurveyApiV1,
} from "@veris-health/virtual-doc-ms/lib/v1/api";
import config from "../axios-config";
import { API_ROUTES, getVersion, VERSIONS } from "../helpers";

export const educationalVideosApiV1 = new EducationalVideosApiV1(
  undefined,
  getVersion(API_ROUTES.VIRTUAL_DOC, VERSIONS.v1),
  config,
);

export const symptomsApiV1 = new SymptomsApiV1(
  undefined,
  getVersion(API_ROUTES.VIRTUAL_DOC, VERSIONS.v1),
  config,
);
export const articlesApiV1 = new EducationalArticlesApiV1(
  undefined,
  getVersion(API_ROUTES.VIRTUAL_DOC, VERSIONS.v1),
  config,
);

export const feedbackSurveyApiV1 = new FeedbackSurveyApiV1(
  undefined,
  getVersion(API_ROUTES.VIRTUAL_DOC, VERSIONS.v1),
  config,
);
