import { useEffect, useState } from "react";
import { FeedbackSurvey } from "@veris-health/virtual-doc-ms/lib/v1/api";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { fetchSurveyByIdAsync, selectSurveys, selectSurveysStatus } from "../surveysSlice";
import { useAppDispatch } from "../../../hooks/useAppDispatch";

export const useCurrentSurvey = (id?: string) => {
  const surveys = useAppSelector(selectSurveys);
  const status = useAppSelector(selectSurveysStatus);
  const dispatch = useAppDispatch();
  const [survey, setSurvey] = useState<FeedbackSurvey | undefined>();

  useEffect(() => {
    if (id && id !== "new") {
      const currentSurvey = surveys.find(({ id: surveyId }) => surveyId === +id);
      if (currentSurvey) {
        setSurvey(currentSurvey);
      } else if (status !== "loading") {
        dispatch(fetchSurveyByIdAsync({ surveyId: +id }));
      }
    }
  }, [id, surveys]);

  return { survey, status };
};
