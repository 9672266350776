/* eslint-disable camelcase */
import { alpha, Box, Stack, styled, Typography, useTheme } from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { NotificationView } from "@veris-health/communication-ms/lib/v1";
import { VrsDivider } from "@veris-health/web-core";

import { Colors } from "@veris-health/web-core/dist/ui/utils/theme/configuration/colors";
import { VrsSystemCard } from "../../../features/Notifications/components/VrsSystemCard";
import { calculateDate, utcToLocal } from "../../../utils/date";

dayjs.extend(relativeTime);

const getBackgroundColor = (isActive: boolean, isUnread: boolean, verisColors: Colors) => {
  if (isActive) return alpha(verisColors.amethyst.light, 0.5);
  if (isUnread) return verisColors.pink.light;
  return verisColors.neutrals.white;
};

const StyledNotificationWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "isActive" && prop !== "isUnread",
})<{ isActive: boolean; isUnread: boolean }>(({ theme, isActive, isUnread }) => ({
  backgroundColor: getBackgroundColor(isActive, isUnread, theme.veris.colors),
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  border: isActive ? `1px solid ${theme.veris.colors.amethyst.light}` : "",
  borderRadius: isActive || isUnread ? +theme.shape.borderRadius + 2 : 0,
  margin: theme.spacing(0.7, 0),
}));

export interface NotificationListItemProps {
  notification: NotificationView;
  isActive?: boolean;
  onClick?: (id?: string) => void;
}

export function NotificationListItem({
  notification,
  isActive,
  onClick,
}: NotificationListItemProps): JSX.Element {
  const { notification_id, payload, date_created, read } = notification;
  const handleClick = () => onClick && onClick(`${notification_id}`);
  const theme = useTheme();
  return (
    <>
      <StyledNotificationWrapper
        isActive={!!isActive}
        onClick={handleClick}
        data-test-hook="listitem"
        isUnread={!read}
      >
        <Stack direction="row" paddingRight={1} justifyContent="space-between">
          <Box paddingLeft={notification?.read || isActive ? 0.5 : 0}>
            <VrsSystemCard backgroundColor="transparent" newNotification={!read && !isActive} />
          </Box>
          <Typography variant="body" color={theme.veris.colors.neutrals["grey-mid"]} pt={1}>
            {calculateDate(utcToLocal(date_created).toISOString())}
          </Typography>
        </Stack>
        <Box pl={8} pb={2}>
          {payload.text}
        </Box>
      </StyledNotificationWrapper>
      <VrsDivider height="1px" orientation="horizontal" />
    </>
  );
}
