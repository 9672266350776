import { Grid, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { VrsDivider } from "@veris-health/web-core";

export interface VrsActionLayoutProps {
  title: string;
  children: React.ReactNode;
}
export function VrsActionLayout({ title, children }: VrsActionLayoutProps): JSX.Element {
  const theme = useTheme();
  return (
    <Grid
      data-test-hook="action-layout"
      container
      sx={{
        backgroundColor: theme.veris.colors.neutrals.white,
        border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        borderRadius: ({ spacing }) => spacing(1),
      }}
      p={2}
    >
      <Grid item xs={12} p={2}>
        <Typography variant="h6" fontWeight={500} color={theme.veris.colors.neutrals["grey-4"]}>
          {title}
        </Typography>
        <VrsDivider
          height="1px"
          orientation="horizontal"
          color={theme.veris.colors.neutrals["grey-4"]}
          sx={{ marginY: ({ spacing }) => spacing(2) }}
        />
      </Grid>
      {children}
    </Grid>
  );
}
