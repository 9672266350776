import React from "react";
import { Box } from "@mui/material";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { $generateNodesFromDOM } from "@lexical/html";
import { $getRoot, $insertNodes, LexicalEditor } from "lexical";
import { Lang } from "@veris-health/virtual-doc-ms/lib/v1";
import { SharedHistoryContext } from "./context/SharedHistoryContext";

import Editor from "./Editor";

import EditorNodes from "./nodes/EditorNodes";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";
import "./index.css";
import { SettingsContext } from "./context/SettingsContext";

interface EditorContainerProps {
  title?: string;
  content: string;
  lang: Lang;
  setTitle: (title: string) => void;
  onChange: (htmlString: string, lang: Lang) => void;
  onSave: () => void;
}

export function EditorContainer({
  content,
  title,
  setTitle,
  onSave,
  onChange,
  lang,
}: EditorContainerProps) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        paddingTop: "1rem",
      }}
    >
      <SettingsContext>
        <LexicalComposer
          initialConfig={{
            editorState: (editor: LexicalEditor) => {
              const parser = new DOMParser();
              const dom = parser.parseFromString(content, "text/html");

              const nodes = $generateNodesFromDOM(editor, dom);
              $getRoot().select();

              $insertNodes(nodes);
            },
            namespace: "Playground",
            nodes: [...EditorNodes],
            onError: (error: Error) => {
              throw error;
            },
            theme: PlaygroundEditorTheme,
          }}
        >
          <SharedHistoryContext>
            <div className="editor-shell">
              <Editor
                title={title}
                setTitle={setTitle}
                onSave={onSave}
                onChange={onChange}
                lang={lang}
              />
            </div>
          </SharedHistoryContext>
        </LexicalComposer>
      </SettingsContext>
    </Box>
  );
}
