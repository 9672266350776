import { Box, Toolbar, Typography, AppBar, styled, Breadcrumbs, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { VrsConfirmationModals, VrsDivider } from "@veris-health/web-core";
import { NotificationsDropdownContainer } from "../../../Notifications";
import { StyledNavigationItem } from "../../../shared/styledComponents";
import { UserCard } from "../../../../ui/components/UserCard";
import { useProfile } from "../../../../context/profile";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { logout } from "../../../shared/slices/authSlice";
import { useAzureChatClient } from "../../../Communication/hooks/useAzureChatClient";

export interface NavBarProps {
  title: string;
  menuItems: string[];
  onTabClick: (index: number) => void;
  breadcrumbItems: string[];
}

export const StyledBreadCrumbLink = styled(Link)(({ theme }) => ({
  ...theme.typography.h3,
  margin: theme.spacing(0, 1),
}));

export function NavBarContainer({
  title,
  menuItems,
  onTabClick,
  breadcrumbItems,
}: NavBarProps): JSX.Element {
  const theme = useTheme();
  const location = useLocation();
  const profile = useProfile();

  const dispatch = useAppDispatch();

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogout = (): void => {
    dispatch(logout());
  };

  useAzureChatClient();

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: theme.veris.colors.neutrals.white,
        position: "relative",
        zIndex: 1,
      }}
    >
      <AppBar
        position="static"
        color="transparent"
        sx={{ boxShadow: `1px 1px ${theme.veris.colors.neutrals["grey-2"]}` }}
      >
        <Toolbar sx={{ height: "50px", zIndex: ({ zIndex }) => zIndex.drawer + 1 }}>
          {menuItems.map((menuTitle, index) => {
            return (
              <Box key={menuTitle}>
                <StyledNavigationItem
                  m={2}
                  variant="h3"
                  key={menuTitle}
                  onClick={() => {
                    onTabClick(index);
                  }}
                >
                  {menuTitle}
                </StyledNavigationItem>
              </Box>
            );
          })}
          {breadcrumbItems.length > 0 && (
            <Breadcrumbs>
              {breadcrumbItems.map((item) => (
                <StyledBreadCrumbLink
                  key={item}
                  to={location.pathname}
                  sx={{
                    color: ({ veris }) =>
                      item === "Patient Details"
                        ? veris.colors.neutrals["grey-4"]
                        : veris.colors.amethyst.normal,
                  }}
                >
                  {item}
                </StyledBreadCrumbLink>
              ))}
            </Breadcrumbs>
          )}

          <Typography m={1} variant="h3" sx={{ marginBottom: ({ spacing }) => spacing(0) }}>
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <NotificationsDropdownContainer />
          <VrsDivider orientation="vertical" height="50%" />
          <UserCard profile={profile} />
          <VrsConfirmationModals
            isOpen={showLogoutModal}
            handleClose={() => setShowLogoutModal(false)}
            dialogHeader="Warning!"
            dialogContent="You will be logged out."
            dialogSecondContentLine="Please confirm."
            onCancel={() => setShowLogoutModal(false)}
            onConfirm={() => handleLogout()}
            cancelButtonText="Cancel"
            confirmButtonText="Logout"
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
