import React from "react";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  selectPatients,
  getPatientPeriods,
  selectPeriods,
  selectPeriodsStatus,
  selectPatientsStatus,
} from "../billingSlice";
import PeriodPicker from "./PeriodPicker";
import { VrsStatusDisplay } from "../../../ui/components/VrsStatusDisplay";
import { AutoComplete } from "../../../ui/components/Autocomplete";

export interface BillingFormProps {
  handlePatientChange: (value: { value: string; label: string } | null) => void;
  handlePeriodChange: (value: string) => void;
  handleExportCSV: () => void;
  selectedPatient?: {
    value: string;
    label: string;
  };
  selectedPeriod?: string;
  loadingStatus: boolean;
}

export const BillingForm = ({
  handlePatientChange,
  handlePeriodChange,
  selectedPatient,
  selectedPeriod,
  handleExportCSV,
  loadingStatus,
}: BillingFormProps): JSX.Element => {
  const patients = useAppSelector(selectPatients);
  const periods = useAppSelector(selectPeriods);
  const periodsStatus = useAppSelector(selectPeriodsStatus);
  const patientsStatus = useAppSelector(selectPatientsStatus);
  const dispatch = useAppDispatch();

  return (
    <>
      <AutoComplete
        options={
          patients?.map((patient) => ({
            value: `${patient.id}`,
            label: patient.name || "",
          })) || []
        }
        placeholder="Select patient"
        handleChange={(e, value) => handlePatientChange(value)}
        autoCompleteId="patient-select"
        loading={patientsStatus === "loading"}
      />
      {selectedPatient && (
        <VrsStatusDisplay
          status={periodsStatus}
          errorRetryFunction={() => {
            if (selectedPatient.value) dispatch(getPatientPeriods({ id: +selectedPatient.value }));
          }}
        >
          <PeriodPicker
            handleExportCSV={handleExportCSV}
            handlePeriodChange={handlePeriodChange}
            periods={periods}
            disabledExport={!selectedPeriod || loadingStatus}
          />
        </VrsStatusDisplay>
      )}
    </>
  );
};
export default BillingForm;
