import React from "react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Datepicker, formatDate } from "@mobiscroll/react";
import "../billing.css";
import { BILLING_DATE_FORMAT } from "../../Billing/components/constants";

const inputProps = {
  className: "md-mobile-picker-input",
  placeholder: "Please Select...",
};

interface BillingRangePickerProps {
  onDateChange: (date: { value: Date }) => void;
}

function BillingRangePicker({ onDateChange }: BillingRangePickerProps) {
  const today = formatDate(BILLING_DATE_FORMAT, new Date());
  return (
    <Datepicker
      theme="material"
      themeVariant="light"
      controls={["date"]}
      dateFormat="MM/YYYY"
      inputComponent="input"
      max={today}
      inputProps={inputProps}
      onChange={onDateChange}
    />
  );
}

export default BillingRangePicker;
