import { get } from "lodash";

export async function retryIfFailed<T>(
  fn: () => Promise<T>,
  maxRetries = 1,
  statusCode = [404],
): Promise<T> {
  try {
    const response = await fn();
    return response;
  } catch (error) {
    const status = get(error, "response.status");
    if (statusCode.includes(Number(status)) && maxRetries > 0) {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      return retryIfFailed(fn, maxRetries - 1);
    }

    throw error;
  }
}
