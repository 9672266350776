import { PatientType, UpdatePatientDataRequest } from "@veris-health/user-ms/lib/v1";
import { PatientInfo, PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v2";
import { patientProfileApiV2, patientsApiV1 } from "../../../api";
import { VrsPatientInfo } from "../../shared/interfaces";

const mapPatientDetails = (patient: PatientInfo): VrsPatientInfo => {
  return {
    ...patient,
    healthStatus: patient.health_stats,
    activeSymptoms: {
      allActive: patient.active_symptoms?.all_active?.map((symptom) =>
        symptom.replaceAll("-", " "),
      ),
      latestSymptomTimeline: {
        symptom_name: {
          label:
            patient.active_symptoms?.latest_symptom_timeline?.symptom_name?.label?.replaceAll(
              "-",
              " ",
            ) || "",
          value:
            patient.active_symptoms?.latest_symptom_timeline?.symptom_name?.value.replaceAll(
              "-",
              " ",
            ) || "",
        },
        name: patient.active_symptoms?.latest_symptom_timeline?.name?.replaceAll("-", " ") || "",
        events: patient.active_symptoms?.latest_symptom_timeline?.events,
      },
    },
    lastInfusion: patient.last_infusion,
    tags: patient.tags,
    type: patient.type || PatientType.GfAhk,
    gender: patient.gender || "N/A",
    batteryLevel: patient.battery_level,
    diagnosis: {
      cancerType: patient.diagnosis?.cancer_type || "N/A",
      cancerStage: patient.diagnosis?.cancer_stage || "N/A",
    },
    picture: patient.picture,
    qualityOfLife: patient.quality_of_life,
    qualityOfLifeTrend: patient.quality_of_life_trending,
    inCareTeam: patient.in_care_team,
    ingestionData: patient.ingestion_data,
    ehrId: patient.ehr_id,
    patientStatus: patient.patient_status,
  };
};

export const getPatientDetails = async (userId: number): Promise<VrsPatientInfo> => {
  const response = await patientProfileApiV2.patientProfileGet(userId);
  return mapPatientDetails(response.data);
};

export const updatePatientData = async (
  userId: number,
  data: UpdatePatientDataRequest,
): Promise<void> => {
  const response = await patientsApiV1.adminUpdatePatientData(userId, data);
  return response.data;
};

export const updateHubSN = async (userId: number, hubSN: string): Promise<unknown> => {
  const response = await patientsApiV1.adminUpdatePatientMioHubSn(userId, {
    mio_hub_serial_number: hubSN,
  });
  return response.data;
};

export const updatePatientStatus = async (
  userId: number,
  status: PatientStatusEnumAPI,
): Promise<void> => {
  const response = await patientsApiV1.updatePatientStatus(userId, {
    status,
  });
  return response.data;
};
