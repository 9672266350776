import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Chip,
  ChipProps,
  CircularProgress,
  DialogContent,
  FormHelperText,
  FormLabel,
  Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { filter } from "lodash";
import { TagItem } from "@veris-health/user-ms/lib/v1";
import {
  IconName,
  Option,
  VrsButton,
  VrsDialog,
  VrsIconButton,
  VrsSelect,
} from "@veris-health/web-core";
import { createTags, deleteTag, editTag } from "../api/actionsApi";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectHospitals } from "../../Users/userSlice";
import { getHospitalTags } from "../../Users/api/usersApi";
import { Status } from "../../shared/interfaces";
import { EditTagInput } from "./EditTagInput";
import { NewTagInput } from "./NewTagInput";
import SnackbarUtils from "../../../utils/SnackbarUtils";

const StyledChip = styled(Chip)<ChipProps>(({ theme }) => ({
  ...theme.typography.body,
  marginRight: theme.spacing(1),
  marginBlock: theme.spacing(0.25),
  ".MuiChip-root": {
    paddingX: theme.spacing(1),
    fontWeight: theme.typography.body,
  },
  ".MuiChip-label": {
    paddingTop: "3px",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  flexWrap: "wrap",
  display: "flex",
  borderRadius: +theme.shape.borderRadius * 0.375,
  border: `1px solid ${theme.veris.colors.neutrals["grey-2"]} `,
  padding: theme.spacing(1, 1.25),
  minHeight: "44.5px",
}));

export const TagsDialog = (): JSX.Element => {
  const [showTagsDialog, setShowTagsDialog] = useState(false);
  const hospitals = useAppSelector(selectHospitals);
  const [hospital, setHospital] = useState<string>();
  const [status, setStatus] = useState<Status>("idle");
  const [currentTagList, setCurrentTagList] = useState<TagItem[]>([]);
  const [idsToDelete, setIdsToDelete] = useState<number[]>([]);
  const [tagsToEdit, setTagsToEdit] = useState<TagItem[]>([]);
  const [editingTag, setEditingTag] = useState<TagItem>();
  const [toggleEdit, setToggleEdit] = useState(false);
  const [tagsToCreate, setTagsToCreate] = useState<string[]>([]);
  const [newTag, setNewTag] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  const hospitalOptions: Option[] = useMemo(() => {
    return hospitals.map((item) => ({ label: item.name, value: item.id.toString() }));
  }, [hospitals]);

  const resetAllTags = () => {
    setToggleEdit(false);
    setTagsToCreate([]);
    setCurrentTagList([]);
    setIdsToDelete([]);
    setTagsToEdit([]);
    setNewTag("");
  };

  const onSelectedHospital = (value: string) => {
    setHospital(value);
  };

  const onEditingChange = (tag: TagItem, value: string) => setEditingTag({ ...tag, label: value });

  const onNewTagChange = (value: string) => {
    if (toggleEdit) setToggleEdit(false);
    setNewTag(value);
  };

  const onEdit = (editedTag: TagItem) => {
    setTagsToEdit([...tagsToEdit, editedTag]);
    setToggleEdit(false);
    setEditingTag(undefined);
    const editedTagList = currentTagList.map((item) => {
      if (item.id === editedTag.id) {
        return {
          ...item,
          label: editedTag.label,
        };
      }
      return { ...item };
    });
    setCurrentTagList(editedTagList);
  };

  const onDelete = (tag: TagItem) => {
    if (toggleEdit) setToggleEdit(false);
    setIdsToDelete([...idsToDelete, tag.id]);
    const updatedList = filter(currentTagList, (item) => item.id !== tag.id);
    setCurrentTagList(updatedList);
  };

  const onCreate = (tag: string) => {
    setTagsToCreate([...tagsToCreate, tag.trim()]);
    setNewTag("");
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (tagsToCreate.length > 0) await handleCreate(Number(hospital), tagsToCreate);
    if (idsToDelete.length > 0) await handleDelete(idsToDelete);
    if (tagsToEdit.length > 0) await handleEdit(tagsToEdit);
    handleClose();
  };

  const handleSuccessResponse = () => {
    SnackbarUtils.success("Tags sucessfully updated.");
  };

  const handleErrorResponse = () => {
    SnackbarUtils.error("Something went wrong, please try again");
  };

  async function handleEdit(tags: TagItem[]) {
    try {
      await Promise.all(tags.map((item) => editTag(Number(item.id), { new_label: item.label })));
      handleSuccessResponse();
      handleClose();
    } catch (err) {
      handleErrorResponse();
    } finally {
      setIsLoading(false);
    }
  }
  async function handleDelete(ids: number[]) {
    try {
      await Promise.all(ids.map((id) => deleteTag(id)));
      handleSuccessResponse();
      handleClose();
    } catch (err) {
      handleErrorResponse();
    } finally {
      setIsLoading(false);
    }
  }
  async function handleCreate(hospitalId: number, tags: string[]) {
    try {
      await createTags({ hospital_id: hospitalId, labels: tags });
      handleSuccessResponse();
      handleClose();
    } catch (err) {
      handleErrorResponse();
    } finally {
      setIsLoading(false);
    }
  }

  function handleClose() {
    setShowTagsDialog(false);
    setHospital(undefined);
    resetAllTags();
  }

  const disableSaveBtn =
    isLoading ||
    !hospital ||
    (tagsToCreate.length < 1 && tagsToEdit.length < 1 && idsToDelete.length < 1);

  useEffect(() => {
    resetAllTags();
    if (hospital) {
      getHospitalTags(Number(hospital)).then((response) => {
        setStatus("loading");
        setCurrentTagList(response.items);
        setStatus("idle");
      });
    }
  }, [hospital]);

  return (
    <Box pb={2}>
      <VrsButton buttonType="primary" onClick={() => setShowTagsDialog(true)}>
        <Typography variant="bodySemibold" fontWeight={400} pt={0.25} px={2}>
          Predefined Tags
        </Typography>
      </VrsButton>
      <VrsDialog
        title="Predefined Tags"
        sx={{ "& .MuiPaper-root": { height: "auto" } }}
        open={showTagsDialog}
        onClose={() => handleClose()}
      >
        <DialogContent sx={{ overflowY: "unset" }}>
          <Grid
            item
            xs={12}
            container
            spacing={4}
            style={{ margin: "auto", width: "auto", paddingLeft: "0" }}
          >
            <Grid item xs={6} style={{ width: "91.5%", paddingLeft: 0 }}>
              <Box>
                <Typography m={0} variant="subtitle2">
                  Hospital
                  <FormLabel />
                </Typography>
                <VrsSelect
                  innerSx={{ padding: theme.spacing(0.75, 0.75, 0.75, 0) }}
                  value={hospital}
                  options={hospitalOptions}
                  placeholder="Choose hospital"
                  onSelected={onSelectedHospital}
                  maxHeight="250px"
                />
              </Box>
            </Grid>
            <Grid item xs={12} style={{ width: "91.5%", paddingLeft: "0" }}>
              <Typography m={0} variant="subtitle2">
                Tag List
                <FormLabel />
              </Typography>
              <StyledBox>
                {status === "loading" && (
                  <Box sx={{ paddingTop: theme.spacing(1) }}>
                    <CircularProgress sx={{ marginX: theme.spacing(2) }} size={16} />
                  </Box>
                )}
                {currentTagList.map((value) => (
                  <React.Fragment key={value.id}>
                    {value.id === editingTag?.id && toggleEdit ? (
                      <EditTagInput
                        hospital={hospital}
                        editingTag={editingTag}
                        onEdit={onEdit}
                        onEditingChange={onEditingChange}
                      />
                    ) : (
                      <StyledChip
                        icon={
                          <VrsIconButton
                            onClick={() => {
                              setEditingTag(value);
                              setToggleEdit(!toggleEdit);
                            }}
                            iconProps={{ name: IconName.Edit }}
                          />
                        }
                        variant="outlined"
                        label={value.label}
                        key={value.id}
                        onDelete={() => onDelete(value)}
                      />
                    )}
                  </React.Fragment>
                ))}
                {tagsToCreate.map((value) => (
                  <StyledChip
                    key={value}
                    variant="outlined"
                    label={value}
                    onDelete={() => {
                      if (toggleEdit) setToggleEdit(false);
                      setTagsToCreate(tagsToCreate.filter((name) => name !== value));
                    }}
                  />
                ))}
                <NewTagInput
                  hospital={hospital}
                  newTag={newTag}
                  onCreate={onCreate}
                  onNewTagChange={onNewTagChange}
                />
              </StyledBox>
              <FormHelperText color={theme.veris.colors.neutrals["grey-3"]}>
                Press Enter key to add or edit a tag.
              </FormHelperText>
            </Grid>
            <Grid item xs={12} style={{ width: "91.5%", paddingLeft: "0" }}>
              <Box display="flex" justifyContent="end" gap={2} py={2}>
                <VrsButton
                  buttonType="tertiary"
                  onClick={handleSubmit}
                  type="submit"
                  disabled={disableSaveBtn}
                >
                  Save
                </VrsButton>
                <VrsButton buttonType="secondary" onClick={() => handleClose()}>
                  Cancel
                </VrsButton>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </VrsDialog>
    </Box>
  );
};
