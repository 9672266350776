import React, { useState, useCallback } from "react";
import {
  Box,
  Stack,
  Grid,
  InputAdornment,
  Skeleton,
  Typography,
  useMediaQuery,
  Theme,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChatThreadInfo, ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import { VrsIcon, IconName, VrsButton } from "@veris-health/web-core";
import { AdminDetailUserInfoResponse, UserTypeResponse } from "@veris-health/user-ms/lib/v1";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";
import { UserType } from "@veris-health/med-data-ms/lib/v1";
import { StyledTextField, VrsUserSelectionDialog } from "../components/VrsUserSelectionDialog";
import { Routes } from "../../../routes-config";
import { VrsThreadPreview } from "../components/VrsThreadPreview";
import { calculateTimeFromNow } from "../../../utils/date";
import {
  createSupportThreadAsync,
  fetchChatThreads,
  resetThreadId,
  selectChatThreadsPreview,
  selectChatThreadsStatus,
  selectCurrentThread,
  selectTotalThreads,
  setThreadId,
  setThreadUnreadStatus,
} from "../store";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../hooks/useAppDispatch";

export interface ChatSidebarContainerProps {
  userId: string;
}

const LoadingSkeleton = () => (
  <Box
    sx={{
      paddingY: (theme) => theme.spacing(2),
      paddingX: (theme) => theme.spacing(0.4),
      borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
    }}
    display="flex"
  >
    <Skeleton variant="circular" width={50} height={50} sx={{ marginRight: "0.5rem" }} />
    <Box width="84%">
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
    </Box>
  </Box>
);

export const ChatSidebarContainer = ({ userId }: ChatSidebarContainerProps): JSX.Element => {
  const [toggleNewChatDialog, setToggleNewChatDialog] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>();
  const chatThreadsStatus = useAppSelector(selectChatThreadsStatus);
  const chatThreadsTotal = useAppSelector(selectTotalThreads);
  const chatThreads = useAppSelector(selectChatThreadsPreview);
  const currentThread = useAppSelector(selectCurrentThread);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const debouncedSearch = useCallback(
    debounce((search) => {
      dispatch(
        fetchChatThreads({
          filterTerm: search,
        }),
      );
    }, 500),
    [],
  );

  const handleThreadPreviewClick = (
    thread: ChatThreadInfo & { patient: ThreadParticipantInfo },
  ) => {
    dispatch(setThreadId({ threadId: thread.id }));
    dispatch(setThreadUnreadStatus(thread.id));
    navigate(`${Routes.SUPPORT}/${thread.id}`);
  };

  const handleChatCreation = (patient: AdminDetailUserInfoResponse): void => {
    setToggleNewChatDialog(false);
    dispatch(resetThreadId());
    dispatch(
      createSupportThreadAsync({
        patientId: patient.id,
        userId: +userId,
      }),
    )
      .unwrap()
      .then((resp) => {
        navigate(`${Routes.SUPPORT}/${resp.id}`);
      });
  };
  const getThreadPreviewUser = (
    thread: ChatThreadInfo & {
      patient: ThreadParticipantInfo;
      hasUnread: boolean;
      lastMessageReceivedOn?: string;
    },
  ) => {
    return (
      thread.for_patient ||
      thread.patient ||
      thread.participants.find((participant) => participant.user_type === UserType.SupportMember)
    );
  };

  return (
    <Grid
      item
      lg={4}
      xl={3}
      p={1}
      xs={4}
      sx={{
        borderLeft: (theme) => `2px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        height: "90vh",
        overflow: "hidden",
      }}
      data-test-hook="sidebar-chat-container"
    >
      <Grid container spacing={0.25} alignItems="center">
        <Grid item xs={7} lg={7} xl={8}>
          <StyledTextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VrsIcon name={IconName.Search} />
                </InputAdornment>
              ),
            }}
            type="text"
            fullWidth
            placeholder="Search by name"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              debouncedSearch(e.target.value);
            }}
            autoFocus
          />
        </Grid>
        <Grid display="flex" item xs={5} lg={5} xl={4}>
          <VrsButton
            sx={{ marginLeft: "auto" }}
            onClick={() => setToggleNewChatDialog(true)}
            buttonType="secondary"
            endIcon={
              <VrsIcon name={IconName.Plus} size={16} sx={{ position: "relative", top: "-2px" }} />
            }
          >
            New chat
          </VrsButton>
        </Grid>

        {toggleNewChatDialog && (
          <VrsUserSelectionDialog
            isOpen={toggleNewChatDialog}
            handleSelection={handleChatCreation}
            closeNewChatDialog={() => setToggleNewChatDialog(false)}
            userType={[UserTypeResponse.Patient, UserTypeResponse.SupportMember]}
          />
        )}
      </Grid>
      <Box
        display="flex"
        flexDirection="column"
        id="chat-sidebar-list"
        sx={{
          overflowY: "auto",
          maxHeight: isSmallScreen ? "85%" : "89%",
          backgroundColor: (theme) => theme.veris.colors.neutrals.white,
        }}
      >
        {chatThreadsStatus === "loading" && chatThreads.length === 0 && (
          <Stack
            sx={{
              paddingTop: (theme) => theme.spacing(1),
            }}
          >
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
          </Stack>
        )}
        {chatThreadsStatus === "idle" && chatThreads.length === 0 && (
          <Typography
            pt={2}
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
            textAlign="center"
            variant="body"
          >
            No matches found
          </Typography>
        )}
        <InfiniteScroll
          scrollableTarget="chat-sidebar-list"
          dataLength={chatThreads.length}
          hasMore={chatThreadsTotal - chatThreads.length > 0}
          next={() => {
            dispatch(
              fetchChatThreads({
                filterTerm: searchValue,
                offset: chatThreads.length,
              }),
            );
          }}
          loader={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={1}
              borderTop={(theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`}
            >
              <CircularProgress />
            </Box>
          }
        >
          {chatThreads.map((thread) => {
            return (
              <Box
                sx={{
                  paddingY: (theme) => theme.spacing(1),
                  borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
                }}
                key={thread.id}
              >
                <VrsThreadPreview
                  isGroupChat={thread.participants.length > 2 && !thread.private}
                  groupChatParticipants={thread.participants}
                  key={thread.id}
                  participantInfo={undefined}
                  threadParticipant={getThreadPreviewUser(thread)}
                  msgTime={
                    thread?.lastMessageReceivedOn &&
                    calculateTimeFromNow(thread.lastMessageReceivedOn)
                  }
                  hasUnread={thread.hasUnread}
                  isActive={currentThread?.id === thread.id}
                  handleClick={() => handleThreadPreviewClick(thread)}
                  status={thread?.support_chat_info?.chat_status}
                />
              </Box>
            );
          })}
        </InfiniteScroll>
      </Box>
    </Grid>
  );
};
