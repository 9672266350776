import {
  TermsAndConditionsItem,
  TermsAndConditionType,
  AdminDetailUserInfoResponse,
} from "@veris-health/user-ms/lib/v1";
import { termsAndConditionsApiV1, usersApiV1 } from "../../../../api";

export const getTermsAndConditions = async (): Promise<TermsAndConditionsItem> => {
  const { data } = await termsAndConditionsApiV1.getTermsAndConditions(
    TermsAndConditionType.TechStaff,
    "en",
  );
  return data;
};

export const getAuthenticatedUser = async (id: string): Promise<AdminDetailUserInfoResponse> => {
  const response = await usersApiV1.adminGetSelfUserInfo(id);
  return response.data;
};
