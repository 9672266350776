import React, { ReactNode, useEffect } from "react";
import { Box, Collapse } from "@mui/material";
import { VrsAlert } from "@veris-health/web-core";
import VrsLeftSidebarContainer from "./VrsLeftSidebarContainer";
import NavBarLayoutContainer from "./NavBarLayoutContainer";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectIsLoginBannerOpen, toggleLoginBanner } from "../shared/slices/authSlice";
import { RolesContext } from "../../context/roles";

export interface VrsAppContainerProps {
  children: ReactNode;
}

const VrsLoginBanner = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const showBanner = useAppSelector(selectIsLoginBannerOpen);

  useEffect(() => {
    if (showBanner)
      setTimeout(() => {
        dispatch(toggleLoginBanner(false));
      }, 15000);
    return () => {
      dispatch(toggleLoginBanner(false));
    };
  }, [showBanner, dispatch]);
  return (
    <>
      {showBanner && (
        <Box m={1}>
          <Collapse in={showBanner}>
            <VrsAlert
              message="You will remain logged in for additional 15 minutes after your last action on the portal, even if you close the browser. Please logout via the Profile section for immediate termination of your session"
              severity="warning"
              onClose={() => dispatch(toggleLoginBanner(false))}
            />
          </Collapse>
        </Box>
      )}
    </>
  );
};

export function VrsAppContainer({ children }: VrsAppContainerProps): JSX.Element {
  return (
    <RolesContext>
      <Box
        height="100vh"
        display="flex"
        sx={{
          backgroundColor: ({ veris }) => veris.colors.neutrals["grey-1"],
          overflow: "hidden",
        }}
      >
        <VrsLeftSidebarContainer />
        <Box width="100%">
          <NavBarLayoutContainer />
          <VrsLoginBanner />
          <Box height="90%" sx={{ overflowY: "scroll", overflowX: "hidden" }}>
            {children}
          </Box>
        </Box>
      </Box>
    </RolesContext>
  );
}
