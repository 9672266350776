import React from "react";
import { Badge, Box, Typography, styled, BadgeProps, Chip } from "@mui/material";
import { SupportChatStatus, ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import { VrsAvatar, VrsDivider, VrsButton } from "@veris-health/web-core";
import { UserType } from "@veris-health/med-data-ms/lib/v1";

export interface VrsGroupChatHeaderProps {
  participants: ThreadParticipantInfo[];
  onUpdateAsigneeClick: () => void;
  onUpdate: (status: SupportChatStatus) => void;
  threadStatus?: SupportChatStatus;
  threadParticipant?: ThreadParticipantInfo;
}

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 11,
    top: 20,
    padding: "3px 4px 0",
    border: `1px solid ${theme.palette.background.paper}`,
    backgroundColor: theme.veris.colors.neutrals["grey-mid"],
    color: theme.veris.colors.neutrals.white,
  },
}));

export const VrsGroupChatHeader = ({
  onUpdate,
  threadStatus,
  threadParticipant,
}: VrsGroupChatHeaderProps): JSX.Element => {
  const imageUrl =
    threadParticipant &&
    threadParticipant.image &&
    `data:image/png;base64,${threadParticipant.image}`;
  const name = threadParticipant?.display_name ?? "Unknown";
  const type =
    threadParticipant?.user_type === UserType.SupportMember
      ? "Friends and Family"
      : threadParticipant?.user_type;

  return (
    <Box overflow="visible">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box
          display="flex"
          sx={{
            backgroundColor: (theme) => theme.veris.colors.neutrals.white,
          }}
          alignItems="center"
          p={1}
        >
          <VrsAvatar name={name} imageUrl={imageUrl} />

          <Box
            sx={{
              marginLeft: (theme) => theme.spacing(1),
              marginRight: (theme) => theme.spacing(3),
            }}
          >
            <Box display="flex" gap={1} alignItems="center">
              <Typography variant="subtitle2">{threadParticipant?.display_name}</Typography>
              <Typography
                variant="body"
                color={(theme) => theme.veris.colors.neutrals["grey-mid"]}
                sx={{ textTransform: "capitalize" }}
              >
                {threadParticipant?.patient_status}
              </Typography>
            </Box>
            <Chip
              label={
                <Typography
                  variant="description"
                  color={(theme) => theme.veris.colors.mango.normal}
                >
                  {type}
                </Typography>
              }
              sx={{
                backgroundColor: (theme) => theme.veris.colors.mango.light,
                height: "25px",
                marginTop: (theme) => theme.spacing(0.5),
                textTransform: "capitalize",
              }}
            />
          </Box>
        </Box>
        {threadStatus === SupportChatStatus.Pending ? (
          <VrsButton
            buttonType="primary"
            sx={{ marginRight: (theme) => theme.spacing(1) }}
            onClick={() => onUpdate(SupportChatStatus.Resolved)}
          >
            Mark as resolved
          </VrsButton>
        ) : (
          <VrsButton
            buttonType="secondary"
            sx={{ marginRight: (theme) => theme.spacing(1) }}
            onClick={() => onUpdate(SupportChatStatus.Pending)}
          >
            Mark as pending
          </VrsButton>
        )}
      </Box>
      <VrsDivider orientation="horizontal" height="auto" />
    </Box>
  );
};
