import { styled } from "@mui/material";
import { verisColors } from "@veris-health/web-core";

export const TitleInput = styled("input")(({ theme }) => ({
  ...theme.typography.h2,
  background: "none",
  padding: 0,
  marginBottom: ".75rem",
  borderStyle: "none",
  outline: "none",
  width: "95%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  color: verisColors.neutrals["grey-5"],
  "::placeholder": {
    color: verisColors.neutrals["grey-2"],
  },
}));
