import React from "react";
import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider, ErrorBoundary } from "@veris-health/web-core";
import * as Sentry from "@sentry/react";
import VrsRouter from "./router/Router";
import { SessionContainer } from "./features/Session/SessionContainer";
import { SnackbarUtilsConfigurator } from "./utils/SnackbarUtils";
import { SnackbarProvider } from "./context/snackbar";
import { ProfileContext } from "./context/profile";

function App(): JSX.Element {
  return (
    <ThemeProvider>
      <ErrorBoundary
        onError={(error, errorInfo) => {
          Sentry.captureException(error, { extra: { errorInfo } });
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider>
            <SnackbarUtilsConfigurator />
            <Container className="App" maxWidth={false} disableGutters>
              <CssBaseline />
              <SessionContainer>
                <ProfileContext>
                  <VrsRouter />
                </ProfileContext>
              </SessionContainer>
            </Container>
          </SnackbarProvider>
        </LocalizationProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
