import React from "react";
import { Box, Grid, Theme, Typography, Avatar, styled, Badge } from "@mui/material";
import { IconName, VrsIcon } from "@veris-health/web-core";

export interface VrsSystemCardProps {
  // TODO: Refactor this once we have all system details from BE
  backgroundColor?: string;
  newNotification?: boolean;
}

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: "white",
  border: `1px solid ${theme.veris.colors.neutrals["grey-3"]}`,
}));

export function VrsSystemCard({
  backgroundColor,
  newNotification,
}: VrsSystemCardProps): JSX.Element {
  return (
    <Box
      display="flex"
      alignItems="center"
      paddingY={(theme) => theme.spacing(0.375)}
      paddingX={(theme) => theme.spacing(0.625)}
      sx={{
        borderRadius: 1.5,
        flexGrow: 1,
        backgroundColor: (theme) =>
          backgroundColor || theme.veris.colors.neutrals["grey-background"],
      }}
    >
      {newNotification ? (
        <Badge
          variant="dot"
          color="info"
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          sx={{ paddingLeft: "4px", paddingTop: "4px" }}
          overlap="circular"
        >
          <Box mr={1.5} p={0.3}>
            <StyledAvatar variant="circular">
              <VrsIcon
                name={IconName.Logo}
                sx={{
                  position: "relative",
                  left: "2.45px",
                  top: "4px",
                  width: "92%",
                }}
              />
            </StyledAvatar>
          </Box>
        </Badge>
      ) : (
        <Box mr={1.5} p={0.3}>
          <StyledAvatar variant="circular">
            <VrsIcon
              name={IconName.Logo}
              sx={{
                position: "relative",
                left: "2.45px",
                top: "4px",
                width: "92%",
              }}
            />
          </StyledAvatar>
        </Box>
      )}

      <Grid marginX={(theme) => theme.spacing(0, 1.5)} marginTop={(theme) => theme.spacing(0.5)}>
        <Box marginRight={(theme) => theme.spacing(1)} display="inline-block" whiteSpace="nowrap">
          <Typography
            variant="subtitle1"
            color={`${(theme: Theme) => theme.veris.colors.neutrals.black}`}
          >
            Veris System
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
}
