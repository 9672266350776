import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import {
  ChatClient,
  ChatMessageReceivedEvent,
  ChatThreadCreatedEvent,
  ParticipantsAddedEvent,
  TypingIndicatorReceivedEvent,
} from "@azure/communication-chat";
import {
  AzureCommunicationTokenCredential,
  CommunicationUserIdentifier,
} from "@azure/communication-common";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {
  addChatThread,
  addChatThreadAsync,
  addChatThreadMessage,
  fetchAttachmentsForThreadAsync,
  selectChatApiEndpoint,
  selectChatUserAccessToken,
  selectCurrentThreadId,
  selectMyAzureChatUserId,
  setNewMessageNotification,
  setThreadUnreadStatus,
  setTypingIndicator,
  updateThreadAsync,
} from "../store";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectUserId } from "../../shared/slices/authSlice";

export const useAzureChatClient = (): { azureChatClient: ChatClient | undefined } => {
  const dispatch = useAppDispatch();
  const [azureChatClient, setAzureChatClient] = useState<ChatClient>();
  const userId = useAppSelector(selectUserId);
  const typingIndicatorTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const currentThreadId = useAppSelector(selectCurrentThreadId);
  const myAzureChatUserId = useAppSelector(selectMyAzureChatUserId);
  const endpoint = useAppSelector(selectChatApiEndpoint);
  const token = useAppSelector(selectChatUserAccessToken);

  useEffect(() => {
    if (!azureChatClient && token && endpoint) {
      setAzureChatClient(new ChatClient(endpoint, new AzureCommunicationTokenCredential(token)));
    }
  }, [dispatch, azureChatClient, endpoint, token]);

  useEffect(() => {
    const handleChatMessageReceived = (messageEvent: ChatMessageReceivedEvent) => {
      dispatch(
        addChatThreadMessage({
          ...messageEvent,
          createdOn: dayjs(messageEvent.createdOn).toISOString(),
        }),
      );
      if (
        (messageEvent.sender as CommunicationUserIdentifier).communicationUserId !==
          myAzureChatUserId &&
        currentThreadId &&
        currentThreadId === messageEvent.threadId
      ) {
        dispatch(setNewMessageNotification({ threadId: messageEvent.threadId }));
      }
      if (
        messageEvent.metadata &&
        messageEvent.metadata.attachmentName &&
        (messageEvent.sender as CommunicationUserIdentifier).communicationUserId !==
          myAzureChatUserId
      ) {
        dispatch(
          fetchAttachmentsForThreadAsync({ threadId: messageEvent.threadId, onlyLatest: true }),
        );
      }
      const threadClient = azureChatClient?.getChatThreadClient(messageEvent.threadId);
      if (currentThreadId && currentThreadId === messageEvent.threadId)
        threadClient?.sendReadReceipt({ chatMessageId: messageEvent.id });
      dispatch(setThreadUnreadStatus(messageEvent.threadId));
    };

    const handleChatThreadCreated = (chatThreadCreatedEvent: ChatThreadCreatedEvent) => {
      dispatch(
        addChatThread({
          ...chatThreadCreatedEvent,
          createdOn: dayjs(chatThreadCreatedEvent.createdOn).toISOString(),
        }),
      );
      dispatch(
        addChatThreadAsync({
          threadId: chatThreadCreatedEvent.threadId,
          topic: chatThreadCreatedEvent.properties.topic,
        }),
      );
    };

    const handleTypingIndicatorReceived = (
      typingIndicatorReceivedEvent: TypingIndicatorReceivedEvent,
    ) => {
      dispatch(
        setTypingIndicator({
          ...typingIndicatorReceivedEvent,
          receivedOn: dayjs(typingIndicatorReceivedEvent.receivedOn).toISOString(),
        }),
      );
      if (typingIndicatorTimeoutRef.current) {
        clearTimeout(typingIndicatorTimeoutRef.current);
      }

      typingIndicatorTimeoutRef.current = setTimeout(() => {
        dispatch(setTypingIndicator(undefined));
      }, 2000);
    };

    const handleParticipantsAdded = (event: ParticipantsAddedEvent) => {
      dispatch(updateThreadAsync({ userId: String(userId), threadId: event.threadId }));
    };

    const setupAzureChatClient = async (chatClient: ChatClient) => {
      await chatClient.startRealtimeNotifications();
      chatClient.on("chatMessageReceived", handleChatMessageReceived);
      chatClient.on("chatThreadCreated", handleChatThreadCreated);
      chatClient.on("typingIndicatorReceived", handleTypingIndicatorReceived);
      chatClient.on("participantsAdded", handleParticipantsAdded);
    };

    const cleanAzureChatClient = async (chatClient: ChatClient) => {
      chatClient.off("chatThreadCreated", handleChatThreadCreated);
      chatClient.off("chatMessageReceived", handleChatMessageReceived);
      chatClient.off("typingIndicatorReceived", handleTypingIndicatorReceived);
      chatClient.off("participantsAdded", handleParticipantsAdded);
      await chatClient.stopRealtimeNotifications();
    };
    if (azureChatClient) {
      setupAzureChatClient(azureChatClient);
    }
    return () => {
      if (azureChatClient) {
        cleanAzureChatClient(azureChatClient);
      }
    };
  }, [dispatch, azureChatClient, currentThreadId]);

  useEffect(() => {
    return () => {
      if (typingIndicatorTimeoutRef.current) clearTimeout(typingIndicatorTimeoutRef.current);
    };
  }, []);

  return { azureChatClient };
};
