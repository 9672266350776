export enum API_ROUTES {
  USER = "user",
  MED_DATA = "med-data",
  VIRTUAL_DOC = "virtual-doc",
  COMMUNICATION = "communication",
}

export enum VERSIONS {
  v1 = "v1",
  v2 = "v2",
}
export function getVersion(ms: API_ROUTES, version: VERSIONS): string {
  return `${ms}/${version}`;
}
