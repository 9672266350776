import {
  ApplicationType,
  NewReleaseNotificationDataTypeEnum,
  ReleaseNotificationResponse,
} from "@veris-health/communication-ms/lib/v1";
import {
  CreatePatientRequest,
  CreatePatientResponse,
  CreateAdminTagsRequest,
  TagItem,
  UpdateAdminTagRequest,
  SuccessfulResponse,
} from "@veris-health/user-ms/lib/v1";
import {
  notificationsApiV1,
  registerApiV1,
  usersApiV1,
  adminTagsApiV1,
  hospitalsApiV1,
} from "../../../api";

export const sendReleaseNotification = async (
  link: string,
  version: string,
  appType: ApplicationType,
): Promise<ReleaseNotificationResponse> => {
  const response = await notificationsApiV1.sendReleaseNotifications({
    app_type: appType,
    link,
    version,
    type: NewReleaseNotificationDataTypeEnum.ReleaseNotes,
  });
  return response.data;
};

export const invitePatient = async (data: CreatePatientRequest): Promise<CreatePatientResponse> => {
  const response = await usersApiV1.createPatient(data);
  return response.data;
};

export const registerPatientsFromCSV = async (
  hospitalId: number,
  file: File,
): Promise<SuccessfulResponse> => {
  const response = await registerApiV1.bulkRegisterPatientUsersCsv(hospitalId, file);
  return response.data;
};

export const createTags = async (data: CreateAdminTagsRequest): Promise<TagItem> => {
  const response = await adminTagsApiV1.adminCreateTags(data);
  return response.data;
};

export const editTag = async (
  tagId: number,
  updatedName: UpdateAdminTagRequest,
): Promise<TagItem> => {
  const response = await adminTagsApiV1.adminUpdateTag(tagId, updatedName);
  return response.data;
};

export const deleteTag = async (tagId: number): Promise<void> => {
  const response = await adminTagsApiV1.adminDeleteTag(tagId);
  return response.data;
};

export const createHospital = async (name: string): Promise<void> => {
  await hospitalsApiV1.createHospital({ name });
};
