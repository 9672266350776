import { styled, Typography } from "@mui/material";

export const StyledActiveNavigationItem = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  cursor: "pointer",
  borderBottom: `${theme.spacing(1)} solid ${theme.veris.colors.amethyst.normal}`,
  color: theme.veris.colors.amethyst.normal,
}));

export const StyledNavigationItem = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  paddingBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  cursor: "pointer",
  color: theme.veris.colors.neutrals["grey-3"],
  "&:hover": {
    fontWeight: 600,
    color: theme.veris.colors.amethyst.normal,
  },
}));
