import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BillReport } from "@veris-health/communication-ms/lib/v1";
import { AdminDetailUserInfoResponse, UserTypeResponse } from "@veris-health/user-ms/lib/v1";
import { RootState } from "../../store";
import { Status } from "../shared/interfaces";
import { logout } from "../shared/slices/authSlice";
import { getUsers } from "../Users/api/usersApi";
import {
  getPeriods,
  getHospitalPeriods,
  getPeriodDetail,
  VrsBillingPeriod,
} from "./api/billingApi";

export const getPatientsAsync = createAsyncThunk("users/getPatientsAsync", async () => {
  const response = await getUsers({
    type: [UserTypeResponse.Patient],
    active: true,
  });
  return response;
});

export const getPatientPeriods = createAsyncThunk(
  "bill/periods",
  async ({ id }: { id: number }) => {
    const response = await getPeriods(id);
    return response;
  },
);

export const fetchHospitalPeriods = createAsyncThunk(
  "bill/periods/hospital",
  async ({ id }: { id: number }) => {
    const response = await getHospitalPeriods(id);
    return response;
  },
);

export const getPeriodDetails = createAsyncThunk(
  "bill/periods/details",
  async ({ patientId, start, end }: { patientId: number; start: string; end: string }) => {
    const response = await getPeriodDetail(patientId, start, end);
    return response;
  },
);

interface BillingState {
  patients: { data: AdminDetailUserInfoResponse[]; status: Status };
  periods: { data?: VrsBillingPeriod[]; status: Status };
  periodDetails: { data?: BillReport; status: Status };
}

const initialState: BillingState = {
  patients: { data: [], status: "idle" },
  periods: { data: undefined, status: "idle" },
  periodDetails: { data: undefined, status: "idle" },
};

const billingSlice = createSlice({
  name: "bsilling",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(getPatientsAsync.pending, (state) => {
        state.patients.status = "loading";
      })
      .addCase(getPatientsAsync.fulfilled, (state, { payload }) => {
        state.patients.data = payload.items;
        state.patients.status = "idle";
      })
      .addCase(getPatientsAsync.rejected, (state) => {
        state.patients.status = "failed";
      })
      .addCase(getPatientPeriods.pending, (state) => {
        state.periods.status = "loading";
      })
      .addCase(getPatientPeriods.fulfilled, (state, { payload }) => {
        state.periods.data = payload;
        state.periods.status = "idle";
      })
      .addCase(getPatientPeriods.rejected, (state) => {
        state.periods.status = "failed";
      })
      .addCase(fetchHospitalPeriods.pending, (state) => {
        state.periods.status = "loading";
      })
      .addCase(fetchHospitalPeriods.fulfilled, (state, { payload }) => {
        state.periods.data = payload;
        state.periods.status = "idle";
      })
      .addCase(fetchHospitalPeriods.rejected, (state) => {
        state.periods.status = "failed";
      })
      .addCase(getPeriodDetails.pending, (state) => {
        state.periodDetails.status = "loading";
      })
      .addCase(getPeriodDetails.fulfilled, (state, { payload }) => {
        state.periodDetails.data = payload;
        state.periodDetails.status = "idle";
      })
      .addCase(getPeriodDetails.rejected, (state) => {
        state.periodDetails.status = "failed";
      });
  },
});

export const selectPatients = ({ billing }: RootState): AdminDetailUserInfoResponse[] =>
  billing.patients.data;

export const selectPatientsStatus = ({ billing }: RootState): Status => billing.patients.status;

export const selectPeriods = ({ billing }: RootState): VrsBillingPeriod[] | undefined =>
  billing.periods.data;

export const selectPeriodsStatus = ({ billing }: RootState): Status => billing.periods.status;

export const selectPeriodDetails = ({ billing }: RootState): BillReport | undefined =>
  billing.periodDetails.data;

export const selectPeriodDetailsStatus = ({ billing }: RootState): Status =>
  billing.periodDetails.status;

export default billingSlice.reducer;
