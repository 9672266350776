import { ChatClient } from "@azure/communication-chat";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import * as Sentry from "@sentry/react";

let client: ChatClient;
let chatToken: string;

export const getChatClient = ({
  endpoint,
  token,
}: {
  endpoint: string;
  token: string;
}): ChatClient => {
  try {
    if (!client || token !== chatToken) {
      client = new ChatClient(endpoint, new AzureCommunicationTokenCredential(token));
      chatToken = token;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
  return client;
};
