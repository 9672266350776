import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AppApiV1ModelsChatModelsStatusResponse,
  AttachmentMetadata,
  ChatThreadInfo,
  CommunicationIdentityTokenResponse,
  LimitOffsetPageChatThreadInfo,
  Permissions,
  SupportChatInfoRequest,
  SupportChatStatus,
  SupportedAttachmentTypesEnum,
  ThreadParticipant,
  URLResponse,
} from "@veris-health/communication-ms/lib/v1";
import { ChatMessage } from "@azure/communication-chat";
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";
import { RootState } from "../../../store";
import {
  downloadFile,
  fetchAllChatAttachmentsForThread,
  fetchChatApiEndpoint,
  fetchChatUserAccessToken,
  generateSas,
  getChatThreadDetails,
  uploadFileApi,
  getSupportThreads,
  joinSupportThread,
  createSupportChat,
  updateSupportChat,
  getSupportChatDetails,
} from "../api/chatApi";
import { getChatClient } from "../utils/AzureChatClient";
import downloadBlob from "../utils/downloadBlob";
import {
  NormalizedAttachments,
  NormalizedChatMessages,
  VrsChatMessage,
  VrsChatMessageContent,
  VrsChatThread,
} from "./interfaces";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { retryIfFailed } from "../../../api/retry";

const MESSAGES_PAGE_SIZE = 15;

export const fetchAttachmentsForThreadAsync = createAsyncThunk(
  "communication/fetchAttachmentsForThread",
  async (
    { threadId, onlyLatest, limit }: { threadId: string; onlyLatest?: boolean; limit?: number },
    { getState },
  ) => {
    const { auth, communication } = getState() as RootState;
    const { userId } = auth;
    const { attachments } = communication;

    const existingItems = attachments.items.entities[threadId];

    const fetchedAttachments = await fetchAllChatAttachmentsForThread(
      Number(userId),
      threadId,
      onlyLatest ? 0 : existingItems?.attachments.length,
      onlyLatest ? 1 : limit,
    );
    const selectedAttachments = onlyLatest
      ? [fetchedAttachments.items[0]]
      : fetchedAttachments.items;

    const expandedItemsList = await Promise.all(
      selectedAttachments.map(async (attachment) => {
        if (
          (attachment.file_extension === SupportedAttachmentTypesEnum.Jpg ||
            attachment.file_extension === SupportedAttachmentTypesEnum.Jpeg ||
            attachment.file_extension === SupportedAttachmentTypesEnum.Png) &&
          userId
        ) {
          let imageSrc = "";
          await generateSas(+userId, attachment.file_name, Permissions.Read).then((response) => {
            const { blobEndpoint, containerName, blobName, sharedAccessSigniture } = response;
            imageSrc = `${blobEndpoint}/${containerName}/${blobName}?${sharedAccessSigniture}`;
          });
          return { ...attachment, imageSrc };
        }
        return attachment;
      }),
    );
    const response: NormalizedAttachments = {
      id: threadId,
      attachments: [...(existingItems?.attachments || []), ...expandedItemsList],
      offset: fetchedAttachments.offset,
      total: fetchedAttachments.total,
      limit: fetchedAttachments.limit,
    };
    return response;
  },
);

export const fetchChatConfiguration = createAsyncThunk<
  URLResponse & CommunicationIdentityTokenResponse & { chatDisplayName: string },
  { id: number }
>("communication/fetchChatConfig", async ({ id }, { rejectWithValue }) => {
  try {
    const [chatApiEndpoint, chatUserAccessToken] = await Promise.all([
      fetchChatApiEndpoint(),
      fetchChatUserAccessToken(id),
    ]);
    return {
      ...chatApiEndpoint,
      ...chatUserAccessToken,
      chatDisplayName: "Veris Support",
    };
  } catch (error) {
    Sentry.captureException(error);
    return rejectWithValue(error);
  }
});

export const fetchChatThreadsCombinedAsync = createAsyncThunk<LimitOffsetPageChatThreadInfo>(
  "communication/fetchChatThreadsCombinedAsync",
  async (_entry, { rejectWithValue }) => {
    try {
      const vrsChatThreads = await getSupportThreads();
      return vrsChatThreads;
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue(error);
    }
  },
);

export const fetchChatThreads = createAsyncThunk<
  LimitOffsetPageChatThreadInfo,
  { filterTerm?: string; offset?: number }
>("communication/fetchChatThreads", async ({ filterTerm, offset }, { rejectWithValue }) => {
  try {
    return await getSupportThreads(filterTerm, undefined, offset);
  } catch (error) {
    Sentry.captureException(error);
    return rejectWithValue(error);
  }
});

export const addChatThreadAsync = createAsyncThunk<
  VrsChatThread,
  { threadId: string; topic: string }
>(
  "communication/addChatThreadAsync",
  async ({ threadId, topic }, { getState, rejectWithValue }) => {
    try {
      const { auth, communication } = getState() as RootState;
      const { userId } = auth;
      const currentThreadDetails = communication.chatThreads.threads.find(
        (thread) => thread.id === threadId,
      );
      if (currentThreadDetails) {
        return { ...currentThreadDetails, topic };
      }
      const chatThread = await getChatThreadDetails(threadId, userId);
      return { ...chatThread, topic };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return rejectWithValue(error);
    }
  },
);

export const joinSupportThreadAsync = createAsyncThunk<
  { response: AppApiV1ModelsChatModelsStatusResponse; threadId: string },
  { id: number; threadId: string; participants: ThreadParticipant[] }
>("communication/addParticipantToSupportThread", async ({ id, threadId, participants }) => {
  const response = await joinSupportThread(id, threadId, participants);
  return { response, threadId };
});

export const getChatThreadMessagesAsync = createAsyncThunk<
  NormalizedChatMessages,
  { threadId: string; endpoint: string; token: string; continuationToken?: string },
  { rejectValue: string }
>(
  "communication/getChatThreadMessagesAsync",
  async ({ threadId, endpoint, token, continuationToken }, { dispatch }) => {
    const chatClient = getChatClient({ endpoint, token });
    const chatThreadClient = chatClient.getChatThreadClient(threadId);
    let newContToken;
    const threadClientResponse = await retryIfFailed(
      () =>
        chatThreadClient
          .listMessages({
            maxPageSize: MESSAGES_PAGE_SIZE,
            onResponse: (response) => {
              newContToken = response.parsedBody.nextLink;
            },
          })
          .byPage({ continuationToken })
          .next(),
      3,
      [403],
    );
    const messages = threadClientResponse.value as ChatMessage[];
    const [lastMessage] = messages;
    await chatThreadClient.sendReadReceipt({ chatMessageId: lastMessage.id });

    const { serializedMessages, attachmentsToFetch } = messages.reduce(
      (acc: { serializedMessages: VrsChatMessage[]; attachmentsToFetch: number }, message) => {
        if (message.type === "text") {
          const extendedMsgObj = {
            ...message,
            createdOn: dayjs(message.createdOn).toISOString(),
            editedOn: message.editedOn && dayjs(message.editedOn).toISOString(),
            deletedOn: message.deletedOn && dayjs(message.deletedOn).toISOString(),
          };
          acc.serializedMessages.push(extendedMsgObj);
          if (message.metadata) {
            acc.attachmentsToFetch += 1;
          }
        }
        return acc;
      },
      { serializedMessages: [], attachmentsToFetch: 0 },
    );
    if (attachmentsToFetch > 0) {
      await dispatch(
        fetchAttachmentsForThreadAsync({
          threadId,
          onlyLatest: false,
          limit: attachmentsToFetch,
        }),
      );
    }
    return {
      id: threadId,
      continuationToken: newContToken,
      messages: serializedMessages,
      isLastPage: !!threadClientResponse.done,
    };
  },
);

export const createSupportThreadAsync = createAsyncThunk<
  ChatThreadInfo,
  { patientId: number; userId: number }
>(
  "communication/createSupportThreadAsync",
  async ({ patientId }, { rejectWithValue, getState }) => {
    try {
      const { thread_id: threadId } = await createSupportChat(patientId);
      const { communication } = getState() as RootState;
      const currentThreadDetails = communication.chatThreads.threads.find(
        (thread) => thread.id === threadId,
      );
      if (currentThreadDetails) {
        return currentThreadDetails;
      }
      const response = await getSupportChatDetails(patientId, threadId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateSupportChatThreadAsync = createAsyncThunk<
  SupportChatInfoRequest,
  { patientId: number; threadId: string; status: SupportChatStatus }
>(
  "communication/updateSupportChatThreadAsync",
  async ({ patientId, threadId, status }, { rejectWithValue }) => {
    try {
      const request: SupportChatInfoRequest = {
        support_chat_status: status,
      };
      const response = await updateSupportChat(patientId, threadId, request);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const sendChatThreadMessage = createAsyncThunk(
  "communication/sendChatThreadMessage",
  async (
    { threadId, message }: { threadId: string; message: VrsChatMessageContent },
    { getState },
  ) => {
    const { communication } = getState() as RootState;
    const { chatApiEndpoint, chatUserAccessToken, chatDisplayName } = communication;
    const chatClient = getChatClient({ endpoint: chatApiEndpoint, token: chatUserAccessToken });
    try {
      const chatThreadClient = chatClient.getChatThreadClient(threadId);
      const response = await chatThreadClient.sendMessage(
        {
          content: message.text,
        },
        {
          senderDisplayName: chatDisplayName,
          metadata: message.attachment && { ...message.attachment },
        },
      );
      return response.id;
    } catch (error) {
      // TODO: handle error
      // eslint-disable-next-line no-console
      console.error(error);
      return error;
    }
  },
);

export const uploadAttachmentAsync = createAsyncThunk(
  "communication/uploadAttachmentAsync",
  async (
    {
      file,
    }: {
      file: File;
    },
    { getState, dispatch },
  ) => {
    const { communication, auth } = getState() as RootState;
    const { userId } = auth;
    const { currentThreadId } = communication;
    try {
      const {
        blob_container: containerName,
        original_file_name: originalFileName,
        file_name: fileName,
        file_extension: fileExtension,
        blob_endpoint: blobEndpoint,
      } = await uploadFileApi(Number(userId), String(currentThreadId), file);

      dispatch(
        sendChatThreadMessage({
          threadId: String(currentThreadId),
          message: {
            text: "",
            attachment: {
              attachmentUuid: fileName,
              attachmentName: originalFileName,
              attachmentUrl: `${blobEndpoint}${containerName}/${fileName}`,
              attachmentFileType: fileExtension ?? "unknown",
            },
          },
        }),
      );
      dispatch(
        fetchAttachmentsForThreadAsync({ threadId: String(currentThreadId), onlyLatest: true }),
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  },
);

export const downloadAttachmentSasAsync = createAsyncThunk<
  { objectUrl: string; messageId: string; threadId: string } | undefined,
  { metadata: AttachmentMetadata; messageId: string; threadId: string }
>(
  "communication/downloadAttachmentSasAsync",
  async ({ metadata, messageId, threadId }, { getState, rejectWithValue }) => {
    const { auth } = getState() as RootState;
    const { userId } = auth;
    const { attachmentUuid, attachmentName } = metadata;
    try {
      const { blobEndpoint, containerName, blobName, sharedAccessSigniture } = await generateSas(
        Number(userId),
        attachmentUuid,
        Permissions.Read,
      );

      const blob = await downloadFile({
        blobEndpoint,
        containerName,
        blobName,
        sharedAccessSigniture,
      });

      if (!blob) {
        return undefined;
      }

      downloadBlob(blob, attachmentName);

      return { objectUrl: URL.createObjectURL(blob), messageId, threadId };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return rejectWithValue(error);
    }
  },
);

export const updateThreadAsync = createAsyncThunk(
  "communication/updateThread",
  async ({ userId, threadId }: { userId: string; threadId: string }) => {
    const thread = await getChatThreadDetails(threadId, userId);
    return thread;
  },
);

export const updateSupportChatAsync = createAsyncThunk(
  "communication/updateSupportChat",
  async ({
    patientId,
    status,
    threadId,
    asigneeId,
  }: {
    status: SupportChatStatus;
    threadId: string;
    asigneeId?: number;
    patientId: number;
  }) => {
    const request: SupportChatInfoRequest = {
      support_chat_status: status,
      support_chat_assignee_id: asigneeId,
    };
    const thread = await updateSupportChat(patientId, threadId, request);
    SnackbarUtils.success("Update completed successfully.");
    return thread;
  },
);
