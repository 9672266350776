import React from "react";
import { Box, styled, TextareaAutosize } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { VrsIconButton, IconName } from "@veris-health/web-core";
import { VrsChatUploadMenu } from "../VrsChatUploadMenu";

const ENTER_KEY = "Enter";

export interface VrsChatInputAreaProps {
  targetRecipient?: string;
  onSendMessage: (message: string) => void;
  onUploadFromComputer?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: () => void;
}

const StyledAutoSizeTextArea = styled(TextareaAutosize)(({ theme }) => ({
  ...theme.typography.subtitle2,
  resize: "none",
  width: "99%",
  border: "none",
  outline: "none",
  background: "transparent",
  padding: theme.spacing(1),
}));

const StyledChatActionsBar = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const StyledInputForm = styled("form")(({ theme }) => ({
  background: theme.veris.colors.neutrals["soft-white"],
  padding: theme.spacing(1.5),
  border: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
  borderRadius: theme.spacing(2),
  minHeight: "97px",
  transition: "0.2s all ease-in-out",
  "&:focus-within": {
    borderColor: theme.veris.colors.neutrals["grey-mid"],
  },
}));

const chatValidationSchema = yup.object({
  textMessage: yup.string().min(1).required(),
});

export function VrsChatInputArea({
  targetRecipient,
  onSendMessage,
  onUploadFromComputer,
  onKeyPress,
}: VrsChatInputAreaProps): JSX.Element {
  const formik = useFormik({
    initialValues: {
      textMessage: "",
    },
    validationSchema: chatValidationSchema,
    onSubmit: ({ textMessage }) => {
      if (textMessage.trim() !== "") {
        onSendMessage(textMessage);
        formik.resetForm();
      }
    },
  });

  const handleTextareaKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    onKeyPress();
    if (event.key === ENTER_KEY) {
      formik.submitForm();
      event.preventDefault();
    }
  };

  return (
    <StyledInputForm onSubmit={formik.handleSubmit}>
      <StyledAutoSizeTextArea
        placeholder={targetRecipient ? `Message to ${targetRecipient}` : `Send a message`}
        name="textMessage"
        value={formik.values.textMessage}
        onKeyPress={handleTextareaKeyPress}
        onChange={formik.handleChange}
        minRows={3}
        maxRows={5}
        disabled={false} // {currentPatient?.inactive}
      />
      <StyledChatActionsBar>
        <Box sx={{ display: "flex", gap: (theme) => theme.spacing(1.5) }}>
          <VrsChatUploadMenu
            onUploadFromComputer={onUploadFromComputer}
            disabled={false} // {currentPatient?.inactive}
          />
        </Box>
        <VrsIconButton
          iconProps={
            formik.errors.textMessage || !formik.dirty
              ? { name: IconName.DisabledSend }
              : { name: IconName.SendIcon }
          }
          disabled={!!formik.errors.textMessage || !formik.dirty || false} // currentPatient?.inactive}
          type="submit"
          sx={{ height: "33px" }}
        />
      </StyledChatActionsBar>
    </StyledInputForm>
  );
}
