import React, { useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { IconName, VrsAlert, VrsIconButton } from "@veris-health/web-core";
import { topBarMenuItems } from "../../constants";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import Login from "./Login";
import {
  clearAuthError,
  clearValidationError,
  selectAuthError,
  selectOtpScreen,
  setOTPScreen,
} from "../shared/slices/authSlice";
import { StyledActiveNavigationItem, StyledNavigationItem } from "../shared/styledComponents";

const LoginContainer = (): JSX.Element => {
  const loginError = useAppSelector(selectAuthError);
  const otpScreen = useAppSelector(selectOtpScreen);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState<number>(0);

  const onTabClick = (index: number) => {
    setActiveTab(index);
  };

  const renderLogic = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        return <Login />;
      case 1:
        return <> </>;
      default:
        return <Login />;
    }
  };

  return (
    <>
      {loginError && (
        <Grid item xs={11} container spacing={4} style={{ margin: "0 auto" }}>
          <Grid item xs={12}>
            <VrsAlert message={loginError} onClose={() => dispatch(clearAuthError())} />
          </Grid>
        </Grid>
      )}
      {otpScreen && (
        <Box
          display="flex"
          alignItems="center"
          m={5}
          onClick={() => {
            dispatch(setOTPScreen(false));
            dispatch(clearValidationError());
          }}
          sx={{ cursor: "pointer" }}
        >
          <VrsIconButton
            iconProps={{
              name: IconName.ArrowLeft,
              stroke: theme.veris.colors.neutrals.black,
              size: 12,
            }}
          />
          <Typography variant="subtitle2" color={({ veris }) => veris.colors.neutrals["grey-4"]}>
            Back
          </Typography>
        </Box>
      )}
      <Box>
        <Box m={5} display="flex">
          {otpScreen ? (
            <StyledActiveNavigationItem variant="h3" m={2} key="OTP">
              Enter the 4-digit OTP
            </StyledActiveNavigationItem>
          ) : (
            <>
              {topBarMenuItems["/staff-login"] &&
                topBarMenuItems["/staff-login"].map((menuTitle, index) => {
                  return (
                    <Box key={menuTitle}>
                      {activeTab === index ? (
                        <StyledActiveNavigationItem variant="h3" m={2} key={menuTitle}>
                          {menuTitle}
                        </StyledActiveNavigationItem>
                      ) : (
                        <StyledNavigationItem
                          m={2}
                          variant="h3"
                          key={menuTitle}
                          onClick={() => {
                            onTabClick(index);
                          }}
                        >
                          {menuTitle}
                        </StyledNavigationItem>
                      )}
                    </Box>
                  );
                })}
            </>
          )}
        </Box>
        <Box>{renderLogic(activeTab)}</Box>
      </Box>
    </>
  );
};

export default LoginContainer;
