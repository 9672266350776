import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Lang } from "@veris-health/virtual-doc-ms/lib/v1";
import {
  ArticleCategoryGroup,
  SearchEducationalArticles,
} from "@veris-health/virtual-doc-ms/lib/v1/api";
import { RootState } from "../../store";
import { extractErrorMessage } from "../shared/helpers";
import { Status } from "../shared/interfaces";
import { localizedLogout, logout } from "../shared/slices/authSlice";
import { searchArticles, deleteArticle, getCategories } from "./api/articlesApi";
import SnackbarUtils from "../../utils/SnackbarUtils";

export const searchArticlesAsync = createAsyncThunk<
  SearchEducationalArticles,
  { searchTerm?: string; category?: ArticleCategoryGroup; contentLanguage?: Lang }
>(
  "articles/searchArticle",
  async (
    {
      searchTerm,
      category,
      contentLanguage,
    }: { searchTerm?: string; category?: ArticleCategoryGroup; contentLanguage?: Lang },
    { rejectWithValue },
  ) => {
    try {
      const response = await searchArticles(searchTerm, category, contentLanguage);
      return response;
    } catch (error) {
      const errorMsg = extractErrorMessage(error);
      return rejectWithValue(errorMsg || "Could not fetch educational article");
    }
  },
);

export const getCategoriesAsync = createAsyncThunk("articles/categories", async () => {
  const categories = await getCategories();
  return categories;
});

export const deleteArticleAsync = createAsyncThunk<number, number>(
  "articles/deleteArticle",
  async (metaArticleId, { rejectWithValue }) => {
    try {
      await deleteArticle(metaArticleId);
      return metaArticleId;
    } catch (error) {
      const errorMsg = extractErrorMessage(error);
      return rejectWithValue(errorMsg || "Could not delete article");
    }
  },
);

interface ArticlesState {
  articles: {
    data: SearchEducationalArticles;
    status: Status;
  };
  categories: {
    data: ArticleCategoryGroup[];
    status: Status;
  };
}

const initialState: ArticlesState = {
  articles: {
    data: {} as SearchEducationalArticles,
    status: "idle",
  },
  categories: {
    data: [],
    status: "idle",
  },
};

const articlesSlice = createSlice({
  name: "Articles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(localizedLogout, () => {
        return initialState;
      })
      .addCase(searchArticlesAsync.pending, (state) => {
        state.articles.status = "loading";
      })
      .addCase(searchArticlesAsync.fulfilled, (state, { payload }) => {
        state.articles.data = payload;
        state.articles.status = "idle";
      })
      .addCase(searchArticlesAsync.rejected, (state) => {
        state.articles.status = "failed";
      })

      .addCase(deleteArticleAsync.fulfilled, (state, { payload }) => {
        SnackbarUtils.success("Educational Article deleted!");
        state.articles.data.items = state.articles.data.items.filter(
          (article) => article.meta_article_id !== payload,
        );
        state.articles.status = "idle";
      })
      .addCase(getCategoriesAsync.pending, (state) => {
        state.categories.status = "loading";
      })
      .addCase(getCategoriesAsync.fulfilled, (state, { payload }) => {
        state.categories.status = "idle";
        state.categories.data = payload.items;
      })
      .addCase(getCategoriesAsync.rejected, (state) => {
        state.categories.status = "failed";
      });
  },
});

export const selectArticles = ({ articles }: RootState): SearchEducationalArticles => {
  return articles.articles.data;
};
export const selectCategories = ({ articles }: RootState): ArticleCategoryGroup[] => {
  return articles.categories.data;
};

export const selectArticlesStatus = ({ articles }: RootState): Status => articles.articles.status;

export default articlesSlice.reducer;
