import { Box, Link, Typography, useTheme } from "@mui/material";
import React from "react";
import { VrsSystemCard } from "../VrsSystemCard";

export function SecretsExpirationReminder(): JSX.Element {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        mb="4rem"
        py="0.5rem"
        borderBottom={`1px solid ${theme.veris.colors.neutrals["grey-light"]}`}
      >
        <VrsSystemCard />
      </Box>
      <Box paddingLeft="0.5rem">
        <Typography mb="1rem" component="h3" variant="h6Semibold">
          Password Expiration Reminder
        </Typography>
        <Typography component="p" variant="subtitle2">
          Your password will expire in 10 days. You can change it in the&nbsp;
          <Link href="/profile" variant="body">
            profile
          </Link>
          &nbsp; section
        </Typography>
      </Box>
    </Box>
  );
}
