import { CreateEducationalArticle, Lang } from "@veris-health/virtual-doc-ms/lib/v1";
import {
  ArticleCategoryGroup,
  GetEducationalArticleCategories,
  GetEducationalArticleTranslations,
  SearchEducationalArticles,
} from "@veris-health/virtual-doc-ms/lib/v1/api";
import { articlesApiV1 } from "../../../api";

export const searchArticles = async (
  searchTerm?: string,
  category?: ArticleCategoryGroup,
  contentLanguage: Lang = Lang.En,
): Promise<SearchEducationalArticles> => {
  const response = await articlesApiV1.searchEducationalArticles(
    searchTerm === "" ? undefined : searchTerm,
    category ? [category] : undefined,
    [contentLanguage],
    false,
    false,
  );
  return response.data;
};

export const getTranslationOfArticle = async (
  metaArticleId: number,
): Promise<GetEducationalArticleTranslations> => {
  const response = await articlesApiV1.getEducationalArticleTranslations(metaArticleId);
  return response.data;
};

export const addArticle = async (
  articles: CreateEducationalArticle[],
): Promise<GetEducationalArticleTranslations> => {
  const response = await articlesApiV1.uploadEducationalArticle(articles);
  return response.data;
};

export const updateArticle = async (
  articleId: number,
  article: CreateEducationalArticle,
): Promise<CreateEducationalArticle> => {
  const response = await articlesApiV1.updateEducationalArticle(articleId, article);
  return response.data;
};

export const deleteArticle = async (metaArticleId: number): Promise<void> => {
  const response = await articlesApiV1.deleteEducationalArticleTranslations(metaArticleId);
  return response.data;
};

export const getCategories = async (): Promise<GetEducationalArticleCategories> => {
  const response = await articlesApiV1.getEducationalArticleCategories();
  return response.data;
};
