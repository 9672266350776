import { Box, CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import {
  AdminDetailUserInfoResponse,
  AdminUpdateMedStaffInfo,
  MedRoleEnum,
  UserRoleTypeProfile,
  ProfileRole,
} from "@veris-health/user-ms/lib/v1";
import * as yup from "yup";
import { FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import {
  VrsButton,
  VrsErrorMsg,
  VrsSelect,
  VrsTooltip,
  dateFormats,
  verisColors,
} from "@veris-health/web-core";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { VrsFormInputField } from "../../../ui/components/VrsFormInputField";
import { updateMedStaffData } from "../api/usersApi";
import { selectUsersStatus } from "../userSlice";
import { useRoles } from "../../../context/roles";
import { useSuperAdmin } from "../../../context/profile";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { getInternationalPhoneFormat } from "../helpers/utils";
import { extractErrorMessage } from "../../shared/helpers";
import { utcToLocal } from "../../../utils/date";

interface FieldType {
  name: string;
  label: string;
  value: string | string[] | boolean;
  helperText?: string;
  isValidField?: boolean;
  touched?: boolean;
  editable?: boolean;
  error?: boolean;
  required?: boolean;
}

interface FieldComponentProps {
  field: FieldType;
  editMode: boolean;
  handleBlur: (e: React.FocusEvent<unknown, Element>) => void;
  handleChange: (e: string | React.ChangeEvent<Element>) => void;
  setFieldValue: (field: string, value: string[]) => void;
  medStaffRoles: ProfileRole[];
}

const FieldComponent = ({
  field,
  editMode,
  handleBlur,
  handleChange,
  setFieldValue,
  medStaffRoles,
}: FieldComponentProps) => {
  const theme = useTheme();

  if (typeof field.value === "object" && !editMode) {
    return (
      <>
        <Grid container>
          <Grid item xs={3}>
            <UserInfoLabel fieldName={field.label} />
          </Grid>
          <Grid item xs={6} display="flex" flexWrap="wrap">
            {field.value.length > 0 ? (
              <>
                {field.name === "Hospital" &&
                  field.value.map((item) => (
                    <UserInfoValue fieldValue={item || "N/A"} key={item} />
                  ))}
                {field.name === "roles" &&
                  field.value.map((role) => (
                    <UserInfoValue fieldValue={role || "N/A"} key={role} />
                  ))}
              </>
            ) : (
              <UserInfoValue fieldValue="N/A" />
            )}
          </Grid>
        </Grid>
      </>
    );
  }

  return field.editable && editMode ? (
    <Grid container>
      <Grid item xs={3} sx={{ alignSelf: "center" }}>
        <UserInfoLabel fieldName={field.label || field.name} required={field.required} />
      </Grid>
      <Grid item xs={6}>
        {field.name === "roles" ? (
          <VrsSelect
            onBlur={handleBlur}
            name="role"
            placeholder="Choose Staff Role"
            options={medStaffRoles.map((value) => {
              return {
                label: value?.role_name,
                value: value?.role_name,
              };
            })}
            value={field.value as string}
            onSelected={(value) => setFieldValue("roles", [value])}
            innerSx={{ padding: theme.spacing(0.75, 0.75, 0.75, 0) }}
            maxHeight="250px"
          />
        ) : (
          <VrsFormInputField
            name={field.name}
            value={field.value as string}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!field.error}
            isValidField={!!field.isValidField}
            helperText={field.helperText}
          />
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid container>
      <Grid item xs={3} sx={{ alignSelf: "center" }}>
        <UserInfoLabel fieldName={field.label} />
      </Grid>
      <Grid item xs={6}>
        <UserInfoValue fieldValue={(field.value as string) || "N/A"} />
      </Grid>
    </Grid>
  );
};

const validationSchema = yup.object({
  firstName: yup.string().required("First Name is required.").min(1),
  lastName: yup.string().required("Last Name is required.").min(1),
  profession: yup.string(),
  roles: yup.array().of(yup.string()).required().min(1, "Roles are required."),
  npi: yup
    .string()
    .when("roles", {
      is: (roles: string[]) => roles.includes(MedRoleEnum.Doctor),
      then: yup.string().required("NPI is required"),
      otherwise: yup.string().notRequired(),
    })
    .matches(/^[+]?\d+$/, "Should contain only digits"),
});

function UserInfoLabel({
  fieldName,
  required,
}: {
  fieldName: string;
  required?: boolean;
}): JSX.Element {
  return (
    <Box display="flex">
      <Typography
        variant="body"
        color={(theme) => theme.veris.colors.neutrals["grey-3"]}
        pr={2}
        pl={1}
      >
        {fieldName}
        {required && "*"}
      </Typography>
    </Box>
  );
}

function UserInfoValue({ fieldValue }: { fieldValue: string | boolean | undefined }): JSX.Element {
  return (
    <Box display="flex">
      <Typography
        variant="body"
        pr={2}
        pl={1}
        sx={{
          color: (theme) => theme.veris.colors.neutrals["grey-4"],
          padding: (theme) => theme.spacing(0.25, 1, 0, 1),
        }}
      >
        {String(fieldValue)}
      </Typography>
    </Box>
  );
}

const MedStaffUserDemographics = ({
  user,
}: {
  user: AdminDetailUserInfoResponse | undefined;
}): JSX.Element => {
  const userStatus = useAppSelector(selectUsersStatus);
  const handleDateValue = (fieldValue?: string) =>
    fieldValue === undefined || !fieldValue
      ? "N/A"
      : utcToLocal(fieldValue).format(`${dateFormats["MMM DD, YYYY"]}, ${dateFormats["hh:mm"]} A`);
  const [editMode, setEditMode] = useState<boolean>(false);
  const isSuperAdmin = useSuperAdmin();
  const medStaffRoles = useRoles(UserRoleTypeProfile.MedStaff);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.first_name || "",
      lastName: user?.last_name || "",
      profession: user?.profession || "",
      phoneNumber: (user?.phone_number && getInternationalPhoneFormat(user?.phone_number)) || "",
      email: user?.email || "",
      type: user?.type || "",
      hospital: user?.hospital?.map((hospital) => hospital.name) || [],
      dateChanged: user?.date_changed || "",
      dateRegistered: user?.date_registered || "",
      dateCreated: user?.date_created || "",
      active: user?.active || "",
      lockStatus: user?.lock_status === "active" ? "not locked" : user?.lock_status || "",
      npi: user?.npi || "",
      roles: user?.user_roles?.map((role) => role.role_name) || [],
    },
    validationSchema,
    onSubmit: (values) => {
      const formattedValues: AdminUpdateMedStaffInfo = {
        first_name: values.firstName,
        last_name: values.lastName,
        profession: values.profession ? values.profession : undefined,
        npi: values.npi ? values.npi : undefined,
        roles: values.roles as MedRoleEnum[],
      };
      if (user?.id)
        updateMedStaffData(user.id, formattedValues)
          .then(() => {
            setEditMode(false);
            SnackbarUtils.success("Med Staff details sucessfully updated.");
          })
          .catch((e) => {
            const message = extractErrorMessage(e);
            SnackbarUtils.error(message || "Med Staff details editing failed, Please try again");
          });
    },
  });

  const { errors, handleSubmit, handleChange, handleBlur, touched, setFieldValue } = formik;
  const {
    phoneNumber,
    profession,
    email,
    firstName,
    lastName,
    type,
    hospital,
    dateChanged,
    dateRegistered,
    dateCreated,
    active,
    lockStatus,
    npi,
    roles,
  } = formik.values;

  const fields: FieldType[] = [
    {
      name: "firstName",
      label: "First Name",
      value: firstName,
      helperText: touched.firstName && errors.firstName ? errors.firstName : " ",
      isValidField: !!firstName.length && !errors.firstName,
      touched: touched.firstName,
      editable: true,
      required: true,
    },
    {
      name: "lastName",
      label: "Last Name",
      value: lastName,
      helperText: touched.lastName && errors.lastName ? errors.lastName : " ",
      isValidField: !!lastName.length && !errors.lastName,
      touched: touched.lastName,
      editable: true,
      required: true,
    },
    {
      name: "profession",
      label: "Profession",
      value: profession,
      helperText: touched.profession && errors.profession ? errors.profession : " ",
      error: !!touched.profession && Boolean(errors.profession),
      isValidField: !!profession.length && !errors.profession,
      touched: touched.profession,
      editable: true,
    },
    {
      name: "npi",
      label: "NPI",
      value: npi,
      helperText: touched.npi && errors.npi ? errors.npi : " ",
      error: !!touched.npi && Boolean(errors.npi),
      isValidField: !!npi.length && !errors.npi,
      touched: touched.npi,
      editable: true,
      required: formik.values.roles.includes(MedRoleEnum.Doctor),
    },
    {
      name: "roles",
      label: "Role",
      value: roles,
      helperText: (errors.roles as string) ? (errors.roles as string) : " ",
      error: Boolean(errors.roles),
      isValidField: !!roles.length && !errors.roles,
      touched: touched.roles,
      editable: true,
      required: true,
    },

    { name: "Type", label: "Type", value: type || "N/A" },
    { name: "Phone Number", label: "Phone Number", value: phoneNumber || "N/A" },
    { name: "Hospital", label: "Hospital", value: hospital },
    { name: "Email", label: "Email", value: email || "N/A" },
    { name: "Registered", label: "Registered", value: active ? "yes" : "no" },
    { name: "Lock status", label: "Lock status", value: lockStatus || "N/A" },
    {
      name: "Date Registered",
      label: "Date Registered",
      value: handleDateValue(dateRegistered),
    },
    {
      name: "Date Created",
      label: "Date Created",
      value: handleDateValue(dateCreated),
    },
    {
      name: "Date Changed",
      label: "Date Changed",
      value: handleDateValue(dateChanged),
    },
  ];

  return (
    <>
      <Box
        px={1}
        py={2}
        sx={{
          backgroundColor: (theme) => theme.veris.colors.neutrals.white,
          border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
          borderRadius: (theme) => theme.spacing(1),
        }}
      >
        {userStatus === "loading" && (
          <Box height="20vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {userStatus === "idle" && (
          <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h3" p={1} mb={2}>
                  {firstName} {lastName}
                </Typography>
                {isSuperAdmin && (
                  <Grid item xs={2} pr={2}>
                    {editMode ? (
                      <Box display="flex" justifyContent="flex-end" gap={2}>
                        <VrsButton
                          buttonType="secondary"
                          onClick={() => {
                            setEditMode(false);
                            formik.resetForm();
                          }}
                        >
                          Cancel
                        </VrsButton>
                        <VrsButton buttonType="primary" type="submit">
                          Save
                        </VrsButton>
                      </Box>
                    ) : (
                      <Box justifyContent="flex-end" display="flex">
                        {user?.active ? (
                          <VrsButton buttonType="secondary" onClick={() => setEditMode(true)}>
                            Edit Details
                          </VrsButton>
                        ) : (
                          <VrsTooltip
                            bcgcolor={verisColors.neutrals.black}
                            title="You can't edit disabled users"
                          >
                            <span>
                              <VrsButton
                                buttonType="secondary"
                                disabled
                                onClick={() => setEditMode(true)}
                              >
                                Edit Details
                              </VrsButton>
                            </span>
                          </VrsTooltip>
                        )}
                      </Box>
                    )}
                  </Grid>
                )}
              </Box>

              <Grid container spacing={2}>
                {fields.map((field) => (
                  <Grid key={field.name} display="flex" alignItems="baseline" py={2} item xs={6}>
                    <FieldComponent
                      field={field}
                      editMode={editMode}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      medStaffRoles={medStaffRoles}
                    />
                  </Grid>
                ))}
              </Grid>
            </form>
          </FormikProvider>
        )}
        {userStatus === "failed" && <VrsErrorMsg size="small" />}
      </Box>
    </>
  );
};

export default MedStaffUserDemographics;
