import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  Stack,
  Table,
  Typography,
  useTheme,
} from "@mui/material";
import {
  AccountStatus,
  AdminDetailUserInfoResponse,
  LimitOffsetPageAdminDetailUserInfoResponse,
  ProfileInvitationStatus,
  UserTypeResponse,
} from "@veris-health/user-ms/lib/v1";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  IconName,
  VrsConfirmationModals,
  VrsIconButton,
  VrsTooltip,
  dateFormats,
} from "@veris-health/web-core";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../ui/components/Tables/shared/StyledTableElements";
import {
  Column,
  TableColumnNames,
  VrsSortedTableHeader,
} from "../../../ui/components/VrsSortedTableHeader";
import { Order } from "../../../utils/sorting";
import { Status } from "../../shared/interfaces";
import { selectUserId } from "../../shared/slices/authSlice";
import { calculateDate } from "../../../utils/date";
import { getInternationalPhoneFormat } from "../helpers/utils";
import { formattedAccountStatus, formattedUserType } from "../../../constants";

export interface UsersTableProps {
  usersData: LimitOffsetPageAdminDetailUserInfoResponse;
  userStatus?: Status;
  unblockUser: (userId: number) => void;
  deleteUser: (userId: number) => void;
  disableUser: (userId: number) => void;
  fetchMore: () => void;
  onUserClick: (userId: number) => void;
  setSortingFilter?: (filter: string) => void;
  sortingFilter?: string;
  resendInvite: (userId: number) => void;
}

const columns: Column[] = [
  {
    id: TableColumnNames.Name,
    label: TableColumnNames.Name,
    orderBy: "name",
  },
  {
    id: TableColumnNames.UserType,
    label: TableColumnNames.UserType,
    orderBy: "type",
  },
  {
    id: TableColumnNames.AccountStatus,
    label: TableColumnNames.AccountStatus,
  },
  {
    id: TableColumnNames.LockStatus,
    label: TableColumnNames.LockStatus,
  },
  {
    id: TableColumnNames.Email,
    label: TableColumnNames.Email,
    orderBy: "email",
  },
  {
    id: TableColumnNames.PhoneNumber,
    label: TableColumnNames.PhoneNumber,
  },
  {
    id: TableColumnNames.SerialNumber,
    label: TableColumnNames.SerialNumber,
  },
  {
    id: TableColumnNames.Roles,
    label: TableColumnNames.Roles,
  },
  {
    id: TableColumnNames.Hospitals,
    label: TableColumnNames.Hospitals,
  },
  {
    id: TableColumnNames.InvitationExpiration,
    label: TableColumnNames.InvitationExpiration,
  },
  {
    id: TableColumnNames.Actions,
    label: TableColumnNames.Actions,
  },
];

const getExpirationStatus = (user: AdminDetailUserInfoResponse): string => {
  switch (user.invitation_status) {
    case ProfileInvitationStatus.Expired: {
      return `Expired at: ${
        user.invitation_expiration
          ? dayjs(user.invitation_expiration).format(dateFormats["MM/DD/YYYY"])
          : "N/A"
      }`;
    }
    case ProfileInvitationStatus.Pending: {
      return `Expires ${
        user.invitation_expiration ? calculateDate(user.invitation_expiration) : ", N/A"
      }`;
    }
    case ProfileInvitationStatus.Verified: {
      return "Verified";
    }
    default: {
      return "N/A";
    }
  }
};

const ActionsMenu = ({
  user,
  unblockUser,
  deleteUser,
  disableUser,
  resendInvite,
}: {
  user: AdminDetailUserInfoResponse;
  unblockUser: (userId: number) => void;
  deleteUser: (userId: number) => void;
  disableUser: (userId: number) => void;
  resendInvite: (userId: number) => void;
}) => {
  const anchorRef = useRef(null);
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [action, setAction] = useState<"delete" | "disable">();
  const [confimationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const loggedUserId = useAppSelector(selectUserId);

  const showDeleteOption =
    [UserTypeResponse.SupportMember, UserTypeResponse.Patient].includes(user.type) ||
    user.account_status === AccountStatus.NotRegistered;
  const showDisableOption = [
    UserTypeResponse.TechStaff,
    UserTypeResponse.MedStaff,
    UserTypeResponse.ProcedureStaff,
  ].includes(user.type);

  return (
    <Stack direction="row" ref={anchorRef}>
      {confimationModalOpen && (
        <VrsConfirmationModals
          isOpen={confimationModalOpen}
          handleClose={() => setConfirmationModalOpen(false)}
          dialogHeader="Warning!"
          dialogContent={
            action === "delete"
              ? `You will delete user ${user.name}.`
              : `You will disable user ${user.name}.`
          }
          dialogSecondContentLine="Please confirm."
          onCancel={() => setConfirmationModalOpen(false)}
          onConfirm={() => {
            if (action === "delete") {
              deleteUser(user.id);
            } else {
              disableUser(user.id);
            }
            setConfirmationModalOpen(false);
          }}
          cancelButtonText="Cancel"
          confirmButtonText="Continue"
        />
      )}

      <VrsIconButton
        iconProps={{ name: open ? IconName.ActiveDots : IconName.Dots }}
        onClick={() => setOpen(true)}
        disabled={Number(loggedUserId) === user.id}
      />
      {Number(loggedUserId) !== user.id && (
        <Menu anchorEl={anchorRef.current} open={open} onClose={() => setOpen(false)}>
          {showDeleteOption && (
            <MenuItem
              onClick={() => {
                setConfirmationModalOpen(true);
                setOpen(false);
                setAction("delete");
              }}
            >
              <Typography variant="body" color={theme.veris.colors.neutrals["grey-4"]}>
                Delete
              </Typography>
            </MenuItem>
          )}

          {showDisableOption && (
            <MenuItem
              onClick={() => {
                setConfirmationModalOpen(true);
                setOpen(false);
                setAction("disable");
              }}
              disabled={!user.active}
            >
              <Typography variant="body" color={theme.veris.colors.neutrals["grey-4"]}>
                Disable
              </Typography>
            </MenuItem>
          )}
          {user.lock_status !== "active" && (
            <MenuItem
              onClick={() => {
                unblockUser(user.id);
                setOpen(false);
              }}
            >
              <Typography variant="body" color={theme.veris.colors.neutrals["grey-4"]}>
                Unlock
              </Typography>
            </MenuItem>
          )}
          {[
            UserTypeResponse.TechStaff,
            UserTypeResponse.MedStaff,
            UserTypeResponse.ProcedureStaff,
          ].includes(user.type) &&
            !user.active && (
              <MenuItem
                onClick={() => {
                  resendInvite(user.id);
                  setOpen(false);
                }}
              >
                <Typography variant="body" color={theme.veris.colors.neutrals["grey-4"]}>
                  Resend Invite
                </Typography>
              </MenuItem>
            )}
        </Menu>
      )}
    </Stack>
  );
};

const Roles = ({ user }: { user: AdminDetailUserInfoResponse }) => {
  const theme = useTheme();
  return (
    <>
      {user.user_roles && user.user_roles?.length > 1 ? (
        <VrsTooltip
          title={
            <Typography variant="description" sx={{ textTransform: "capitalize" }}>
              {user?.user_roles
                .map((role) => role.role_name)
                .splice(1, user.user_roles.length)
                ?.join(", ")}
            </Typography>
          }
          bcgcolor={theme.veris.colors.neutrals.black}
        >
          <Typography
            variant="subtitle2"
            color={theme.veris.colors.neutrals["grey-4"]}
            sx={{ cursor: "pointer" }}
          >
            {user.user_roles ? user.user_roles[0]?.role_name : "N/A"}
            <Typography
              display="block"
              variant="description"
              color={theme.veris.colors.neutrals["grey-mid"]}
              sx={{ cursor: "pointer" }}
            >
              {user.hospital &&
                user.user_roles.length > 1 &&
                `+ ${user.user_roles.length - 1} more`}
            </Typography>
          </Typography>
        </VrsTooltip>
      ) : (
        <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-4"]}>
          {user.user_roles && user.user_roles?.length > 0 ? user.user_roles[0]?.role_name : "N/A"}
        </Typography>
      )}
    </>
  );
};

const Hospitals = ({ user }: { user: AdminDetailUserInfoResponse }) => {
  const theme = useTheme();
  return (
    <>
      {user.hospital && user.hospital?.length > 1 ? (
        <VrsTooltip
          title={
            <Typography variant="description" sx={{ textTransform: "capitalize" }}>
              {user?.hospital
                .map((hospital) => hospital.name)
                .splice(1, user.hospital.length)
                ?.join(", ")}
            </Typography>
          }
          bcgcolor={theme.veris.colors.neutrals.black}
        >
          <Typography
            variant="subtitle2"
            color={theme.veris.colors.neutrals["grey-4"]}
            sx={{ cursor: "pointer" }}
          >
            {user.hospital ? user.hospital[0]?.name : "N/A"}
            <Typography
              display="block"
              variant="description"
              color={theme.veris.colors.neutrals["grey-mid"]}
              sx={{ cursor: "pointer" }}
            >
              {user.hospital && user.hospital.length > 1 && `+ ${user.hospital.length - 1} more`}
            </Typography>
          </Typography>
        </VrsTooltip>
      ) : (
        <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-4"]}>
          {user.hospital && user.hospital?.length > 0 ? user.hospital[0]?.name : "N/A"}
        </Typography>
      )}
    </>
  );
};

const StyledTableColumn = ({
  column,
  user,
  unblockUser,
  deleteUser,
  onUserClick,
  resendInvite,
  disableUser,
}: {
  column: Column;
  user: AdminDetailUserInfoResponse;
  unblockUser: (userId: number) => void;
  deleteUser: (userId: number) => void;
  disableUser: (userId: number) => void;
  resendInvite: (userId: number) => void;
  onUserClick: (userId: number) => void;
}) => {
  const theme = useTheme();
  return (
    <StyledTableCell key={column.id}>
      {column.id === TableColumnNames.Name && (
        <Box
          onClick={() => onUserClick(user.id)}
          sx={{ cursor: "pointer", wordBreak: "break-word" }}
        >
          <Typography variant="subtitle1" color={theme.veris.colors.neutrals["grey-4"]}>
            {user.name || "N/A"}
            {user.test_user && (
              <sub
                style={{
                  color: theme.veris.colors.amethyst.normal,
                  fontSize: "12px",
                  marginLeft: "2px",
                }}
              >
                Test
              </sub>
            )}
          </Typography>
          {user.profession && (
            <Typography
              variant="subtitle2"
              color={theme.veris.colors.neutrals["grey-3"]}
              sx={{ textTransform: "capitalize" }}
            >
              {user.profession || "N/A"}
            </Typography>
          )}
          {user.patient_status && (
            <Typography
              variant="subtitle2"
              color={theme.veris.colors.neutrals["grey-3"]}
              sx={{ textTransform: "capitalize" }}
            >
              {user.patient_status}
            </Typography>
          )}
        </Box>
      )}
      {column.id === TableColumnNames.Email && (
        <Typography
          variant="subtitle2"
          color={theme.veris.colors.neutrals["grey-4"]}
          sx={{ wordBreak: "break-word" }}
        >
          {user.email || "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.AccountStatus && (
        <Typography variant="subtitle1" color={theme.veris.colors.neutrals["grey-4"]}>
          {formattedAccountStatus[user.account_status || "N/A"]}
        </Typography>
      )}

      {column.id === TableColumnNames.UserType && (
        <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-4"]}>
          {formattedUserType[user.type]}
        </Typography>
      )}
      {column.id === TableColumnNames.PhoneNumber && (
        <Typography
          sx={{ wordBreak: "break-word" }}
          variant="subtitle2"
          color={theme.veris.colors.neutrals["grey-4"]}
        >
          {(user.phone_number && getInternationalPhoneFormat(user.phone_number)) || "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.Roles && <Roles user={user} />}
      {column.id === TableColumnNames.SerialNumber && (
        <Typography
          variant="subtitle2"
          color={theme.veris.colors.neutrals["grey-4"]}
          sx={{ wordBreak: "break-word" }}
        >
          {user.mio_hub_serial_number || "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.LockStatus && (
        <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-4"]}>
          {user.lock_status === "active" ? "not locked" : user.lock_status || "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.Hospitals && <Hospitals user={user} />}
      {column.id === TableColumnNames.InvitationExpiration && (
        <Typography
          sx={{ wordBreak: "break-word" }}
          variant="subtitle2"
          color={theme.veris.colors.neutrals["grey-4"]}
          id={(user.invitation_status ?? "") + user.id}
        >
          {getExpirationStatus(user)}
        </Typography>
      )}
      {column.id === TableColumnNames.Actions && (
        <ActionsMenu
          user={user}
          unblockUser={unblockUser}
          deleteUser={deleteUser}
          disableUser={disableUser}
          resendInvite={resendInvite}
        />
      )}
    </StyledTableCell>
  );
};

export const UsersTable = React.forwardRef<HTMLTableElement, UsersTableProps>(
  (
    {
      usersData,
      userStatus,
      unblockUser,
      fetchMore,
      deleteUser,
      disableUser,
      setSortingFilter,
      sortingFilter,
      onUserClick,
      resendInvite,
    }: UsersTableProps,
    ref,
  ) => {
    const scrollParentRef = useRef<HTMLElement>(null);
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState("");

    useEffect(() => {
      if (setSortingFilter && orderBy) setSortingFilter(`${orderBy}__${order}`);
    }, [order, orderBy]);

    useEffect(() => {
      if (sortingFilter === "") {
        setOrder("asc");
        setOrderBy("");
      }
    }, [sortingFilter]);

    return (
      <>
        {usersData.items.length > 0 ? (
          <Box
            id="users-table-scroll-container"
            ref={scrollParentRef}
            sx={{
              backgroundColor: (theme) => theme.veris.colors.neutrals.white,
              border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
              borderRadius: (theme) => theme.shape.borderRadius,
            }}
          >
            <InfiniteScroll
              scrollableTarget="users-table-scroll-container"
              hasMore={usersData.total - usersData.items.length > 0}
              style={{ borderRadius: 15, maxHeight: "73vh" }}
              height="73vh"
              dataLength={usersData?.items?.length}
              next={() => {
                fetchMore();
              }}
              loader={
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={1}
                  borderTop={(theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`}
                >
                  <CircularProgress />
                </Box>
              }
            >
              <Table ref={ref}>
                <VrsSortedTableHeader
                  columns={columns}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  order={order}
                  orderBy={orderBy}
                />
                <StyledTableBody>
                  {usersData.items.map((user) => (
                    <StyledTableRow key={user.id}>
                      {columns.map((column) => (
                        <StyledTableColumn
                          column={column}
                          user={user}
                          key={column.id}
                          unblockUser={unblockUser}
                          deleteUser={deleteUser}
                          disableUser={disableUser}
                          onUserClick={onUserClick}
                          resendInvite={resendInvite}
                        />
                      ))}
                    </StyledTableRow>
                  ))}
                </StyledTableBody>
              </Table>
            </InfiniteScroll>
          </Box>
        ) : (
          <>
            {userStatus === "idle" && usersData.items.length === 0 && (
              <Typography
                color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                textAlign="center"
                variant="body"
              >
                There are no users at this moment.
              </Typography>
            )}
          </>
        )}
      </>
    );
  },
);
