import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import sharedReducer from "./features/shared/slices/sharedSlice";
import authReducer from "./features/shared/slices/authSlice";
import uiReducer from "./features/shared/slices/uiSlice";
import userReducer from "./features/Users/userSlice";
import billingReducer from "./features/Billing/billingSlice";
import communicationReducer from "./features/Communication/store/communicationSlice";
import notificationsReducer from "./features/Notifications/notificationsSlice";
import educationalVideosReducer from "./features/EducationalArea/educationalVideosSlice";
import articlesReducer from "./features/EducationalArea/articlesSlice";
import surveys from "./features/Surveys/surveysSlice";
import { socketMiddleware } from "./middleware/socketMiddleware";
import { snackbarMiddleware } from "./middleware/snackbarMiddleware";
import { crashReporterMiddleware } from "./middleware/crashReporterMiddleware";

export const store = configureStore({
  reducer: {
    shared: sharedReducer,
    auth: authReducer,
    users: userReducer,
    ui: uiReducer,
    communication: communicationReducer,
    notifications: notificationsReducer,
    educationalVideos: educationalVideosReducer,
    billing: billingReducer,
    articles: articlesReducer,
    surveys,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([
      socketMiddleware,
      snackbarMiddleware,
      crashReporterMiddleware,
    ]);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
