export const enum TableColumnNames {
  HubSn = "Hub Sn",
  SetAt = "Set At",
  UnsetAt = "Unset At",
}

export interface HubColumn {
  id: TableColumnNames;
  label: TableColumnNames;
  orderBy?: string;
  description?: string;
}
export const columns: HubColumn[] = [
  {
    id: TableColumnNames.HubSn,
    label: TableColumnNames.HubSn,
  },
  {
    id: TableColumnNames.SetAt,
    label: TableColumnNames.SetAt,
  },
  {
    id: TableColumnNames.UnsetAt,
    label: TableColumnNames.UnsetAt,
  },
];
